import React from 'react';
import { Page, PageElement, PageElementType } from '../../models/EditorModels';
import EditorResolver from '../../utils/EditorResolver';

type Props = {
    page: Page;
    element: PageElement;
};

const EditorPageElementRender: React.FC<Props> = ({ page, element }: Props) => {

    const ElementTag = EditorResolver.getElementRenderByType(element.type);
    
    if (ElementTag) {
        return (
            <div>
                <ElementTag
                    element={element}
                    page={page}
                    selected={false}
                />
            </div>
        );
    }

    return <div>ELEMENT RENDER TYPE NOT FOUND ({PageElementType[element.type].toString()})</div>;
}

export default EditorPageElementRender;
