import { Action, ADD_NOTIFICAITON, DECREMENT_TOTAL_NOTIFICAITONS_NOT_READ, INCREMENT_TOTAL_NOTIFICAITONS_NOT_READ, NotificationsState, SET_NOTIFICAITONS, SET_NOTIFICAITONS_STATUS, SET_TOTAL_NOTIFICAITONS_NOT_READ } from './type';
import update from 'immutability-helper';

const initialState: NotificationsState = {
	notifications: [],
	totalNotRead: 0,
	status: null,
};

function rootReducer(state: NotificationsState = initialState, action: Action): NotificationsState {
	switch (action.type) {

		case ADD_NOTIFICAITON:
			return {
				...state,
				notifications: update(state.notifications, { $push: [action.notification] })
			};

		case SET_NOTIFICAITONS:
			return {
				...state,
				notifications: action.notifications,
			};

		case SET_TOTAL_NOTIFICAITONS_NOT_READ:
			return {
				...state,
				totalNotRead: action.total,
			};

		case INCREMENT_TOTAL_NOTIFICAITONS_NOT_READ:
			return {
				...state,
				totalNotRead: state.totalNotRead + 1,
			};

		case DECREMENT_TOTAL_NOTIFICAITONS_NOT_READ:
			return {
				...state,
				totalNotRead: state.totalNotRead > 0 ? state.totalNotRead - 1 : 0,
			};

		case SET_NOTIFICAITONS_STATUS:
			return {
				...state,
				status: action.status,
			};

		default:
			return state;
	}
}

export default rootReducer;
