import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DocumentModelPicker.module.scss';
import FormItem from 'common/components/formItem/FormItem';
import Input from 'common/components/input/Input';
import { DocumentViewModel } from 'api/documents/models/DocumentViewModel';
import IconPaperBlue from 'assets/svgs/Paper-blue2.svg';
import IconGlassBlue from 'assets/svgs/Glass-blue2.svg';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { DocumentType } from 'api/documents/enums/DocumentType';
import Select from 'common/components/select/Select';
import { DocumentsSearchCriteria } from 'api/documents/models/DocumentsSearchCriteria';
import Loading from 'common/services/Loading';
import DocumentsService from 'api/documents/DocumentsService';
import InfinityScroll from 'common/components/infinityScroll/InfinityScroll';
import { useDebouncedCallback } from 'use-debounce';
import { addToast } from 'common/services/Toaster';
import ModelItem from 'screens/documents/components/createDocumentModal/components/modelItem/ModelItem';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import { IS_ADMIN } from 'Config';
import EditorDefaults from 'screens/editor/utils/EditorDefaults';
import { DocumentFormat } from 'api/documents/enums/DocumentFormat';

type Props = {
    onSelectModel: (item: DocumentViewModel | null) => void;
    onSelectNewEmptyPage?: () => void;
    selectedModelId: string | null;
    companyId: string | null;
    format?: DocumentFormat | null;
    fromEditor?: boolean | null;
    documentType?: DocumentType;
    style?: CSSProperties;
    onShowDetails?: (item: DocumentViewModel | null) => void
};

const DocumentModelPicker: React.FC<Props> = ({ onSelectModel, onSelectNewEmptyPage, selectedModelId, companyId, format, documentType, fromEditor, style, onShowDetails }: Props) => {
    const { t } = useTranslation();
    

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const isAdmin: boolean = IS_ADMIN(loggedUser);

    const documentTypesOptions: SelectValueLabel[] = [];

    if(fromEditor){
        documentTypesOptions.push({ value: DocumentType.SIMPLE_MODEL.toString(), label: t('documents.document_type.simple_model') });
        documentTypesOptions.push({ value: DocumentType.MODEL_PRE_FILLED.toString(), label: t('documents.document_type.model_pre_filled') });
    }

    const [models, setModels] = useState<DocumentViewModel[]>([]);
    const [criteria, setCriteria] = useState<DocumentsSearchCriteria>({ page: 1, itemsPerPage: 10, companyId: companyId, isAdmin: isAdmin, fromEditor: fromEditor });
    const [totalItems, setTotalItems] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [selectEmptyModel, setSelectEmptyModel] = useState<boolean>(false);

    const getData = async () => {
        try {

            if ((documentType && documentType === DocumentType.SIMPLE_MODEL.toString()) || (isAdmin && fromEditor)){
                criteria.type = DocumentType.SIMPLE_MODEL.toString();
            }
            
            const formats = format ? [format] : [];
            if (format === DocumentFormat.PRODUCT_BROCHURE && (fromEditor)) {
                formats.push(DocumentFormat.PRODUCT_SHEET);
            }

            const getModels = await DocumentsService.getDocumentTemplates({
                ...criteria,
                formats,
                orderColumn: 'name',
                orderBy: 'asc'
            });

            getModels?.items?.map(doc => {
                if(doc) {
                    const PageInfo = EditorDefaults.getPageSizeByFormat(doc.format);
                    doc.orientation = PageInfo.orientation;
                }
            });
            
            setModels([
                ...(criteria.page == 1 ? [] : models),
                ...getModels?.items
            ]);

            setTotalItems(models.length);
            setTotalPages(getModels?.totalPages || 1);
            onSelectModel(null);
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    useEffect(() => {
        getData();
    }, [criteria]);

    useEffect(() => {
        setCriteria({
            ...criteria,
            page: 1
        });
    }, [format]);

    const debounced = useDebouncedCallback(value => {
        setModels([]);
        setCriteria({
            ...criteria,
            name: value,
            page: 1
        });
    }, 500);

    const onChangeSearchText = (text: string) => {
        debounced(text);
    }

    const renderModelItem = (value: DocumentViewModel | undefined, onClick: () => void, isSelected: boolean, key: number, onShowDetails?: () => void) => (
        <div key={`item-model-${key}`}>
            <ModelItem
                isSelected={isSelected}
                document={value}
                onSelectModel={onClick}
                onShowItemDetails={onShowDetails}
                hasItemOverlay={true}
                newLabelText={ fromEditor ? t('documents.add_blank_page') : t('documents.add_document') }
                showDetailsButton={!fromEditor}
            />
        </div>
    );

    return (
        <div className={styles.container} style={style}>
            <div className={styles.content} >
                <div className={styles.inputsContainer}>
                    { (fromEditor && !isAdmin) && <FormItem className={styles.searchOptionLeft}>
                        <Select
                            options={documentTypesOptions}
                            placeholder={t('documents.select_model').toUpperCase()}
                            onChange={(data: SelectValueLabel) => {
                                setModels([]);
                                setCriteria({
                                    ...criteria,
                                    type: data?.value,
                                    page: 1,
                                });
                            }}
                            value={ documentType && documentType === DocumentType.SIMPLE_MODEL.toString() ? documentTypesOptions.find(x => x.value === DocumentType.SIMPLE_MODEL.toString()) : documentTypesOptions.find(x => x.value === criteria.type)}
                            icon={IconPaperBlue}
                        />
                    </FormItem> }
                    <FormItem className={`${styles.searchOptionRight} ${!(fromEditor && !isAdmin) ? styles.searchOptionRightAlone : ''}`}>
                        <Input icon={IconGlassBlue} placeholder={t('documents.search_model').toUpperCase()} onChangeText={onChangeSearchText} />
                    </FormItem> 
                </div>
            </div>

            <div id="scrollModalHere" className={styles.documents}>
                <div>
                    <InfinityScroll
                        totalItems={totalItems}
                        inModal={true}
                        executeOnScroll={() => {
                            setCriteria({
                                ...criteria,
                                page: criteria.page + 1
                            });
                        }}
                        continueScroll={totalPages > criteria.page}
                        elementIdToScroll='scrollModalHere'>
                        <div className={styles.list}>
                            {
                                fromEditor && renderModelItem(undefined, () => { onSelectNewEmptyPage && onSelectNewEmptyPage(); setSelectEmptyModel(!selectEmptyModel) }, selectEmptyModel, 0, undefined)
                            }
                            {
                                models.map((value, index) => {
                                    const isSelected = selectedModelId && value.id === selectedModelId ? true : false;
                                    return renderModelItem(value, () => { onSelectModel(isSelected ? null : value); selectEmptyModel && setSelectEmptyModel(false)}, isSelected, index, () => { onShowDetails && onShowDetails(value);});
                                })
                            }
                        </div>
                    </InfinityScroll>
                </div>
            </div>
        </div>
    );
}

export default DocumentModelPicker;