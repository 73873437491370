/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './Input.module.scss';
import IconClose from '../../../assets/svgs/cross-red-outlines.svg';

type InputPreset = 'default' | 'login';

type Props = {
    limit?: number;
    preset?: InputPreset;
    error?: FieldError | undefined;
    onErrorClick?: () => void;
    //autoComplete?: boolean;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const InputWithError = React.forwardRef<HTMLInputElement, Props>(({ limit, preset, error, onErrorClick, ...props }, ref) => {
    const presetClassName = preset ? styles[preset] : styles.default;

    const { t } = useTranslation();

    const [focusInput, setFocusInput] = useState<boolean>(false);

    const limitNumber = limit ? ' '+ limit : '';

    const renderMessage = () => {
        switch (error?.type) {
            case 'required':
                return t('common.errors.required');
            case 'username_not_available':
                return t('users.username_not_available');
            case 'name_not_available':
                return t('common.errors.name_not_available');
            case 'email_not_available':
                return t('common.errors.email_not_available');
            case 'too.many.characters':
                return t('common.errors.too_many_characters') + limitNumber ;
            default:
                return error?.message;
        }
    }

    return (
        <div className={styles.formLine}>
            <div className={`${props.disabled ? styles.disabled : ''} ${focusInput ? `${styles.inputFocus} ${styles.contentError}` : `${styles.contentError}` }` }>
                <div className={`${styles.leftInput} ${!(error && error.type) ? styles.fullWidth : ''}`}>
                    <input ref={ref}
                        type={props.type || 'text'}
                        className={`${props.disabled ? styles.disabled : ''} ${styles.inputError} ${presetClassName}` }
                        {...props}
                        onFocus={() => setFocusInput(true)}
                        onBlur={() => setFocusInput(false)} />
                </div>
                { error && error.type && <div className={styles.rightInput} onClick={() => ref }>
                    <div className={styles.showError}>
                        <img src={IconClose} onClick={() => onErrorClick && onErrorClick()} className={styles.iconError} /> {renderMessage()}
                    </div>
                </div> }
            </div>
        </div>
    );
});

export default InputWithError;
