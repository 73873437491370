import React, { memo, useMemo } from 'react';
import { ChartRenderProps } from '../../../../utils/EditorProps';
import { Cell, Global, Legend, Pie, PieChart } from 'recharts';
import EditorChartsUtils from 'screens/editor/utils/EditorChartsUtils';
Global.isSsr = true;

const DonutChartRender = ({ data, width, height, fallback, children, preview, startFromBottom, withoutLegend, ...props }: ChartRenderProps, ref: React.ForwardedRef<any>) => {

    if (!data || !data.tableData || data.tableData.length < 2) {
        return fallback || <></>;
    }

    const chartData = useMemo(() => {
        return EditorChartsUtils.tableDataToRowsData(data.tableData);
    }, [data.tableData]);

    if (!chartData || chartData.length === 0) {
        return fallback || <></>;
    }

    return (
        <PieChart
            {...props}
            width={width}
            height={height - 20}
            ref={ref}
        >
            <Pie
                data={chartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                nameKey="name"
                outerRadius={startFromBottom ? '95%' : '90%'}
                innerRadius={startFromBottom ? '80%' :'58%'}
                fill="#8884d8"
                dataKey="value"
                isAnimationActive={false}
                startAngle={startFromBottom ? -90 : undefined}
                endAngle={startFromBottom ? 270 : undefined}
            >
                {chartData.map((row, index) => (
                    <Cell key={`cell-${index}`} fill={row.color} />
                ))}
                {children}
            </Pie>
            {!withoutLegend && <Legend verticalAlign="bottom" iconSize={preview ? 14 : 16} wrapperStyle={{ position: 'relative', fontSize: preview ? '0.8rem' : '1rem' }} />}
        </PieChart>
    );
}

export default memo(React.forwardRef<any, ChartRenderProps>(DonutChartRender));
