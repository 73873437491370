import BellIcon from 'assets/svgs/Bell-bleu2.svg';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import styles from './NotificationsIcon.module.scss';

type Props = {
    iconClassName?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const NotificationsIcon = ({ className, iconClassName, ...props }: Props) => {
    const total = useSelector<Reducers, number>(state => state.notifications.totalNotRead);

    return (
        <span {...props} className={`${styles.iconParent} ${className || ''}`}>
            <img src={BellIcon} className={`${styles.icon} ${iconClassName || ''}`} />
            {total > 0 && <span className={styles.badge}>{total}</span>}
        </span>
    );
};

export default NotificationsIcon;