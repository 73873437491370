import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from 'common/services/Toaster';
import UserService from '../../../api/users/UsersService';
import { UserSearchCriteria } from '../../../api/users/models/UserSearchCriteria';
import { UserViewModel } from '../../../api/users/models/UserViewModel';
import Button from '../../../common/components/button/Button';
import Loading from '../../../common/services/Loading';
import ListingTable, { ListingTableCell, ListingTableColumn } from '../../../common/components/listingTable/ListingTable';
import Dropdown from '../../../common/components/dropdown/Dropdown';
import DropdownItem from '../../../common/components/dropdown/DropdownItem';
import { FaEllipsisH, FaChartLine } from 'react-icons/fa';
import styles from './ListUsersScreen.module.scss';
import ScreenTitle from '../../../common/components/screenTitle/ScreenTitle';
import PageHeader from '../../../common/components/pageHeader/PageHeader';
import FormItem from '../../../common/components/formItem/FormItem';
import UserModal from '../UserModal';
import QuestionYesNo from '../../../common/components/questionYesNo/QuestionYesNo';
import Pagination, { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from '../../../common/components/pagination/Pagination';
import { Controller, useForm } from 'react-hook-form';
import Select from '../../../common/components/select/Select';
import { SelectValueLabel } from '../../../common/types/SelectValueLabel';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from '../../../store/types';
import { UserProfile } from '../../../api/account/models/UserProfile';

import IconUsers from '../../../assets/svgs/People-bleu2.svg';
import IconPlus from '../../../assets/svgs/Plus-white-outlines.svg';
import { logout } from '../../../store/authentication/action';
import { useHistory } from 'react-router';

export type OpenDialogType = 'details' | 'edit' | 'new' | 'profile';

const ListUsersScreen: React.FC = () => {

    const { t } = useTranslation();
    
    const history = useHistory();

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const [users, setUsers] = useState<UserViewModel[]>([]);

    const { control } = useForm<UserViewModel>();

    const [rolesOptions, setRolesOptions] = useState<SelectValueLabel[]>([]);

    const [itemToRemove, setItemToRemove] = useState<UserViewModel>();

    const [openDialog, setOpenDialog] = React.useState<OpenDialogType | null>(null);
    const [openDialogItem, setOpenDialogItem] = React.useState<UserViewModel | null>(null);

    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);

    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const criteria: UserSearchCriteria = {};

    const [selectedRole, setSelectedRole] = useState<SelectValueLabel>();

    const dispatch = useDispatch();

    const [searchValue, setSearchValue] = useState('');

    const getUsers = async () => {
        try {
            Loading.show();
            if (criteria) {
                criteria.page = currentPage;
                criteria.itemsPerPage = DEFAULT_PAGINATION_ITEMS_PER_PAGE;
                criteria.orderColumn = 'real_name';
                criteria.orderBy = 'asc';
                criteria.name = searchValue;

                criteria.role = selectedRole?.label;

                if (loggedUser?.companyId) {
                    criteria.companyId = loggedUser?.companyId;
                }

                const result = await UserService.getList(criteria);
                setUsers(result.items);
                setTotalItems(result.totalItems);
            }
            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const getRoles = async () => {
        try {
            Loading.show();

            const roles = await UserService.getRoles({ companyId: loggedUser?.companyId, orderColumn: 'real_name' });
            setRolesOptions(roles.map(x => ({ value: x.id, label: x.realName })));

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const renderTableActionCell = (cell: ListingTableCell) => {
        return (
            <Dropdown options={<>
                <DropdownItem onClick={() => openModal(cell.row as UserViewModel, 'details')}>{t('common.details')}</DropdownItem>
                <DropdownItem onClick={() => openModal(cell.row as UserViewModel, 'edit')}>{t('common.edit')}</DropdownItem>
                <DropdownItem onClick={() => showRemoveItemDialog(cell.row as UserViewModel)}>{t('common.remove')}</DropdownItem>
            </>}>
                <div><FaEllipsisH /></div>
            </Dropdown>
        );
    }

    const tableColumns: ListingTableColumn[] = [
        {
            field: 'realName',
            name: t('users.user_real_name'),
            width: 'fill'
        },
        {
            field: 'email',
            name: t('users.user_email'),
            width: 'fill',
            cellAlignment: 'left'
        },
        {
            field: 'roleName',
            name: t('users.user_role'),
            width: 3,
            type: 'role'
        },
        {
            field: 'lastLogin',
            name: t('users.user_last_connection'),
            width: 'fill',
            type: 'date'
        },
        {
            field: 'action',
            name: t('users.options'),
            width: 2,
            cellAlignment: 'center',
            preventClick: true,
            renderCell: renderTableActionCell,
        }
    ];

    const openModal = (item: UserViewModel | null, dialogType: OpenDialogType) => {
        setOpenDialog(dialogType);
        setOpenDialogItem(item);
    };

    const showRemoveItemDialog = (item: UserViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await UserService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                if (itemToRemove.id == loggedUser?.id) {
                    dispatch(logout());
                } else {
                    getUsers();
                }
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'success' });
                Loading.hide();
            }
        }
    };

    const onPageChange = (page: number) => {
        setCurrentPage(page);
    }

    const onUserModalClose = () => {
        setOpenDialog(null);
        getUsers();
    }

    const applyFilter = (filter: any) => {
        setSelectedRole(filter);
    }

    const onChangeSearch = (text: string) => {
        setSearchValue(text);
        setCurrentPage(1);
    }

    useEffect(() => {
        getRoles();
    }, []);

    useEffect(() => {
        getUsers();
    }, [currentPage, selectedRole, searchValue]);

    return (
        <ScreenTitle title={t('menu.user_management')}>
            <PageHeader title={t('users.user_management')} onChangeSearch={onChangeSearch} searchValue={searchValue}></PageHeader>

            <div className={styles.container}>

                <div className={styles.line}></div>

                <div className={styles.header}>
                    <div className={styles.select}>
                        <FormItem>
                            <Controller
                                render={({ onChange, value }) => {
                                    return (
                                        <Select
                                            menuPortalTarget={document.querySelector('body')}
                                            options={rolesOptions.map(x => {
                                                return { label: x.label, value: x.value }
                                            })}
                                            placeholder={t('users.classify_by_user_type').toUpperCase()}
                                            onChange={(data: SelectValueLabel) => {
                                                onChange(data?.value);
                                                applyFilter(data);
                                            }}
                                            value={rolesOptions.find(x => x.value === value) ? { label: rolesOptions.find(x => x.value === value)?.label ?? '', value: value } : null
                                            }
                                            icon={IconUsers}
                                        />
                                    );
                                }}
                                control={control}
                                name='selectedRole'
                                defaultValue={''}
                            />
                        </FormItem>
                    </div>

                    <div className={styles.buttons}>
                        <Button className={styles.button} type="button" preset={'success'} onClick={() => history.push('users')} >
                            <span className={styles.noLabel}>{t('users.analytics').toUpperCase()}</span>
                            <FaChartLine className={styles.icon} />
                        </Button>
                        <Button className={styles.button} type="button" preset={'primary'} onClick={() => openModal(null, 'new')}>
                            <span className={styles.labelNormal}>{t('users.add_user').toUpperCase()}</span>
                            <span className={styles.labelSmall}>{t('users.user').toUpperCase()}</span>
                            <img src={IconPlus} className={styles.icon} />
                        </Button>
                    </div>
                </div>
                <div style={{ minHeight: '511px', position: 'relative' }}>

                    <ListingTable columns={tableColumns} rows={users} allowHover={true} onRowClick={row => openModal(row as UserViewModel, 'details')} striped={true} />

                    {openDialog !== null && <UserModal
                        mode={openDialog}
                        item={openDialogItem}
                        isVisible={openDialog ? true : false}
                        onCancel={onUserModalClose}
                    />}
                    <br />
                    <QuestionYesNo
                        title={t('common.remove')}
                        message={t('common.messages.remove_record')} isVisible={dialogDeleteItemIsOpen}
                        onYes={() => removeItem()}
                        onNo={() => setDialogDeleteItemIsOpen(false)} />

                    {totalItems > 0 && <div className={styles.paginationContainer}>
                        <Pagination currentPage={1} onChange={onPageChange} totalItems={totalItems} />
                    </div>}
                </div>
            </div>
        </ScreenTitle>
    );
}

export default ListUsersScreen;
