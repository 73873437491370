import { useEffect, useRef, useState } from 'react';
import styles from './ScrollShadow.module.scss';

type Props = {
    children: React.ReactElement | React.ReactElement[];
    scrollClassName?: string;
    scrollId?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ScrollShadow = ({ children, scrollClassName, scrollId, ...props }: Props) => {
    const [showStart, setShowStart] = useState(false);
    const [showEnd, setShowEnd] = useState(false);
    const ref = useRef<any>();

    useEffect(() => {
        const onScroll = () => {
            const { scrollHeight, scrollTop, offsetHeight } = ref.current || { scrollHeight: 0, scrollTop: 0, offsetHeight: 0 };
            setShowStart(scrollTop > 0);
            setShowEnd(scrollTop + offsetHeight < scrollHeight);
        };

        setTimeout(() => {
            onScroll();
        }, 100);

        const node = ref.current as any;
        node?.addEventListener('scroll', onScroll);

        return () => {
            node?.removeEventListener('scroll', onScroll);
        };
    }, []);

    return (
        <div
            {...props}
            className={`${styles.container} ${props.className || ''}`}
        >
            {showStart && <div className={styles.shadowStart}></div>}
            <div className={`${styles.scroll} ${scrollClassName || ''}`} ref={ref} id={scrollId}>
                {children}
            </div>
            {showEnd && <div className={styles.shadowEnd}></div>}
        </div>
    );
};

export default ScrollShadow;