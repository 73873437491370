import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Searchbar.module.scss';
import IconSearch from 'assets/svgs/Glass-blue2.svg';
import { useDebouncedCallback } from 'use-debounce';
import useEffectOnChange from 'common/hooks/useEffectOnChange';


type Props = {
    value?: string;
    defaultValue?: string;
    placeholder?: string;
    icon?: string;
    onChange: (value: string) => void;
    setSearchValue?: (value: string) => void;
};


const Searchbar: React.FC<Props> = ({ value, defaultValue, placeholder, icon, onChange, setSearchValue }: Props) => {
    const { t } = useTranslation();

    const clearSearch = () => {
        if (value != '') {
            onChangeSearchText('');
            setSearchValue && setSearchValue('');
        }
    }

    const debounced = useDebouncedCallback(value => {
        onChange && onChange(value);
    }, 500);

    const onChangeSearchText = (text: string) => {
        debounced(text);
        onChange(text);
    }

    return (
        <div className={styles.container}>
            {icon && <img src={icon} className={styles.iconClose} onClick={() => clearSearch()} />}
            {!icon && <img src={IconSearch} className={styles.icon} />}
            <input
                type="text"
                className={styles.input}
                onChange={e => onChangeSearchText(e.target.value)}
                value={value}
                defaultValue={defaultValue}
                placeholder={placeholder || t('common.searchbar_placeholder')}
            />
        </div>
    );
}

export default Searchbar;
