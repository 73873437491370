import React, { useState } from 'react';
import { addToast } from 'common/services/Toaster';
import styles from './PageHeader.module.scss';
import SearchIcon from 'assets/svgs/Glass-blue2.svg';
import CloseIcon from 'assets/svgs/Cross-bleu2.svg';
import HelpIcon from 'assets/svgs/Help.svg';
import NotificationsIcon from 'common/components/notificationsIcon/NotificationsIcon';
import NotificationsPopup from '../notificationsPopup/NotificationsPopup';
import { useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { Reducers } from 'store/types';
import { IS_ADMIN } from 'Config';
import Input from '../input/Input';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import colors from './../../../styles/export/colors.module.scss';
import { SearchListItemsViewModel } from 'api/home/models/SearchListItemsViewModel';
import SearchPanel from '../searchPanel/SearchPanel';
import AppSettingsService from 'api/appSettings/AppSettingsService';

interface Props {
    title: string;
    hideSearchIcon?: boolean;
    onChangeSearch?: (name: string) => void;
    searchValue?: string;
    hasTitle?: boolean;
    fullSearchBar?: boolean;
    showPanelResults?: boolean;
    searchResults?: SearchListItemsViewModel | null;
    stateBackDrop?: (state: boolean) => void;
    resetSearch?: () => void;
    setSearchValue?: (value: string) => void;
}

const PageHeader: React.FunctionComponent<Props> = ({ title, hideSearchIcon, onChangeSearch, searchValue = '', hasTitle = true, fullSearchBar = false, showPanelResults, searchResults, stateBackDrop, resetSearch, setSearchValue }: Props) => {

    
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const isAdmin = IS_ADMIN(loggedUser);

    const { t } = useTranslation();

    const [showNotificationsMenu, setShowNotificationsMenu] = useState(false);
    const [showSearchBar, setShowSearchBar] = useState(false);


    const onNotificationsClickOutside = () => {
        if (showNotificationsMenu) {
            setShowNotificationsMenu(false);
        }
    }

    const onSearchClick = () => {
        if (showSearchBar) {
            setShowSearchBar(false);
            if (searchValue != '') {
                onChangeSearchText('');
                setSearchValue && setSearchValue('');
            }
        } else {
            setShowSearchBar(true);
        }
    }

    const onHelpClick = async () => {
        try {
            const result = await AppSettingsService.getAppSettings();
            if(result?.faqUrl){
                window.open(result.faqUrl, '_blank');
            }
            else{
                addToast(t('common.messages.faq_not_found'), { appearance: 'warning' });
            }
        } catch (error) {
            addToast(t('common.messages.faq_not_found'), { appearance: 'warning' });
        }
    }

    const debounced = useDebouncedCallback(value => {
        onChangeSearch && onChangeSearch(value);
    }, 500);


    const onChangeSearchText = (text: string) => {
        debounced(text);
        setSearchValue && setSearchValue(text);
    }
    
    return (
        <div className={styles.container}>
            { hasTitle && <h1 className={styles.title}>{title}</h1> }

            {!fullSearchBar &&
                <>
                    {showSearchBar && <div className={styles.searchBar}>
                        <Input
                            style={{ borderColor: colors.primaryLight, borderWidth: '2px' }}
                            defaultValue={searchValue}
                            placeholder={t('common.searchbar_placeholder').toUpperCase()}
                            onChangeText={onChangeSearchText}
                            autoFocus={true}
                        />
                    </div>
                    }

                    <div className={styles.iconsContainer}>
                        { !hideSearchIcon &&
                            <img src={showSearchBar ? CloseIcon : SearchIcon} className={`${`${showSearchBar ? styles.closeIcon : styles.icon}`} ${styles.iconSpace}`} onClick={onSearchClick} />
                        }

                        <img src={HelpIcon} className={`${styles.icon} ${styles.iconSpace}`} onClick={onHelpClick} />

                        { !isAdmin &&
                            <NotificationsIcon onClick={() => setShowNotificationsMenu(!showNotificationsMenu)} className={styles.iconSpace} />
                        }

                        { !isAdmin && showNotificationsMenu &&
                            <NotificationsPopup onClose={onNotificationsClickOutside} />
                        }
                    </div>
                </>
            }

            { fullSearchBar &&
                <>
                    <div className={styles.searchBarFull}>
                        <Input
                            style={{ borderColor: colors.primaryLight, borderWidth: '2px', width: 'calc(100% - 2rem)' }}
                            containerStyle={{ position: showPanelResults ? 'relative' : 'unset', zIndex: showPanelResults ? 600 : 'unset' }}
                            value={searchValue}
                            placeholder={t('common.searchbar_placeholder').toUpperCase()}
                            onChangeText={onChangeSearchText}
                            cleanInputIcon={resetSearch}
                            autoFocus={true}
                            icon={showSearchBar ? CloseIcon : SearchIcon}
                            hasShadow={true}
                            isSearchPanel={true}
                        />
                        { showPanelResults && <SearchPanel className={styles.panelResults} searchResults={searchResults} stateBackDrop={stateBackDrop}></SearchPanel> }
                    </div>

                    <div className={styles.iconsContainer}>
                        <img src={HelpIcon} className={`${styles.icon} ${styles.iconSpace}`} onClick={onHelpClick} />

                        { !isAdmin &&
                            <NotificationsIcon onClick={() => setShowNotificationsMenu(!showNotificationsMenu)} className={styles.iconSpace} />
                        }

                        { !isAdmin && showNotificationsMenu &&
                            <NotificationsPopup onClose={onNotificationsClickOutside} />
                        }
                    </div>
                </>
            }
        </div>
    );
}

export default PageHeader;
