import React, { memo, useMemo } from 'react';
import { ChartRenderProps } from '../../../../utils/EditorProps';
import { Bar, BarChart, Global, Legend, XAxis, YAxis } from 'recharts';
import EditorChartsUtils from 'screens/editor/utils/EditorChartsUtils';
Global.isSsr = true;

type Props = {
    stacked?: boolean;
} & ChartRenderProps;

const VerticalBarsChartRender = ({ data, width, height, fallback, stacked = false, children, withoutLegend, ...props }: Props, ref: React.ForwardedRef<any>) => {

    if (!data || !data.tableData || data.tableData.length < 2) {
        return fallback || <></>;
    }

    const [chartData, chartRows] = useMemo(() => {
        return EditorChartsUtils.tableDataToLinesChartData(data.tableData);
    }, [data.tableData]);

    if (!chartData || chartData.length === 0) {
        return fallback || <></>;
    }

    return (
        <BarChart
            {...props}
            width={width}
            height={height}
            data={chartData}
            ref={ref}
            margin={{
                top: 10,
                right: 10,
                left: 10,
                bottom: 10,
            }}
        >
            <XAxis dataKey="name" />
            <YAxis width={40} />
            {chartRows.map((row, i) => <Bar key={i} dataKey={row.name} fill={row.color} stackId={stacked ? 'stack' : undefined} isAnimationActive={false} />)}
            {!withoutLegend && <Legend verticalAlign="bottom" wrapperStyle={{ position: 'relative' }} />}
            {children}
        </BarChart>
    );
}

 export default memo(React.forwardRef<any, Props>(VerticalBarsChartRender));
