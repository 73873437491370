import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css'
import DraggableContentItem from '../draggableContentItem/DraggableContentItem';
import EditorElementGenerator from '../../../../utils/EditorElementGenerator';
import EditorUtils from '../../../../utils/EditorUtils';
import { PageElement, PageElementType } from '../../../../models/EditorModels';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { FaEllipsisH } from 'react-icons/fa';
import GiphyService from 'api/giphy/GiphyService';
import Utils from 'common/services/Utils';
import Loading from 'common/services/Loading';
import store from 'store/store';
import { updatePageElement } from 'store/editor/action';
import ScrollShadow from 'common/components/scrollShadow/ScrollShadow';
import styles from './GifsContent.module.scss';
import InfinityScroll from 'common/components/infinityScroll/InfinityScroll';
import Popover from 'common/components/popover/Popover';
import IconSaveDanger from 'assets/svgs/Cross-danger.svg';
import Searchbar from 'common/components/searchbar/Searchbar';
import BoxCategory from 'common/components/boxCategory/BoxCategory';
import { PaginationOptions as GiphyPaginationOptions } from '@giphy/js-fetch-api';

interface ImageItem {
    id: string;
    url: string;
    thumbUrl?: string;
    width?: number;
    height?: number;
    description: string | null;
    userUsername: string | null;
    userDisplayName: string | null;
    userImageUrl: string | null;
}

type Props = {
};

const GifsContent: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState<string>('');
    const dispatch = useDispatch();

    const defaultOffset = 0;
    const defaultLimit = 25;
    const [giphyCriteria, setGiphyCriteria] = useState<GiphyPaginationOptions>({ offset: defaultOffset, limit: defaultLimit });
    const [giphyImages, setGiphyImages] = useState<ImageItem[]>([]);
    const [giphyLoadMore, setGiphyLoadMore] = useState<boolean>(true);
    const [giphyErrorCode, setGiphyErrorCode] = useState<string>('');
    const [tryingGiphy, setTryingGiphy] = useState(false);

    const debounced = useDebouncedCallback(value => {
        setSearchValue(value);
        setGiphyImages([]);

        setGiphyCriteria({
            ...giphyCriteria,
            offset: defaultOffset
        });

    }, 500);

    const onChangeText = (value: string) => {
        debounced(value);
    }

    const loadGiphyPhotos = async () => {
        try {
            const res = searchValue
                ? await GiphyService.searchGifs(searchValue, giphyCriteria)
                : await GiphyService.getTrendingGifs(giphyCriteria);

            if (!res || res.meta.status != 200) {
                throw new Error(res.meta.status.toString());
            }

            const images: ImageItem[] = (res.data || []).map(r => ({
                id: Utils.newGuid(),
                url: r.images.original.url,
                thumbUrl: r.images.preview_gif.url,
                height: r.images.original.height,
                width: r.images.original.width,
                description: r.title,
                userUsername: r.user ? r.user.username : null,
                userDisplayName: r.user ? r.user.display_name : null,
                userImageUrl: r.url
            }));
            setGiphyImages([
                ...giphyImages,
                ...images
            ]);
            setGiphyLoadMore(giphyImages.length < res.pagination.total_count);
            setGiphyErrorCode('');
            setTryingGiphy(false);

            Loading.hide();
        } catch (error) {
            setGiphyErrorCode(error.status || 500);
            setTryingGiphy(false);
            Loading.hide();
        }
    }

    const retryGiphy = () => {
        setTryingGiphy(true);
        setTimeout(() => loadGiphyPhotos(), 100);
    }

    const createImageElement = (imageItem: ImageItem): PageElement => {
        return EditorElementGenerator.createGifElement(
            imageItem.url,
            (imageItem?.width ? imageItem.width : 0), (imageItem?.height ? imageItem.height : 0),
            imageItem.thumbUrl || null,
        );
    }

    const onImageClick = (image: ImageItem) => {
        const [selectedElement, selectedPage] = EditorUtils.getFirstSelectedElementFromState(store.getState().editor.present);

        if (selectedElement && selectedPage && selectedElement.type === PageElementType.GIF) {
            dispatch(updatePageElement(selectedPage, {
                ...selectedElement,
                value: image.url,
                width: image.width ? image.width : 0,
                height: image.height ? image.height : 0,
            }));
        }
    }

    const renderGiphyImagesList = (images: ImageItem[]) => (
        <ScrollShadow scrollId="scrollGiphy" className={styles.scrollContentContainer} scrollClassName={styles.scrollContent}>
            <InfinityScroll
                inModal={true}
                totalItems={images.length}
                executeOnScroll={() => {
                    setGiphyCriteria({
                        ...giphyCriteria,
                        offset: (giphyCriteria.offset ?? defaultOffset) + (giphyCriteria.limit ?? defaultLimit)
                    });
                }}
                continueScroll={giphyLoadMore}
                elementIdToScroll="scrollGiphy"
            >
                {!giphyErrorCode
                    ? <Masonry
                        breakpointCols={3}
                        className={styles.masonryGrid}
                        columnClassName={styles.masonryGridColumn}
                    >
                        {images.map((image, i) => {
                            return (
                                <DraggableContentItem key={i} generateElement={() => createImageElement(image)}>
                                    <div className={styles.imageItem} onClick={() => onImageClick(image)}>
                                        <Popover
                                            positions={['right']}
                                            content={() => (
                                                <div>
                                                    <h1 className={styles.imagePopoverTitle}>{image.description || t('editor.sidebar.no_description')}</h1>
                                                    <p className={styles.imagePopoverAuthor}>
                                                        {t('editor.sidebar.giphy_photo_by')}
                                                        <a href={'https://giphy.com/' + image.userUsername || '#'} target="_blank" rel="noreferrer"> {image.userDisplayName} </a>
                                                        {t('editor.sidebar.powered_by')}
                                                        <a href={image.userImageUrl || '#'} target="_blank" rel="noreferrer"> {t('editor.sidebar.giphy_photos')}</a>
                                                    </p>
                                                </div>
                                            )}
                                            contentContainerClassName={styles.imagePopover}
                                        >
                                            {(_, setIsOpen) => (
                                                <div className={styles.imageMenuButton} onClick={() => setIsOpen(true)}><FaEllipsisH /></div>
                                            )}
                                        </Popover>

                                        <img src={image.thumbUrl || image.url} />
                                    </div>
                                </DraggableContentItem>
                            );
                        })}
                    </Masonry>
                    : <div className={styles.loadError}>
                        <img src={IconSaveDanger} className={styles.loadErrorIcon} />
                        {giphyErrorCode == '500'
                            ? <div className={styles.loadErrorText}>
                                <span>{t('editor.sidebar.error_connecting_giphy')}</span>
                                <a
                                    onClick={retryGiphy}
                                    className={`${styles.tryAgainLink} ${tryingGiphy && styles.tryAgainLinkDisabled}`}
                                >
                                    {t('common.try_again')}
                                </a>
                            </div>
                            : <div className={styles.loadErrorText}>
                                <span>{t(('giphy.errors.' + giphyErrorCode as any))}</span>
                            </div>
                        }
                    </div>
                }
            </InfinityScroll>
        </ScrollShadow>
    );

    useEffect(() => {
        loadGiphyPhotos();
    }, [giphyCriteria]);

    return (
        <div className={styles.container}>
            <Searchbar
                defaultValue={searchValue}
                placeholder={t('editor.sidebar.photos_searchbar_placeholder')}
                onChange={onChangeText}
            />
            <div className={styles.boxCategoryContainer}>
                <BoxCategory
                    title={t('editor.sidebar.giphy_photos')}
                    className={styles.boxCategory}
                >
                    {renderGiphyImagesList(giphyImages)}
                </BoxCategory>
            </div>
        </div>
    );

}

export default GifsContent;
