import React from 'react';
import { PageThumbnail } from 'screens/editor/models/EditorModels';
import styles from './MiniThumbnail.module.scss';

type Props = {
    thumbnail: PageThumbnail;
    number: number;
    isDouble?: boolean;
};

const MiniThumbnail: React.FC<Props> = ({ thumbnail, number, isDouble = false }: Props) => {
    return (
        <div className={styles.thumbnailContainer} style={{ margin: !isDouble ? '0 1rem' : '0 0.2rem' }}>
            <div className={styles.thumbnailImageContainer}>
                <img className={styles.thumbnailImageContainerContent} src={thumbnail.dataUrl ?? ''} />
            </div>
            <div className={styles.thumbnailNumberContainer}>{number}</div>
        </div>
    );
}

export default MiniThumbnail;

