import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import reducers from './reducers';
import { Reducers } from './types';

const store: Store<Reducers> = createStore(
	combineReducers(reducers),
	composeWithDevTools(applyMiddleware(thunk)),
);

export default store;
