import React from 'react';
import styles from './Button.module.scss';

type ButtonPreset = 'primary' | 'secondary' | 'success' | 'danger' | 'popover' | 'secondaryDanger';

type ButtonSize = 'normal' | 'small' | 'extraSmall';

type Props = {
    text?: string;
    preset?: ButtonPreset;
    onlyIcon?: boolean;
    size?: ButtonSize;
    isDisabled?: boolean;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const Button: React.FC<Props> = ({ children, type, text, preset = 'primary', 
size, onlyIcon, isDisabled, ...props }: Props) => {

    const presetClassName = preset ? styles[preset] : styles.primary;
    const sizeClassName = size ? (styles as any)['size'+size[0].toUpperCase()+size.substr(1, size.length)] : styles.sizeNormal;

    return (
        <button
            type={type || 'button'}
            {...props}
            className={`${onlyIcon ? styles.buttonIcon : styles.button} ${isDisabled || props.disabled? styles.disabled : ''} ${presetClassName} ${sizeClassName} ${props.className || ''}`}
        >
            {Boolean(text) && text}
            {children}
        </button>
    );
}

export default Button;
