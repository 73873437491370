import { NotificationDto } from 'api/notifications/models/NotificationDto';

export const ADD_NOTIFICAITON = 'ADD_NOTIFICAITON';
export const SET_NOTIFICAITONS = 'SET_NOTIFICAITONS';
export const SET_TOTAL_NOTIFICAITONS_NOT_READ = 'SET_TOTAL_NOTIFICAITONS_NOT_READ';
export const INCREMENT_TOTAL_NOTIFICAITONS_NOT_READ = 'INCREMENT_TOTAL_NOTIFICAITONS_NOT_READ';
export const DECREMENT_TOTAL_NOTIFICAITONS_NOT_READ = 'DECREMENT_TOTAL_NOTIFICAITONS_NOT_READ';
export const SET_NOTIFICAITONS_STATUS = 'SET_NOTIFICAITONS_STATUS';

export type Action = AddNotificationAction |
    SetNotificationsAction |
    SetTotalNotificationsNotReadAction |
    IncrementTotalNotificationsNotReadAction |
    DecrementTotalNotificationsNotReadAction |
    SetNotificationsStatusAction;

export interface AddNotificationAction {
    type: typeof ADD_NOTIFICAITON;
    notification: NotificationDto;
}

export interface SetNotificationsAction {
    type: typeof SET_NOTIFICAITONS;
    notifications: NotificationDto[];
}

export interface SetTotalNotificationsNotReadAction {
    type: typeof SET_TOTAL_NOTIFICAITONS_NOT_READ;
    total: number;
}

export interface IncrementTotalNotificationsNotReadAction {
    type: typeof INCREMENT_TOTAL_NOTIFICAITONS_NOT_READ;
}

export interface DecrementTotalNotificationsNotReadAction {
    type: typeof DECREMENT_TOTAL_NOTIFICAITONS_NOT_READ;
}

export interface SetNotificationsStatusAction {
    type: typeof SET_NOTIFICAITONS_STATUS;
    status: NotificationStatusState | null;
}

export interface NotificationStatusState {
    statusType: 'success' | 'error';
    message?: string;
}

export interface NotificationsState {
    notifications: NotificationDto[];
    totalNotRead: number;
    status: NotificationStatusState | null,
}
