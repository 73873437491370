import { createApi } from 'unsplash-js';
import { PaginationParams } from 'unsplash-js/dist/types/request';
import { UNSPLASH_ACCESS_KEY } from '../../Config';

class UnsplashService {

    private unsplash: ReturnType<typeof createApi>;

    constructor() {
        this.unsplash = createApi({
            accessKey: UNSPLASH_ACCESS_KEY,
        });
    }

    public searchPhotos(term: string, paginationParams: PaginationParams) {
        return this.unsplash.search.getPhotos({ 
            query: term,
            page: paginationParams.page,
            perPage: paginationParams.perPage
         });
    }

    public listPhotos(paginationParams: PaginationParams) {
        return this.unsplash.photos.list({ 
            page: paginationParams.page,
            perPage: paginationParams.perPage
         });
    }

    public trackDownload(url: string) {
        return this.unsplash.photos.trackDownload({
            downloadLocation: url,
        });
    }
}

export default new UnsplashService();