
export enum CropEditorImageType {
    RECT = 'RECT',
    CIRCLE = 'CIRCLE',
    POLYGON = 'POLYGON',
}

export interface CropEditorImageViewModel {
    imageUrl: string;
    currentCroppedMediaId: string | null;
    type: CropEditorImageType;

    width: number;
    height: number;

    rectX: number | null;
    rectY: number | null;
    rectWidth: number | null;
    rectHeight: number | null;

    circleX: number | null;
    circleY: number | null;
    circleRadius: number | null;

    polygonPaths: number[][] | null;
}
