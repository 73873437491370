import { EditorFont, Page, PageElement } from 'screens/editor/models/EditorModels';
import { AddPageAction, ADD_ELEMENT_TO_PAGE, 
	ADD_PAGE, ClearSelectedAction, CLEAR_SELECTED, DuplicatePageAction, DuplicatePageElementAction, DUPLICATE_PAGE, DUPLICATE_PAGE_ELEMENT, EditorRedoAction, 
	EditorUndoAction, EDITOR_REDO, EDITOR_UNDO, RemoveCroppedFromPastAction, REMOVE_CROPPED_FROM_PAST, REMOVE_PAGE, 
	REMOVE_PAGE_ELEMENTS, 
	ReOrderPagesAction, 
	RE_ORDER_PAGES, 
	SetFontsAction, 
	SetInitialStateAction, 
	SetPagesAction, SetSelectedElementsIdsAction, SetSelectedPageIdAction, SetSidebarSelectedCategoryIdAction, SetSidebarSelectedChartTypeAction, SetSidebarSelectedTableTypeAction, SetSidebarStateAction, SetZoomAction,
	SET_FONTS,
	SET_INITIAL_STATE,
	SET_PAGES, SET_SELECTED_ELEMENTS_IDS, SET_SELECTED_PAGE_ID, SET_SIDEBAR_SELECTED_CATEGORY_ID, SET_SIDEBAR_SELECTED_CHART_TYPE, SET_SIDEBAR_SELECTED_TABLE_TYPE, SET_SIDEBAR_STATE, SET_ZOOM,
	UpdateEditorAnalyticsAction,
	UPDATE_EDITOR_ANALYTICS,
	UPDATE_PAGE, UPDATE_PAGE_ELEMENT, UPDATE_PAGE_THUMBNAIL, UPDATE_PAGE_THUMBNAIL_CHANGER_ID
} from './type';
import { Dispatch } from 'redux';
import EditorInternalService from 'screens/editor/utils/EditorInternalService';
import { DocumentFormat } from 'api/documents/enums/DocumentFormat';
import { DocumentType } from 'api/documents/enums/DocumentType';
import { EditorSidebarCategoryId, EditorSidebarState } from 'screens/editor/models/SidebarModels';
import { EditorChartType } from 'screens/editor/models/ChartModels';
import Logger from 'common/services/Logger';
import { EditorTableType } from 'screens/editor/models/TableModels';
import { LOGGER_LOG_TYPE } from 'Config';
import { EditorAnalytics } from 'screens/editor/models/EditorAnalytics';

export const setSelectedPageId = (selectedPageId: string | null): SetSelectedPageIdAction => ({
	type: SET_SELECTED_PAGE_ID,
	selectedPageId,
});

export const setSelectedElementsIds = (selectedPageId: string | null, selectedElementsIds: string[] | null): SetSelectedElementsIdsAction => ({
	type: SET_SELECTED_ELEMENTS_IDS,
	selectedPageId,
	selectedElementsIds,
});

export const setPages = (pages: Page[]): SetPagesAction => ({
	type: SET_PAGES,
	pages,
});

export const setInitialState = (pages: Page[], format: DocumentFormat, documentType: DocumentType, documentId: string, fonts: EditorFont[]): SetInitialStateAction => ({
	type: SET_INITIAL_STATE,
	pages,
	format, 
	documentType,
	documentId,
	fonts,
});

export const updatePage = (page: Page) => async (dispatch: Dispatch) => {
	dispatch({
		type: UPDATE_PAGE,
		page,
	});
	updatePageThumbnailChangerIdDispatch(dispatch, page);
};

export const addPage = (page: Page): AddPageAction => ({
	type: ADD_PAGE,
	page,
});

export const removePage = (page: Page) => async (dispatch: Dispatch) => {
	dispatch({
		type: REMOVE_PAGE,
		page,
	});
	dispatch(removeCroppedFromPast(page.id, null));
};

export const duplicatePage = (page: Page): DuplicatePageAction => ({
	type: DUPLICATE_PAGE,
	page,
});

export const updatePageElement = (page: Page, element: PageElement, generateThumbnail = true) => async(dispatch: Dispatch) => {
	dispatch({
		type: UPDATE_PAGE_ELEMENT,
		page,
		element,
	});

	if (generateThumbnail) {
		updatePageThumbnailChangerIdDispatch(dispatch, page);
	}
};

export const clearSelected = (): ClearSelectedAction => ({
	type: CLEAR_SELECTED,
});

export const addElementToPage = (page: Page, element: PageElement) => async (dispatch: Dispatch) => {
	dispatch({
		type: ADD_ELEMENT_TO_PAGE,
		page,
		element,
	});
	updatePageThumbnailChangerIdDispatch(dispatch, page);
};

export const removePageElements = (page: Page, elements: PageElement[]) => async (dispatch: Dispatch) => {
	dispatch({
		type: REMOVE_PAGE_ELEMENTS,
		page,
		elements,
	});
	updatePageThumbnailChangerIdDispatch(dispatch, page);

	for (const element of elements) {
		dispatch(removeCroppedFromPast(page.id, element.id));
	}
};

export const setZoom = (zoom: number | null): SetZoomAction => ({
	type: SET_ZOOM,
	zoom,
});

export const editorUndo = (): EditorUndoAction => ({
	type: EDITOR_UNDO,
});

export const editorRedo = (): EditorRedoAction => ({
	type: EDITOR_REDO,
});

export const reOrderPages = (sourceIndex: number, destinationIndex: number): ReOrderPagesAction => ({
	type: RE_ORDER_PAGES,
	sourceIndex,
	destinationIndex,
});

export const duplicatePageElement = (pageId: string, elementId: string): DuplicatePageElementAction => ({
	type: DUPLICATE_PAGE_ELEMENT,
	pageId,
	elementId,
});

export const updatePageThumbnail = (page: Page) => async (dispatch: Dispatch) => {
	Logger.info(LOGGER_LOG_TYPE.EDITOR, `Generating an page thumbnail (Page:${page.number})`)
	const thumbnail = await EditorInternalService.generatePageThumbnail(page, { width: page.thumbnail.width, height: page.thumbnail.height });
	dispatch({
		type: UPDATE_PAGE_THUMBNAIL,
		pageId: page.id,
		thumbnail,
	});
};

export const updatePageThumbnailChangerIdDispatch = async (dispatch: Dispatch, page: Page) => {
	dispatch({
		type: UPDATE_PAGE_THUMBNAIL_CHANGER_ID,
		pageId: page.id,
	});
};

export const setSidebarState = (sidebarState: EditorSidebarState): SetSidebarStateAction => ({
	type: SET_SIDEBAR_STATE,
	sidebarState,
});

export const setSidebarSelectedCategoryId = (selectedCategoryId: EditorSidebarCategoryId | null): SetSidebarSelectedCategoryIdAction => ({
	type: SET_SIDEBAR_SELECTED_CATEGORY_ID,
	selectedCategoryId,
});

export const setSidebarSelectedChartType = (selectedChartType: EditorChartType | null): SetSidebarSelectedChartTypeAction => ({
	type: SET_SIDEBAR_SELECTED_CHART_TYPE,
	selectedChartType,
});

export const removeCroppedFromPast = (pageId: string, elementId: string | null): RemoveCroppedFromPastAction => ({
	type: REMOVE_CROPPED_FROM_PAST,
	pageId,
	elementId,
});

export const setSidebarSelectedTableType = (selectedTableType: EditorTableType | null): SetSidebarSelectedTableTypeAction => ({
	type: SET_SIDEBAR_SELECTED_TABLE_TYPE,
	selectedTableType,
});

export const setFonts = (fonts: EditorFont[]): SetFontsAction => ({
	type: SET_FONTS,
	fonts,
});

export const updateEditorAnalytics = (analytics: EditorAnalytics): UpdateEditorAnalyticsAction => ({
	type: UPDATE_EDITOR_ANALYTICS,
	analytics,
});