import React, { useMemo } from 'react';
import EditorDefaults from '../../../../utils/EditorDefaults';
import { ElementProps } from '../../../../utils/EditorProps';
import Prefixer from 'common/services/Prefixer';

type Props = {
    clipActive?: boolean;
} & ElementProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ImageElementRender = ({ page, element, selected, clipActive = false, ...props }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {

    const style = useMemo(() => Prefixer.prefixStyle(EditorDefaults.getElementStyles(element)), [element]);
    const borderRadiusStyle = useMemo(() => Prefixer.prefixStyle(EditorDefaults.getElementBorderRadiusStyle(element)), [element]);
    const borderStyle = useMemo(() => Prefixer.prefixStyle(EditorDefaults.getElementBorderStyle(element)), [element]);
    
    // Typeof for legacy reasons
    const url: string = typeof element.value === 'string' ? element.value : element.value.url;

    const useCroppedImage = Boolean(element.clip && element.clip.enabled && element.clip?.croppedMedia && !clipActive);

    return (
        <div
            {...props}
            ref={ref}
            style={{
                ...style,
                ...(useCroppedImage ? {
                    clip: undefined,
                    clipPath: undefined,
                    WebkitClipPath: undefined,
                } : {}),
                // ...borderRadiusStyle,
                // ...borderStyle,
                // backgroundImage: `url("${useCroppedImage ? element.clip!.croppedMedia!.url : url}")`,
                // backgroundSize: 'cover',
                // backgroundRepeat: 'no-repeat',
            }}
        >
            <img
                src={useCroppedImage ? element.clip!.croppedMedia!.url : url}
                style={{
                    width: `100%`,
                    height: `100%`,
                    margin: 0,
                    padding: 0,
                    verticalAlign: 'top',
                    display: 'block',
                    lineHeight: 0,
                    fontSize: 0,
                    ...borderRadiusStyle,
                    ...borderStyle,
                }}
            />
        </div>
    );
}

export default React.forwardRef<HTMLDivElement, Props>(ImageElementRender);
