import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DocumentsNumberScreen.module.scss';
import colors from 'styles/export/colors.module.scss';
import MiniCard from './components/miniCard/MiniCard';
import { DocumentFormat } from 'api/documents/enums/DocumentFormat';
import { EditorChartDataValue, EditorChartType } from 'screens/editor/models/ChartModels';
import IconDocumentBlue from 'assets/svgs/Document-bleu2.svg';
import IconDocumentGreen from 'assets/svgs/Document-green.svg';
import IconDocumentRed from 'assets/svgs/HorizontalSupport.svg';
import IconDocumentBlack from 'assets/svgs/SquareSupport.svg';

type Props = {
    data: EditorChartDataValue[];
};

const DocumentsNumberScreen: React.FC<Props> = ({ data }: Props) => {
    const { t } = useTranslation();

    const PRODUCT_SHEET_DATA = data.find(x => x.type === EditorChartType.BARS_HORIZONTAL);
    const PRODUCT_BROCHURE_DATA = data.find(x => x.type === EditorChartType.BARS_VERTICAL);
    const PRESENTATION_SLIDES_DATA = data.find(x => x.type === EditorChartType.CURVE_SINGLE);
    const SOCIAL_MEDIA_POSTS_DATA = data.find(x => x.type === EditorChartType.DONUT);

    return (
        <div className={styles.container}>
            {PRODUCT_SHEET_DATA && <div className={`${styles.card} ${styles.noMarginTop} ${styles.noMarginLeft}`}>
                <MiniCard
                    color={colors.primary}
                    title={t(`companies.analytics.formats.${DocumentFormat.PRODUCT_SHEET}` as any)}
                    data={PRODUCT_SHEET_DATA}
                    icon={IconDocumentBlue} />
            </div>}

            {PRODUCT_BROCHURE_DATA &&
                <div className={`${styles.card} ${styles.noMarginTop} ${styles.noMarginRight}`}>
                    <MiniCard
                        color={colors.success}
                        title={t(`companies.analytics.formats.${DocumentFormat.PRODUCT_BROCHURE}` as any)}
                        data={PRODUCT_BROCHURE_DATA} 
                        icon={IconDocumentGreen} />
                </div>}

            {PRESENTATION_SLIDES_DATA &&
                <div className={`${styles.card} ${styles.noMarginBottom} ${styles.noMarginLeft}`}>
                    <MiniCard
                        color={colors.danger}
                        title={t(`companies.analytics.formats.${DocumentFormat.PRESENTATION_SLIDES}` as any)}
                        data={PRESENTATION_SLIDES_DATA}
                        icon={IconDocumentRed} />
                </div>}

            {SOCIAL_MEDIA_POSTS_DATA &&
                <div className={`${styles.card} ${styles.noMarginBottom} ${styles.noMarginRight}`}>
                    <MiniCard
                        color={colors.lightblack}
                        title={t(`companies.analytics.formats.${DocumentFormat.SOCIAL_MEDIA_POSTS}` as any)}
                        data={SOCIAL_MEDIA_POSTS_DATA}
                        icon={IconDocumentBlack} />
                </div>}
        </div>
    );
}

export default DocumentsNumberScreen;
