import React from 'react';
import styles from './OthersContent.module.scss';
import DraggableContentItem from '../draggableContentItem/DraggableContentItem';
import { PageElement } from '../../../../models/EditorModels';
import EditorElementGenerator from '../../../../utils/EditorElementGenerator';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import { FaCircle, FaFont, FaShapes, FaSquareFull } from 'react-icons/fa';
import colors from './../../../../../../styles/export/colors.module.scss';
import BoxCategory from 'common/components/boxCategory/BoxCategory';

const OthersContent: React.FC = () => {
    const { t } = useTranslation();

    const createTextElement = (text: string): PageElement => {
        return EditorElementGenerator.createTextElement(text, 500, 200);
    }

    const createRectangleElement = (): PageElement => {
        return EditorElementGenerator.createRectangleElement(500, 200, colors.primary);
    }

    const createTriangleElement = (): PageElement => {
        return EditorElementGenerator.createTriangleElement(250, 220, colors.primary);
    }

    const createCircleElement = (): PageElement => {
        return EditorElementGenerator.createCircleElement(200, 200, colors.primary);
    }

    return (
        <div className={styles.container}>
            <BoxCategory
                title={t('editor.sidebar.others')}
            >
                <Masonry
                    breakpointCols={2}
                    className={styles.masonryGrid}
                    columnClassName={styles.masonryGridColumn}
                >
                    <DraggableContentItem generateElement={() => createTextElement('Hello World')}>
                        <div className={styles.item}>
                            <FaFont className={styles.itemIcon} />
                            <span>{t('editor.sidebar.text')}</span>
                        </div>
                    </DraggableContentItem>
                    <DraggableContentItem generateElement={() => createRectangleElement()}>
                        <div className={styles.item}>
                            <FaSquareFull className={styles.itemIcon} />
                            <span>{t('editor.sidebar.rectangle')}</span>
                        </div>
                    </DraggableContentItem>
                    <DraggableContentItem generateElement={() => createTriangleElement()}>
                        <div className={styles.item}>
                            <FaShapes className={styles.itemIcon} />
                            <span>{t('editor.sidebar.triangle')}</span>
                        </div>
                    </DraggableContentItem>
                    <DraggableContentItem generateElement={() => createCircleElement()}>
                        <div className={styles.item}>
                            <FaCircle className={styles.itemIcon} />
                            <span>{t('editor.sidebar.circle')}</span>
                        </div>
                    </DraggableContentItem>
                </Masonry>
            </BoxCategory>
        </div>
    );

}

export default OthersContent;
