import React, { Fragment, useState } from 'react';
import styles from './PagesItemsList.module.scss';
import { Page } from 'screens/editor/models/EditorModels';
import { useDispatch, useSelector } from 'react-redux';
import { reOrderPages } from 'store/editor/action';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import ScrollShadow from 'common/components/scrollShadow/ScrollShadow';
import PageItem from '../pageItem/PageItem';
import { DocumentFormat } from 'api/documents/enums/DocumentFormat';
import { Reducers } from 'store/types';

type Props = {
    pages: Page[];
    selectedPageId: string | null;
    onDuplicatePage: (page: Page) => void;
    onDeletePage: (page: Page) => void;
    onSetAsPreFilledModel: (page: Page) => void;
    onTimePage?: (page: Page, time: number) => void;
};

const PagesItemsList: React.FC<Props> = ({ pages, selectedPageId, onDuplicatePage, onDeletePage, onSetAsPreFilledModel, onTimePage }: Props) => {
    const dispatch = useDispatch();
    const format = useSelector<Reducers, DocumentFormat>(state => state.editor.present.format);
    const [isDragging, setIsDragging] = useState(true);
    
    const onDragEnd = (result: DropResult) => {
        // setIsDragging(false);
        if (!result.destination) {
            return;
        }

        dispatch(reOrderPages(result.source.index, result.destination.index));
    }

    const renderPages = () => {
        const isDoublePage = format === DocumentFormat.PRODUCT_BROCHURE && !isDragging;
        if (!isDoublePage) {
            return pages.map((page, i) => {
                const isActive = selectedPageId && selectedPageId === page.id ? true : false;
                return (
                    <PageItem
                        key={`${page.id}-${i}`}
                        index={i}
                        page={page}
                        onDeletePage={onDeletePage}
                        onDuplicatePage={onDuplicatePage}
                        onSetAsPreFilledModel={onSetAsPreFilledModel}
                        onTimePage={ format === DocumentFormat.SOCIAL_MEDIA_POSTS ? onTimePage : undefined}
                        selected={isActive}
                        canDelete={isActive}
                    />
                );
            });
        }

        const doublePages: Page[][] = pages.filter((_, i) => i > 0).reduce<any>((result, _value, index, array) => {
            if (index % 2 === 0) {
                result.push(array.slice(index, index + 2));
            }
            return result;
        }, []);

        const firstPage = pages[0];
        const firstPageIsActive = selectedPageId && selectedPageId === firstPage.id ? true : false;

        let index = 1;
        return (
            <Fragment>
                <PageItem
                    key={`${firstPage.id}-${0}`}
                    index={0}
                    page={firstPage}
                    onDeletePage={onDeletePage}
                    onDuplicatePage={onDuplicatePage}
                    onSetAsPreFilledModel={onSetAsPreFilledModel}
                    onTimePage={ format === DocumentFormat.SOCIAL_MEDIA_POSTS ? onTimePage : undefined}
                    selected={firstPageIsActive}
                    canDelete={firstPageIsActive}
                />
                {doublePages.map((pageContainer, i) => {
                    return (
                        <div
                            className={styles.doublePagesContainer}
                            key={`page-container-${i + 1}`}
                        >
                            {pageContainer.map((page, ii) => {
                                const isActive = selectedPageId && selectedPageId === page.id ? true : false;
                                const tempIndex = index;
                                index++;
                                return (
                                    <PageItem
                                        key={`${page.id}-${i}-${ii}`}
                                        index={tempIndex}
                                        page={page}
                                        onDeletePage={onDeletePage}
                                        onDuplicatePage={onDuplicatePage}
                                        onSetAsPreFilledModel={onSetAsPreFilledModel}
                                        onTimePage={ format === DocumentFormat.SOCIAL_MEDIA_POSTS ? onTimePage : undefined}
                                        selected={isActive}
                                        canDelete={isActive}
                                        optionsAlignLeft={ii == 0 && pageContainer.length > 1}
                                    />
                                );
                            })}
                        </div>
                    );
                })}
            </Fragment>
        );
    }

    return (
        <ScrollShadow className={styles.listContainer} scrollClassName={styles.list}>
            <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={() => setIsDragging(true)}>
                <Droppable droppableId="droppable">
                    {provided => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {renderPages()}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </ScrollShadow>
    );
}

export default PagesItemsList;
