import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './DocumentExport.module.scss';
import { DocumentViewModel } from 'api/documents/models/DocumentViewModel';
import { useTranslation } from 'react-i18next';
import OverlayedModal from '../../../common/components/overlayedModal/OverlayedModal';
import ExportItem from '../../../common/components/exportItem/ExportItem';
import Background from '../../../assets/img/no_image.png';
import Button from '../button/Button';
import { FaArrowRight } from 'react-icons/fa';
import { DocumentFormatTypeViewModel } from 'api/documents/models/DocumentFormatTypeViewModel';
import { DocumentFormat } from 'api/documents/enums/DocumentFormat';
import { DocumentExport } from 'api/documents/enums/DocumentExport';
import Loading from 'common/services/Loading';
import DocumentsService from 'api/documents/DocumentsService';
import { addToast } from 'common/services/Toaster';
import FileSaver from 'file-saver';
import EditorUtils from 'screens/editor/utils/EditorUtils';
import { EditorExportViewModel } from 'api/editor/models/EditorExportViewModel';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE, SERVER_BASE_URL } from 'Config';
import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import ProgressBar from 'common/components/progressBar/ProgressBar';
import axios, { CancelTokenSource } from 'axios';
import { createLinkUrl } from '../googleFontsLoader/GoogleFontsLoader';
import { prepareEditorFonts } from 'screens/editor/components/editorFontsLoader/EditorFontsLoader';
import { createEvent } from 'store/analytics/action';
import { AnalyticEventType } from 'api/analytics/enums/AnalyticEventType';

export interface DocumentExportModel {
    thumbnailMediaUrl: string;
}

type Props = {
    isVisible: boolean;
    documentName?: string;
    documentId: string;
    onCancel: (value?: CloseDialogResultType) => void;
    onSuccess: (value?: CloseDialogResultType) => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export type CloseDialogResultType = 'edit' | 'validate' | 'refuse';

const DocumentExportModal: React.FC<Props> = ({ isVisible, documentName, documentId, onCancel, onSuccess }: Props) => {

    const { t } = useTranslation();
    
    const dispatch = useDispatch();
    const [exportItems, setExportItems] = useState<DocumentExport[]>([]);
    const [item, setItem] = useState<DocumentViewModel | null>(null);
    const user = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const imageCacheKey = useRef(new Date().getTime())
    
    const canShowProgressBar = useRef(false);
    
    const [totalProgress, setTotalProgress] = useState(0);
    const totalrogressRef = useRef<number>();
    totalrogressRef.current = totalProgress;
    
    const [currentProgress, setCurrentProgress] = useState(0);
    const currentrogressRef = useRef<number>();
    currentrogressRef.current = currentProgress;
    
    const requestCancelToken = useRef<CancelTokenSource | null>(null);

    const getDocumentById = async () => {
        try {
            const document = await DocumentsService.getById(documentId);
            setItem(document);

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const backgroundImage: React.CSSProperties = {
        backgroundImage: 'url(' + item?.thumbnailMediaUrl + '?_=' + imageCacheKey.current + ')'
    }

    const backgroundNoImage: React.CSSProperties = {
        backgroundImage: 'url(' + Background + ')'
    }

    const exporTypesByFormat: DocumentFormatTypeViewModel[] = useMemo(() => [
        {
            title: t(('documents.format.' + DocumentFormat.PRODUCT_SHEET) as any).toString(),
            formatType: DocumentFormat.PRODUCT_SHEET,
            text: '',
            exportTypes: [
                { 
                    type: DocumentExport.PDF_PRINT, 
                    title: t(('documents.export.' + DocumentExport.PDF_PRINT) as any).toString(), 
                    text: t(('documents.export.' + DocumentExport.PDF_PRINT + '_text') as any).toString() 
                },
                { 
                    type: DocumentExport.PDF_WEB, 
                    title: t(('documents.export.' + DocumentExport.PDF_WEB) as any).toString(),
                    text: t(('documents.export.' + DocumentExport.PDF_WEB + '_text') as any).toString() 
                },
                { 
                    type: DocumentExport.JPG, 
                    title: t(('documents.export.' + DocumentExport.JPG) as any).toString(), 
                    text: t(('documents.export.' + DocumentExport.JPG + '_text') as any).toString() 
                }
            ]
        },
        {
            title: t(('documents.format.' + DocumentFormat.PRODUCT_BROCHURE) as any).toString(),
            formatType: DocumentFormat.PRODUCT_BROCHURE,
            text: '',
            exportTypes: [
                { 
                    type: DocumentExport.PDF_PRINT, 
                    text: t(('documents.export.' + DocumentExport.PDF_PRINT + '_text') as any).toString(), 
                    title: t(('documents.export.' + DocumentExport.PDF_PRINT) as any).toString() 
                },
                { 
                    type: DocumentExport.PDF_WEB, 
                    text: t(('documents.export.' + DocumentExport.PDF_WEB + '_text') as any).toString(), 
                    title: t(('documents.export.' + DocumentExport.PDF_WEB) as any).toString() 
                },
                { 
                    type: DocumentExport.JPG, 
                    text: t(('documents.export.' + DocumentExport.JPG + '_text') as any).toString(), 
                    title: t(('documents.export.' + DocumentExport.JPG) as any).toString() 
                }
            ]
        },
        {
            title: t(('documents.format.' + DocumentFormat.PRESENTATION_SLIDES) as any).toString(),
            formatType: DocumentFormat.PRESENTATION_SLIDES,
            text: '',
            exportTypes: [
                { 
                    type: DocumentExport.PDF_PRINT, 
                    text: t(('documents.export.' + DocumentExport.PDF_PRINT + '_text') as any).toString(), 
                    title: t(('documents.export.' + DocumentExport.PDF_PRINT) as any).toString() 
                },
                { 
                    type: DocumentExport.PDF_WEB, 
                    text: t(('documents.export.' + DocumentExport.PDF_WEB + '_text') as any).toString(), 
                    title: t(('documents.export.' + DocumentExport.PDF_WEB) as any).toString() 
                },
                { 
                    type: DocumentExport.JPG, 
                    text: t(('documents.export.' + DocumentExport.JPG + '_text') as any).toString(), 
                    title: t(('documents.export.' + DocumentExport.JPG) as any).toString() 
                }
            ]
        },
        {
            title: t(('documents.format.' + DocumentFormat.SOCIAL_MEDIA_POSTS) as any).toString(),
            formatType: DocumentFormat.SOCIAL_MEDIA_POSTS,
            text: '',
            exportTypes: [
                { 
                    type: DocumentExport.MP4, 
                    text: t(('documents.export.' + DocumentExport.MP4 + '_text') as any).toString(), 
                    title: t(('documents.export.' + DocumentExport.MP4) as any).toString() 
                },
                { 
                    type: DocumentExport.GIF, 
                    text: t(('documents.export.' + DocumentExport.GIF + '_text') as any).toString(), 
                    title: t(('documents.export.' + DocumentExport.GIF) as any).toString() 
                },
                { 
                    type: DocumentExport.JPG, 
                    text: t(('documents.export.' + DocumentExport.JPG + '_text') as any).toString(), 
                    title: t(('documents.export.' + DocumentExport.JPG) as any).toString() 
                }
            ]
        }
    ], []);

    const itemsExport = (type: DocumentExport, isChecked: boolean) => {
        if (isChecked) {
            setExportItems([
                ...exportItems,
                type,
            ]);
        }
        else {
            setExportItems([
                ...exportItems.filter(t => t !== type)
            ]);
        }
    }

    const onExport = async () => {
        try {
            if (exportItems.length > 0) {

                if (item != null) {
                    const editorData = EditorUtils.parseDocumentEditorData(item.editorData, item.format);

                    const links = [];
                    if (editorData.fonts && editorData.fonts.length > 0 ) {
                        links.push({
                            url: createLinkUrl(prepareEditorFonts(editorData.fonts)),
                        });
                    }

                    const model: EditorExportViewModel = { pages: [], links };

                    for (const page of editorData.pages) {
                        const html = EditorUtils.generatePageHtml(page);
                        model.pages.push({
                            number: page.number,
                            htmlContent: html,
                            width: page.width,
                            height: page.height,
                            pageTime: page.pageTime
                        });
                    }

                    requestCancelToken.current = axios.CancelToken.source();

                    const result = await DocumentsService.exportDocument({
                        model: model,
                        documentName: item.name ?? '',
                        documentId: item.id ?? '',
                        exportTypes: exportItems
                    }, requestCancelToken.current.token);

                    requestCancelToken.current = null;

                    FileSaver.saveAs(result, 'Document_' + item.name ?? '' + '.zip');

                    dispatch(createEvent({ type: AnalyticEventType.DOCUMENT_EXPORT, documentId }));

                    onSuccess && onSuccess();
                }
            } else {
                addToast(t('documents.export_files_empty'), { appearance: 'warning' });
            }
        } catch (error) {
            if (!axios.isCancel(error)) {
                Logger.error(LOGGER_LOG_TYPE.EDITOR, `Couldn't export document`, error);
                addToast(t('common.messages.error_export_support'), { appearance: 'error' });
                setTotalProgress(0);
            }
        }
    }

    const setProgressTotal = (total: number) => {
        currentrogressRef.current = 0;
        totalrogressRef.current = total;
        setTotalProgress(totalrogressRef.current);
        setCurrentProgress(currentrogressRef.current!);
    }

    const incrementProgress = () => {
        if (currentrogressRef.current! < totalrogressRef.current!) {
            currentrogressRef.current! += 1;
            setCurrentProgress(currentrogressRef.current!);
        }
    }

    const cancelRequest = () => {
        if (requestCancelToken.current) {
            requestCancelToken.current?.cancel();
        }

        onCancel();
    }

    const exportType = exporTypesByFormat.find(x => x.formatType == item?.format);

    useEffect(() => {
        getDocumentById();
    }, []);

    useEffect(() => {
        const connection = new HubConnectionBuilder()
            .withUrl(`${SERVER_BASE_URL}/hubs/editor`, {
                transport: HttpTransportType.LongPolling,
                logger: LogLevel.None,
                accessTokenFactory: () => {
                    return user!.token;
                },
            })
            .withAutomaticReconnect()
            .build();

        connection.start()
            .then(() => {
                canShowProgressBar.current = true;
                connection.on('ExportProgressTotal', setProgressTotal);
                connection.on('ExportProgressValue', incrementProgress);
            })
            .catch(e => {
                Logger.error(LOGGER_LOG_TYPE.NOTIFICATIONS, `Couldn't connect to signalr`, e);
                canShowProgressBar.current = false;
            });

        return () => {
            connection.stop()
        };
    }, []);

    if (totalProgress > 0 && canShowProgressBar) {
        return (
            <OverlayedModal isOpen={isVisible} style={{ content: { width: '40%', minWidth: '40rem', maxWidth: '100%' } }}>
                <div className={styles.container}>
                    <div className={styles.exportingContainer}>
                        <h1 className={styles.exportingTitle}>{t('documents.export_in_progress')}</h1>
                        <ProgressBar percentageValue={Math.floor((100 * currentProgress) / totalProgress)} />
                        <div className={styles.exportingButtons}>
                            <Button
                                type="button"
                                text={t('common.cancel').toUpperCase()}
                                preset="danger"
                                size="small"
                                className={styles.button}
                                onClick={cancelRequest}
                            />
                        </div>
                    </div>
                </div>
            </OverlayedModal>
        );
    }

    return (
        <OverlayedModal isOpen={isVisible}>
            <div className={styles.container}>

                <div className={styles.left}>
                    <div className={styles.imageContainer}>
                        <div className={styles.image}>
                            <div style={item?.thumbnailMediaUrl != null ? backgroundImage : backgroundNoImage} className={styles.imageContent}></div>
                        </div>
                    </div>
                </div>

                <div className={styles.right}>
                    <div className={styles.header}>
                        <div className={styles.title}>
                            {t('documents.document_export')}
                        </div>
                    </div>

                    <div className={styles.content}>

                        {(exportType?.exportTypes || []).map((docExport, i) => {
                            const checked = Boolean(exportItems.find(t => t === docExport.type));
                            return <div key={i} className={styles.export}>
                                <ExportItem type={docExport} onChangeItemExport={itemsExport} checked={checked} />
                            </div>
                        })}

                        <div className={styles.buttonsContainer}>
                            <Button
                                type="button"
                                text={t('common.cancel').toUpperCase()}
                                preset={'secondary'}
                                className={styles.button}
                                onClick={() => onCancel()} />

                            <Button
                                type="button"
                                text={t('common.export').toUpperCase()}
                                className={styles.button}
                                onClick={onExport}
                            >
                                <FaArrowRight className={styles.icon} />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

        </OverlayedModal>
    );
}

export default DocumentExportModal;
