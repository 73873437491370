import Http from '../../common/services/Http';
import { DocumentsSearchCriteria, DocumentsSearchItems } from './models/DocumentsSearchCriteria';
import { DocumentsTemplatesSearchCriteria } from './models/DocumentsTemplatesSearchCriteria';
import { DocumentViewModel, UpdateCollaboratorViewModel, ValidateDocumentViewModel } from './models/DocumentViewModel';
import { Page } from '../common/types/Page';
import { UpdateDocumentEditorDataViewModel } from './models/UpdateDocumentEditorDataViewModel';
import CustomFile from '../../common/models/CustomFile';
import { DocumentCollaboratorDto, DocumentDto } from './models/DocumentDto';
import { ExportDocumentViewModel } from './models/ExportDocumentViewModel';
import { CreatePreFilledDataViewModel } from './models/CreatePreFilledDataViewModel';
import { CropEditorImageViewModel } from './models/CropEditorImageViewModel';
import { SearchListItemsViewModel } from '../home/models/SearchListItemsViewModel';
import { CancelToken } from 'axios';

class DocumentsService {
    public getList(criteria: DocumentsSearchCriteria) {
        return Http.get<Page<DocumentDto>>('documents', criteria);
    }

    public getById(id: string) {
        return Http.get<DocumentDto>('documents/' + id);
    }

    public create(model: DocumentViewModel) {
        return Http.post<string>('documents', model);
    }

    public update(model: DocumentViewModel) {
        return Http.put('documents', model);
    }

    public validate(model: ValidateDocumentViewModel) {
        return Http.put('documents/validate', model);
    }

    public updateEditorData(model: UpdateDocumentEditorDataViewModel, thumbnail: CustomFile | null) {
        return Http.putWithFile('documents/editor-data', model, thumbnail ? [thumbnail] : []);
    }

    public createPreFilled(model: CreatePreFilledDataViewModel, thumbnail: CustomFile | null) {
        return Http.postWithFile('documents/create-pre-filled', model, thumbnail ? [thumbnail] : []);
    }

    public remove(model: { id: string }) {
        return Http.put('documents/deactivate', model);
    }

    public exportDocument(data: ExportDocumentViewModel, cancelToken?: CancelToken) {
        return Http.post<Blob>('documents/export-document', data, {
            responseType: 'blob',
            cancelToken,
        });
    }

    public getCollaboratorsById(id: string) {
        return Http.get<DocumentCollaboratorDto[]>('documents/get-collaborators/' + id);
    }

    public getCompaniesById(id: string) {
        return Http.get<DocumentCollaboratorDto[]>('documents/get-companies/' + id);
    }

    public getUsersForCollaboration(criteria: DocumentsSearchCriteria) {
        return Http.get<DocumentCollaboratorDto[]>('documents/get-user-for-collaboration/', criteria);
    }

    public getDocumentTemplates(criteria: DocumentsTemplatesSearchCriteria) {
        return Http.get<Page<DocumentDto>>('documents/get-document-templates/', criteria);
    }

    public updateCollaborators(model: UpdateCollaboratorViewModel) {
        return Http.put('documents/update-collaborators', model);
    }

    public cropEditorImage(model: CropEditorImageViewModel) {
        return Http.post<{ id: string, url: string }>('documents/crop-editor-image', model);
    }

    public getSearchItems(search: DocumentsSearchItems) {
        return Http.post<SearchListItemsViewModel>('documents/get-search-items', search);
    }
}

export default new DocumentsService();