import { IdNameDto } from 'api/common/models/IdNameDto';
import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { CheckCompanyInfoAvailabilityViewModel } from './models/CheckCompanyInfoAvailabilityViewModel';
import { CompanySearchCriteria } from './models/CompanySearchCriteria';
import { CompanyViewModel } from './models/CompanyViewModel';

class CompanyService {

    public getList(criteria: CompanySearchCriteria) {
        return Http.get<Page<CompanyViewModel>>('companies', criteria);
    }

    public getCompanyById(id: string) {
        return Http.get<CompanyViewModel>('companies/'+ id);
    }

    public save(model: CompanyViewModel) {
        return !!model.id ? Http.put('companies', model) : Http.post('companies', model);
    }

    public remove(model: CompanyViewModel) {
        return Http.put('companies/deactivate', model);
    }

    public checkCompanyInfoAvailability(model: CheckCompanyInfoAvailabilityViewModel) {
        return Http.post<any>('companies/check-company-info-availability', model);
    }
    
    public getAllCompaniesForSelectItem() {
        return Http.get<IdNameDto[]>('companies/get-all-company-for-select-item');
    }

}

export default new CompanyService();