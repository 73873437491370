import React, { useState } from 'react';
import styles from './AddPageModelModal.module.scss';
import { useTranslation } from 'react-i18next';
import OverlayedModal from 'common/components/overlayedModal/OverlayedModal';
import DocumentModelPicker from 'common/components/documentModelPicker/DocumentModelPicker';
import { DocumentViewModel } from 'api/documents/models/DocumentViewModel';
import Button from 'common/components/button/Button';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import IconPlus from 'assets/svgs/Plus-white-outlines.svg';
import { DocumentFormat } from 'api/documents/enums/DocumentFormat';

type Props = {
    isVisible: boolean;
    onApply: (doc: DocumentViewModel | undefined) => void;
    onClose: () => void;
    format?: DocumentFormat | null;
};

const AddPageModelModal: React.FC<Props> = ({ isVisible, onApply, onClose, format }: Props) => {
    const { t } = useTranslation();
    const [selectedModel, setSelectedModel] = useState<DocumentViewModel | null>(null);
    const companyId = useSelector<Reducers, string | null>(state => state.authentication.profile?.companyId || null)
    const [selectEmptyModel, setSelectEmptyModel] = useState<boolean>(false);

    const onSelectModel = (item: DocumentViewModel | null) => {
        setSelectedModel(item || null); 
        setSelectEmptyModel(false);
    }

    const onSelectNewEmptyPage = () => {
        setSelectedModel(null);
        setSelectEmptyModel(!selectEmptyModel);
    }

    return (
        <OverlayedModal isOpen={isVisible} style={{ content: { width: '80%' } }}>
            <div>
                <h1 className={styles.title}>
                    { t('add_page_model_modal.title') }
                </h1>
            </div>
            <DocumentModelPicker
                companyId={companyId}
                onSelectModel={onSelectModel}
                onSelectNewEmptyPage={onSelectNewEmptyPage}
                selectedModelId={selectedModel?.id || null}
                format={format}
                fromEditor={true}
            />
            <div className={styles.buttonsContainer} >
                <Button
                    className={styles.button}
                    type="button"
                    preset={'secondary'}
                    onClick={onClose}
                >
                    <span>{(t('common.cancel')).toUpperCase()}</span>
                </Button>

                <Button
                    className={styles.button}
                    type="button"
                    text={(t('common.add')).toUpperCase()}
                    onClick={() => onApply(!selectedModel ? undefined : selectedModel)}
                    disabled={Boolean(!((!selectedModel && selectEmptyModel) || selectedModel))}
                >
                    <img src={IconPlus} className={styles.addButtonIcon} />
                </Button>
            </div>
        </OverlayedModal>
    );
}

export default AddPageModelModal;
