import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './MiniCard.module.scss';
import { EditorChartDataValue } from 'screens/editor/models/ChartModels';
import PercentageFormat from 'common/components/percentageFormat/PercentageFormat';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Utils from 'common/services/Utils';
import ReactTooltip from 'react-tooltip';

type Props = {
    color: string;
    title: string;
    data: EditorChartDataValue;
    icon: string;
};

const MiniCard: React.FC<Props> = ({ color, title, data, icon }: Props) => {
    const { t } = useTranslation();

    const [graphWidth, setGraphWidth] = useState<number>(0);

    const ref = useRef<HTMLDivElement>(null);

    const setDimensions = () => {
        if (!ref.current) return;

        const { width } = ref.current.getBoundingClientRect();
        setGraphWidth(width);
    }

    useEffect(() => {
        setDimensions();

        function handleResize() {
            setDimensions();
        }

        window.addEventListener('resize', handleResize)
    }, []);

    const percentageValue = Number((data.tableData[0] as any)['A'] || 0);
    const tooltipValue = (data.tableData[0] as any)['B'];

    return (
        <div
            className={styles.container}
        >
            <div className={styles.title} style={{ color }}>{title}</div>
            <div className={styles.graphContainer}>
                <div
                    ref={ref}
                    className={styles.graphContainerLeft}
                >
                    <CircularProgressbarWithChildren
                        value={percentageValue}
                        counterClockwise
                        circleRatio={1}
                        styles={buildStyles({
                            rotation: 0.5,
                            trailColor: 'transparent',
                            pathColor: color,
                        })
                        }
                    >
                        <div style={{ width: '78%' }}>
                            <CircularProgressbarWithChildren
                                value={0}
                                background
                                backgroundPadding={0}
                                styles={buildStyles({
                                    trailColor: 'transparent',
                                    backgroundColor: Utils.hexToRGBA(color, .2),
                                })}
                            >
                                <img src={icon} style={{ width: (graphWidth * .4), height: (graphWidth * .4) }} />
                            </CircularProgressbarWithChildren>
                        </div>
                    </CircularProgressbarWithChildren>
                </div>
                <div className={styles.graphContainerRight}>
                    <div
                        className={styles.percentage}
                        style={{ color: color }}
                        data-for="main"
                        data-tip={tooltipValue}
                        data-iscapture="true"
                    >
                        <PercentageFormat value={percentageValue} />
                    </div>
                    <div className={styles.description}>{t('companies.analytics.charts.mini_card_document_format_description')}</div>
                </div>
            </div>
            <ReactTooltip
                id="main"
                place="top"
                type="dark"
                effect="float"
                multiline={true}
            />
        </div>
    );
}

export default MiniCard;
