import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from 'common/services/Toaster';
import ListingTable, { ListingTableCell, ListingTableColumn } from 'common/components/listingTable/ListingTable';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { FaEllipsisH } from 'react-icons/fa';
import styles from './NotificationsListScreen.module.scss';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import PageHeader from 'common/components/pageHeader/PageHeader';
import Pagination, { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from 'common/components/pagination/Pagination';
import Loading from 'common/services/Loading';
import { NotificationsTemplatesSearchCriteria } from 'api/notificationsTemplates/models/NotificationsTemplatesSearchCriteria'
import NotificationsTemplatesService from 'api/notificationsTemplates/NotificationsTemplatesService'
import { NotificationTemplateViewModel } from 'api/notificationsTemplates/models/NotificationTemplateViewModel';
import { useHistory } from 'react-router-dom';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';

export type OpenDialogType = 'details' | 'edit' | 'new' | 'profile';

const NotificationsListScreen: React.FC = () => {

    const { t } = useTranslation();
    

    const [notificationsTemplate, setNotificationsTemplate] = useState<NotificationTemplateViewModel[]>([]);

    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);


    const history = useHistory();

    const [searchValue, setSearchValue] = useState('');

    const getNotificationsTemplate = async () => {
        try {
            Loading.show();
            const criteria: NotificationsTemplatesSearchCriteria = {};

            criteria.page = currentPage;
            criteria.itemsPerPage = DEFAULT_PAGINATION_ITEMS_PER_PAGE;
            criteria.orderColumn = 'nt.title';
            criteria.orderBy = 'asc';
            criteria.name = searchValue;

            const result = await NotificationsTemplatesService.getList(criteria);
            setNotificationsTemplate(result.items);
            setTotalItems(result.totalItems);
            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.NOTIFICATIONS, `Couldn't get the notification List`, error);
            Loading.hide();
        }
    }

    const navigateTo = (type: string, id: string) => {
        history.push(`/notification/${type}/${id}`);
    }

    const renderTableActionCell = (cell: ListingTableCell) => {
        return (
            <Dropdown options={<>
                <DropdownItem onClick={() => navigateTo('details', (cell.row as NotificationTemplateViewModel).id)}>{t('common.details')}</DropdownItem>
                <DropdownItem onClick={() => navigateTo('edit', (cell.row as NotificationTemplateViewModel).id)}>{t('common.edit')}</DropdownItem>
            </>}>
                <div><FaEllipsisH /></div>
            </Dropdown>
        );
    }

    const tableColumns: ListingTableColumn[] = [
        {
            field: 'title',
            name: t('notification.list.title')
        },
        {
            field: 'emailEnabled',
            name: t('notification.list.hasEmailEnabled'),
            type: 'checkbox'
        },
        {
            field: 'pushEnabled',
            name: t('notification.list.hasPushEnabled'),
            type: 'checkbox'
        },
        {
            field: 'action',
            name: t('users.options'),
            cellAlignment: 'center',
            preventClick: true,
            renderCell: renderTableActionCell,
        }
    ];

    const onPageChange = (page: number) => {
        setCurrentPage(page);
    }

    const onChangeSearch = (text: string) => {
        setSearchValue(text);
        setCurrentPage(1);
    }

    useEffect(() => {
        getNotificationsTemplate();
    }, [currentPage, searchValue]);

    return (
        <ScreenTitle title={t('menu.notification_management')}>
            <PageHeader title={t('notification.title')} onChangeSearch={onChangeSearch} searchValue={searchValue}></PageHeader>

            <div className={styles.container}>

                <div className={styles.line}></div>

                <div style={{ minHeight: '511px', position: 'relative', marginTop: '1rem' }}>

                    <ListingTable columns={tableColumns} rows={notificationsTemplate} allowHover={true} onRowClick={row => navigateTo('details', (row as NotificationTemplateViewModel).id)} striped={true} />

                    {totalItems > 0 && <div className={styles.paginationContainer}>
                        <Pagination currentPage={1} onChange={onPageChange} totalItems={totalItems} />
                    </div>}
                </div>
            </div>
        </ScreenTitle>
    );
}

export default NotificationsListScreen;
