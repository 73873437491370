import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePageElement } from 'store/editor/action';
import EditorDefaults from '../../../../utils/EditorDefaults';
import { ToolbarOptionProps } from '../../../../utils/EditorProps';
import styles from './TextFontFamilyToolbarOption.module.scss';
import IconPlus from 'assets/svgs/toolbar/Border1_Green.svg';
import AddGoogleFontsModal from '../addGoogleFontsModal/AddGoogleFontsModal';
import { Reducers } from 'store/types';
import { EditorFont, EditorFontItem } from 'screens/editor/models/EditorModels';
import { sortBy } from 'lodash';

const defaultFonts = EditorDefaults.getFonts();

const TextFontFamilyToolbarOption: React.FC<ToolbarOptionProps> = ({ option, page, elements, ...props }: ToolbarOptionProps) => {

    const dispatch = useDispatch();
    const [isAddGoogleFontsModalOpen, setIsAddGoogleFontsModalOpen] = useState(false);
    const documentFonts = useSelector<Reducers, EditorFont[]>(state => state.editor.present.fonts || []);
    const element = elements![0];
    const selectedFont = element.style.fontFamily || defaultFonts[0].value;

    const onChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        evt.preventDefault();
        evt.stopPropagation();
        
        const el = element;
        dispatch(updatePageElement(page!, {
            ...el,
            style: {
                ...el.style,
                fontFamily: evt.target.value,
            }
        }));
    }

    const addGoogleFonts = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        evt.preventDefault();
        evt.stopPropagation();
        setIsAddGoogleFontsModalOpen(true);
    }

    const fonts = useMemo(() => {
        const list: EditorFontItem[] = [
            ...defaultFonts,
            ...documentFonts.map(f => ({
                name: f.family,
                value: f.family,
            })),
        ];
        return sortBy(list, ['name']);
    }, [documentFonts]);

    return (
        <div
            {...props}
            className={`${styles.container} ${props.className || ''}`}
        >
            <select
                value={selectedFont}
                onChange={onChange}
                className={styles.input}
                style={{ fontFamily: selectedFont }}
            >
                {fonts.map((font, i) => (
                    <option key={i} value={font.value} style={{ fontFamily: font.value }}>{font.name}</option>
                ))}
            </select>
            <div className={styles.button} onMouseDown={e => addGoogleFonts(e)}>
                <img src={IconPlus} className={styles.icon} />
            </div>
            <AddGoogleFontsModal isVisible={isAddGoogleFontsModalOpen} onClose={() => setIsAddGoogleFontsModalOpen(false)} />
        </div>
    );
}

export default TextFontFamilyToolbarOption;
