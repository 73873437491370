import React from 'react';
import styles from './SearchItem.module.scss';
import { DocumentViewModel } from '../../../api/documents/models/DocumentViewModel';
import DateFormat from '../dateFormat/dateFormat';

type Props = {
    document: DocumentViewModel;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const SearchItem: React.FC<Props> = ({ document, ...props }: Props) => {
    return (
        <div className={styles.documentsContent} {...props}>
            <div className={styles.documentsContentImage}>
                <img src={document.thumbnailMediaUrl} alt='' style={{ width: document.orientation === 'PORTRAIT' ? '3rem' : '4rem',
                height: document.orientation === 'PORTRAIT' ? '4rem' : '3rem' }}/>
            </div>
            <div className={styles.documentsContentInfos}>
                <span className={styles.documentsContentInfosTitle}>
                    {document.name}
                </span>
                <DateFormat className={styles.documentsContentInfosText} value={document.createdDate}></DateFormat>
                <span className={styles.documentsContentInfosText}>
                    {document.userCreatedName}
                </span>
            </div>
        </div> 
    );
}

export default SearchItem;