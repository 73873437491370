import scrollIntoView from 'scroll-into-view-if-needed';
import { EditorState } from '../../../store/editor/type';
import { Page, PageElement } from '../models/EditorModels';
import React from 'react';
import EditorPageRender from '../components/editorPage/EditorPageRender';
import ReactDOMServer from 'react-dom/server';
import DocumentEditorData from '../../../api/documents/models/DocumentEditorData';
import EditorDefaults from './EditorDefaults';
import { DocumentFormat } from '../../../api/documents/enums/DocumentFormat';
import Utils from '../../../common/services/Utils';
import { XYCoord } from 'react-dnd';

class EditorUtils {

    public calculateSizeByPercentage(width: number, height: number, pageWidth: number, pageHeight: number, percentage = 20): {width: number, height: number} {
        const percentageFraction = percentage / 100;
        const pagePercentageWidth = pageWidth * percentageFraction;
        const newWidth = width > pagePercentageWidth ? pagePercentageWidth : width;

        const newHeight = (newWidth * height) / width;

        return {
            width: newWidth,
            height: newHeight,
        };
    }

    public calculatePageScale(bodyWidth: number, bodyHeight: number, pageWidth: number, pageHeight: number, addPaddings = true) {
        const pageWidthPadding = addPaddings ? 100 : 0;
        const pageHeightPadding = addPaddings ? 200 : 0;

        const maxWidth = (bodyWidth - pageWidthPadding);
        const maxHeight = (bodyHeight - pageHeightPadding);

        const scale = Math.min(maxWidth / pageWidth, maxHeight / pageHeight);

        return scale;
    }

    public calculatePageSize(pageWidth: number, pageHeight: number, scale: number): { width: number; height: number } {
        const width = Math.floor(pageWidth * scale);
        const height = Math.floor(pageHeight * scale);

        return {
            width,
            height,
        };
    }

    public getCorrectDroppedOffsetValue = (initialPosition: XYCoord, finalPosition: XYCoord, dropTargetPosition: any) => {
        // get the container (view port) position by react ref...
        // const dropTargetPosition = innerRef.current.getBoundingClientRect();

        const { y: finalY, x: finalX } = finalPosition;
        const { y: initialY, x: initialX } = initialPosition;

        // calculate the correct position removing the viewport position.
        const newYposition =
            finalY > initialY
                ? initialY + (finalY - initialY) - dropTargetPosition.top
                : initialY - (initialY - finalY) - dropTargetPosition.top;

        const newXposition =
            finalX > initialX
                ? initialX + (finalX - initialX) - dropTargetPosition.left
                : initialX - (initialX - finalX) - dropTargetPosition.left;

        return {
            x: newXposition,
            y: newYposition,
        };
    };

    public getPageContainerId(pageNumber: number) {
        return `editor-page-${pageNumber}-container`;
    }

    public getPageId(pageNumber: number) {
        return `editor-page-${pageNumber}`;
    }

    public getPagePortalId(pageNumber: number) {
        return `editor-page-portal-${pageNumber}`;
    }

    public getPageElementId(id: string) {
        return `editor-page-element-${id}`;
    }

    public scrollToPage(pageNumber: number) {
        const node = document.getElementById(this.getPageContainerId(pageNumber));
        if (node) {
            scrollIntoView(node, {
                behavior: 'smooth',
                scrollMode: 'if-needed',
                block: 'start',
            });
        }
    }

    public getPageThumbnailId(pageNumber: number) {
        return `editor-page-thumbnail-${pageNumber}`;
    }

    public scrollToPageThumbnail(pageNumber: number) {
        const node = document.getElementById(this.getPageThumbnailId(pageNumber));
        if (node) {
            scrollIntoView(node, {
                behavior: 'smooth',
                scrollMode: 'if-needed',
                block: 'start',
            });
        }
    }

    public getSelectedPageFromState(state: EditorState): (Page | null) {
        if (!state.selectedPageId) {
            return null;
        }

        const page = state.pages.find(p => p.id === state.selectedPageId);
        if (!page) {
            return null;
        }

        return page || null;
    }

    public getElementFromPage(page: Page, elementId: string): (PageElement | null) {
        const element = page.elements.find(p => p.id === elementId);
        if (!element) {
            return null;
        }

        return element || null;
    }

    public getSelectedElementsFromState(state: EditorState): ([(PageElement[] | null), (Page | null)]) {
        const page = this.getSelectedPageFromState(state);
        if (!page || !state.selectedElementsIds || state.selectedElementsIds.length === 0) {
            return [null, null];
        }

        const elements = state.selectedElementsIds
            .map(elementId => this.getElementFromPage(page, elementId))
            .filter(x => x) as PageElement[];

        return [(elements || null), page];
    }

    public getFirstSelectedElementFromState(state: EditorState): ([(PageElement | null), (Page | null)]) {
        const [elements, page] = this.getSelectedElementsFromState(state); 
        return [(elements && elements.length > 0 ? elements[0] : null ), page];
    }

    public generatePageHtml(page: Page) {
        return ReactDOMServer.renderToStaticMarkup(<EditorPageRender page={page} />);
    }

    public parseDocumentEditorData(editorData: string, format: DocumentFormat): DocumentEditorData {
        const data: DocumentEditorData | null = JSON.parse(editorData);
        return (!data) ? EditorDefaults.getDefaultEditorData(format) : data;
    }

    public serializeDocumentEditorData(editorData: DocumentEditorData): string {
        return JSON.stringify(editorData);
    }

    public toggleTextTag(html: string, tag: string) {
        const tags = Utils.getAllMatches(new RegExp(`(<\/?${tag}>)`, 'gi'), html);
        if (tags && tags.length > 0) {
            return html.replaceAll(new RegExp(`(<\/?${tag}>)`, 'gi'), '');
        }

        return `<${tag}>${html}</${tag}>`;
    }
}

export default new EditorUtils();