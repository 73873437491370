export const SET_LAST_VISIT_ID = 'SET_LAST_VISIT_ID';

export type Action = SetLastVisitIdAction;

export interface SetLastVisitIdAction {
    type: typeof SET_LAST_VISIT_ID;
    lastVisitId: string;
}

export interface AnalyticsState {
    sessionId: string;
    lastVisitId: string | null;
}
