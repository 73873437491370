import React, { useState } from 'react';
import styles from './SetPageAsPreFilledModal.module.scss';
import { useTranslation } from 'react-i18next';
import OverlayedModal from 'common/components/overlayedModal/OverlayedModal';
import Button from 'common/components/button/Button';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { addToast } from 'common/services/Toaster';

type Props = {
    isVisible: boolean;
    onApply: (name: string) => void;
    onClose: () => void;
};

const SetPageAsPreFilledModal: React.FC<Props> = ({ isVisible, onApply, onClose }: Props) => {
    const { t } = useTranslation();
    

    const [modelName, setModelName] = useState<string>('');

    const onClickApply = () => {
        if(!modelName || modelName.length === 0) {
            addToast(t('set_page_as_pre_filled_modal.warning_input_not_filled'), { appearance: 'warning' });
            return;
        }

        onApply(modelName);
    }

    return (
        <OverlayedModal isOpen={isVisible} style={{ content: { maxWidth: '80%' } }}>
            <div className={styles.modalTitle} dangerouslySetInnerHTML={
                { __html: t('set_page_as_pre_filled_modal.title') }}>
            </div>

            <div className={styles.modalDescription} dangerouslySetInnerHTML={{ 
                __html: t('set_page_as_pre_filled_modal.description') }}>
            </div>

            <input
                value={modelName}
                type="text"
                placeholder={t('set_page_as_pre_filled_modal.input').toUpperCase()}
                className={styles.input}
                onChange={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    setModelName(e.target.value);
                }}
            />

            <div className={styles.buttonsContainer} >
                <Button
                    className={styles.button}
                    type="button"
                    preset={'secondary'}
                    onClick={onClose}
                >
                    <span>{(t('common.cancel')).toUpperCase()}</span>
                </Button>

                <Button
                    className={styles.button}
                    type={'button'}
                    text={(t('common.save')).toUpperCase()}
                    onClick={() => onClickApply()}
                >
                    <FaCheck className={styles.icon} />
                </Button>
            </div>
        </OverlayedModal>
    );
}

export default SetPageAsPreFilledModal;
