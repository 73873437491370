import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './InformationSlide.module.scss';
import FormItem from '../../../../../../common/components/formItem/FormItem';
import Label from '../../../../../../common/components/label/Label';
import Input from '../../../../../../common/components/input/Input';
import Tags from '../../../../../../common/components/tags/Tags';
import { Controller, UseFormMethods } from 'react-hook-form';
import { DocumentCompanyViewModel, DocumentViewModel } from '../../../../../../api/documents/models/DocumentViewModel';
import NavigationButtons from '../navigationButtons/NavigationButtons';
import IllustrationSave from '../../../../../../assets/svgs/Illustration-Save.svg'
import TagsService from '../../../../../../api/tags/TagsService';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../../../../store/types';
import { UserProfile } from '../../../../../../api/account/models/UserProfile';
import { TagsSearchCriteria } from '../../../../../../api/tags/models/TagsSearchCriteria';
import { addToast } from 'common/services/Toaster';
import { Tag } from 'react-tag-autocomplete';
import Select from 'common/components/select/Select';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import CompaniesService from 'api/companies/CompaniesService';
import { DocumentType } from '../../../../../../api/documents/enums/DocumentType';
import { isTablet, IS_ADMIN } from 'Config';

type Props = {
    onPreviousSlide: () => void;
    onNextSlide: () => void;
    form: UseFormMethods<DocumentViewModel>;
    mode: string | null; // TODO: usar enum
    documentType?: DocumentType;
    nextButtonText: string;
};

const InformationSlide: React.FC<Props> = ({ onPreviousSlide, onNextSlide, form, mode, documentType, nextButtonText }: Props) => {
    const { t } = useTranslation();
    
    const [tags, setTags] = useState<Tag[]>([]);
    const [currentTags, setCurrentTags] = useState<Tag[]>([]);

    const [companiesOptions, setCompanies] = useState<SelectValueLabel[]>([]);

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const isAdmin = IS_ADMIN(loggedUser);

    const criteriaTag: TagsSearchCriteria = {};

    const [hasName, setHasName] = useState<boolean | null>(null);

    const getData = async () => {
        try {
            criteriaTag.companyId = loggedUser?.companyId;
            const tagsResult = await TagsService.getList(criteriaTag);
            const companies = await CompaniesService.getAllCompaniesForSelectItem();

            setTags(tagsResult.map(x => ({ id: x.id, name: x.description })));
            setCompanies(companies.map(x => ({ value: x.id || '', label: x.name || '' })));
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    useEffect(() => {
        getData();
    }, []);

    const onChangeName = (name: string) => {
        setHasName((name == null || name == '') ? false : true);
    }

    const isTabletSize = isTablet(window.innerWidth);

    return (
        <>
            <div className={styles.content}>
                <div className={styles.form}>
                    <div className={styles.title}>
                        { documentType === DocumentType.SIMPLE_MODEL && t('documents.register_document_simple_model') }
                        { documentType !== DocumentType.SIMPLE_MODEL && isAdmin && t('documents.register_document_model') }
                        { documentType !== DocumentType.SIMPLE_MODEL && !isAdmin && t('documents.register_document_support') }
                    </div>

                    <FormItem className={styles.marginBottom2}>
                        <Label className={styles.label}>{t('documents.add_file_name')}</Label>
                        <Input className={styles.input} name="name" onChangeText={onChangeName}
                            placeholder={t('documents.file_name').toUpperCase()}
                            ref={form.register({ required: true })} disabled={mode == 'details'} />
                    </FormItem>

                    <FormItem className={styles.marginBottom2}>
                        <Label className={styles.label}>
                            { documentType === DocumentType.SIMPLE_MODEL && t('documents.tags_text_simple_model') }
                            { documentType !== DocumentType.SIMPLE_MODEL && isAdmin && t('documents.tags_text_model') }
                            { documentType !== DocumentType.SIMPLE_MODEL && !isAdmin && t('documents.tags_text_support') }
                        </Label>
                        <div className={styles.tag}>
                            <Controller
                                render={({ onChange, value }) => {
                                    return (
                                        <Tags
                                            placeholderText={t('documents.tags')}
                                            onAddition={tag => {
                                                onChange([
                                                    ...(form.getValues<string, string[]>('tags') || []),
                                                    tag.name
                                                ]);
                                                if (!(tags || []).find(x => x.name == tag.name) && !(currentTags || []).find(x => x.name == tag.name)) {
                                                    setCurrentTags([...(currentTags || []), { id: '', name: tag.name }])
                                                }
                                            }}
                                            onDelete={tagIndex => {
                                                const tagNameRemove = (form.getValues<string, string[]>('tags') || []).filter((_, i) => i === tagIndex)[0];
                                                onChange((form.getValues<string, string[]>('tags') || []).filter((_, i) => i !== tagIndex));
                                                if ((currentTags || []).find(x => x.name == tagNameRemove)) {
                                                    setCurrentTags((currentTags || []).filter(x => x.name !== tagNameRemove));
                                                }
                                            }}
                                            tags={(value || []).map((x: string) => ({ name: x }))}
                                            allowNew={true}
                                            suggestions={tags.concat(currentTags)}
                                            addOnBlur={true} />
                                    );
                                }}
                                control={form.control}
                                name="tags"
                                defaultValue={form.getValues('tags')} />
                        </div>
                    </FormItem>

                    {!loggedUser?.companyId &&
                        <FormItem className={styles.marginBottom2}>
                            <Label className={styles.label}>
                                { documentType === DocumentType.SIMPLE_MODEL && t('documents.select_companies_simple_model') }
                                { documentType !== DocumentType.SIMPLE_MODEL && isAdmin && t('documents.select_companies_model') }
                                { documentType !== DocumentType.SIMPLE_MODEL && !isAdmin && t('documents.select_companies_support') }
                            </Label>
                            <div className={styles.company}>
                                <Controller
                                    render={({ onChange, value }) => {
                                        return (
                                            <Select
                                                maxHeight={'13rem'}
                                                options={companiesOptions}
                                                isClearable
                                                isMulti
                                                placeholder={t('documents.companies')}
                                                onChange={(data: SelectValueLabel[]) => {
                                                    onChange(data?.map(x => ({ companyId: x.value })));
                                                }}
                                                value={value ? companiesOptions.filter(x => value.find((y: DocumentCompanyViewModel) => y.companyId === x.value)) : []}
                                                filterOption={(candidate: any, input: any) => input ? candidate.label.toUpperCase().includes(input.toUpperCase()) : true}
                                            />
                                        );
                                    }}
                                    control={form.control}
                                    name="companies"
                                    defaultValue={form.getValues('companies')}
                                />
                            </div>
                        </FormItem>
                    }
                    
                    <div className={styles.buttons}>
                        <NavigationButtons
                            onBack={onPreviousSlide}
                            onNext={(!hasName) ? undefined : onNextSlide}
                            nextDisabled={!hasName}
                            backText={t('common.back')}
                            nextText={nextButtonText}
                            iconBack={true}
                            iconNext={true}
                            style={{ padding: isTabletSize ? '0' : '0 2rem' }}
                        />
                    </div>
                </div>
                <div className={styles.image} style={{ backgroundImage: `url('${IllustrationSave}')` }}>
                </div>
            </div>
        </>
    );
}

export default InformationSlide;


