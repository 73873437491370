import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from 'common/services/Toaster';
import styles from './TipScreen.module.scss';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import PageHeader from 'common/components/pageHeader/PageHeader';
import Loading from 'common/services/Loading';
import { useHistory, useParams } from 'react-router-dom';
import InputWithError from 'common/components/input/InputWithError';
import FormItem from 'common/components/formItem/FormItem';
import { useForm } from 'react-hook-form';
import TextAreaWithHelper, { ContextMenuOption } from 'common/components/textAreaWithHelper/TextAreaWithHelper';
import Button from 'common/components/button/Button';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import TipsService from 'api/tips/TipsService';
import { TipViewModel } from 'api/tips/models/TipViewModel';
import RemoveMessage from 'common/components/removeMessage/RemoveMessage';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';

type Form = {
    text: string;
    title: string;
    icon: any;
}

const TipScreen: React.FC = () => {

    const { t } = useTranslation();
    
    const { register, handleSubmit, setValue, clearErrors, getValues } = useForm<Form>({ shouldUnregister: false });

    const { id, type } = useParams<{ id: string, type: string }>();

    const history = useHistory();
    const isDetails = type !== null && type === 'details' ? true : false
    const [tips, setTips] = useState<TipViewModel>();

    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [itemToRemove, setItemToRemove] = useState<TipViewModel>();

    const contextOptions: ContextMenuOption[] = [];

    const getData = async () => {
        try {

            if (id) {
                Loading.show();
                const result = await TipsService.getById(id);
                if (result === undefined) {
                    addToast(t('common.messages.error_load_info'), { appearance: 'error' });
                    Logger.error(LOGGER_LOG_TYPE.TIPS, `Couldn't get the tip with id: ${id}`);
                    navigateTo();
                }

                setValue('title', result.title);
                setValue('text', result.text);
                setTips(result);
                Loading.hide();

            }

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.TIPS, `Couldn't get the tip with id: ${id}`, error);
            navigateTo();
            Loading.hide();
        }
    }

    const onSubmit = async (form: Form) => {
        try {

            if ((!isDetails && (!form.title || !form.text))) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            Loading.show();

            const model: TipViewModel = { ...tips, id: id, ...form };
            let resultId = await TipsService.save(model);

            if (!resultId) {
                resultId = model.id;
            }

            const result = await TipsService.getById(resultId as any);
            setTips(result);

            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            navigateTo('details', resultId as any);

        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.TIPS, `Couldn't update the tip with id: ${id}`, error);
            Loading.hide();
        }
    };

    const navigateTo = (type?: string, id?: string) => {
        if (!type) {
            history.push(`/tips`);
            return;
        }
        history.push(`/tips/${type}/${id}`);
        return;
    }

    const showRemoveItemDialog = (item: TipViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();

                await TipsService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });

                navigateTo();
                getData();
                Loading.hide();
            } catch (error) {
                Logger.error(LOGGER_LOG_TYPE.TIPS, `Couldn't remove tip`, error);
                addToast(t('common.messages.record_delete_error'), { appearance: 'success' });
                Loading.hide();
            }
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <ScreenTitle title={t('tips.tips_management')}>
            <PageHeader title={t('tips.tips_management')} hideSearchIcon={true}></PageHeader>

            <div className={styles.container}>
                <div className={styles.line}></div>

                <div style={{ minHeight: '511px', position: 'relative', marginTop: '1rem' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormItem>
                            <h2 className={styles.label}>{t('tips.title')}</h2>
                            <InputWithError
                                name="title"
                                ref={register({ required: true, maxLength: 30 })}
                                limit={30}
                                placeholder={t('tips.tip_title')}
                                disabled={isDetails}
                                defaultValue={getValues('title')}
                                error={{ type: 'too.many.characters' }}
                                onErrorClick={() => clearErrors('title')}
                            />
                        </FormItem>

                        <FormItem>
                            <h2>{t('tips.text')}</h2>
                            <TextAreaWithHelper style={{ marginBottom: '1rem' }} id={'text'} key="text" value={getValues('text')} setValue={(content: string) => setValue('text', content)} contextMenuOptions={contextOptions} readOnly={isDetails}></TextAreaWithHelper>
                        </FormItem>

                        <div className={styles.buttonsContainer} >
                            <div className={styles.buttonRemove}>
                                {isDetails && <RemoveMessage
                                    message={(t('common.remove'))}
                                    onElementClick={() => { showRemoveItemDialog({ id: id } as TipViewModel) }}
                                />}

                                <QuestionYesNo
                                    title={t('common.remove')}
                                    message={t('common.messages.remove_record')}
                                    isVisible={dialogDeleteItemIsOpen}
                                    onYes={() => removeItem()}
                                    onNo={() => setDialogDeleteItemIsOpen(false)} />

                            </div>
                            <div className={styles.buttons}>
                                <Button
                                    type="button"
                                    text={t('common.cancel').toUpperCase()}
                                    preset={'secondary'}
                                    onClick={() => navigateTo()}
                                    className={styles.button}
                                />
                                {isDetails && <Button
                                    type="button"
                                    text={t('common.edit').toUpperCase()}
                                    onClick={() => navigateTo('edit', id)}
                                    className={styles.button}
                                >
                                </Button>}
                                {!isDetails && <Button
                                    type={'submit'}
                                    text={t('common.save').toUpperCase()}
                                    className={styles.button}
                                >
                                </Button>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </ScreenTitle>
    );
}

export default TipScreen;
