import React, { useState } from 'react';
import styles from './PageToolbar.module.scss';
import { Page } from 'screens/editor/models/EditorModels';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { removePage } from 'store/editor/action';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import IconTrash from 'assets/svgs/Trash-red.svg';

type Props = {
    page: Page;
    canDelete: boolean;
    selected: boolean;
};

const PageToolbar: React.FC<Props> = ({ page, canDelete, selected }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [showDeletePageModal, setShowDeletePageModal] = useState(false);

    const deletePage = () => {
        if (!canDelete) {
            return;
        }

        dispatch(removePage(page));
        setShowDeletePageModal(false);
    }

    return (
        <div className={styles.toolbar}>
            <div className={styles.toolbarTitle}>{t('editor.page')} {page.number}</div>
            {selected && <div className={styles.toolbarItems}>
                {canDelete && <div className={styles.toolbarItem} onClick={() => setShowDeletePageModal(true)}>
                    <img src={IconTrash} className={styles.toolbarItemIcon} />
                </div>}
            </div>}
            <QuestionYesNo
                title={t('common.remove')}
                message={t('editor.remove_page_message')}
                isVisible={showDeletePageModal}
                onYes={() => deletePage()}
                onNo={() => setShowDeletePageModal(false)}
            />
        </div>
    );

}

export default PageToolbar;
