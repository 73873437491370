import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import LoadingProvider from './common/components/loading/LoadingProvider';
import i18n from './common/services/I18n';
import DefaultRouter from './Router';
import Setup from './Setup';
import store from './store/store';
import NotificationsProvider from './common/providers/notifications/NotificationsProvider';

const App: React.FunctionComponent = () => {
    return (
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <DndProvider backend={HTML5Backend}>
                <Toaster/>
                    <Setup>
                        <DefaultRouter />
                        <NotificationsProvider />
                    </Setup>
                    <LoadingProvider />
                </DndProvider>
            </I18nextProvider>
        </Provider>
    );
}

export default App;
