import React, { useMemo } from 'react';
import EditorDefaults from '../../../../utils/EditorDefaults';
import { ElementProps } from '../../../../utils/EditorProps';
import Prefixer from 'common/services/Prefixer';

type Props = {
    clipActive?: boolean;
} & ElementProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GifElementRender = ({ page, element, selected, clipActive = false, ...props }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {

    const style = useMemo(() => Prefixer.prefixStyle(EditorDefaults.getElementStyles(element)), [element]);
    
    // Typeof for legacy reasons
    const url: string = typeof element.value === 'string' ? element.value : element.value.url;

    return (
        <div
            {...props}
            ref={ref}
            style={style}
        >
            <img
                src={url}
                style={{
                    width: `100%`,
                    height: `100%`,
                }}
            />
        </div>
    );
}

export default React.forwardRef<HTMLDivElement, Props>(GifElementRender);
