import React, { memo,  ReactNode,useMemo } from 'react';
import styles from './EditorPageContainer.module.scss';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import EditorUtils from '../../utils/EditorUtils';

type Props = {
    bodyWidth: number;
    bodyHeight: number;
    pageWidth: number;
    pageHeight: number;
    children: (width: number, height: number, scale: number) => ReactNode;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const EditorPageContainer: React.FC<Props> = ({ children, bodyWidth, bodyHeight, pageWidth, pageHeight, ...props }: Props) => {

    const zoom = useSelector<Reducers, number | null>(state => state.editor.present.zoom);

    const pageScale = useMemo(() =>
        EditorUtils.calculatePageScale(bodyWidth, bodyHeight, pageWidth, pageHeight),
        [bodyWidth, bodyHeight, pageWidth, pageHeight]
    );
    const scale = (!zoom ? pageScale : ((zoom * pageScale) / 100));

    const { width, height } = EditorUtils.calculatePageSize(pageWidth, pageHeight, scale);

    return (
        <div
            {...props}
            className={`${styles.container} ${props.className || ''}`}
            style={{ ...(props.style || {}), width: `${width}px`, height: `${height}px` }}
        >
            {children(width, height, scale)}
        </div>
    );
}

export default memo(EditorPageContainer);
