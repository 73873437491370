import React from 'react';
import styles from './Editor.module.scss';
import EditorSidebar, { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPEN_WIDTH } from '../editorSidebar/EditorSidebar';
import EditorBody from '../editorBody/EditorBody';
import { useWindowSize } from '@react-hook/window-size';
import EditorSideOptions, { SIDE_OPTIONS_WIDTH } from '../editorSideOptions/EditorSideOptions';
import EditorAutoSave from '../editorAutoSave/EditorAutoSave';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import EditorFontsLoader from '../editorFontsLoader/EditorFontsLoader';

const Editor = () => {
    const isSidebarOpen = useSelector<Reducers, boolean>(state => state.editor.present.sidebarState.selectedCategoryId !== null);
    const [windowWidth, windowHeight] = useWindowSize()
    const sidebarWidth = isSidebarOpen ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH;
    const bodyWidth = (windowWidth || 0) - sidebarWidth - SIDE_OPTIONS_WIDTH;
    
    return (
        <div className={styles.container}>
            <EditorFontsLoader />
            <EditorSidebar />
            <EditorBody width={bodyWidth} height={windowHeight} sidebarWidth={sidebarWidth} />
            <EditorSideOptions />
            <EditorAutoSave />
        </div>
    );
}

export default Editor;
