import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from 'common/services/Toaster';

import ValidateDocumentModal, { CloseDialogResultType } from 'screens/documents/list/components/modal/DocumentModal';

import Loading from 'common/services/Loading';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import PageHeader from 'common/components/pageHeader/PageHeader';
import InfinityScroll from 'common/components/infinityScroll/InfinityScroll';

import DocumentsService from 'api/documents/DocumentsService';
import { DocumentViewModel } from 'api/documents/models/DocumentViewModel';
import { DocumentsSearchCriteria } from 'api/documents/models/DocumentsSearchCriteria';
import { DocumentVisibility, DocumentVisibilityAction } from 'api/documents/enums/DocumentVisibility';

import BoxItem from 'screens/documents/list/components/box/BoxItem';
import styles from './ListDocumentsToValidateScreen.module.scss';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import EditorDefaults from 'screens/editor/utils/EditorDefaults';
import { useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { Reducers } from 'store/types';
import { PreviousPage } from 'api/editor/enums/PreviousPage';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from 'common/components/pagination/Pagination';
import { useHistory } from 'react-router-dom';

const ListDocumentsToValidateScreen: React.FC = () => {

    const { t } = useTranslation();
    
    const history = useHistory();

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const companyId = loggedUser?.companyId;

    const [documents, setDocuments] = useState<DocumentViewModel[]>([]);

    const [openDialog, setOpenDialog] = React.useState<string | null>(null);
    const [openRemoveDialog, setOpenRemoveDialog] = React.useState<boolean>(false);
    const [openDialogItem, setOpenDialogItem] = React.useState<DocumentViewModel | null>(null);

    const [totalItems, setTotalItems] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    const [criteria, setCriteria] = useState<DocumentsSearchCriteria>({
        page: 1, 
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE, 
        visibility: DocumentVisibility.PENDING,
        isAdmin: !companyId ? true : false, 
        companyId: companyId
    });
    const [searchValue, setSearchValue] = useState('');

    const getData = async () => {
        try {
            Loading.show();

            const docs = await DocumentsService.getList({
                ...criteria,
                orderColumn: 'name',
                orderBy: 'asc',
                name: searchValue
            });

            // Convert string to date
            docs?.items?.map(doc => doc.createdDate = new Date(doc.createdDate));

            docs?.items?.map(doc => {
                if (doc) {
                    const PageInfo = EditorDefaults.getPageSizeByFormat(doc.format);
                    doc.orientation = PageInfo.orientation;
                }
            });

            setDocuments([
                ...(criteria.page == 1 ? [] : documents),
                ...docs?.items
            ]);
            setTotalItems(docs?.totalItems || 1);
            setTotalPages(docs?.totalPages || 1);

            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.DOCUMENTS, `Couldn't get the documents `, error);
            Loading.hide();
        }
    }

    const validateDocument = async (id: string, visibilityAction: DocumentVisibilityAction) => {
        Loading.show();
        try {
            await DocumentsService.validate({ id, visibilityAction });
            setCriteria({ ...criteria, page: 1 });
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.DOCUMENTS, `Couldn't validate the documents `, error);
        }
        Loading.hide();
    }

    const openModal = (item: DocumentViewModel | null) => {
        setOpenDialog('validate');
        setOpenDialogItem(item);
    };

    const onUserModalClose = (result?: CloseDialogResultType) => {
        setOpenDialog(null);
        if (openDialogItem?.id) {
            if (result === 'validate') {
                validateDocument(openDialogItem.id, DocumentVisibilityAction.ACCEPTED);
            }
            else if (result === 'refuse') {
                validateDocument(openDialogItem.id, DocumentVisibilityAction.REJECTED);
            }
            else if (result === 'edit') {
                history.push(`/editor/${openDialogItem.id}/${PreviousPage.DOCUMENTS_VALIDATE}`);
            }
        }
    }

    const openModalRemoveDocument = () => {
        setOpenDialog(null);
        setOpenRemoveDialog(true);
    };

    const closeModalRemoveDocumentYes = () => {
        setOpenRemoveDialog(false);
        setOpenDialogItem(null);
    }

    const closeModalRemoveDocumentNo = () => {
        setOpenRemoveDialog(false);
        setOpenDialog('validate');
    }

    const removeItem = async () => {
        try {
            Loading.show();
            if (openDialogItem) {
                await DocumentsService.remove({ id: openDialogItem?.id });
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                closeModalRemoveDocumentYes();
                setCriteria({ ...criteria, page: 1 });
            }
            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            Loading.hide();
            closeModalRemoveDocumentYes();
        }
    };

    const onChangeSearch= (text: string) => {
        setSearchValue(text);
        setCriteria({
            ...criteria,
            page: 1
        });
    }

    useEffect(() => {
        getData();
    }, [criteria, searchValue]);

    return (
        <ScreenTitle title={t('menu.documents_validate')}>
            <PageHeader title={t('documents_validate.title')} onChangeSearch={onChangeSearch} searchValue={searchValue}></PageHeader>

            <div className={styles.container}>

                <div className={styles.line}></div>

                <div className={styles.content}>
                    <InfinityScroll
                        inModal={false}
                        totalItems={documents.length}
                        executeOnScroll={() => {
                            setCriteria({
                                ...criteria,
                                page: criteria.page + 1
                            });
                        }}
                        continueScroll={documents.length < totalItems}
                        elementIdToScroll='scrollHere'
                    >
                        <div className={styles.list}>
                            {
                                documents.map((value, index) => {
                                    return <BoxItem
                                        key={index}
                                        document={value}
                                        onClick={() => openModal(value)}
                                    />
                                })
                            }
                        </div>
                    </InfinityScroll>
                </div>

                {documents.length == 0 && <div className={styles.withoutElements}>
                    {t('common.without_elements')}
                </div>}

                <div>
                    {openDialog !== null && openDialogItem &&
                        <ValidateDocumentModal
                            mode={openDialog}
                            item={openDialogItem}
                            isVisible={openDialog ? true : false}
                            onCancel={onUserModalClose}
                            onRemove={openModalRemoveDocument}
                        />
                    }
                </div>
                <div>
                    {openRemoveDialog != null && <QuestionYesNo
                        title={t('common.remove')}
                        message={t('common.messages.remove_record')}
                        isVisible={openRemoveDialog}
                        onYes={() => removeItem()}
                        onNo={() => { closeModalRemoveDocumentNo(); }}
                    />}
                </div>
            </div>
        </ScreenTitle>
    );
}

export default ListDocumentsToValidateScreen;
