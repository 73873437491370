import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from 'common/services/Toaster';
import Button from '../../common/components/button/Button';
import Loading from '../../common/services/Loading';
import styles from './UserModal.module.scss';
import UsersService from '../../api/users/UsersService';
import { UserViewModel } from '../../api/users/models/UserViewModel';
import { FaArrowRight, FaCheck, FaEdit } from 'react-icons/fa';
import FormItem from '../../common/components/formItem/FormItem';
import InputWithError from '../../common/components/input/InputWithError';
import CheckBox from '../../common/components/checkBox/CheckBox';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import QuestionYesNo from '../../common/components/questionYesNo/QuestionYesNo';
import UserService from '../../api/users/UsersService';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from '../../store/types';
import { UserProfile } from '../../api/account/models/UserProfile';
import IconClose from '../../assets/svgs/Cross-bleu2-contour.svg';
import IconCloseRed from 'assets/svgs/cross-red-outlines.svg';
import image from '../../assets/img/addUser.jpg';
import { logout, setAuthentication } from '../../store/authentication/action';
import OverlayedModal from '../../common/components/overlayedModal/OverlayedModal';
import Label from '../../common/components/label/Label';
import EditableLabel from '../../common/components/editableLabel/EditableLabel';
import RemoveMessage from 'common/components/removeMessage/RemoveMessage';
import { IS_ADMIN } from 'Config';

type Props = {
    userId?: string;
    isVisible: boolean;
    mode: string | null;
    item: UserViewModel | null;
    onCancel: () => void;
};

type Form = {
    realName: string;
    userName: string;
    email: string;
    roles: FormRole[];
    password: string;
    passwordRepeat: string;
}

type FormRole = {
    name: string;
    realName: string;
    checked: boolean;
}

const UserModal: React.FC<Props> = ({ isVisible, mode, item, onCancel }: Props) => {

    const { t } = useTranslation();
    
    const { register, handleSubmit, errors, setValue, control, watch, clearErrors } = useForm<Form>({ shouldUnregister: false });
    const [itemToRemove, setItemToRemove] = useState<UserViewModel>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [emailIsAvailable, setEmailIsAvailable] = useState<boolean>(true);

    const [showDelete, setShowDelete] = useState<boolean>(false);

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const isAdmin = IS_ADMIN(loggedUser);

    const [editUsernameError, setEditUsernameError] = useState<boolean>(false);

    const [currentMode, setCurrentMode] = useState<string | null>(mode);

    const [roleError, setRoleError] = useState<boolean>(false);

    const dispatch = useDispatch();

    const roles = useFieldArray<FormRole>({
        control: control,
        name: 'roles',
    });

    const onSubmit = async (form: Form) => {
        try {
            if (currentMode === 'details') {
                setCurrentMode('edit');
                return;
            }

            if (currentMode != 'profile') {
                if ((form.roles.filter(x => x.checked == true).length == 0)) {
                    setRoleError(true);
                }
                if (!form.realName || !form.email || !form.roles || (form.roles.filter(x => x.checked == true).length == 0)) {
                    addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                    return;
                }
            }
            else {
                if (!form.realName || !form.email) {
                    if (!form.realName || form.realName != '') {
                        setEditUsernameError(true);
                    }
                    addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                    return;
                }
            }

            Loading.show();

            const result = await UsersService.checkEmailAvailability({ userId: item?.id, userName: form.userName, email: form.email });

            if (result != null && result.available == false) {
                if (!!result.errors) {
                    if (!!result.errors.find((x: string) => x == 'EMAIL')) {
                        setEmailIsAvailable(false);
                        addToast(t('common.errors.email_not_available'), { appearance: 'warning' });
                    }
                }
                Loading.hide();
                return;
            }

            const model = {
                ...form,
                id: item?.id,
                userName: form.userName || form.email,
                roles: form.roles.filter(x => x.checked == true).find(y => y.name)?.name,
                companyId: !!loggedUser?.companyId ? loggedUser?.companyId : null
            }

            if (loggedUser && currentMode == 'profile') {
                dispatch(setAuthentication({
                    ...loggedUser,
                    realName: form.realName
                }));
            }

            await UsersService.save(model);
            setValue('userName', form.email);
            addToast(t('common.messages.record_save_success'), { appearance: 'success' });

            Loading.hide();

            if (currentMode != 'profile') {
                onCancel();
            }
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const getData = async () => {
        try {
            Loading.show();

            const allRoles = await UserService.getRoles({ companyId: loggedUser?.companyId, orderColumn: 'real_name' });

            if (item?.id) {
                const result = await UsersService.getUserById(item.id);
                setValue('realName', result?.realName);
                setValue('email', result?.email);
                setValue('userName', result?.userName);
                setValue('roles', allRoles.map(x => ({ realName: x.realName, name: x.name, checked: (result.roles == x.name ? true : false) })));
            } else {
                setValue('roles', allRoles.map(x => ({ realName: x.realName, name: x.name, checked: false })));
            }

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const showRemoveItemDialog = (item: UserViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await UsersService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                if (itemToRemove.id == loggedUser?.id) {
                    dispatch(logout());
                } else {
                    onCancel();
                }
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'success' });
                Loading.hide();
            }
        }
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [currentMode]);

    const onChangeText = (text: string) => {
        setValue('realName', text);
        setEditUsernameError(false);
    }

    return (
        <OverlayedModal isOpen={isVisible} className={showDelete ? styles.showDelete : ''}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.container}>
                    <div className={styles.left}>
                        <div className={styles.image}>
                            <img src={image} />
                        </div>
                    </div>

                    <div className={styles.right}>
                        <div className={styles.header}>
                            <div className={styles.title}>
                                {currentMode == 'new' && t('users.add_user')}
                                {currentMode == 'edit' && t('users.edit')}
                                {currentMode == 'details' && t('users.details')}
                                {currentMode == 'profile' && t('menu.my_profile')}
                            </div>
                            <img src={IconClose} onClick={onCancel} className={styles.icon} />
                        </div>

                        {currentMode != 'profile' && <div className={styles.form}>
                            <FormItem>
                                <h2 className={styles.h2} >{t('users.user')}</h2>

                                <InputWithError
                                    error={errors.realName}
                                    name="realName"
                                    placeholder={t('users.user_real_name')}
                                    ref={register({ required: true, maxLength: 255 })}
                                    disabled={currentMode == 'details'}
                                    onErrorClick={() => clearErrors('realName')}
                                />

                                <InputWithError
                                    error={!emailIsAvailable ? { type: 'email_not_available' } : errors.email}
                                    type="email"
                                    name="email"
                                    placeholder={t('users.user_address')}
                                    ref={register({ required: true, maxLength: 255 })}
                                    disabled={currentMode == 'details'}
                                    onErrorClick={() => clearErrors('email')} />

                                <div className={styles.rolesContainer}>
                                    <div className={styles.roles}>
                                        {roles.fields.map((role, i) => {
                                            const itemFormKey = `roles[${i}].checked`;
                                            return (
                                                <Controller
                                                    key={i}
                                                    control={control}
                                                    render={({ value }) => {
                                                        return (
                                                            <CheckBox
                                                                onChange={e => {
                                                                    setValue(`roles`, [
                                                                        ...roles.fields
                                                                            .sort(function (a, b) {
                                                                                if (!a.realName || !b.realName) return 0;
                                                                                if (a.realName < b.realName) { return -1; }
                                                                                if (a.realName > b.realName) { return 1; }
                                                                                return 0;
                                                                            })
                                                                            .map((x, xi) => {
                                                                                x.checked = (xi === i) ? true : false;

                                                                                return x;
                                                                            })
                                                                    ]);

                                                                    setRoleError(false);
                                                                }}
                                                                checked={value ? true : false}
                                                                disabled={currentMode == 'details'}
                                                            >
                                                                <a className={styles.a}>{t(('users.roles.' + role.realName) as any).toUpperCase()}</a>
                                                            </CheckBox>
                                                        );
                                                    }}
                                                    name={itemFormKey}
                                                    defaultValue={watch(itemFormKey) || false}
                                                />
                                            );
                                        })}
                                    </div>
                                    {roleError && <div className={styles.rolesError}>
                                        <div className={styles.showError}>
                                            <img src={IconCloseRed} className={styles.iconError} /> {t('common.errors.required')}
                                        </div>
                                    </div>}

                                </div>
                            </FormItem>
                        </div>}

                        {currentMode == 'profile' && <div className={styles.form}>
                            <FormItem>
                                <div className={editUsernameError ? styles.editableError : styles.editable}>
                                    <EditableLabel
                                        text={loggedUser?.realName ? loggedUser.realName : ''}
                                        onChangeText={onChangeText}
                                        placeholder={t('users.user_real_name')} />
                                </div>

                                <InputWithError
                                    error={!emailIsAvailable ? { type: 'email_not_available' } : errors.email}
                                    type="email"
                                    name="email"
                                    placeholder={t('users.user_address')}
                                    ref={register({ required: true, maxLength: 255 })}
                                    onErrorClick={() => clearErrors('email')} />

                                <Label className={watch('password') ? styles.labelActive : styles.label}>{t('profile.new_password').toUpperCase()}</Label>
                                <InputWithError
                                    type="password"
                                    name="password"
                                    minLength={5}
                                    placeholder={t('profile.new_password')}
                                    ref={register({ minLength: 5 })}
                                    onErrorClick={() => clearErrors('password')} />

                                <Label className={watch('passwordRepeat') ? styles.labelActive : styles.label}>{t('profile.confirm_password').toUpperCase()}</Label>
                                <InputWithError
                                    error={watch('password') != null || watch('password') != '' ? errors.passwordRepeat : undefined}
                                    type="password"
                                    name="passwordRepeat"
                                    placeholder={t('profile.confirm_password')}
                                    ref={register({ validate: value => value === watch('password') || t('common.errors.password_not_match') })}
                                    onErrorClick={() => clearErrors('passwordRepeat')} />

                            </FormItem>
                        </div>}
                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={styles.buttonRemove}>
                        <div className={styles.remove}>
                            {(currentMode == 'edit' || currentMode == 'details' || currentMode == 'profile')
                                && !isAdmin &&
                                <RemoveMessage
                                    message={(currentMode == 'profile' ? t('profile.remove') : t('users.remove'))}
                                    onElementClick={() => { showRemoveItemDialog({ id: item?.id } as UserViewModel) }} />
                            }
                        </div>
                    </div>

                    <div className={styles.buttonsContainer} >
                        {currentMode == 'details' && <Button
                            className={styles.button}
                            type={'submit'}
                            text={t('common.edit').toUpperCase()} >
                            <FaEdit className={styles.icon} />
                        </Button>}

                        <Button
                            className={styles.button}
                            type="button"
                            text={t('common.cancel').toUpperCase()}
                            preset={'secondary'}
                            onClick={onCancel}
                        />
                        {currentMode == 'new' && <Button
                            className={styles.button}
                            type={'submit'}
                            text={t('users.send_invitation').toUpperCase()} >
                            <FaArrowRight className={styles.icon} />
                        </Button>}

                        {(currentMode == 'edit' || currentMode == 'profile') && <Button
                            className={styles.button}
                            type={'submit'}
                            text={t('common.save').toUpperCase()} >
                            <FaCheck className={styles.icon} />
                        </Button>}

                        <QuestionYesNo
                            title={t('common.remove')}
                            message={t('common.messages.remove_record')}
                            isVisible={dialogDeleteItemIsOpen}
                            onYes={() => removeItem()}
                            onNo={() => { setShowDelete(false); setDialogDeleteItemIsOpen(false) }} />

                    </div>
                </div>
            </form>
        </OverlayedModal>
    );
}

export default UserModal;
