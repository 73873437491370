import CustomFile from '../../common/models/CustomFile';
import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { MediaSearchCriteria } from './models/MediaSearchCriteria';
import { MediaViewModel } from './models/MediaViewModel';
import { MediaDto } from './models/MediaDto';

class MediaService {
    public getList(criteria: MediaSearchCriteria) {
        return Http.get<Page<MediaDto>>('medias', criteria);
    }

    public getById(id: string) {
        return Http.get<MediaDto>('medias/' + id);
    }

    public create(model: MediaViewModel, files: CustomFile[]) {
        return Http.postWithFile<string[]>('medias', model, files);
    }

    public update(model: MediaViewModel, files: CustomFile[]) {
        return Http.putWithFile<string[]>('medias', model, files);
    }

    public remove(id: string) {
        return Http.delete('medias/' + id);
    }

    public duplicate(id: string) {
        return Http.post<{ id: string; url: string; }>(`medias/${id}/duplicate`, null);
    }
}

export default new MediaService();