import React, { useState } from 'react';
import styles from './PageItem.module.scss';
import IconTrash from 'assets/svgs/Trash-red.svg';
import IconSettings from 'assets/svgs/Settings-green.svg';
import IconDuplicate from 'assets/svgs/Duplicate-blue2.svg';
import IconDrag from 'assets/svgs/Place-gris.svg';
import EditorUtils from 'screens/editor/utils/EditorUtils';
import { Page } from 'screens/editor/models/EditorModels';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPageId } from 'store/editor/action';
import { Draggable } from 'react-beautiful-dnd';
import { FaHourglassHalf } from 'react-icons/fa';
import Popover from 'common/components/popover/Popover';
import FormItem from 'common/components/formItem/FormItem';
import Label from 'common/components/label/Label';
import InputIcon from 'common/components/input/InputIcon';
import { useTranslation } from 'react-i18next';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import { IS_ADMIN } from 'Config';

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    ...(isDragging ? { border: '1px dashed gray' } : {}),
    ...draggableStyle
});

const OPTIONS_WIDTH = '1.8rem';

type Props = {
    index: number;
    page: Page;
    onDuplicatePage: (page: Page) => void;
    onDeletePage: (page: Page) => void;
    onSetAsPreFilledModel: (page: Page) => void;
    onTimePage?: (page: Page, time: number) => void;
    className?: string;
    selected: boolean;
    canDelete: boolean;
    optionsAlignLeft?: boolean;
};

const PageItem: React.FC<Props> = ({ index, page, onDuplicatePage, onDeletePage, onSetAsPreFilledModel, onTimePage, selected, canDelete, optionsAlignLeft = false }: Props) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const isAdmin = IS_ADMIN(loggedUser);

    const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);
    const [pageTime, setPageTime] = useState<number>(page.pageTime);

    const scrollToPage = (page: Page) => {
        dispatch(setSelectedPageId(page.id));
        EditorUtils.scrollToPage(page.number);
    }

    const optionsElement = selected && (
        <div className={styles.itemOptions} style={{ [optionsAlignLeft ? 'left' : 'right']: `-${OPTIONS_WIDTH}` }}>
            {!isAdmin && <span onClick={() => onSetAsPreFilledModel(page)}>
                <img src={IconSettings} className={styles.itemOptionsIcon} />
            </span>}
            {canDelete && <span onClick={() => onDeletePage(page)}>
                <img src={IconTrash} className={styles.itemOptionsIcon} />
            </span>}
            <span onClick={() => onDuplicatePage(page)}>
                <img src={IconDuplicate} className={styles.itemOptionsIcon} />
            </span>
            {/*<span>
                <img src={IconDrag} className={styles.itemOptionsIcon} />
            </span> */}
            { onTimePage &&
                <Popover
                    onClickOutside={() => onTimePage(page, pageTime)}
                    positions={['left']}
                    content={() => (
                        <div>
                            <Label>{t('editor.duration_page')}</Label>
                            <FormItem className={styles.content}>
                                <InputIcon placeholder={t('editor.duration_page')} className={styles.contentInput} defaultValue={pageTime} type={'number'} maxLength={255} icon={'S'} 
                                    onChange={event => setPageTime(Number(event.target.value))} />
                                {/* <img src={IconClose} className={styles.buttonIcon} onClick={() => onTimePage(page, pageTime) }></img> */}
                            </FormItem>
                        </div>
                    )}
                >
                    {(isPopoverOpen, setIsOpen) => (
                        <div onClick={() => {
                            setIsOpen(!isPopoverOpen)
                        } }>
                            <FaHourglassHalf className={styles.itemOptionsIcon} />
                        </div>
                    )}
                </Popover>
            }
        </div>
    );

    return (
        <Draggable draggableId={`${page.id}-${index}`} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={styles.item}
                    id={EditorUtils.getPageThumbnailId(page.number)}
                    style={{
                        ...getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        ),
                    }}
                >
                    <div
                        className={`${styles.itemImage} ${selected ? styles.active : ''}`}
                        style={{
                            backgroundImage: page.thumbnail ? `url('${page.thumbnail.dataUrl}')` : undefined,
                            width: page.thumbnail.width,
                            height: page.thumbnail.height,
                        }}
                        onClick={() => scrollToPage(page)}
                    >
                        {optionsElement}
                    </div>
                    <div className={`${styles.itemNumber} ${selected ? styles.active : ''}`} onClick={() => scrollToPage(page)}>
                        {page.number}
                    </div>
                </div>
            )}
        </Draggable>
    );
}

export default PageItem;
