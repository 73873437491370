import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToolbarOptionProps } from '../../../../utils/EditorProps';
import ToolbarItemIconGroup from '../toolbarItemIconGroup/ToolbarItemIconGroup';
import ToolbarItemIconGroupItem from '../toolbarItemIconGroup/ToolbarItemIconGroupItem';
import IconTop from 'assets/svgs/toolbar/Top.svg';
import IconBottom from 'assets/svgs/toolbar/Bottom.svg';
import IconBackgroud from 'assets/svgs/toolbar/Backgroud.svg';
import IconForeground from 'assets/svgs/toolbar/Foreground.svg';
import IconLock from 'assets/svgs/toolbar/Lock.svg';
import { useDispatch } from 'react-redux';
import arrayMove from 'array-move';
import { updatePage } from 'store/editor/action';

const OrganizationToolbarOption: React.FC<ToolbarOptionProps> = ({ elements, page, className }: ToolbarOptionProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const bringBackward = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        let newElements = page!.elements;

        for (const element of elements!) {
            const oldIndex = newElements.findIndex(el => el.id === element.id);
            const newIndex = oldIndex > 0 ? oldIndex - 1 : 0;
            newElements = arrayMove(newElements, oldIndex, newIndex);
        }

        dispatch(updatePage({
            ...page!,
            elements: newElements,
        }));
    }

    const bringBackwardMax = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        let newElements = page!.elements;

        for (const element of elements!) {
            const oldIndex = newElements.findIndex(el => el.id === element.id);
            newElements = arrayMove(newElements, oldIndex, 0);
        }

        dispatch(updatePage({
            ...page!,
            elements: newElements,
        }));
    }

    const bringForward = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        let newElements = page!.elements;

        for (const element of elements!) {
            const oldIndex = newElements.findIndex(el => el.id === element.id);
            const newIndex = oldIndex < newElements.length ? oldIndex + 1 : newElements.length;
            newElements = arrayMove(newElements, oldIndex, newIndex);
        }

        dispatch(updatePage({
            ...page!,
            elements: newElements,
        }));
    }

    const bringForwardMax = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        let newElements = page!.elements;

        for (const element of elements!) {
            const oldIndex = newElements.findIndex(el => el.id === element.id);
            newElements = arrayMove(newElements, oldIndex, newElements.length);
        }

        dispatch(updatePage({
            ...page!,
            elements: newElements,
        }));
    }

    const lockUnlock = () => {
        let lock = false;
        if(elements?.find(x => !x.locked)){
            lock = true;
        }
        
        for (const element of elements!) {
            element.locked = lock;
        }

        dispatch(updatePage(page!));
    }

    return (
        <ToolbarItemIconGroup text={t('editor.toolbar.organisation')} className={className}>
            <ToolbarItemIconGroupItem icon={IconBackgroud} onClick={bringForwardMax} />
            <ToolbarItemIconGroupItem icon={IconTop} onClick={bringForward} />
            <ToolbarItemIconGroupItem icon={IconBottom} onClick={bringBackward} />
            <ToolbarItemIconGroupItem icon={IconForeground} onClick={bringBackwardMax} />
            <ToolbarItemIconGroupItem icon={IconLock} onClick={lockUnlock} />
        </ToolbarItemIconGroup>
    );
}

export default OrganizationToolbarOption;
