import MoneyFormat from 'common/components/moneyFormat/MoneyFormat';
import React from 'react';
import styles from './ValueInfo.module.scss';

type Props = {
    title: string;
    value: number;
    description: string;
    color?: string;
    icon?: string;
    backgroundColor?: string;
    bold?: boolean;
    autoMargins?: boolean;
};

const ValueInfo = ({ title, value, description, color, icon, backgroundColor, bold, autoMargins = true }: Props) => {

    return (
        <div className={`${styles.container} ${autoMargins ? styles.containerAutoMargins : ''}`} style={backgroundColor ? { backgroundColor: backgroundColor } : {}}>
            <div className={styles.title} style={{ color, fontWeight: bold ? 'bold' : 'unset' }}>{title}</div>
            <div style={icon ? { display: 'flex', alignItems: 'center' } : {}}>
                {icon && <img src={icon} style={{ width: 40, height: 40, marginRight: '1rem' }} />}
                <div className={styles.value} style={{ color, marginRight: icon ? '1rem' : '' }}>
                    <MoneyFormat value={value} suffix={''} decimalScale={0} />
                </div>
                <div className={styles.description} style={icon ? { marginRight: '1rem', flex: 1 } : {}}>{description}</div>
            </div>
        </div>
    );
}

export default ValueInfo;
