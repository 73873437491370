import React, { useEffect, useRef, useState } from 'react';
import styles from './EditableLabel.module.scss';

import useKeypress from 'common/hooks/useKeyPress';
import ContentEditable from 'react-contenteditable';
import useOnClickOutside from 'common/hooks/useOnClickOutside';


type Props = {
  text: string;
  placeholder?: string;
  onChangeText: (text: string) => void;
};

const EditableLabel: React.FC<Props> = ({ text, onChangeText, placeholder, ...props }: Props, ref) => {

  const textRef = useRef(null);

  const [isInputActive, setIsInputActive] = useState(false);
  const textCERef = useRef(text);
  const setInputValue = (text: string) => textCERef.current = text;
  const wrapperRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const enter = useKeypress('Enter');
  const esc = useKeypress('Escape');

  const setText = (text: string) => {
    onChangeText && onChangeText(text);
  }

  const setEditingMode = (value: boolean) => {
    onChangeText;
    setIsInputActive(value);
  }

  // check to see if the user clicked outside of this component
  useOnClickOutside(wrapperRef, () => {
    if (isInputActive) {
      setText(textCERef.current);
      setEditingMode(false);

    }
  }, []);

  const handleInputChange = () => {
    // sanitize the input a little
    setIsInputActive(true);
    setInputValue(inputRef.current ? inputRef.current.innerText : '');
  };

  const handleSpanClick = () => setEditingMode(true);

  useEffect(() => {
    if (isInputActive) {
      inputRef.current?.focus();
    }
  }, [isInputActive]);

  useEffect(() => {
    if (isInputActive) {
      // if Enter is pressed, save the text and case the editor
      if (enter) {
        setText(textCERef.current);
        setEditingMode(false);
      }
      // if Escape is pressed, revert the text and close the editor
      if (esc) {
        setInputValue(text);
        setEditingMode(false);
      }
    }
  }, [enter, esc]); // watch the Enter and Escape key presses


  return (
    <div className={styles.divClass}>
      <div
        {...props}
      >
        <span ref={wrapperRef}>
          <span
            ref={textRef}
            onDoubleClick={handleSpanClick}
          ></span>
          <ContentEditable
            innerRef={inputRef}
            html={textCERef.current}
            disabled={false}
            onChange={handleInputChange}
            className={isInputActive ? styles.inputClass : styles.labelClass}
            placeholder={placeholder}
          />
        </span>
      </div>

    </div>
  );

}

export default EditableLabel;