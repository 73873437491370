import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import Button from '../button/Button';
import Label from '../label/Label';
import styles from './QuestionYesNo.module.scss';
import IconTrash from '../../../assets/svgs/Trash-white.svg';


type Props = {
    title?: string;
    message: string;
    isVisible: boolean;
    onYes: () => void;
    onNo: () => void;
};

const QuestionYesNo: React.FC<Props> = ({ title, message, isVisible, onYes, onNo }: Props) => {

    const { t } = useTranslation();

    const customStyles = {
        content: {
            top: '30%',
            left: '25%',
            right: '25%',
            bottom: 'auto',
            backgroundClip: 'padding-box',
            backgroundColor: '#FFFFFF',
            border: '1px solid transparent',
            borderRadius: '1rem',
            boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)',
            outline: '0 none',

        },
        overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            backdropFilter: 'blur(5px)',
            backgroundColor: 'rgba(0,0,0,0.2)',
        }
    };

    return (
        <Modal
            isOpen={isVisible}
            style={customStyles}
        >
            <div className={styles.container}> 
                <div className={styles.titleContainer}>
                    <Label className={styles.textTitle}>{title}</Label>
                </div>
                <div className={styles.messageContainer}>
                    <label className={styles.textMessage}>{message}</label>
                </div>
                <div className={styles.buttonsContainer}>
                    <Button text={t('common.cancel').toUpperCase()}
                        preset={'secondaryDanger'}
                        onClick={onNo}
                        size={'normal'}
                        className={styles.button}
                    />
                    <Button text={t('common.remove').toUpperCase()}
                        preset={'danger'}
                        size={'normal'}
                        onClick={onYes}
                        className={styles.button}
                    >
                        <img src={IconTrash} className={styles.icon} />
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default QuestionYesNo;
