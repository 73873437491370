import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Reducers } from '../../store/types';
import { POLICIES } from '../../Config';
import { UserProfile } from '../../api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';

type Props = {
    policies: (keyof typeof POLICIES)[];
    type?: 'OR' | 'AND';
} & RouteProps;

const PolicyRoute: React.FC<Props> = ({ children, policies, type = 'AND', component, ...props }: Props) => {
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const userPolicies = userProfile?.policies || [];

    const allowed = UsersService.hasPolicies(userPolicies, policies, type);

    return (
        <Route {...props} render={({ location }) => {
            const Component = component as any;
            return allowed ? (component ? <Component /> : children) : <Redirect to={{
                pathname: '/',
                state: { from: location }
            }} />
        }} />
    );
}

export default PolicyRoute;
