import { EditorAutoSave } from '../../screens/editor/models/EditorModels';

export const SET_IS_TEXT_ELEMENT_IN_EDITING_MODE = 'SET_IS_TEXT_ELEMENT_IN_EDITING_MODE';
export const SET_AUTO_SAVE = 'SET_AUTO_SAVE';
export const SET_CLIP_ACTIVE_ELEMENT_ID = 'SET_CLIP_ACTIVE_ELEMENT_ID';

export type Action = 
      SetIsTextElementInEditingModeAction
    | SetAutoSaveAction
    | SetClipActiveElementIdAction;

export interface SetIsTextElementInEditingModeAction {
    type: typeof SET_IS_TEXT_ELEMENT_IN_EDITING_MODE;
    isTextElementInEditingMode: boolean;
}

export interface SetAutoSaveAction {
    type: typeof SET_AUTO_SAVE;
    autoSave: EditorAutoSave;
}

export interface SetClipActiveElementIdAction {
    type: typeof SET_CLIP_ACTIVE_ELEMENT_ID;
    clipActiveElementId: string | null;
}

export interface EditorControlState {
    isTextElementInEditingMode: boolean;
    autoSave: EditorAutoSave | null;
    clipActiveElementId: string | null;
}
