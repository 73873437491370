import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from 'common/services/Toaster';
import CompanyService from 'api/companies/CompaniesService';
import { CompanySearchCriteria } from 'api/companies/models/CompanySearchCriteria';
import { CompanyViewModel } from 'api/companies/models/CompanyViewModel';
import Button from 'common/components/button/Button';
import Loading from 'common/services/Loading';
import ListingTable, { ListingTableCell, ListingTableColumn } from 'common/components/listingTable/ListingTable';
import styles from './ListCompaniesScreen.module.scss';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import PageHeader from 'common/components/pageHeader/PageHeader';
import CompanyModal from '../companyModal/CompanyModal';
import IconPlus from 'assets/svgs/Plus-white-outlines.svg';
import Pagination, { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from 'common/components/pagination/Pagination';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { FaEllipsisH } from 'react-icons/fa';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';
import { useHistory } from 'react-router-dom';
import colors from 'styles/export/colors.module.scss';
import IconIncrease from 'assets/svgs/Increase-gris.svg';

export type OpenDialogType = 'details' | 'edit' | 'new';

type Props = {
    name?: string;
};

const ListCompaniesScreen: React.FC<Props> = ({ name }: Props) => {

    const { t } = useTranslation();
    
    const history = useHistory();

    const [companies, setCompanies] = useState<CompanyViewModel[]>([]);
    const [openDialog, setOpenDialog] = React.useState<OpenDialogType | null>(null);
    const [openDialogItem, setOpenDialogItem] = React.useState<CompanyViewModel | null>(null);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');

    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [itemToRemove, setItemToRemove] = useState<CompanyViewModel>();

    const getCompanies = async () => {
        try {
            Loading.show();

            const criteria: CompanySearchCriteria = { name };
            criteria.page = currentPage;
            criteria.itemsPerPage = DEFAULT_PAGINATION_ITEMS_PER_PAGE;
            criteria.orderColumn = 'name';
            criteria.orderBy = 'asc';
            criteria.name = searchValue;

            const result = await CompanyService.getList(criteria);
            setCompanies(result.items);
            setTotalItems(result.totalItems);

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const renderTableActionCell = (cell: ListingTableCell) => {
        return (
            <Dropdown options={<>
                <DropdownItem onClick={() => openModal(cell.row as CompanyViewModel, 'details')}>{t('common.details')}</DropdownItem>
                <DropdownItem onClick={() => openModal(cell.row as CompanyViewModel, 'edit')}>{t('common.edit')}</DropdownItem>
                <DropdownItem onClick={() => showRemoveItemDialog(cell.row as CompanyViewModel)}>{t('common.remove')}</DropdownItem>
            </>}>
                <div><FaEllipsisH /></div>
            </Dropdown>
        );
    }

    const tableColumns: ListingTableColumn[] = useMemo(() => [
        {
            field: 'name',
            name: t('companies.title'),
            width: 'fill'
        },
        {
            field: 'action',
            name: t('welcome_messages.list.options'),
            width: 12,
            cellAlignment: 'center',
            preventClick: true,
            renderCell: renderTableActionCell
        }
    ], []);

    const showRemoveItemDialog = (item: CompanyViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await CompanyService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                getCompanies();
                Loading.hide();
            } catch (error) {
                Logger.error(LOGGER_LOG_TYPE.COMPANIES, `Couldn't remove company`, error);
                addToast(t('common.messages.record_delete_error'), { appearance: 'success' });
                Loading.hide();
            }
        }
    };

    const openModal = (item: CompanyViewModel | null, dialogType: OpenDialogType) => {
        setOpenDialog(dialogType);
        setOpenDialogItem(item);
    };

    const onPageChange = (page: number) => {
        setCurrentPage(page);
    }

    const onUserModalClose = () => {
        setOpenDialog(null);
        getCompanies();
    }
    const onChangeSearch = (text: string) => {
        setSearchValue(text);
        setCurrentPage(1);
    }

    useEffect(() => {
        getCompanies();
    }, [currentPage, searchValue]);

    return (
        <ScreenTitle title={t('menu.companies')}>
            <PageHeader title={t('companies.title')} onChangeSearch={onChangeSearch} searchValue={searchValue}></PageHeader>

            <div className={styles.container}>
                <div className={styles.line}></div>
                <div className={styles.buttonsContainer}>
                    <Button
                        type="button"
                        text={t('companies.analytics.title').toUpperCase()}
                        preset={'success'} onClick={() => history.push('companies')}
                        className={styles.button}
                        style={{ color: colors.doveGray }}
                    >
                        <img src={IconIncrease} className={styles.buttonIcon} />
                    </Button>
                    <Button
                        type="button"
                        text={t('companies.add_company').toUpperCase()}
                        preset={'primary'} onClick={() => openModal(null, 'new')}
                        className={styles.button}
                    >
                        <img src={IconPlus} className={styles.buttonIcon} />
                    </Button>
                </div>
                <ListingTable columns={tableColumns} rows={companies} allowHover={true} onRowClick={row => openModal(row as CompanyViewModel, 'details')} striped={true} />
                {openDialog !== null && <CompanyModal
                    mode={openDialog}
                    item={openDialogItem}
                    isVisible={openDialog ? true : false}
                    onCancel={onUserModalClose}
                />
                }
                <br />

                <QuestionYesNo
                    title={t('common.remove')}
                    message={t('common.messages.remove_record')} isVisible={dialogDeleteItemIsOpen}
                    onYes={() => removeItem()}
                    onNo={() => setDialogDeleteItemIsOpen(false)} />

                {totalItems > 0 && <div className={styles.paginationContainer}>
                    <Pagination currentPage={1} onChange={onPageChange} totalItems={totalItems} />
                </div>}
            </div>
        </ScreenTitle>
    );
}

export default ListCompaniesScreen;