import React, { useCallback, useState } from 'react';
import styles from './PagesList.module.scss';
import IconPage from 'assets/svgs/Document-bleu2.svg';
import IconPlus from 'assets/svgs/Plus-white-outlines.svg';
import IconAddUser from 'assets/svgs/AddPers-bleu2.svg';
import EditorUtils from 'screens/editor/utils/EditorUtils';
import { useTranslation } from 'react-i18next';
import { EditorFont, Page, PageElementType } from 'screens/editor/models/EditorModels';
import Button from 'common/components/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { addPage, duplicatePage, removePage, setFonts, setSelectedPageId } from 'store/editor/action';
import { DocumentFormat } from 'api/documents/enums/DocumentFormat';
import { DocumentType } from 'api/documents/enums/DocumentType';
import { Reducers } from 'store/types';
import AddPageModelModal from './addPageModelModal/AddPageModelModal';
import { DocumentCollaboratorViewModel, DocumentViewModel, UpdateCollaboratorViewModel } from 'api/documents/models/DocumentViewModel';
import { useParams } from 'react-router-dom';
import DocumentsService from 'api/documents/DocumentsService';
import Logger from 'common/services/Logger';
import { IS_ADMIN, IS_COMPANY_ADMIN, LOGGER_LOG_TYPE } from 'Config';
import { addToast } from 'common/services/Toaster';
import { UserProfile } from 'api/account/models/UserProfile';
import AddCollaboratorsModal from './addCollaboratorsModal/AddCollaboratorsModal';
import Loading from 'common/services/Loading';
import SetPageAsPreFilledModal from './setPageAsPreFilledModal/SetPageAsPreFilledModal';
import EditorInternalService from 'screens/editor/utils/EditorInternalService';
import Utils from 'common/services/Utils';
import { CreatePreFilledDataViewModel } from 'api/documents/models/CreatePreFilledDataViewModel';
import PagesItemsList from './pagesItemsList/PagesItemsList';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import EditorDefaults from 'screens/editor/utils/EditorDefaults';
import MediasService from 'api/medias/MediasService';

type Props = {
    pages: Page[];
    selectedPageId: string | null;
    className?: string;
};

const PagesList: React.FC<Props> = ({ pages, selectedPageId, className }: Props) => {
    const { t } = useTranslation();
    
    const dispatch = useDispatch();

    const documentFormat = useSelector<Reducers, DocumentFormat>(state => state.editor.present.format);
    const documentType = useSelector<Reducers, DocumentType>(state => state.editor.present.documentType)
    const fonts = useSelector<Reducers, EditorFont[]>(state => state.editor.present.fonts || [])
    const [showAddPageModelModal, setShowAddPageModelModal] = useState(false);
    const [showAddCollaboratorsModal, setShowAddCollaboratorsModal] = useState(false);
    const [showSetPageAsPreFilledModal, setShowSetPageAsPreFilledModal] = useState(false);
    const [selectedPage, setSelectedPage] = useState<Page>();
    const [showDeletePageModal, setShowDeletePageModal] = useState<Page | null>(null);

    const { id } = useParams<{ id: string }>();

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const companyId = loggedUser?.companyId;
    const isAdmin = IS_ADMIN(loggedUser);

    const addPageModel = async (doc: DocumentViewModel | undefined) => {
        setShowAddPageModelModal(false);

        if(doc === undefined) {
            onAddEmptyPage();
            return;
        }
        
        const editorData = EditorUtils.parseDocumentEditorData(doc.editorData, doc.format);
        let pageCounter = pages.length + 1;
        for(const page of editorData.pages) {

            const elements = [...page.elements];
            for (const el of elements) {
                el.id = Utils.newGuid();

                if (el.type === PageElementType.IMAGE &&
                    el.clip && 
                    el.clip.enabled && 
                    el.clip.croppedMedia && 
                    el.clip.croppedMedia.id
                ) {
                    try {
                        el.clip.croppedMedia = await MediasService.duplicate(el.clip.croppedMedia.id);
                    } catch (error) {
                        Logger.error(LOGGER_LOG_TYPE.EDITOR, `couldn't duplicate crop '${el.clip.croppedMedia.id}'`, error);
                        el.clip = null;
                    }
                }
            }

            const p: Page = {
                ...page,
                number: pageCounter,
                id: Utils.newGuid(),
                elements,
            };
            dispatch(addPage(p));
            pageCounter++;
        }

        const tempFonts = [ ...fonts ];
        for (const font of (editorData.fonts || [])) {
            if (!tempFonts.find(x => x.family === font.family)) {
                tempFonts.push(font);
            }
        }
        dispatch(setFonts(tempFonts));
    }

    const onDeletePage = (page: Page) => {
        if (pages.length <= 1) {
            return;
        }

        dispatch(removePage(page));
        setShowDeletePageModal(null);
    }

    const onAddEmptyPage = () => {
        const page = EditorDefaults.getNewPage(documentFormat, pages.length + 1);

        dispatch(addPage(page));

        dispatch(setSelectedPageId(page.id));
        setTimeout(() => EditorUtils.scrollToPage(page.number), 400);
    }

    const onDuplicatePage = useCallback((page: Page) => {
        dispatch(duplicatePage(page));
    }, []);

    const onSetAsPreFilledModel = (page: Page) => {
        setSelectedPage(page);
        setShowSetPageAsPreFilledModal(true);
    }

    const setAsPreFilled = async (name: string) => {
        setShowSetPageAsPreFilledModal(false);
        
        if(!selectedPage) return;

        Loading.show();
        
        const page: Page = { ...selectedPage, number: 1 };

        try {
            const model: CreatePreFilledDataViewModel = {
                name: name,
                format: documentFormat,
                editorData: EditorUtils.serializeDocumentEditorData({
                    pages: [page],
                    fonts,
                }),
                companyId: companyId,
                isAdmin: IS_COMPANY_ADMIN(loggedUser)
            };

            const thumbnailBase64 = await EditorInternalService.generatePageThumbnail(selectedPage);
            const thumbnail = thumbnailBase64 ? Utils.dataURLtoFile(thumbnailBase64, 'thumbnail.png', 'thumbnail') : null;

            await DocumentsService.createPreFilled(model, thumbnail);

            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.DOCUMENTS, `Couldn't create the document`, error);
            Loading.hide();
        }
    }
    
    const onTimePage = async (page: Page, time: number) => {
        setSelectedPage(page);
        page.pageTime = time;
        await EditorInternalService.quickSave();
    }

    const addCollaborators = async (collabs: DocumentCollaboratorViewModel[], comps: DocumentCollaboratorViewModel[]) => {
        setShowAddCollaboratorsModal(false);
        Loading.show()
        try{
            const model: UpdateCollaboratorViewModel = {
                id: id,
                collaborators: collabs,  
                companyId: companyId,
                companies: comps,
                isAdmin: isAdmin
            };

            await await DocumentsService.updateCollaborators(model);
            Loading.hide();
        } catch(error) {
            Logger.error(LOGGER_LOG_TYPE.DOCUMENTS, `Couldn't update the collaborators from document ${id}`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onOpenAddCollaboratorsModal = () => {
        setShowAddCollaboratorsModal(true);
    }
    
    return (
        <div className={`${styles.container} ${className ? className : ''}`}>
            <div className={styles.header}>
                <div>
                    <img src={IconPage} className={styles.headerIcon} />
                    <span className={styles.headerText}>{t('editor.pages')}</span>
                </div>
                <div className={styles.headerContainer} onClick={() => onOpenAddCollaboratorsModal()}>
                    <img src={IconAddUser} className={styles.headerIcon} />
                </div>
            </div>

            <PagesItemsList
                pages={pages}
                selectedPageId={selectedPageId}
                onDeletePage={onDeletePage}
                onDuplicatePage={onDuplicatePage}
                onSetAsPreFilledModel={onSetAsPreFilledModel}
                onTimePage={ documentFormat === DocumentFormat.SOCIAL_MEDIA_POSTS ? onTimePage : undefined}
            />

            <div className={styles.buttonsContainer}>
                { documentType !== DocumentType.SIMPLE_MODEL && <Button type="button" className={styles.addButton} onClick={() => setShowAddPageModelModal(true)}>
                    <span>{t('editor.add_page')}</span>
                    <img src={IconPlus} className={styles.addButtonIcon} />
                </Button>}
            </div>
            <AddPageModelModal
                isVisible={showAddPageModelModal}
                onApply={doc => addPageModel(doc)}
                onClose={() => setShowAddPageModelModal(false)}
                format={documentFormat}
            />
            { showAddCollaboratorsModal &&<AddCollaboratorsModal
                isVisible={showAddCollaboratorsModal}
                onApply={(collabList, compList) => addCollaborators(collabList, compList)}
                onClose={() => setShowAddCollaboratorsModal(false)}
                id={id}
            /> }
            { showSetPageAsPreFilledModal && <SetPageAsPreFilledModal
                isVisible={showSetPageAsPreFilledModal}
                onApply={(name: string) => setAsPreFilled(name)}
                onClose={() => setShowSetPageAsPreFilledModal(false)}
            />}

            <QuestionYesNo
                title={t('common.remove')}
                message={t('editor.remove_page_message')}
                isVisible={showDeletePageModal ? true : false}
                onYes={() => onDeletePage(showDeletePageModal!)}
                onNo={() => setShowDeletePageModal(null)}
            />
        </div>
    );
}

export default PagesList;
