import TipsService from 'api/tips/TipsService';
import React, { useEffect, useState } from 'react';
import styles from './Tips.module.scss';
import { addToast } from 'common/services/Toaster';
import { LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';
import { useTranslation } from 'react-i18next';
import TipCard from '../tipCard/TipCard';
import colors from 'styles/export/colors.module.scss';
import { TipDto } from 'api/tips/models/TipDto';

const Tips: React.FC = () => {
    const { t } = useTranslation();
    
    const [tips, setTips] = useState<TipDto[]>([]);
    const [closing, setClosing] = useState(false);

    const getData = async () => {
        try {
            const result = await TipsService.getAllTips();

            // set colors
            for (let i = 0; i < result.length; i++) {
                let color = colors.success;

                if ((i + 1) % 2 === 0) {
                    color = colors.scandal;
                } else if((i + 1) % 3 === 0) {
                    color = colors.foam;
                } 

                result[i].color = color;
            }

            setTips(result);
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.TIPS, `Couldn't get the tips list`, error);
        }
    }

    const onClose = () => {
        setClosing(true);
    }

    const updateTips = () => {
        if (!tips || tips.length <= 0) {
            return;
        }
        
        const tempTips = [...tips];
        const firstTip = tempTips.splice(0, 1);
        tempTips.push(firstTip[0]);

        setTips(tempTips);
        setClosing(false);
    }

    useEffect(() => {
        let isMounted = true;

        const intervalId = setInterval(() => {
            if (!isMounted) return;
            setClosing(true);
        }, 10000);

        return () => {
            clearInterval(intervalId);
            isMounted = false;
        };
    }, [tips]);

    useEffect(() => {
        getData();
    }, []);

    if (!tips || tips.length === 0) {
        return null;
    }

    const currentTips = [...tips].slice(0, 3);

    return (
        <div className={styles.boxContainer}>
            {currentTips.map((tip, i) => (
                <TipCard
                    tip={tip}
                    onClose={onClose}
                    key={tip.id + '-' + i}
                    className={`${styles.card}`}
                    style={{ backgroundColor: tip.color }}
                    closing={closing && i === (currentTips.length - 1)}
                    onTransitionEnd={() => {
                        if (closing && i === (currentTips.length - 1)) {
                            updateTips();
                        }
                    }}
                />
            ))}
        </div>
    );
};

export default Tips;