import React, { useCallback, useMemo, useState } from 'react';
import styles from './ChartModifier.module.scss';
import { useTranslation } from 'react-i18next';
import { Page, PageElement, PageElementType } from 'screens/editor/models/EditorModels';
import BoxCategory from 'common/components/boxCategory/BoxCategory';
import ChartTable from '../chartTable/ChartTable';
import ChartPreview from '../chartPreview/ChartPreview';
import EditorChartsUtils from 'screens/editor/utils/EditorChartsUtils';
import EditorUtils from 'screens/editor/utils/EditorUtils';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import store from 'store/store';
import { updatePageElement } from 'store/editor/action';
import { EditorChartDataValue, EditorChartTableData, EditorChartType } from 'screens/editor/models/ChartModels';

type Props = {
    chartType: EditorChartType;
    onGoBack: () => void;
}

const ChartModifier = ({ chartType, onGoBack }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedElement] = useSelector<Reducers, ([(PageElement | null), (Page | null)])>(state => EditorUtils.getFirstSelectedElementFromState(state.editor.present))
    const [data, setData] = useState<EditorChartTableData[]>(EditorChartsUtils.generateChartTableData(chartType));

    const onDataChange = useCallback((newData: EditorChartTableData[]) => {
        const [selectedElementUpdated, selectedPageUpdated] = EditorUtils.getFirstSelectedElementFromState(store.getState().editor.present);
        if (selectedElementUpdated && selectedPageUpdated && selectedElementUpdated.type === PageElementType.CHART) {
            dispatch(updatePageElement(selectedPageUpdated, {
                ...selectedElementUpdated,
                value: {
                    ...selectedElementUpdated.value,
                    type: chartType,
                    tableData: newData,
                } as EditorChartDataValue,
            }));
        } else {
            setData(newData);
        }
    }, []);

    const currentData = useMemo(() => (
        selectedElement && selectedElement.value && (selectedElement.value as EditorChartDataValue).tableData && selectedElement.type === PageElementType.CHART
            ? (selectedElement.value as EditorChartDataValue).tableData
            : data
    ), [selectedElement, data]);

    return (
        <div className={styles.container}>
            <BoxCategory
                title={t('editor.sidebar.modify_chart')}
                onGoBack={onGoBack}
                className={styles.boxContainer}
            >
                <ChartTable chartType={chartType} data={currentData} onChange={onDataChange} />
                <ChartPreview chartType={chartType} data={currentData} />
            </BoxCategory>
        </div>
    );
}

export default ChartModifier;
