import React, { ChangeEvent, memo, useEffect, useState } from 'react'
import { EditorTableData } from 'screens/editor/models/TableModels';
import styles from './TableCell.module.scss';

type Props = {
    onDataChange: (rowIndex: number, columnId: string, value: string) => void;
    value: string;
    row: EditorTableData;
    rowIndex: number;
    columnId: string;
};

const TableCell = ({ value: initialValue, onDataChange, row, rowIndex, columnId }: Props) => {
    const [value, setValue] = useState(initialValue);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        // setValue(e.target.value?.trim().replace(',', '.') || '');
        setValue(e.target.value?.trim() || '');
    }

    const save = () => {
        onDataChange(rowIndex, columnId, value);
    }

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    return columnId === 'id' 
        ? <div className={styles.container}>
            <span className={styles.valueText}>{value}</span>
        </div>
        : <input
            value={value}
            onChange={onChange}
            onBlur={() => save()}
            className={styles.input}
            tabIndex={rowIndex}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    save();
                }
            }}
        />
}

export default memo(TableCell);