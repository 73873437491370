import { EditorChartType } from 'screens/editor/models/ChartModels';
import { EditorSidebarCategoryId, EditorSidebarState } from 'screens/editor/models/SidebarModels';
import { EditorTableType } from 'screens/editor/models/TableModels';
import { DocumentFormat } from '../../api/documents/enums/DocumentFormat';
import { DocumentType } from '../../api/documents/enums/DocumentType';
import { EditorFont, Page, PageElement } from '../../screens/editor/models/EditorModels';
import { EditorAnalytics } from '../../screens/editor/models/EditorAnalytics';

export const SET_SELECTED_PAGE_ID = 'SET_SELECTED_PAGE_ID';
export const SET_SELECTED_ELEMENTS_IDS = 'SET_SELECTED_ELEMENTS_IDS';
export const SET_PAGES = 'SET_PAGES';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const ADD_PAGE = 'ADD_PAGE';
export const REMOVE_PAGE = 'REMOVE_PAGE';
export const UPDATE_PAGE_ELEMENT = 'UPDATE_PAGE_ELEMENT';
export const CLEAR_SELECTED = 'CLEAR_SELECTED';
export const ADD_ELEMENT_TO_PAGE = 'ADD_ELEMENT_TO_PAGE';
export const REMOVE_PAGE_ELEMENTS = 'REMOVE_PAGE_ELEMENTS';
export const DUPLICATE_PAGE = 'DUPLICATE_PAGE';
export const SET_ZOOM = 'SET_ZOOM';
export const EDITOR_UNDO = 'EDITOR_UNDO';
export const EDITOR_REDO = 'EDITOR_REDO';
export const UPDATE_PAGE_THUMBNAIL = 'UPDATE_PAGE_THUMBNAIL';
export const UPDATE_PAGE_THUMBNAIL_CHANGER_ID = 'UPDATE_PAGE_THUMBNAIL_CHANGER_ID';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const RE_ORDER_PAGES = 'RE_ORDER_PAGES';
export const DUPLICATE_PAGE_ELEMENT = 'DUPLICATE_PAGE_ELEMENT';
export const SET_SIDEBAR_STATE = 'SET_SIDEBAR_STATE';
export const SET_SIDEBAR_SELECTED_CATEGORY_ID = 'SET_SIDEBAR_SELECTED_CATEGORY_ID';
export const SET_SIDEBAR_SELECTED_CHART_TYPE = 'SET_SIDEBAR_SELECTED_CHART_TYPE';
export const REMOVE_CROPPED_FROM_PAST = 'REMOVE_CROPPED_FROM_PAST';
export const SET_SIDEBAR_SELECTED_TABLE_TYPE = 'SET_SIDEBAR_SELECTED_TABLE_TYPE';
export const SET_FONTS = 'SET_FONTS';
export const UPDATE_EDITOR_ANALYTICS = 'UPDATE_EDITOR_ANALYTICS';

export type Action = SetSelectedPageIdAction 
    | SetSelectedElementsIdsAction
    | SetPagesAction
    | UpdatePageAction
    | AddPageAction
    | RemovePageAction
    | UpdatePageElementAction
    | ClearSelectedAction
    | AddElementToPageAction
    | RemovePageElementsAction
    | DuplicatePageAction
    | SetZoomAction
    | UpdatePageThumbnailAction
    | UpdatePageThumbnailChangerIdAction
    | SetInitialStateAction
    | ReOrderPagesAction
    | DuplicatePageElementAction
    | SetSidebarStateAction
    | SetSidebarSelectedCategoryIdAction
    | SetSidebarSelectedChartTypeAction
    | RemoveCroppedFromPastAction
    | SetSidebarSelectedTableTypeAction
    | SetFontsAction
    | UpdateEditorAnalyticsAction;

export interface SetSelectedPageIdAction {
    type: typeof SET_SELECTED_PAGE_ID;
    selectedPageId: string | null;
}

export interface SetSelectedElementsIdsAction {
    type: typeof SET_SELECTED_ELEMENTS_IDS;
    selectedPageId: string | null;
    selectedElementsIds: string[] | null;
}

export interface SetPagesAction {
    type: typeof SET_PAGES;
    pages: Page[];
}

export interface UpdatePageAction {
    type: typeof UPDATE_PAGE;
    page: Page;
}

export interface AddPageAction {
    type: typeof ADD_PAGE;
    page: Page;
}

export interface RemovePageAction {
    type: typeof REMOVE_PAGE;
    page: Page;
}

export interface UpdatePageElementAction {
    type: typeof UPDATE_PAGE_ELEMENT;
    page: Page;
    element: PageElement;
}

export interface ClearSelectedAction {
    type: typeof CLEAR_SELECTED;
}

export interface AddElementToPageAction {
    type: typeof ADD_ELEMENT_TO_PAGE;
    page: Page;
    element: PageElement;
}

export interface RemovePageElementsAction {
    type: typeof REMOVE_PAGE_ELEMENTS;
    page: Page;
    elements: PageElement[];
}

export interface DuplicatePageAction {
    type: typeof DUPLICATE_PAGE;
    page: Page;
}

export interface SetZoomAction {
    type: typeof SET_ZOOM;
    zoom: number | null;
}

export interface EditorUndoAction {
    type: typeof EDITOR_UNDO;
}

export interface EditorRedoAction {
    type: typeof EDITOR_REDO;
}

export interface UpdatePageThumbnailAction {
    type: typeof UPDATE_PAGE_THUMBNAIL;
    pageId: string;
    thumbnail: string | null;
}

export interface UpdatePageThumbnailChangerIdAction {
    type: typeof UPDATE_PAGE_THUMBNAIL_CHANGER_ID;
    pageId: string;
}

export interface SetInitialStateAction {
    type: typeof SET_INITIAL_STATE;
    pages: Page[];
    format: DocumentFormat;
    documentType: DocumentType;
    documentId: string;
    fonts: EditorFont[];
}

export interface ReOrderPagesAction {
    type: typeof RE_ORDER_PAGES;
    sourceIndex: number;
    destinationIndex: number;
}

export interface DuplicatePageElementAction {
    type: typeof DUPLICATE_PAGE_ELEMENT;
    pageId: string;
    elementId: string;
}

export interface SetSidebarStateAction {
    type: typeof SET_SIDEBAR_STATE;
    sidebarState: EditorSidebarState;
}

export interface SetSidebarSelectedCategoryIdAction {
    type: typeof SET_SIDEBAR_SELECTED_CATEGORY_ID;
    selectedCategoryId: EditorSidebarCategoryId | null;
}

export interface SetSidebarSelectedChartTypeAction {
    type: typeof SET_SIDEBAR_SELECTED_CHART_TYPE;
    selectedChartType: EditorChartType | null;
}

export interface SetSidebarSelectedTableTypeAction {
    type: typeof SET_SIDEBAR_SELECTED_TABLE_TYPE;
    selectedTableType: EditorTableType | null;
}

export interface RemoveCroppedFromPastAction {
    type: typeof REMOVE_CROPPED_FROM_PAST;
    pageId: string;
    elementId: string | null;
}

export interface SetFontsAction {
    type: typeof SET_FONTS;
    fonts: EditorFont[];
}

export interface UpdateEditorAnalyticsAction {
    type: typeof UPDATE_EDITOR_ANALYTICS;
    analytics: EditorAnalytics;
}

export interface EditorState {
    documentId: string | null;
    selectedPageId: string | null;
    selectedElementsIds: string[] | null;
    pages: Page[];
    format: DocumentFormat;
    documentType: DocumentType;
    zoom: number | null;
    sidebarState: EditorSidebarState;
    fonts?: EditorFont[];
    analytics: EditorAnalytics;
}
