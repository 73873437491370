import React, { useMemo } from 'react';
import EditorDefaults, { EDITOR_DEFAULT_BODER_RADIUS } from '../../../../utils/EditorDefaults';
import { ElementProps } from '../../../../utils/EditorProps';
import Prefixer from '../../../../../../common/services/Prefixer';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RectangleElementRender = ({ page, element, selected, ...props }: ElementProps, ref: React.ForwardedRef<HTMLDivElement>) => {

    const style = useMemo(() => Prefixer.prefixStyle(EditorDefaults.getElementStyles(element)), [element]);
    const borderStyle = useMemo(() => Prefixer.prefixStyle(EditorDefaults.getElementBorderStyle(element)), [element]);

    const borderWidth = borderStyle?.borderWidth ? Number(borderStyle?.borderWidth) : 0;

    return (
        <div
            {...props}
            ref={ref}
            style={style}
        >
            <svg
                width={element.width}
                height={element.height}
                preserveAspectRatio="none"
            >
                <rect
                    x={borderWidth/2}
                    y={borderWidth/2}
                    width={element.width - (borderWidth)}
                    height={element.height - (borderWidth)}
                    style={{
                        color: 'inherit',
                        fill: style.fill,
                        stroke: borderStyle?.borderColor,
                        strokeWidth: borderStyle?.borderWidth,
                    }}
                    rx={element?.borderRadius?.type === 'round' ? EDITOR_DEFAULT_BODER_RADIUS : undefined}
                    ry={element?.borderRadius?.type === 'round' ? EDITOR_DEFAULT_BODER_RADIUS : undefined}
                />
            </svg>
        </div>
    );

}

export default React.forwardRef<HTMLDivElement, ElementProps>(RectangleElementRender);
