import Utils from 'common/services/Utils';
import { EditorChartColumns, EditorChartRowData, EditorChartTableData, EditorChartType, EditorLineChartData, EditorSidebarChartTableColumn } from '../models/ChartModels';

class EditorChartsUtils {

    public generateChartTableColumns(chartType: EditorChartType): EditorSidebarChartTableColumn[] {
        return [
            {
                id: 'id',
                text: '',
                // canRender: (rowIndex: number): boolean => {
                //     if (rowIndex === 0 && (chartType === EditorChartType.PIE || chartType === EditorChartType.DONUT)) {
                //         return false;
                //     }
                //     return true;
                // },
                render: (rowIndex: number): string => {
                    if (chartType === EditorChartType.PIE || chartType === EditorChartType.DONUT) {
                        return (rowIndex + 1).toString();
                    }

                    if (rowIndex == 0) {
                        return 'X';
                    }

                    return rowIndex.toString();
                },
                canChangeColor: (rowIndex: number) => {
                    if (rowIndex > 0 || (chartType === EditorChartType.PIE || chartType === EditorChartType.DONUT)) {
                        return true;
                    }
                    return false;
                },
                accessor: '',
            },
            {
                id: 'name',
                text: 'Y',
                accessor: 'name',
            },
            ...(EditorChartColumns.map(columnKey => ({
                id: columnKey,
                text: columnKey,
                accessor: columnKey,
            }))),
        ];
    }

    public getDefaultColorByIndex = (index: number) => {
        switch (index) {
            case 1:
                return '#bd13e0';
            case 2:
                return '#d01520';
            case 3:
                return '#f4a625';
            case 4:
                return '#4ee4c2';
            default:
                return null;
        }
    }

    public generateChartTableData(chartType: EditorChartType): EditorChartTableData[] {
        const columnsKeys = EditorChartColumns.reduce<any>((acc, curr) => (acc[curr] = ' ', acc), {});
        const defaultTotalRows = 10;

        switch (chartType) {
            case EditorChartType.BARS_HORIZONTAL:
            case EditorChartType.BARS_VERTICAL:
                return [
                    ...new Array(defaultTotalRows).fill({}).map((_: any, index: number) => {
                        let obj: any = {
                            id: Utils.newGuid(),
                            color: this.getDefaultColorByIndex(index),
                            name: ''
                        };

                        if (index === 0) {
                            obj = {
                                ...obj,
                                ...{ ...columnsKeys, 'name': ' ', 'A': 'Groupe1', 'B': 'Groupe2' },
                            };
                        }

                        if (index > 0 && index < 5) {
                            obj = {
                                ...obj,
                                ...{ ...columnsKeys, 'name': `Data${index}`, 'A': `${index}`, 'B': `${index * 2}` },
                            };
                        }

                        return obj;
                    })
                ];
            case EditorChartType.DONUT:
            case EditorChartType.PIE:
                return [
                    ...new Array(defaultTotalRows - 1).fill({}).map((_: any, index: number) => {
                        let obj: any = {
                            id: Utils.newGuid(),
                            color: this.getDefaultColorByIndex(index),
                            name: ''
                        };

                        if (index >= 0 && index < 4) {
                            obj = {
                                ...obj,
                                ...{ ...columnsKeys, 'name': `Data${(index + 1)}`, 'A': `${(index + 1) * 2}` },
                            };
                        }

                        return obj;
                    })
                ];
            case EditorChartType.CURVE_SINGLE:
            case EditorChartType.CURVE_MULTIPLE:
                return [
                    ...new Array(defaultTotalRows).fill({}).map((_: any, index: number) => {
                        let obj: any = {
                            id: Utils.newGuid(),
                            color: this.getDefaultColorByIndex(index),
                            name: ''
                        };

                        if (index === 0) {
                            obj = {
                                ...obj,
                                ...{ ...columnsKeys, 'name': ' ', 'A': 'Groupe1', 'B': 'Groupe2' },
                            };
                        }

                        if (index > 0 && index < 5) {
                            obj = {
                                ...obj,
                                ...{ ...columnsKeys, 'name': `Data${index}`, 'A': `${index}`, 'B': `${index * 2}` },
                            };
                        }

                        return obj;
                    })
                ];
            case EditorChartType.BARS_MULTIPLE_VERTICAL:
            case EditorChartType.BARS_MULTIPLE_HORIZONTAL:
            case EditorChartType.BARS_TRIPLE_VERTICAL:
            case EditorChartType.BARS_TRIPLE_HORIZONTAL:
                return [
                    ...new Array(defaultTotalRows).fill({}).map((_: any, index: number) => {
                        let obj: any = {
                            id: Utils.newGuid(),
                            color: this.getDefaultColorByIndex(index),
                            name: ''
                        };

                        if (index === 0) {
                            obj = {
                                ...obj,
                                ...{ ...columnsKeys, 'name': ' ', 'A': 'Groupe1', 'B': 'Groupe2' },
                            };
                        }

                        if (index > 0 && index < 5) {
                            obj = {
                                ...obj,
                                ...{ ...columnsKeys, 'name': `Data${index}`, 'A': `${index}`, 'B': `${index * 2}` },
                            };
                        }

                        return obj;
                    })
                ];
            default:
                return [
                    ...new Array(defaultTotalRows).fill({}).map(() => ({
                        id: Utils.newGuid(),
                        color: null,
                        name: '',
                        ...columnsKeys,
                    }))
                ];
        }
    }

    public tableDataToLinesChartData(tableData: EditorChartTableData[]) {
        const chartData: EditorLineChartData[] = [];

        const headerRow = tableData[0];
        for (const colKey of EditorChartColumns) {
            const headerName = headerRow[colKey as any]?.trim() || '';

            let obj = {
                name: headerName,
            };

            let hasValues = false;

            for (let i = 0; i < tableData.length; i++) {
                const value = tableData[i][colKey as any]?.trim().replace(',', '.') || '';
                const rowName = tableData[i].name?.trim() || '';

                if (value && value.length > 0 && rowName && rowName.length > 0) {
                    obj = {
                        ...obj,
                        [rowName]: Number(value),
                    }
                    hasValues = true;
                }
            }

            if (hasValues && obj.name && obj.name.length > 0) {
                chartData.push(obj);
            }
        }

        const chartRows: EditorChartRowData[] = [];
        for (let i = 0; i < tableData.length; i++) {
            const rowName = tableData[i].name?.trim() || '';
            const rowColor = tableData[i].color?.trim() || '#000000';

            if (rowName && rowName.length > 0) {
                chartRows.push({
                    name: rowName,
                    color: rowColor,
                });
            }
        }

        return [chartData, chartRows]
    }

    public tableDataToRowsData(tableData: EditorChartTableData[]) {
        const chartData: { name: string; color: string; value: number }[] = [];
        for (let i = 0; i < tableData.length; i++) {
            const rowName = tableData[i].name?.trim() || '';
            const rowColor = tableData[i].color?.trim() || '#000000';
            const value = EditorChartColumns.map(colKey => {
                const val = tableData[i][colKey as any]?.trim().replace(',', '.') || '';
                return val && val.length > 0 ? Number(val) : 0;
            }).reduce((a, b) => a + b);

            if (rowName && rowName.length > 0) {
                chartData.push({
                    name: rowName,
                    color: rowColor,
                    value,
                });
            }
        }

        return chartData;
    }
}

export default new EditorChartsUtils();