import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import styles from './LoginScreen.module.scss';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import Button from '../../common/components/button/Button';
import AccountService from '../../api/account/AccountService';
import { addToast } from 'common/services/Toaster';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateUser } from '../../store/authentication/action';
import Loading from '../../common/services/Loading';
import { Reducers } from '../../store/types';
import LoginForm, { LoginFormModel } from './components/loginForm/LoginForm';
import SetPasswordFirstLoginForm, { SetPasswordFirstLoginFormModel } from './components/setPasswordFirstLoginForm/SetPasswordFirstLoginForm';
import SetPasswordForm, { SetPasswordFormModel } from './components/setPasswordForm/SetPasswordForm';
import RecoverPasswordForm, { RecoverPasswordFormModel } from './components/recoverPasswordform/RecoverPasswordForm';
import LogoImage from './../../assets/svgs/Logo-otto.svg';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

enum ScreenType {
    LOGIN,
    RECOVER_PASSWORD,
    SET_PASSWORD,
    FIRST_LOGIN,
}

const LoginScreen: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector<Reducers, boolean>(state => state.authentication.isAuthenticated);
    // const routeLocation = useLocation<any>();
    const query = useQuery();
    const queryToken = query.get('token');
    const queryUsername = query.get('username');
    const queryFirstLogin = query.get('firstLogin');
    const [screen, setScreen] = useState<ScreenType>((queryToken && queryUsername && !queryFirstLogin ? ScreenType.SET_PASSWORD : ScreenType.LOGIN) || (queryToken && queryUsername && queryFirstLogin ? ScreenType.FIRST_LOGIN : ScreenType.LOGIN));

    const onSubmitLogin = async ({ username, password }: LoginFormModel) => {
        try {
            Loading.show();
            const response = await AccountService.login({ userName: username, password });
            if (response) {
                dispatch(authenticateUser(response));
            }
            Loading.hide();
        } catch (error) {
            addToast(t('login.error_login'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onSubmitRecoverPassword = async ({ email }: RecoverPasswordFormModel) => {
        try {
            Loading.show();
            await AccountService.generateResetPasswordCode({ email: email });

            addToast(t('login.recover_password_email_sent'), { appearance: 'success' });
            setScreen(ScreenType.LOGIN);

            Loading.hide();
        } catch (error) {
            addToast(t('login.error_recover_password'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onSubmitSetPasswordFirstLogin = async ({ password }: SetPasswordFirstLoginFormModel) => {
        try {
            if (!queryToken || !queryUsername || !password) {
                return;
            }

            Loading.show();
            await AccountService.resetPassword({ token: queryToken, userName: queryUsername, password });

            addToast(t('login.password_reset_success'), { appearance: 'success' });
            setScreen(ScreenType.LOGIN);

            Loading.hide();
        } catch (error) {
            addToast(t('login.error_recover_password'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onSubmitSetPassword = async ({ password }: SetPasswordFormModel) => {
        try {
            if (!queryToken || !queryUsername || !password) {
                return;
            }

            Loading.show();
            await AccountService.resetPassword({ token: queryToken, userName: queryUsername, password });

            addToast(t('login.password_reset_success'), { appearance: 'success' });
            setScreen(ScreenType.LOGIN);

            Loading.hide();
        } catch (error) {
            addToast(t('login.error_recover_password'), { appearance: 'error' });
            Loading.hide();
        }
    };

    if (isAuthenticated && screen != ScreenType.SET_PASSWORD && screen != ScreenType.FIRST_LOGIN) {
        return <Redirect to={'/'} />;
    }
    
    return (
        <ScreenTitle title={t('login.title')}>
            <div className={styles.container}>

                <div className={styles.left}>

                    <img src={LogoImage} className={styles.image} />

                    {screen === ScreenType.FIRST_LOGIN && <SetPasswordFirstLoginForm onSubmit={onSubmitSetPasswordFirstLogin} queryUsername={queryUsername} />}

                    {screen === ScreenType.LOGIN && <LoginForm onSubmit={onSubmitLogin} />}

                    {screen === ScreenType.RECOVER_PASSWORD && <RecoverPasswordForm onSubmit={onSubmitRecoverPassword} onGoBack={() => setScreen(ScreenType.LOGIN)} />}

                    {screen === ScreenType.SET_PASSWORD && <SetPasswordForm onSubmit={onSubmitSetPassword} onGoBack={() => setScreen(ScreenType.LOGIN)} />}

                    {(screen === ScreenType.RECOVER_PASSWORD || screen === ScreenType.LOGIN) && <a
                        className={`${styles.recoverPasswordLink} ${screen === ScreenType.RECOVER_PASSWORD ? styles.disabled : ''}`}
                        onClick={() => setScreen(ScreenType.RECOVER_PASSWORD)}
                    >
                        {t('login.recover_password_link')}
                    </a>}

                    {(screen === ScreenType.SET_PASSWORD) && <a
                        className={styles.recoverPasswordLink}
                        onClick={() => setScreen(ScreenType.LOGIN)}
                    >
                        {t('login.title')}
                    </a>}

                </div>

                <div className={styles.right}>

                    <div className={styles.gradient}>{t('login.creating_your_media_text')}</div>

                    <div className={styles.image}></div>

                    <div className={styles.footer}>

                        <div className={styles.footerTextContainer}>
                            <div className={styles.text}>{t('login.know_more_here')}</div>
                        </div>

                        <Button className={styles.button} type="submit" text={t('login.learn_more').toUpperCase()} />

                    </div>

                </div>

            </div>

        </ScreenTitle>
    );
}

export default LoginScreen;
