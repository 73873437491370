import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AutoSaveMessage.module.scss';
import dayjs from 'dayjs';
import IconSaveSuccess from '../../../../../../assets/svgs/Saving-bleu2.svg';
import IconSaveDanger from '../../../../../../assets/svgs/Cross-danger.svg';
import { EditorAutoSave } from 'screens/editor/models/EditorModels';
import EditorInternalService from 'screens/editor/utils/EditorInternalService';

type Props = {
    autoSave: EditorAutoSave;
}

const AutoSaveMessage: React.FC<Props> = ({ autoSave }: Props) => {
    const { t } = useTranslation();
    const [trying, setTrying] = useState(false);

    const tryAgain = async () => {
        if (trying) {
            return;
        }

        setTrying(true);
    }

    const quickSave = async () => {
        await EditorInternalService.quickSave();
        setTrying(false);
    }

    useEffect(() => {
        if (trying) {
            quickSave();
        }
    }, [trying])
    
    return (
        <div className={`${styles.container} ${autoSave.status === 'error' ? styles.error : styles.success }`}>
            <img src={autoSave.status === 'error' ? IconSaveDanger : IconSaveSuccess} className={styles.icon} />
            <span className={styles.text}>
                {autoSave.status === 'error' 
                    ? t('editor.automatic_save_error')
                    : t('editor.automatic_save', { at: `${dayjs(autoSave.date).format('HH')}h${dayjs(autoSave.date).format('mm')}` })
                }
                <br />
                {autoSave.status === 'error' && <a
                    onClick={tryAgain}
                    className={`${styles.tryAgainLink} ${trying && styles.tryAgainLinkDisabled}`}
                >
                    {t('common.try_again')}
                </a>}
            </span>
        </div>
    );
}

export default AutoSaveMessage;
