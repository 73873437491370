import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './EditorSidebar.module.scss';
import PhotosContent from './components/photosContent/PhotosContent';
import IconsContent from './components/iconsContent/IconsContent';
import OthersContent from './components/othersContent/OthersContent';
import ChartsContent from './components/chartsContent/ChartsContent';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as IconPhoto } from 'assets/svgs/editor/Photos.svg';
import { ReactComponent as IconStar } from 'assets/svgs/editor/Icones.svg';
import { ReactComponent as IconClose } from 'assets/svgs/Cross-bleu2-contour.svg';
import { ReactComponent as IconHelp } from 'assets/svgs/Help.svg';
import { ReactComponent as IconOthers } from 'assets/svgs/editor/Autres.svg';
import { ReactComponent as IconPlay } from 'assets/svgs/editor/Gif.svg';
import { ReactComponent as IconCharts } from 'assets/svgs/editor/Graphiques.svg';
import { ReactComponent as IconTables } from 'assets/svgs/editor/Tableaux.svg';
import OttoLogoSmall from 'assets/svgs/Logo-otto-2.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { setSidebarSelectedCategoryId } from 'store/editor/action';
import { EditorSidebarCategory, EditorSidebarCategoryId } from 'screens/editor/models/SidebarModels';
import EditorInternalService from 'screens/editor/utils/EditorInternalService';
import Loading from 'common/services/Loading';
import { PreviousPage } from 'api/editor/enums/PreviousPage';
import GifsContent from './components/gifsContent/GifsContent';
import TablesContent from './components/tablesContent/TablesContent';
import { DocumentFormat } from 'api/documents/enums/DocumentFormat';
import AppSettingsService from 'api/appSettings/AppSettingsService';
import { addToast } from 'common/services/Toaster';

export const SIDEBAR_CLOSED_WIDTH = 125;
export const SIDEBAR_ITEMS_PANEL_WIDTH = 320;
export const SIDEBAR_OPEN_WIDTH = SIDEBAR_CLOSED_WIDTH + SIDEBAR_ITEMS_PANEL_WIDTH;

const EditorSidebar = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { previousPage } = useParams<{ previousPage: string }>();

    const documentFormat = useSelector<Reducers, DocumentFormat>(state => state.editor.present.format);

    const categories: EditorSidebarCategory[] = useMemo(() => [
        {
            id: EditorSidebarCategoryId.PHOTOS,
            icon: IconPhoto,
            show: true,
            name: t('editor.sidebar.photos'),
            content: <PhotosContent />
        },
        {
            id: EditorSidebarCategoryId.ICONS,
            icon: IconStar,
            show: true,
            name: t('editor.sidebar.icons'),
            content: <IconsContent />,
        },
        {
            id: EditorSidebarCategoryId.CHARTS,
            icon: IconCharts,
            show: true,
            name: t('editor.sidebar.charts'),
            content: <ChartsContent />,
        },
        {
            id: EditorSidebarCategoryId.TABLES,
            icon: IconTables,
            show: true,
            name: t('editor.sidebar.tables'),
            content: <TablesContent />
        },
        {
            id: EditorSidebarCategoryId.OTHERS,
            icon: IconOthers,
            show: true,
            name: t('editor.sidebar.others'),
            content: <OthersContent />
        },
        {
            id: EditorSidebarCategoryId.GIF,
            icon: IconPlay, 
            show: documentFormat === DocumentFormat.SOCIAL_MEDIA_POSTS,
            name: t('editor.sidebar.gif'),
            content: <GifsContent />
        }
    ], [documentFormat]);

    const dispatch = useDispatch();
    
    const selectedCategoryId = useSelector<Reducers, EditorSidebarCategoryId | null>(state => state.editor.present.sidebarState.selectedCategoryId);
    const selectedCategory = selectedCategoryId ? categories.find(c => c.id === selectedCategoryId) : null;

    const onSelectCategory = (category: EditorSidebarCategory | null) => {
        dispatch(setSidebarSelectedCategoryId(category?.id || null));
    }

    const onCloseClick = async () => {
        Loading.show();

        await EditorInternalService.quickSave();

        Loading.hide();

        if (previousPage === PreviousPage.DOCUMENTS) {
            history.push('/supports');
        } else if (previousPage === PreviousPage.SIMPLE_MODELS) {
            history.push('/simple-models');
        } else if (previousPage === PreviousPage.DOCUMENTS_VALIDATE) {
            history.push('/supports-validate');
        } else if (previousPage === PreviousPage.MANAGE_MODELS) {
            history.push('/manage-pre-filled-models');
        } else {
            history.push('/supports');
        }
    }

    const onHelpClick = async () => {
        try {
            const result = await AppSettingsService.getAppSettings();
            if(result?.faqUrl){
                window.open(result.faqUrl, '_blank');
            }
            else{
                addToast(t('common.messages.faq_not_found'), { appearance: 'warning' });
            }
        } catch (error) {
            addToast(t('common.messages.faq_not_found'), { appearance: 'warning' });
        }
    }

    const renderMenuItem = (text: string, onClick: () => void, IconElement: any, isSelected: boolean, key: number, single: boolean) => (
        <div
            key={key}
            className={`${styles.category} ${isSelected ? styles.selected : ''} ${single ? styles.single : ''}`}
            onClick={(onClick)}
        >
            <IconElement className={styles.categoryIcon} />
            <span>{text}</span>
        </div>
    );

    return (
        <div className={styles.container}>

            <div className={styles.compactContainer} style={{ width: `${SIDEBAR_CLOSED_WIDTH}px` }}>
                <div className={styles.logoItem}>
                    <img src={OttoLogoSmall} />
                </div>

                <div className={styles.categories}>
                    {categories.filter(x => x.show).map((cat, i) => {
                        const IconElement = cat.icon;
                        const isSelected = selectedCategoryId && cat.id === selectedCategoryId ? true : false;

                        return renderMenuItem(cat.name, () => onSelectCategory(isSelected ? null : cat), IconElement, isSelected, i, false);
                    })}
                </div>
                <div className={styles.quitItemContainer}>
                    <div className={styles.quitItem}>
                        {renderMenuItem(t('editor.sidebar.help'), onHelpClick, IconHelp, false, 1, true)}
                    </div>
                    <div className={styles.quitItemLine}></div>
                    <div className={styles.quitItem}>
                        {renderMenuItem(t('editor.sidebar.quit'), onCloseClick, IconClose, false, 1, true)}
                    </div>
                </div>
            </div>

            {selectedCategory && (
                <div className={styles.items} style={{
                    width: `${SIDEBAR_ITEMS_PANEL_WIDTH}px`,
                    left: `${SIDEBAR_CLOSED_WIDTH}px`,
                }}>
                    {selectedCategory.content}
                </div>
            )}
        </div>
    );

}

export default memo(EditorSidebar);
