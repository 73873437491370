import Http from '../../common/services/Http';
import { TagsViewModel } from './models/TagsViewModel';
import { TagDto } from './models/TagDto';
import { TagsSearchCriteria } from './models/TagsSearchCriteria';

class TagsService {

    public getList(criteria: TagsSearchCriteria) {
        return Http.get<TagDto[]>('tags', criteria);
    }

     public create(model: TagsViewModel) {
        return Http.post('tags', model);
    }

    public update(model: TagsViewModel) {
        return Http.put('tags', model);
    }

    public remove(model: { id: string }) {
        return Http.put('tags/deactivate', model);
    }
}

export default new TagsService();