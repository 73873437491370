import React, { Fragment, useCallback, useRef } from 'react';
import styles from './EditorBody.module.scss';
import EditorPage from '../editorPage/EditorPage';
import EditorPageContainer from '../editorPage/EditorPageContainer';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelected, removePageElements } from 'store/editor/action';
import { Reducers } from 'store/types';
import EditorToolbar from '../editorToolbar/EditorToolbar';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import { Page } from 'screens/editor/models/EditorModels';
import { DocumentFormat } from 'api/documents/enums/DocumentFormat';
import useElementSize from 'common/hooks/useElementSize';
import { PageKeyMaps, PageKeyMapsType } from 'screens/editor/utils/EditorDefaults';
import { GlobalHotKeys } from 'react-hotkeys';
import EditorUtils from 'screens/editor/utils/EditorUtils';
import store from 'store/store';

type Props = {
    width: number;
    height: number;
    sidebarWidth: number;
};

const EditorBody: React.FC<Props> = ({ width, height, sidebarWidth }: Props) => {

    const dispatch = useDispatch();
    const format = useSelector<Reducers, DocumentFormat>(state => state.editor.present.format);
    const pages = useSelector<Reducers, Page[]>(state => state.editor.present.pages);
    const toolbarRef = useRef(null);
    const { height: toolbarHeight } = useElementSize(toolbarRef);

    const clearSelection = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.target === e.currentTarget) {
            Logger.info(LOGGER_LOG_TYPE.EDITOR, 'EditorBody clearSelection (clear all selections)');
            dispatch(clearSelected());
        }
    }, []);

    const renderPages = () => {
        const isDoublePage = format === DocumentFormat.PRODUCT_BROCHURE;
        if (!isDoublePage) {
            return pages.map((page, i) => (
                <EditorPageContainer
                    key={`page-container-${i}`}
                    bodyWidth={width}
                    bodyHeight={height}
                    pageWidth={page.width}
                    pageHeight={page.height}
                >
                    {(containerWidth, containerHeight, scale) => (
                        <EditorPage
                            width={containerWidth}
                            height={containerHeight}
                            scale={scale}
                            page={page}
                        />
                    )}
                </EditorPageContainer>
            ));
        }

        const doublePages: Page[][] = pages.filter((_, i) => i > 0).reduce<any>((result, _value, index, array) => {
            if (index % 2 === 0) {
                result.push(array.slice(index, index + 2));
            }
            return result;
        }, []);

        return (
            <Fragment>
                <EditorPageContainer
                    key={`page-container-${0}`}
                    bodyWidth={width}
                    bodyHeight={height}
                    pageWidth={pages[0].width}
                    pageHeight={pages[0].height}
                >
                    {(containerWidth, containerHeight, scale) => (
                        <EditorPage
                            width={containerWidth}
                            height={containerHeight}
                            scale={scale}
                            page={pages[0]}
                        />
                    )}
                </EditorPageContainer>
                {doublePages.map((pageContainer, i) => {
                    const pagesWidth = pageContainer.map(p => p.width).reduce((a, b) => a + b, 0)

                    return (
                        <EditorPageContainer
                            key={`page-container-${i + 1}`}
                            bodyWidth={width}
                            bodyHeight={height}
                            pageWidth={pagesWidth}
                            pageHeight={pageContainer[0].height}
                        >
                            {(containerWidth, containerHeight, scale) => pageContainer.map((page, ii) => (
                                <EditorPage
                                    width={containerWidth / pageContainer.length}
                                    height={containerHeight}
                                    scale={scale}
                                    page={page}
                                    key={`page-${i}-${ii}`}
                                />
                            ))}
                        </EditorPageContainer>
                    );
                })}
            </Fragment>
        );
    }

    const deleteElement = useCallback(() => {
        const editorState = store.getState().editor.present;
        const [selectedElements, selectedPage] = EditorUtils.getSelectedElementsFromState(editorState);
        
        if (selectedPage && selectedElements && selectedElements.length > 0) {
            dispatch(removePageElements(selectedPage, selectedElements));
        }
    }, [dispatch]);

    const handlers: PageKeyMapsType = {
        DELETE_ELEMENT: deleteElement
    };

    const pagesMarginTop = toolbarHeight > 100 ? toolbarHeight : 100;

    return (
        <div className={styles.container} style={{ width: `${width}px`, left: `${sidebarWidth}px` }} onClick={clearSelection}>
            <EditorToolbar width={width - 8} ref={toolbarRef} />
            <GlobalHotKeys keyMap={PageKeyMaps} handlers={handlers}>
                <div className={styles.pagesContainer} onClick={clearSelection}>
                    {/* style={{ marginTop: `${(toolbarHeight + 25)}px` }} */}
                    <div className={styles.pagesList} style={{ marginTop: `${(pagesMarginTop)}px` }} onClick={clearSelection}>
                        {renderPages()}
                    </div>
                </div>
            </GlobalHotKeys>
        </div>
    );
}

export default EditorBody;
