import React, { memo, useMemo } from 'react';
import { ChartRenderProps } from '../../../../utils/EditorProps';
import { Cell, Global, Legend, Pie, PieChart } from 'recharts';
import EditorChartsUtils from 'screens/editor/utils/EditorChartsUtils';
Global.isSsr = true;

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontSize={(14 * outerRadius) / 110}>
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const PieChartRender = ({ data, width, height, fallback, children, preview, withoutLegend, ...props }: ChartRenderProps, ref: React.ForwardedRef<any>) => {

    if (!data || !data.tableData || data.tableData.length < 2) {
        return fallback || <></>;
    }

    const chartData = useMemo(() => {
        return EditorChartsUtils.tableDataToRowsData(data.tableData);
    }, [data.tableData]);

    if (!chartData || chartData.length === 0) {
        return fallback || <></>;
    }

    return (
        <PieChart
            {...props}
            width={width}
            height={height - 20}
            ref={ref}
        >
            <Pie
                data={chartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                nameKey="name"
                label={renderCustomizedLabel}
                outerRadius={'90%'}
                fill="#8884d8"
                dataKey="value"
                isAnimationActive={false}
            >
                {chartData.map((row, index) => (
                    <Cell key={`cell-${index}`} fill={row.color} />
                ))}
                {children}
            </Pie>
            {!withoutLegend && <Legend verticalAlign="bottom" iconSize={preview ? 14 : 16} wrapperStyle={{ position: 'relative', fontSize: preview ? '0.8rem' : '1rem' }} />}
        </PieChart>
    );
}

export default memo(React.forwardRef<any, ChartRenderProps>(PieChartRender));
