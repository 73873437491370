import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styles from '../infinityScroll/InfinityScroll.module.scss';

type Props = {
    children: JSX.Element[] | JSX.Element;
    totalItems: number;
    executeOnScroll: () => void;
    continueScroll: boolean;
    elementIdToScroll: string;
    inModal?: boolean;
    className?: string;
}

const InfinityScroll: React.FC<Props> = ({ children, totalItems, executeOnScroll, continueScroll, elementIdToScroll, inModal = false }: Props) => {
    const [bottomOpacity, setBottomOpacity] = useState<boolean>(false);
    const [topOpacity, setTopOpacity] = useState<boolean>(false);

    const manageScrollOpacity = () => {
        const scrollElement = document.querySelector('#' + elementIdToScroll); 0;
        const clientHeight = scrollElement?.clientHeight ?? 0;

        const elementToAppendTopBlur = document.querySelector('#scrollContent');
        const rect = elementToAppendTopBlur?.getBoundingClientRect();

        if ((rect?.top ?? 0) < 0) {
            setTopOpacity(true);
        } else {
            setTopOpacity(false);
        }

        if ((rect?.height ?? 0) + (rect?.top ?? 0) > clientHeight) {
            setBottomOpacity(true);
        } else {
            setBottomOpacity(false);
        }
    }

    useEffect(() => {
        if (totalItems === 0) {
            return;
        }

        const container = document.getElementById(elementIdToScroll);
        if (container && container.scrollHeight <= container.offsetHeight) {
            executeOnScroll();
        }
    }, [totalItems]);

    return (
        <div id="scrollContent">
            {topOpacity && <div className={inModal ? '' : styles.opacityTop}></div>}
            <InfiniteScroll
                onScroll={manageScrollOpacity}
                dataLength={totalItems}
                next={executeOnScroll}
                hasMore={continueScroll}
                loader={null}
                scrollableTarget={elementIdToScroll}

            >
                {children}
            </InfiniteScroll>
            {bottomOpacity && <div className={inModal ? '' : styles.opacityBottom}></div>}
        </div>
    );
}

export default InfinityScroll;