import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { NotificationsTemplatesSearchCriteria } from './models/NotificationsTemplatesSearchCriteria';
import { NotificationTemplateViewModel } from './models/NotificationTemplateViewModel';

class NotificationsTemplatesService {

    public getList(criteria: NotificationsTemplatesSearchCriteria) {
        return Http.get<Page<NotificationTemplateViewModel>>('notificationstemplates', criteria);
    }

    public getById(id: string) {
        return Http.get<NotificationTemplateViewModel>('notificationstemplates/'+ id);
    }

    public save(model: NotificationTemplateViewModel) {
        return Http.put('notificationstemplates', model);
    }
}

export default new NotificationsTemplatesService();