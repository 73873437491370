import { DocumentOrientation } from 'api/documents/enums/DocumentOrientation';
import { CSSProperties } from 'react';
import { DocumentFormat } from 'api/documents/enums/DocumentFormat';
import DocumentEditorData from 'api/documents/models/DocumentEditorData';
import Utils from 'common/services/Utils';
import { EditorFontItem, Page, PageElement } from '../models/EditorModels';

export type FormatSetting = {
    width: number;
    height: number;
    thumbnailWidth: number;
    thumbnailHeight: number;
    orientation: DocumentOrientation; 
}

export const PageKeyMaps = {
    DELETE_ELEMENT: ['del', 'backspace']
};

export type PageKeyMapsType = { [key in (keyof typeof PageKeyMaps)]: () => void | undefined; };

export const EDITOR_DEFAULT_BODER_RADIUS = 30;

class EditorDefaults {

    public getElementBorderRadiusStyle(element: PageElement): CSSProperties {
        return element.borderRadius
            ? {
                borderRadius: element.borderRadius.type === 'round' ? EDITOR_DEFAULT_BODER_RADIUS : 0,
            }
            : {};
    }

    public getElementBorderStyle(element: PageElement): CSSProperties {
        const borderWidth = element.border?.width || 0;
        const borderColor = borderWidth > 0 ? (element.border?.color || '#000000') : undefined;
        const borderStyle = borderWidth > 0 ? 'solid' : undefined;

        return element.border
            ? {
                borderWidth,
                borderColor,
                borderStyle,
            }
            : {};
    }

    public getElementStyles(element: PageElement): CSSProperties {
        const clipStyle = element.clip && element.clip.enabled
            ? {
                clip: element.clip.type === 'rect' && element.clip.style ? element.clip.style : undefined,
                clipPath: element.clip.type !== 'rect' && element.clip.style ? element.clip.style : undefined,
            }
            : {};

        return {
            transform: `translate(${element.x}px, ${element.y}px) rotate(${element.rotation}deg)`,
            width: `${element.width}px`,
            height: `${element.height}px`,
            position: 'absolute',
            ...element.style,
            ...clipStyle,
        };
    }

    public getPageSizeByFormat(format: DocumentFormat): FormatSetting {
        switch (format) {
            case DocumentFormat.PRODUCT_SHEET:
                return {
                    width: 793.701,
                    height: 1122.52,
                    thumbnailWidth: 80,
                    thumbnailHeight: 113.136,
                    orientation: DocumentOrientation.PORTRAIT
                };
            case DocumentFormat.PRESENTATION_SLIDES:
                return {
                    width: 1920,
                    height: 1080,
                    thumbnailWidth: 150,
                    thumbnailHeight: 84.375,
                    orientation: DocumentOrientation.LANDSCAPE
                };
            case DocumentFormat.PRODUCT_BROCHURE:
                return {
                    width: 793.701,
                    height: 1122.52,
                    thumbnailWidth: 80,
                    thumbnailHeight: 113.136,
                    orientation: DocumentOrientation.PORTRAIT
                };
            case DocumentFormat.SOCIAL_MEDIA_POSTS:
                return {
                    width: 940,
                    height: 788,
                    thumbnailWidth: 150,
                    thumbnailHeight: 125.745,
                    orientation: DocumentOrientation.LANDSCAPE
                };
            default:
                throw new Error(`Couldn't get the page size by format, probably not implemented yet`);
        }
    }

    public getDefaultEditorData(format: DocumentFormat): DocumentEditorData {
        return {
            pages: [
                this.getNewPage(format, 1),
            ],
            fonts: [],
        };
    }

    public getNewPage(format: DocumentFormat, number: number): Page {
        const { width, height, thumbnailWidth, thumbnailHeight } = this.getPageSizeByFormat(format);
        return {
            id: Utils.newGuid(),
            number: number,
            elements: [],
            width,
            height,
            pageTime: 5,
            thumbnail: {
                dataUrl: null,
                width: thumbnailWidth,
                height: thumbnailHeight,
                changerId: null
            },
        };
    }

    public getFonts(): EditorFontItem[] {
        return [
            {
                name: 'Arial',
                value: 'Arial',
            },
            {
                name: 'Times New Roman',
                value: 'Times New Roman',
            },
            {
                name: 'Arial',
                value: 'Arial',
            },
            {
                name: 'Arial Black',
                value: 'Arial Black',
            },
            {
                name: 'Verdana',
                value: 'Verdana',
            },
            {
                name: 'Tahoma',
                value: 'Tahoma',
            },
            {
                name: 'Trebuchet MS',
                value: 'Trebuchet MS',
            },
            {
                name: 'Impact',
                value: 'Impact',
            },
            {
                name: 'Times New Roman',
                value: 'Times New Roman',
            },
            {
                name: 'Didot',
                value: 'Didot',
            },
            {
                name: 'Georgia',
                value: 'Georgia',
            },
            {
                name: 'American Typewriter',
                value: 'American Typewriter',
            },
            {
                name: 'Andalé Mono',
                value: 'Andalé Mono',
            },
            {
                name: 'Courier',
                value: 'Courier',
            },
            {
                name: 'Lucida Console',
                value: 'Lucida Console',
            },
            {
                name: 'Monaco',
                value: 'Monaco',
            },
            {
                name: 'Bradley Hand',
                value: 'Bradley Hand',
            },
            {
                name: 'Brush Script MT',
                value: 'Brush Script MT',
            },
            {
                name: 'Luminari',
                value: 'Luminari',
            },
            {
                name: 'Comic Sans MS',
                value: 'Comic Sans MS',
            },
        ];
    }
}

export default new EditorDefaults();