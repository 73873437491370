import React, { memo } from 'react';
import styles from './Box.module.scss';
import BoxContent from './BoxContent';

export type Props = {
    icon?: any;
    title: string;
    text: string;
    contentClassName?: string;
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Box: React.FC<Props> = ({  contentClassName, icon, title, text, ...props }: Props) => {

    return (
        <div {...props}>
            <div className={styles.header}>
                <img src={icon} className={styles.icon} />
            </div>
            <BoxContent className={`${contentClassName || ''}`}>
                <div className={styles.title}>
                    {title}
                </div>
                <div className={styles.text}>
                    {text}
                </div>

            </BoxContent>
        </div>
    );
}

export default memo(Box);
