/* eslint-disable react/display-name */
import React from 'react';
import { useTranslation } from 'react-i18next';
import LibSelect, { components, Props as SelectProps } from 'react-select';
import colors from './../../../styles/export/colors.module.scss';
import IconArrowDown from '../../../assets/svgs/Direction-bleu2.svg';


export type Props = {
    isDisabled: boolean;
    icon?: any;
    maxHeight?: string;
} & SelectProps;

const Select = ({ icon, maxHeight, ...props }: Props, ref: any) => {

    const { t } = useTranslation();

    const drawIcon = (icon: any) => ({
        alignItems: 'center',
        display: 'flex',
        ':before': {
            backgroundImage: 'url(' + icon + ')',
            content: '" "',
            display: icon ? 'block' : 'none',
            marginRight: 8,
            height: '2.2rem',
            width: '1.7rem',
            backgroundRepeat: 'no-repeat',
            marginTop: '0.25rem',
            marginBottom: '0.25rem',
        }
    });

    const DropdownIndicator = (props: any) => {
        return (
            <components.DropdownIndicator {...props}>
                {(props.selectProps.menuIsOpen) ? <img src={IconArrowDown} style={{ fontSize: '5rem', width: '1rem', transform: 'rotate(0.5turn)' }} /> : <img src={IconArrowDown} style={{ fontSize: '5rem', width: '1rem' }} />}
            </components.DropdownIndicator>
        );
    };


    return (
        <LibSelect
            isClearable={true}
            noOptionsMessage={() => t('common.no_options')}
            {...props}
            ref={ref}
            components={{ ...(props.components || {}), DropdownIndicator }}
            styles={{
                control: (base, state) => ({
                    ...base,
                    backgroundColor: props.isDisabled ? colors.whisper : colors.white,
                    fontSize: '0.9rem',
                    borderRadius: state.menuIsOpen ? '1rem 1rem 0 0' : '1rem',
                    border: `2px solid ${colors.primaryLight}`,
                    borderBottom: state.menuIsOpen ? `2px solid transparent` : `2px solid ${colors.primaryLight}`,
                    boxShadow: state.menuIsOpen ? '0px 10px 16px -5px #BAD4E0' : 'none', 
                    ':hover': {
                        borderColor: colors.primaryLight,
                    },
                    padding: '0.3rem 0.3rem 0.3rem 0',
                    zIndex: 2,
                }),
                menu: base => ({
                    ...base,
                    border: `2px solid ${colors.primaryLight}`,
                    borderRadius: '0 0 1rem 1rem',
                    borderTop: 0,
                    marginTop: 0,
                }),
                placeholder: base => ({
                    ...base,
                    color: colors.primary,
                    opacity: 0.54,
                    marginLeft: icon ? '2.5rem !important' : '',
                    fontSize: '0.9rem',
                    textTransform: 'uppercase',
                    fontWeight: 'bold'
                }),
                valueContainer: (base, state) => ({
                    ...base,
                    padding: state.isMulti ? '6px 1rem' : '0px 1rem',
                    ...drawIcon(icon),
                }),
                option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected ? colors.lightGrayishBlue : colors.white,
                    color: colors.primary,
                    fontSize: '0.9rem',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    padding:'0.8rem',

                    opacity: 0.54,
                    ...drawIcon(icon),
                    ':nth-of-type(even)': {
                        backgroundColor: colors.white
                    },
                    ':nth-of-type(odd)': {
                        backgroundColor: colors.lightGrayishBlue
                    },
                    ':last-child': {
                        borderRadius: '0 0 0.8rem 0.8rem',
                    },
                    ':hover': {
                        backgroundColor: colors.primaryLight,
                    },
                }),
                singleValue: base => ({
                    ...base,
                    color: colors.primary,
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    marginLeft: icon ? '2.5rem !important' : ''
                }),
                multiValue: base => ({
                    ...base,
                    backgroundColor: colors.primary,
                    borderRadius: '8px',
                    margin: '0.2rem',
                    padding: '0.1rem'
                }),
                multiValueLabel: base => ({
                    ...base,
                    backgroundColor: colors.primary,
                    color: colors.white,
                    borderRadius: '10px 0px 0px 10px',
                    fontSize: '0.9rem',
                    padding: '4px 10px 5px 10px!important'
                }),
                multiValueRemove: base => ({
                    ...base,
                    backgroundColor: colors.white,
                    color: colors.primary,
                    borderRadius: '20px',
                    margin: '0.25rem',
                    padding: '0.3rem'
                }),

                indicatorSeparator: (base, state) => ({
                    ...base,
                    display: state.hasValue ? 'block' : 'none',
                }),
                menuList: (base, state) => ({
                    ...base,
                    padding: 0,
                    maxHeight: maxHeight ? maxHeight : 'none',
                    '&::-webkit-scrollbar': {
                        width: '0.5rem',
                        height: '0.4rem'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: colors.primary,
                        borderRadius: '10px'
                    },
                    '&::-webkit-scrollbar-track': {
                        background: colors.white,
                        borderRadius: '10px'
                    }
                }),

            }}
        />
    )
};

export default React.forwardRef<HTMLInputElement, Props>(Select);