import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updatePageElement } from '../../../../../../store/editor/action';
import { ToolbarOptionProps } from '../../../../utils/EditorProps';
import ToolbarItemIconGroup from '../toolbarItemIconGroup/ToolbarItemIconGroup';
import ToolbarItemIconGroupItem from '../toolbarItemIconGroup/ToolbarItemIconGroupItem';
import { FaAlignCenter, FaAlignJustify, FaAlignLeft, FaAlignRight } from 'react-icons/fa';

const TextAlignToolbarOption: React.FC<ToolbarOptionProps> = ({ page, elements, className }: ToolbarOptionProps) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const element = elements![0];
    const currentAlign = element.style.textAlign || 'left';

    const setAlign = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>, align: 'left' | 'center' | 'right' | 'justify') => {
        evt.preventDefault();
        evt.stopPropagation();
        
        dispatch(updatePageElement(page!, {
            ...element,
            style: {
                ...element.style,
                textAlign: align,
            },
        }));
    }

    return (
        <ToolbarItemIconGroup text={t('editor.toolbar.align')} className={className}>
            <ToolbarItemIconGroupItem iconElem={FaAlignLeft} active={currentAlign === 'left'} onClick={e => setAlign(e, 'left')} />
            <ToolbarItemIconGroupItem iconElem={FaAlignCenter} active={currentAlign === 'center'} onClick={e => setAlign(e, 'center')} />
            <ToolbarItemIconGroupItem iconElem={FaAlignRight} active={currentAlign === 'right'} onClick={e => setAlign(e, 'right')} />
            <ToolbarItemIconGroupItem iconElem={FaAlignJustify} active={currentAlign === 'justify'} onClick={e => setAlign(e, 'justify')} />
        </ToolbarItemIconGroup>
    );
}

export default TextAlignToolbarOption;
