import { AddNotificationAction, ADD_NOTIFICAITON, DecrementTotalNotificationsNotReadAction, DECREMENT_TOTAL_NOTIFICAITONS_NOT_READ, IncrementTotalNotificationsNotReadAction, INCREMENT_TOTAL_NOTIFICAITONS_NOT_READ, NotificationStatusState, SetNotificationsAction, SetNotificationsStatusAction, SetTotalNotificationsNotReadAction, SET_NOTIFICAITONS, SET_NOTIFICAITONS_STATUS, SET_TOTAL_NOTIFICAITONS_NOT_READ } from './type';
import { NotificationDto } from 'api/notifications/models/NotificationDto';

export const addNotification = (notification: NotificationDto): AddNotificationAction => ({
	type: ADD_NOTIFICAITON,
	notification,
});

export const setNotifications = (notifications: NotificationDto[]): SetNotificationsAction => ({
	type: SET_NOTIFICAITONS,
	notifications,
});

export const setTotalNotificationsNotRead = (total: number): SetTotalNotificationsNotReadAction => ({
	type: SET_TOTAL_NOTIFICAITONS_NOT_READ,
	total,
});

export const incrementTotalNotificationsNotRead = (): IncrementTotalNotificationsNotReadAction => ({
	type: INCREMENT_TOTAL_NOTIFICAITONS_NOT_READ,
});

export const decrementTotalNotificationsNotRead = (): DecrementTotalNotificationsNotReadAction => ({
	type: DECREMENT_TOTAL_NOTIFICAITONS_NOT_READ,
});

export const setNotificationsStatus = (status: NotificationStatusState | null): SetNotificationsStatusAction => ({
	type: SET_NOTIFICAITONS_STATUS,
	status,
});
