import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToolbarOptionProps } from '../../../../utils/EditorProps';
import ToolbarButton from '../toolbarButton/ToolbarButton';
import IconBorder from 'assets/svgs/toolbar/Border.svg';
import IconPlus from 'assets/svgs/toolbar/Border1.svg';
import IconLess from 'assets/svgs/toolbar/Border0.svg';
import { ReactComponent as IconColor } from 'assets/svgs/toolbar/Color.svg';
import ToolbarButtonOption, { ToolbarButtonOptionIcon } from '../toolbarButtonOption/ToolbarButtonOption';
import { PageElementBorder } from 'screens/editor/models/EditorModels';
import { useDispatch, useSelector } from 'react-redux';
import { updatePageElement } from 'store/editor/action';
import styles from './BorderToolbarOption.module.scss';
import { ColorResult, SketchPicker } from 'react-color';
import EditorUtils from 'screens/editor/utils/EditorUtils';
import Utils from 'common/services/Utils';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';

const iconWidth = '0.8rem';

const BorderToolbarOption: React.FC<ToolbarOptionProps> = ({ elements, page, className }: ToolbarOptionProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showDropdown, setShowDropdown] = useState(false);
    const element = elements![0];
    const border: PageElementBorder = element.border || { color: 'rgba(0,0,0,1)', width: 0  };
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [color, setColor] = useState<string>(border.color);
    const pickerRef = useRef<any>();
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    
    const setWidth = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, width: number) => {
        e.preventDefault();
        e.stopPropagation();
        
        const el = element;

        dispatch(updatePageElement(page!, {
            ...el,
            border: {
                ...border,
                width: width <= 0 ? 0 : (width > 20 ? 20 : width),
            }
        }));
    }

    const saveColor = () => {
        const el = element;

        dispatch(updatePageElement(page!, {
            ...el,
            border: {
                ...border,
                color,
            }
        }));
    }

    const openColorPicker = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        evt.preventDefault();
        evt.stopPropagation();

        setShowColorPicker(!showColorPicker);
    }

    const onPickerChange = (color: ColorResult, evt: React.ChangeEvent<HTMLInputElement>) => {
        evt.preventDefault();
        evt.stopPropagation();

        const rgba = Utils.rgbaToString(color.rgb);

        setColor(rgba);

        const domElement = document.getElementById(EditorUtils.getPageElementId(element.id));
        if (domElement) {
            const imgs = domElement.getElementsByTagName('img')
            if (imgs && imgs.length > 0) {
                imgs[0].style.borderColor = rgba;
            } else {
                domElement.style.borderColor = rgba;
            }
        }
    }

    const onClickOutside = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        evt.preventDefault();
        evt.stopPropagation();

        saveColor();
        setShowColorPicker(false);
    }

    const getColors = () => {
        const generateColors: string[] = [];
        if (loggedUser && loggedUser.companyColors) {
            loggedUser.companyColors.forEach(x => {
                const rgba = Utils.hexToRGBA(x.color, parseInt(x.alpha) / 100);
                generateColors.push(rgba);
            })
        }
        return generateColors;
    }

    return (
        <div className={`${styles.container} ${className}`}>
            <ToolbarButton
                text={t('editor.toolbar.borders')}
                onClick={() => setShowDropdown(!showDropdown)}
                icon={IconBorder}
                showDropdown={showDropdown}
            >
                <ToolbarButtonOption
                    text={t('editor.toolbar.border_width')}
                >
                    <div className={styles.rightIcons}>
                        <ToolbarButtonOptionIcon icon={IconLess} style={{ width: iconWidth }} onClick={e => setWidth(e, border.width - 1)} />
                        <ToolbarButtonOptionIcon icon={IconPlus} style={{ width: iconWidth }} containerStyle={{ paddingRight: 0 }} onClick={e => setWidth(e, border.width + 1)} />
                    </div>
                </ToolbarButtonOption>
                <ToolbarButtonOption
                    text={t('editor.toolbar.border_color')}
                >
                    <div className={styles.rightIcons}>
                        <ToolbarButtonOptionIcon IconElem={IconColor} style={{ width: iconWidth }} fill={color} containerStyle={{ paddingRight: 0 }} onClick={openColorPicker} />
                        {showColorPicker && <div className={styles.overlay} onMouseDown={onClickOutside}></div>}
                        {showColorPicker && <div className={styles.colorPicker} onMouseDown={evt => evt.stopPropagation()}>
                            <SketchPicker
                                color={color}
                                onChange={onPickerChange}
                                ref={pickerRef}
                                presetColors={loggedUser && loggedUser?.companyColors && loggedUser?.companyColors.length > 0 ? getColors() : undefined}
                            />
                        </div>}
                    </div>
                </ToolbarButtonOption>
            </ToolbarButton>
        </div>
    );
}

export default BorderToolbarOption;
