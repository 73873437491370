import React, { useMemo } from 'react';
import EditorDefaults from '../../../../utils/EditorDefaults';
import { ElementProps } from '../../../../utils/EditorProps';
import Prefixer from 'common/services/Prefixer';
import RenderSVG from 'common/components/renderSVG/RenderSVG';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SvgElementRender = ({ page, element, selected, ...props }: ElementProps, ref: React.ForwardedRef<HTMLDivElement>) => {

    const style = useMemo(() => Prefixer.prefixStyle(EditorDefaults.getElementStyles(element)), [element]);

    return (
        <div
            {...props}
            ref={ref}
            style={style}
        >
            <RenderSVG
                value={element.value as string}
                style={{
                    width: `100%`,
                    height: `100%`,
                    color: 'inherit',
                    fill: style.fill,
                }}
            />
        </div>
    );

}

export default React.forwardRef<HTMLDivElement, ElementProps>(SvgElementRender);
