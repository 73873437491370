import styles from './TablePreview.module.scss';
import IconEye from 'assets/svgs/Eye-vert.svg';
import { useTranslation } from 'react-i18next';
import React from 'react';
import DraggableContentItem from 'screens/editor/components/editorSidebar/components/draggableContentItem/DraggableContentItem';
import EditorElementGenerator from 'screens/editor/utils/EditorElementGenerator';
import { EditorTableData, EditorTableDataValue, EditorTableType } from 'screens/editor/models/TableModels';
import EditorResolver from 'screens/editor/utils/EditorResolver';

type Props = {
    tableType: EditorTableType;
    data: EditorTableData[];
};

const TablePreview = ({ tableType, data }: Props) => {
    const { t } = useTranslation();
    const TableElement = EditorResolver.getTableRenderByType(tableType);
    const tableDataValue: EditorTableDataValue = {
        type: tableType,
        tableData: data,
    };
    const width = 260;
    const height = 200;

    const createTableElement = () => {
        return EditorElementGenerator.createTableElement(width, height, {
            type: tableType,
            tableData: data
        });
    }

    const renderFallback = () => {
        return <>
            <img src={IconEye} className={styles.icon} />
            <span>{t('editor.sidebar.table_preview_text')}</span>
        </>;
    }

    return (
        <DraggableContentItem generateElement={() => createTableElement()} className={styles.container}>
            <TableElement data={tableDataValue} width={width} height={height} fallback={renderFallback()} inPreview={true} />
        </DraggableContentItem>
    );
}

export default TablePreview;