import React, { useState } from 'react';
import styles from './ColorPallete.module.scss';
import IconPlus from '../../../assets/svgs/Plus-Bllue2-Outlines.svg';
import IconDelete from '../../../assets/svgs/Trash-red.svg';
import { Color } from 'api/companies/models/CompanyViewModel';
import { useTranslation } from 'react-i18next';

type Props = {
    isDetails: boolean;
    isEdit: boolean;
    editColors: Color[];
    title: string;
    onChangeColor: (colors: Color[]) => void;
};

const ColorPallete: React.FC<Props> = ({ isDetails, isEdit, editColors, title, onChangeColor }: Props) => {
  
  const { t } = useTranslation();
  
  const [colors, setColors] = useState<Color[]>(isEdit || isDetails ? editColors : []);
  const [selectedColor, setSelectedColor] = useState<Color | null>(null);

  const onSelectColor = (c: Color) => {
    setSelectedColor(c);  
  }
  
  const updateColorHex = (c: Color) => {
    const r = parseInt(c.hex.slice(1, 3), 16);
    const g = parseInt(c.hex.slice(3, 5), 16);
    const b = parseInt(c.hex.slice(5, 7), 16);
    setColors(colors.map(x => { 
        if(x.index === c.index){
            x.hex = c.hex;
            x.color = c.hex;
            x.r = r ? r.toString() : '0'; x.g = g ? g.toString() : '0'; x.b = b ? b.toString() : '0'; x.alpha = c.alpha;
            x.alphaApply = (parseInt(c.alpha)/100).toString();
        } 
        return x; 
    }));
    onChangeColor(colors);
  }

  const updateColorRgb = (c: Color) => {
      setColors(colors.map(x => { 
          if(x.index === c.index){ 
              x.r = c.r; x.g = c.g; x.b = c.b; x.alpha = c.alpha; x.alphaApply = (parseInt(c.alpha)/100).toString();
              x.hex = '#' + ((1 << 24) + (parseInt(c.r) << 16) + (parseInt(c.g) << 8) + parseInt(c.b)).toString(16).slice(1);
              x.color = x.hex;
          } 
          return x; 
      }));
      onChangeColor(colors);
  }

  const addColor = () => {
      const newColor = [...colors, { index: colors.length, hex: '#ffffff', r: '255', g: '255', b: '255', alpha: '100', alphaApply: '1', color: '#ffffff' }];
      const refreshColors = newColor.map((x, i) => ({ index: i, hex: x.hex, r: x.r, g: x.g, b: x.b, alpha: x.alpha, alphaApply: x.alphaApply, color: x.color }));
      setSelectedColor(null);
      setColors(refreshColors);
      onChangeColor(refreshColors);
  }

  const removerColor = (selectedColor: Color | null) => {
      const refreshColors = selectedColor ? colors.filter(x => x.index != selectedColor.index) : colors;
      setSelectedColor(null);
      setColors(refreshColors);
      onChangeColor(refreshColors);
  }

  return (
    <div className={styles.panelColor}>
      <div className={styles.titleColor}>{title}</div>
      <div>
          <div className={styles.pallete}>
              { !isDetails && <img src={IconPlus} onClick={() => addColor()} className={styles.addColor} /> }
              { colors && colors.map((x, index) => {
                  return (
                      <div key={index}>
                          <div 
                              onClick={() => !isDetails ? onSelectColor(x) : null} 
                              className={x.index == selectedColor?.index ? styles.areaColorSelected : styles.areaColor} 
                              style={{ backgroundColor: 'rgba(' + x.r + ', ' + x.g + ', ' + x.b + ', ' + x.alphaApply + ')' }}>
                          </div>
                      </div>
                  )
              })}
              { colors.length == 0 && <div className={styles.noColors}>{t('companies.without_colors')}</div>}
          </div>
          { selectedColor && !isDetails && <div className={styles.infoColor}>
              <div className={styles.infoArea}>
                  <input 
                      maxLength={7}
                      value={selectedColor?.hex} 
                      className={styles.inputHexInfoArea}
                      onChange={e => {
                          selectedColor.hex = e.currentTarget.value;
                          setSelectedColor(selectedColor);
                          updateColorHex(selectedColor);
                      }}></input>
                  <div className={styles.textInfoArea} title={t('companies.hexadecimal')}>Hex</div>
              </div>
              <div className={styles.infoArea}> 
                  <input 
                      type={'number'}
                      min={0}
                      max={255}
                      value={selectedColor?.r} 
                      className={styles.inputRGB}
                      onChange={e => {
                          selectedColor.r = e.currentTarget.value;
                          setSelectedColor(selectedColor);
                          updateColorRgb(selectedColor);
                      }}></input>
                  <div className={styles.textInfoArea}>R</div>
              </div>
              <div className={styles.infoArea}>
                  <input 
                      type={'number'}
                      min={0}
                      max={255}
                      value={selectedColor?.g} 
                      className={styles.inputRGB}
                      onChange={e => {
                          selectedColor.g = e.currentTarget.value;
                          setSelectedColor(selectedColor);
                          updateColorRgb(selectedColor);
                      }}></input>
                  <div className={styles.textInfoArea}>G</div>
              </div>
              <div className={styles.infoArea}>
                  <input 
                      type={'number'}
                      min={0}
                      max={255}
                      value={selectedColor?.b} 
                      className={styles.inputRGB}
                      onChange={e => {
                          selectedColor.b = e.currentTarget.value;
                          setSelectedColor(selectedColor);
                          updateColorRgb(selectedColor);
                      }}></input>
                  <div className={styles.textInfoArea}>B</div>
              </div>
              <div className={styles.infoArea}>
                  <input
                      type={'number'}
                      min={0}
                      step={1}
                      max={100}
                      value={selectedColor?.alpha} 
                      className={styles.inputRGB}
                      onChange={e => {
                          selectedColor.alpha = e.currentTarget.value;
                          setSelectedColor(selectedColor);
                          updateColorRgb(selectedColor);
                      }}></input>
                  <div className={styles.textInfoArea} title={t('companies.alpha')}>A</div>
              </div>
              <div className={styles.infoArea}>
                  <img src={IconDelete} onClick={() => removerColor(selectedColor)} className={styles.removeColor} />
              </div>
          </div> }
      </div>
    </div>
  );
}

export default ColorPallete;