import React, { memo } from 'react';
import { ChartRenderProps } from '../../../../utils/EditorProps';
import { Global } from 'recharts';
import HorizontalBarsChartRender from './HorizontalBarsChartRender';
Global.isSsr = true;

const HorizontalBarsStackedChartRender = ({ ...props }: ChartRenderProps, ref: React.ForwardedRef<any>) => (
    <HorizontalBarsChartRender {...props} ref={ref} stacked={true} />
);
export default memo(React.forwardRef<any, ChartRenderProps>(HorizontalBarsStackedChartRender));
