export enum DocumentVisibility {
    PRIVATE = 'PRIVATE',
    PUBLIC = 'PUBLIC',
    PENDING = 'PENDING'
}

export enum DocumentVisibilityAction {
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    REQUESTED = 'REQUESTED'
}