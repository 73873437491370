const vendorSpecificProperties = [
    'animation',
    'animationDelay',
    'animationDirection',
    'animationDuration',
    'animationFillMode',
    'animationIterationCount',
    'animationName',
    'animationPlayState',
    'animationTimingFunction',
    'appearance',
    'backfaceVisibility',
    'backgroundClip',
    'borderImage',
    'borderImageSlice',
    'boxSizing',
    'boxShadow',
    'contentColumns',
    'transform',
    'transformOrigin',
    'transformStyle',
    'transition',
    'transitionDelay',
    'transitionDuration',
    'transitionProperty',
    'transitionTimingFunction',
    'perspective',
    'perspectiveOrigin',
    'userSelect',
    'clipPath',
    'borderRadius',
];

const specialVendorPrefixes: {[key: string]: string[]} = {
    'clipPath': ['Webkit'],
};

class Prefixer {

    private isVendorSpecific(property: string): boolean {
        return vendorSpecificProperties.indexOf(property) !== -1;
    }

    private getPrefixes(name: string): string[] {
        let prefixes = ['ms', 'Webkit', 'Moz'];

        if (specialVendorPrefixes[name]) {
            prefixes = specialVendorPrefixes[name];
        }

        return prefixes.map(prefix => `${prefix}${name[0].toUpperCase()}${name.substr(1)}`);
    }

    public prefixStyle(style: any): React.CSSProperties {
        const newStyle: any = { };
        
        for (const property of Object.keys(style)) {
            if (this.isVendorSpecific(property)) {

                const prefixes = this.getPrefixes(property);
                newStyle[property] = style[property];
                for (const prefixdProperty of prefixes) {
                    newStyle[prefixdProperty] = style[property];
                }
            } else {
                newStyle[property] = style[property];
            }
        }

        return newStyle;
    }

    public prefixStyles(styles: { [key: string]: React.CSSProperties }): { [key: string]: React.CSSProperties } {
        const newStyle: { [key: string]: React.CSSProperties } = {};

        for (const property of Object.keys(styles)) {
            newStyle[property] = this.prefixStyle(styles[property]);
        }

        return newStyle;
    }
}

export default new Prefixer();
