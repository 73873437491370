import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaChevronLeft } from 'react-icons/fa';
import Button from '../../../../common/components/button/Button';
import FormItem from '../../../../common/components/formItem/FormItem';
import InputWithError from '../../../../common/components/input/InputWithError';
import Label from '../../../../common/components/label/Label';
import styles from '../../LoginScreen.module.scss';

export type SetPasswordFormModel = {
    password: string;
    passwordRepeat: string;
}

type Props = {
    onSubmit: (model: SetPasswordFormModel) => void;
    onGoBack: () => void;
}

const SetPasswordForm: React.FunctionComponent<Props> = ({ onSubmit, onGoBack }: Props) => {
    const { t } = useTranslation();
    const { register, handleSubmit, errors, watch, clearErrors } = useForm<SetPasswordFormModel>();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <div className={styles.goBackButton} onClick={onGoBack}>
                <div className={styles.goBackButtonIcon}>
                    <FaChevronLeft size={16} />
                </div>
                <span className={styles.span}>{t('login.go_back')}</span>
            </div>

            <h1 className={styles.title}>{t('login.set_password_title')}</h1>
            <h3 className={styles.h3}>{t('login.set_password_subtitle')}</h3>

            <FormItem>
                <Label className={watch('password') ? styles.labelActive : styles.label}>{t('login.password').toUpperCase()}</Label>
                <InputWithError 
                    error={errors.password}
                    type="password"
                    name="password" 
                    minLength={5}
                    placeholder={t('login.password')} 
                    ref={register({ required: true, minLength: 5 })}
                    onErrorClick={() => clearErrors('password')} />
            </FormItem>
            <FormItem>
                <Label className={watch('passwordRepeat') ? styles.labelActive : styles.label}>{t('login.password_repeat').toUpperCase()}</Label>
                <InputWithError 
                    error={errors.passwordRepeat}
                    type="password"
                    name="passwordRepeat"
                    placeholder={t('login.password_repeat')} 
                    ref={register({ required: true, validate: value => value === watch('password') || t('common.errors.password_not_match') })}
                    onErrorClick={() => clearErrors('passwordRepeat')} />
            </FormItem>

            <Button type="submit" text={t('login.change_password_button').toUpperCase()} className={styles.submitButton} />

        </form>
    );
}

export default SetPasswordForm;
