import React, { ChangeEvent, memo, useEffect, useState } from 'react'
import { EditorChartTableData, EditorChartType } from 'screens/editor/models/ChartModels';
import ChartTableCellColorPicker from '../chartTableCellColorPicker/ChartTableCellColorPicker';
import styles from './ChartTableCell.module.scss';

type Props = {
    onDataChange: (rowIndex: number, columnId: string, value: string, color: string) => void;
    value: string;
    row: EditorChartTableData;
    rowIndex: number;
    columnId: string;
    canChangeColor: boolean;
    chartType: EditorChartType;
};

const ChartTableCell = ({ value: initialValue, onDataChange, row, rowIndex, columnId, canChangeColor, chartType }: Props) => {
    const [value, setValue] = useState(initialValue);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value?.trim() || '';

        if (value && columnId !== 'name' && (rowIndex > 0 || (rowIndex === 0 && chartType === EditorChartType.PIE || chartType === EditorChartType.DONUT))) {
            value = value.replace(/[^0-9.]/g, '');
        }

        setValue(value);
    }

    const save = (color?: string | null) => {
        onDataChange(rowIndex, columnId, value, color || row.color || '#000000');
    }

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    return columnId === 'id' 
        ? <div className={styles.container}>
            <span className={styles.valueText}>{value}</span>
            <ChartTableCellColorPicker
                enabled={canChangeColor}
                color={row.color || '#000000'}
                onChange={save}
            />
        </div>
        : <input
            value={value}
            onChange={onChange}
            onBlur={() => save()}
            className={styles.input}
            tabIndex={rowIndex}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    save();
                }
            }}
        />
}

export default memo(ChartTableCell);