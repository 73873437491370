import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Redirect } from 'react-router-dom';
import styles from './Sidebar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../store/authentication/action';
import Logo from '../../../assets/svgs/Logo-otto.svg';
import { ReactComponent as IconInfo } from '../../../assets/svgs/i-blue2-outlines.svg';
import UserModal from '../../../screens/users/UserModal';
import { UserProfile } from '../../../api/account/models/UserProfile';
import { Reducers } from '../../../store/types';
import { CSSProperties } from 'react';

import Tips from './tips/Tips';
import { IS_ADMIN } from 'Config';

export interface SidebarItem {
    text: string;
    icon: any;
    url?: string;
    style?: CSSProperties;
    onClick?: () => any;
}

type Props = {
    items: SidebarItem[];
}

const Sidebar: React.FunctionComponent<Props> = ({ items }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isActive = (path: any, match: any, location: any) => !!(match || path === location.pathname);

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const [openProfile, setOpenProfile] = React.useState<boolean>(false);

    const isAdmin = IS_ADMIN(loggedUser);

    const onLogoutClick = () => {
        dispatch(logout());
    }

    const renderMenuItem = (url: string | null, style: any, text: string, IconElement: any, key: number | string, isStatic = false, click?: () => void) => (

        <NavLink
            key={key}
            className={styles.item}
            strict={false}
            to={url || '#'}
            activeClassName={`${styles.itemActive} ${isStatic ? styles.itemActiveNoBorder : ''}`}
            isActive={!isStatic && url ? isActive.bind(this, url) : () => url ? true : false}
            exact={url === '/' ? true : false}
            onClick={click}
        >
            <span className={styles.itemIcon} style={style}><IconElement /></span>
            <span>{text}</span>
        </NavLink>
    );

    const onShowModal = () => {
        setOpenProfile(true);
    };

    const onCancelModal = () => {
        setOpenProfile(false);
    };

    if (!loggedUser) {
        return <Redirect to="/login" />
    }

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.logoContainer}>
                    <NavLink to={'/'}>
                        <img src={Logo} />
                    </NavLink>
                </div>
                <div className={styles.itemsContainer}>
                    {items.map((item, i) => {
                        const IconElement = item.icon;

                        return renderMenuItem(item.url || null, item.style, item.text, IconElement, i, false, item.onClick);
                    })}
                </div>

                <div className={styles.bottomItems}>
                    <div>
                        <div className={styles.profileItemLine}></div>
                        <div className={styles.profileItem}>

                            {/* {renderMenuItem('/profile', t('menu.my_profile'), IconInfo, 'my-profile', true, onShowModal)} */}

                            <div className={styles.itemProfile} onClick={onShowModal}>
                                <span className={styles.itemIcon}><IconInfo /></span>
                                <span>{t('menu.my_profile')}</span>
                            </div>

                            {openProfile && <UserModal
                                mode={'profile'}
                                item={{ id: loggedUser?.id ?? '', realName: loggedUser?.realName ?? '', userName: loggedUser?.userName ?? '', email: loggedUser?.email ?? '' }}
                                isVisible={true}
                                onCancel={() => onCancelModal()}
                            />}

                        </div>
                        <div className={styles.profileItemLine}></div>
                    </div>

                    {!isAdmin && <Tips />}

                    <div className={styles.logoutItem}>
                        <button type="button" onClick={onLogoutClick}>
                            {t('menu.logout')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
