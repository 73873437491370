import React, { useMemo } from 'react';
import EditorDefaults from '../../../../utils/EditorDefaults';
import { ElementProps } from '../../../../utils/EditorProps';
import Prefixer from 'common/services/Prefixer';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TextElementRender = ({ page, element, selected, ...props }: ElementProps, ref: React.ForwardedRef<HTMLDivElement>) => {

    const text = element.value as string;

    const style = useMemo(() => Prefixer.prefixStyle(EditorDefaults.getElementStyles(element)), [element]);
    const borderRadiusStyle = useMemo(() => Prefixer.prefixStyle(EditorDefaults.getElementBorderRadiusStyle(element)), [element]);
    const borderStyle = useMemo(() => Prefixer.prefixStyle(EditorDefaults.getElementBorderStyle(element)), [element]);

    return (
        <div
            {...props}
            ref={ref}
            style={{
                ...style,
                ...borderRadiusStyle,
                ...borderStyle,
                ...{
                    wordBreak: 'keep-all',
                    lineHeight: 1,
                },
            }}
        >
            <span dangerouslySetInnerHTML={{ __html: text }}></span>
        </div>
    );

}

export default React.forwardRef<HTMLDivElement, ElementProps>(TextElementRender);
