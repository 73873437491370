import React, { memo, useMemo } from 'react';
import { ChartRenderProps } from '../../../../utils/EditorProps';
import { Global, Legend, Line, LineChart, XAxis, YAxis } from 'recharts';
import EditorChartsUtils from 'screens/editor/utils/EditorChartsUtils';
Global.isSsr = true;

const CurveSingleChartRender = ({ data, width, height, fallback, children, withoutLegend, ...props }: ChartRenderProps, ref: React.ForwardedRef<any>) => {

    if (!data || !data.tableData || data.tableData.length < 2) {
        return fallback || <></>;
    }

    const [chartData, chartRows] = useMemo(() => {
        return EditorChartsUtils.tableDataToLinesChartData(data.tableData);
    }, [data.tableData]);

    if (!chartData || chartData.length === 0) {
        return fallback || <></>;
    }

    return (
        <LineChart
            {...props}
            width={width}
            height={height - 20}
            ref={ref}
            data={chartData}
            margin={{
                top: 10,
                // right: 40,
                right: 10,
                left: 0,
                bottom: 10,
            }}>
            <XAxis dataKey="name" padding={{ right: 25 }} />
            <YAxis />
            {chartRows.map((row, i) => <Line key={i} type="monotone" dataKey={row.name} stroke={row.color} name={row.name} isAnimationActive={false} />)}
            {!withoutLegend && <Legend verticalAlign="bottom" wrapperStyle={{ position: 'relative' }} />}
            {children}
        </LineChart>
    );
}

export default memo(React.forwardRef<any, ChartRenderProps>(CurveSingleChartRender));
