import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ModelItem.module.scss';
import { DocumentViewModel } from '../../../../../../api/documents/models/DocumentViewModel';
import Label from '../../../../../../common/components/label/Label';
import Background from '../../../../../../assets/img/no_image.png';
import { DocumentOrientation } from 'api/documents/enums/DocumentOrientation';
import { isTablet } from 'Config';
import IconModels from 'assets/svgs/Modeles.svg';
import IconPlus from 'assets/svgs/Plus-green-outlines.svg';

type Props = {
    document?: DocumentViewModel | null;
    onSelectModel?: () => void;
    onShowItemDetails?: () => void;
    isSelected: boolean;
    newLabelText?: string;
    hasItemOverlay?: boolean;
    showDetailsButton: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const ModelItem: React.FC<Props> = ({ document, onSelectModel, onShowItemDetails, isSelected, newLabelText, showDetailsButton, hasItemOverlay = false }: Props) => {
    const { t } = useTranslation();
    const imageCacheKey = useRef(new Date().getTime());

    const [isMobileOverlayActive, setIsMobileOverlayActive] = useState<boolean>(false);

    const isMobile = isTablet(window.innerWidth);

    let noImage = false;
    if (document == null || document?.thumbnailMediaUrl == null) {
        noImage = true;
    }

    const onClickAction = (isFromOverlay: boolean) => {
        if (!onSelectModel) return;
        if (isFromOverlay) {
            hasItemOverlay && onShowItemDetails && onShowItemDetails();
        } else {
            onSelectModel();
        }
    }

    return (
        <div className={styles.boxContainer}>
            {/* CREATE NEW */}
            {!document && <div onClick={() => onClickAction(false)} className={`${styles.create} ${isSelected ? ` ${styles.boxSelectCreate} ${styles.boxItemCreate} ` : styles.boxNotSelect}`} style={{ height: '12rem' }}>
                <img src={IconPlus} className={styles.icon} />
                <Label className={styles.label}>{newLabelText ? newLabelText : t('documents.add_document')}</Label>
            </div>}

            {/* SHOW DOCUMENT ITEM */}
            {document && <>
                <div className={isSelected ? ` ${styles.boxSelect} ${styles.boxItem} ` : ` ${styles.boxNotSelect} ${styles.boxItem} `} style={{ height: document.orientation === DocumentOrientation.PORTRAIT ? '12rem' : '9rem' }}>
                    {noImage &&
                        <div onClick={() => { onClickAction(false); isMobile && setIsMobileOverlayActive(true); }} className={styles.boxItem} style={{ backgroundColor: 'white', backgroundImage: 'url(' + Background + ')', height: '100%' }}>
                        </div>}

                    {!noImage &&
                        <div onClick={() => { onClickAction(false); isMobile && setIsMobileOverlayActive(true); }} className={styles.boxItem} style={{ backgroundImage: 'url(' + document?.thumbnailMediaUrl + '?_=' + imageCacheKey.current + ')', height: '100%' }}>
                        </div>}

                    {hasItemOverlay && !isMobile && showDetailsButton &&
                        <div onClick={() => onClickAction(false)} className={styles.overlayItem}>
                            <button className={styles.btnOverlay} type="button" onClick={e => { e.preventDefault(); e.stopPropagation(); onClickAction(true) }}>
                                <img src={IconModels} className={styles.icon} /> {t('show_model_details_modal.openDetails')}
                            </button>
                        </div>}

                    {hasItemOverlay && isMobile && showDetailsButton &&
                        <div onClick={() => { onClickAction(false); setIsMobileOverlayActive(false); }} className={styles.overlayItem} style={{ display: isMobileOverlayActive && isSelected ? 'flex' : 'none' }}>
                            <button className={styles.btnOverlay} type="button" onClick={e => { e.preventDefault(); e.stopPropagation(); onClickAction(true) }}>
                                <img src={IconModels} className={styles.icon} /> {t('show_model_details_modal.openDetails')}
                            </button>
                        </div>}
                </div>

                <div onClick={() => onClickAction(false)}>
                    <div className={isSelected ? styles.documentNameSelect : styles.documentName}>
                        <label> {document?.name} </label>
                    </div>
                </div>
            </>}
        </div>
    );
}

export default ModelItem;