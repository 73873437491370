import React, { ReactElement } from 'react';
import styles from './BoxCategory.module.scss';
import IconAdd from 'assets/svgs/Plus-Bllue2-Outlines.svg';
import IconGoBack from 'assets/svgs/Arrow-bleu2.svg';

type Props = {
    title: string;
    onAddClick?: () => void;
    onGoBack?: () => void;
    children: ReactElement | ReactElement[];
    headerClassName?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const BoxCategory: React.FC<Props> = ({ title, onAddClick, onGoBack, headerClassName, children, ...props }: Props) => {
    return (
        <div {...props}>
            <div className={`${styles.header} ${headerClassName || ''}`}>
                {onGoBack && <div onClick={onGoBack}>{<img src={IconGoBack} className={`${styles.headerIcon} ${styles.headerIconGoBack}`} />}</div>}
                <div className={styles.headerTitle}>{title}</div>
                {onAddClick && <div onClick={onAddClick}>{<img src={IconAdd} className={styles.headerIcon} />}</div>}
            </div>
            {children}
        </div>
    );
}

export default BoxCategory;
