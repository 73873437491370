import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsTextElementInEditingMode } from 'store/editorControl/action';
import { updatePageElement } from 'store/editor/action';
import { ElementProps } from '../../../../utils/EditorProps';
import useOnClickOutside from 'common/hooks/useOnClickOutside';
import useKeypress from 'common/hooks/useKeyPress';
import { TOOLBAR_CLASS_NAME } from '../../../editorToolbar/EditorToolbar';
import sanitizeHtml from 'sanitize-html';
import ContentEditable from 'react-contenteditable';
import styles from './TextElement.module.scss';
import EditorDefaults from '../../../../utils/EditorDefaults';
import ReactDOM from 'react-dom';
import EditorUtils from 'screens/editor/utils/EditorUtils';
import Prefixer from 'common/services/Prefixer';

const sanitizeConf = {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'h1', 'u', 'strike', 'br', 'div'],
    allowedAttributes: { a: ['href'] }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TextElement = ({ page, element, selected, ...props }: ElementProps, ref: React.ForwardedRef<HTMLDivElement>) => {

    const text = element.value as string;
    const dispatch = useDispatch();
    const [isInputActive, setIsInputActive] = useState(false);

    // const [inputValue, setInputValue] = useState(text);
    const textCERef = useRef(text);
    const setInputValue = (text: string) => textCERef.current = text;

    const wrapperRef = useRef(null);
    const textRef = useRef(null);
    const inputRef = useRef<HTMLInputElement>(null);

    // const enter = useKeypress('Enter');
    const esc = useKeypress('Escape');

    const setText = (text: string) => {
        dispatch(updatePageElement(page, {
            ...element,
            value: text,
        }));
    }

    const setEditingMode = (value: boolean) => {
        dispatch(setIsTextElementInEditingMode(value));
        setIsInputActive(value);
    }

    // check to see if the user clicked outside of this component
    useOnClickOutside(wrapperRef, () => {
        if (isInputActive) {
            setText(textCERef.current);
            setEditingMode(false);
        }
    }, [TOOLBAR_CLASS_NAME]);

    // focus the cursor in the input field on edit start
    useEffect(() => {
        if (isInputActive) {
            inputRef.current?.focus();
        }
    }, [isInputActive]);

    useEffect(() => {
        if (isInputActive) {
            // if Enter is pressed, save the text and case the editor
            // if (enter) {
            //     setText(textCERef.current);
            //     setEditingMode(false);
            // }
            // if Escape is pressed, revert the text and close the editor
            if (esc) {
                setInputValue(text);
                setEditingMode(false);
            }
        }
    }, [esc]); // watch the Enter and Escape key presses

    const handleInputChange = useCallback(
        event => {
            console.log('val', event.target.value);
            // sanitize the input a little
            setInputValue(sanitizeHtml(event.target.value, sanitizeConf));
        },
        [setInputValue]
    );
    
    const handleSpanClick = useCallback(() => setEditingMode(true), [
        setEditingMode
    ]);

    const style = useMemo(() => EditorDefaults.getElementStyles(element), [element]);
    const borderRadiusStyle = useMemo(() => Prefixer.prefixStyle(EditorDefaults.getElementBorderRadiusStyle(element)), [element]);
    const borderStyle = useMemo(() => Prefixer.prefixStyle(EditorDefaults.getElementBorderStyle(element)), [element]);

    const content = (
        <div
            {...props}
            ref={ref}
            style={{
                ...style,
                ...borderRadiusStyle,
                ...borderStyle,
                ...{
                    wordBreak: 'keep-all',
                    lineHeight: 1,
                },
            }}
        >
            <span ref={wrapperRef}>
                <span
                    ref={textRef}
                    onDoubleClick={handleSpanClick}
                    className={!isInputActive ? styles.textCopyActive : styles.textCopyHidden}
                    dangerouslySetInnerHTML={{ __html: text }}
                    style={{ ...element.style }}
                ></span>
                <ContentEditable
                    innerRef={inputRef}
                    html={textCERef.current}
                    disabled={false}
                    onChange={handleInputChange}
                    className={isInputActive ? styles.textInputActive : styles.textInputHidden}
                />
            </span>
        </div>
    );

    if (isInputActive) {
        return ReactDOM.createPortal(content, document.getElementById(EditorUtils.getPagePortalId(page.number))!)
    }

    return content;
}

export default React.forwardRef<HTMLDivElement, ElementProps>(TextElement);
