import React, { memo, useMemo } from 'react';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import EditorChartsUtils from 'screens/editor/utils/EditorChartsUtils';
import { ChartRenderProps } from 'screens/editor/utils/EditorProps';
import colors from 'styles/export/colors.module.scss';
import styles from './CurveSingleChart.module.scss';

const CurveSingleChart = ({ data, width, height, fallback, children, withoutLegend, ...props }: ChartRenderProps, ref: React.ForwardedRef<any>) => {

    if (!data || !data.tableData || data.tableData.length < 2) {
        return fallback || <></>;
    }

    const [chartData, chartRows] = useMemo(() => {
        return EditorChartsUtils.tableDataToLinesChartData(data.tableData);
    }, [data.tableData]);

    if (!chartData || chartData.length === 0) {
        return fallback || <></>;
    }

    const renderCustomXAxisTick = (props: any) => {
        const { x, y, payload } = props;
        return (
            <text x={x} y={y + 4} fill={colors.chartGray} style={{ fontSize: 10, fontWeight: 600 }} textAnchor="middle" dominantBaseline="middle">
                {payload.value}
            </text>
        );
    };

    const renderCustomYAxisTick = (props: any) => {
        const { x, y, payload } = props;
        return (
            <text x={x - 6} y={y + 1} fill={colors.chartGray} style={{ fontSize: 10, fontWeight: 600 }} textAnchor="middle" dominantBaseline="middle">
                {payload.value}
            </text>
        );
    };

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            const value = Math.round(payload[0].value * 100) / 100;
            return (
                <div className={styles.tooltip} style={{ width: 100 }}>
                    <span>{`${label} : ${value}`}</span>
                </div>
            );
        }

        return null;
    };

    return (
        <LineChart
            {...props}
            width={width}
            height={height}
            ref={ref}
            data={chartData}
            margin={{
                top: 10,
                right: 20,
                left: 10,
                bottom: 10,
            }}>
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="name" tick={renderCustomXAxisTick} axisLine={false} tickLine={false} />
            <YAxis tick={renderCustomYAxisTick} axisLine={false} tickLine={false} />
            {chartRows.map((row, i) =>
                <Line
                    key={i}
                    type="monotone"
                    dataKey={row.name}
                    stroke={row.color}
                    strokeWidth={3}
                    name={row.name}
                    dot={false}
                />)}
            {!withoutLegend && <Legend verticalAlign="bottom" />}
            <Tooltip content={<CustomTooltip />} />
            {children}
        </LineChart>
    );
}

export default memo(React.forwardRef<any, ChartRenderProps>(CurveSingleChart));
