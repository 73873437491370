
import Utils from 'common/services/Utils';
import { Action, AnalyticsState, SET_LAST_VISIT_ID } from './type';

const initialState: AnalyticsState = {
    sessionId: Utils.newGuid(),
    lastVisitId: null,
};

function rootReducer(state: AnalyticsState = initialState, action: Action): AnalyticsState {
    switch (action.type) {
        
        case SET_LAST_VISIT_ID:
            return {
                ...state,
                lastVisitId: action.lastVisitId,
            }

        default:
            return state;
    }
}

export default rootReducer;
