import { ReactElement } from 'react';
import { EditorChartType } from './ChartModels';
import { EditorTableType } from './TableModels';

export interface EditorSidebarCategory {
    id: EditorSidebarCategoryId;
    icon: any;
    show: boolean;
    name: string;
    content: ReactElement;
}

export enum EditorSidebarCategoryId {
    PHOTOS = 'PHOTOS',
    ICONS = 'ICONS',
    CHARTS = 'CHARTS',
    OTHERS = 'OTHERS',
    GIF = 'GIF',
    TABLES = 'TABLES'
}

export interface EditorSidebarState {
    selectedCategoryId: EditorSidebarCategoryId | null;
    selectedChartType: EditorChartType | null;
    selectedTableType: EditorTableType | null;
}

