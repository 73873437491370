import { useEffect } from 'react';
// hook from https://usehooks.com/useOnClickOutside/

// Hook
function useOnClickOutside(ref: any, handler: any, blacklistClassNames: string[] = []) {

    const hasSomeParentWithClass = (element: any, classname: string): boolean => {
        
        if (element.className && element.className.split && element.className.split(' ').indexOf(classname) >= 0) return true;
        return element.parentNode && hasSomeParentWithClass(element.parentNode, classname);
    }

    useEffect(
        () => {
            const listener = (event: any) => {

                
                const hasParentWithBlacklistedClassName = (blacklistClassNames && blacklistClassNames.length > 0)
                    ? blacklistClassNames.filter(clName => hasSomeParentWithClass(event.target, clName)).length > 0
                    : false;

                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || (ref.current.contains && ref.current.contains(event.target)) || hasParentWithBlacklistedClassName) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    );
}

export default useOnClickOutside;
