import React, { useState } from 'react';
import styles from './Popover.module.scss'
import { Popover as TinyPopover, PopoverAlign } from 'react-tiny-popover'

type Props = {
    onClickOutside?: () => void;
    content?: (isOpen: boolean, setIsPopoverOpen: (v: boolean) => void) => JSX.Element;
    contentContainerClassName?: string;
    children: (isOpen: boolean, setIsPopoverOpen: (v: boolean) => void) => JSX.Element;
    positions?: ('left' | 'top' | 'right' | 'bottom')[];
    align?: Exclude<PopoverAlign, 'custom'>;
};

const Popover: React.FC<Props> = ({ onClickOutside, content, contentContainerClassName, children, positions = ['left', 'top'], align }: Props) => {

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const setIsOpen = (v: boolean) =>  setIsPopoverOpen(v);

    const onClose = () => {
        setIsOpen(false)
        onClickOutside && onClickOutside();
    }

    return (
        <TinyPopover
            isOpen={isPopoverOpen}
            positions={positions}
            reposition={false}
            align={align}
            onClickOutside={() => { setIsPopoverOpen(false); onClickOutside && onClickOutside() } }
            content={<div className={`${styles.content} ${contentContainerClassName ? contentContainerClassName : ''}`}> 
                {content && content(isPopoverOpen, setIsOpen)}
            </div>}
            containerStyle={{
                zIndex: '6'
            }}

        >
            <div>
                {isPopoverOpen && <div className={styles.overlayContainer} onClick={onClose}></div>}
                {children(isPopoverOpen, setIsOpen)}
            </div>
        </TinyPopover>
    );
}

export default Popover;
