import React from 'react';
import styles from './HomeButton.module.scss';
import IconArrowWhite from 'assets/svgs/Arrow-white.svg';
import IconArrowBlue from 'assets/svgs/Arrow-bleu1.svg';

type Props = {
  action: () => void;
  title: string;
  icon: any;
  type: string;
  isWhite?: boolean;
};

const HomeButton: React.FC<Props> = ({ action, title, icon, type, isWhite = false }: Props) => {
  return (
    <div className={styles.buttonContent}>
      <div className={`${styles.buttonContentInside} ${type === 'createSupport' ? styles.createSupport : (type === 'viewSupport' ? styles.viewSupport : (type === 'addMedia' ? styles.addMedia : ''))}`} onClick={() => action()}>
        <img src={icon} className={styles.mainIcon} />
        <div className={styles.buttonTitle}>
          <span className={styles.buttonTitleSpan}>{title}</span>
          <img src={isWhite ? IconArrowWhite : IconArrowBlue} className={styles.arrowIcon} />
        </div>
      </div>
    </div>
  );
};

export default HomeButton;
