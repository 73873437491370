import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './FormatSlide.module.scss';
import { DocumentFormat } from '../../../../../../api/documents/enums/DocumentFormat';
import Box from '../../../../../../common/components/box/Box';
import IconTemplate1 from '../../../../../../assets/svgs/Template-1.svg';
import IconTemplate2 from '../../../../../../assets/svgs/Template-2.svg';
import IconTemplate3 from '../../../../../../assets/svgs/Template-3.svg';
import IconTemplate4 from '../../../../../../assets/svgs/Template-4.svg';
import { DocumentFormatTypeViewModel } from '../../../../../../api/documents/models/DocumentFormatTypeViewModel';
import NavigationButtons from '../navigationButtons/NavigationButtons';
import Slider, { CustomArrowProps, Settings } from 'react-slick';
import FormItem from '../../../../../../common/components/formItem/FormItem';
import { Controller, UseFormMethods } from 'react-hook-form';
import { DocumentViewModel } from '../../../../../../api/documents/models/DocumentViewModel';
import { DocumentType } from '../../../../../../api/documents/enums/DocumentType';

import ArrowIcon from '../../../../../../assets/svgs/Direction2-bleu2.svg';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import { IS_ADMIN } from 'Config';

type Props = {
    onNextSlide: () => void;
    onCancel: () => void;
    form: UseFormMethods<DocumentViewModel>;
    documentType?: DocumentType;
};

const FormatSlide: React.FC<Props> = ({ onNextSlide, onCancel, form, documentType }: Props) => {
    const { t } = useTranslation();

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const isAdmin = IS_ADMIN(loggedUser);

    const sliderRef = React.useRef<Slider>(null);

    const [selectedFormatType, setSelectedFormatType] = useState<string | null>(null);

    let formatTypes: DocumentFormatTypeViewModel[] = [
        { title: t((documentType === DocumentType.SIMPLE_MODEL && isAdmin) ? ('documents.format.A4_PAGE') : ('documents.format.' + DocumentFormat.PRODUCT_SHEET) as any).toString(), formatType: DocumentFormat.PRODUCT_SHEET, icon: IconTemplate1, text: t((documentType === DocumentType.SIMPLE_MODEL && isAdmin) ? ('documents.format_description.A4_PAGE') : ('documents.format_description.' + DocumentFormat.PRODUCT_SHEET) as any).toString() },
    ];

    if (!(documentType === DocumentType.SIMPLE_MODEL && isAdmin)) {
        formatTypes.push({ title: t(('documents.format.' + DocumentFormat.PRODUCT_BROCHURE) as any).toString(), formatType: DocumentFormat.PRODUCT_BROCHURE, icon: IconTemplate3, text: t(('documents.format_description.' + DocumentFormat.PRODUCT_BROCHURE) as any).toString() });
    }

    formatTypes = [
        ...formatTypes,
        { title: t(('documents.format.' + DocumentFormat.PRESENTATION_SLIDES) as any).toString(), formatType: DocumentFormat.PRESENTATION_SLIDES, icon: IconTemplate2, text: t(('documents.format_description.' + DocumentFormat.PRESENTATION_SLIDES) as any).toString() },
        { title: t(('documents.format.' + DocumentFormat.SOCIAL_MEDIA_POSTS) as any).toString(), formatType: DocumentFormat.SOCIAL_MEDIA_POSTS, icon: IconTemplate4, text: t(('documents.format_description.' + DocumentFormat.SOCIAL_MEDIA_POSTS) as any).toString() }
    ];

    const selectedFormat = selectedFormatType ? formatTypes.find(f => f.formatType === selectedFormatType) : null;

    const selectFormat = (format: DocumentFormatTypeViewModel | null) => {
        setSelectedFormatType(format?.formatType || null);
        form.setValue('format', format?.formatType);
    }

    const ArrowLeft = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
        <a
            {...props}
            className={
                'custom-slick-arrow slick-arrow' +
                (currentSlide === 0 ? ' slick-disabled' : '')
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            <img src={ArrowIcon} className={styles.arrowLeft} />
        </a>
    );
    const ArrowRight = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
        <a
            {...props}
            className={
                'custom-slick-arrow slick-arrow' +
                ((slideCount ? currentSlide === (slideCount - (slideCount - 1)) : null) ? ' slick-disabled' : '')
            }
            aria-hidden="true"
            aria-disabled={(slideCount ? currentSlide === (slideCount - (slideCount - 1)) : null) ? true : false}
            type="button"
        >
            <img src={ArrowIcon} className={styles.arrowRight} />
        </a>
    );
    const ArrowRightSmall = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
        <a
            {...props}
            className={
                'custom-slick-arrow slick-arrow' +
                ((slideCount ? currentSlide === (slideCount - 1) : null) ? ' slick-disabled' : '')
            }
            aria-hidden="true"
            aria-disabled={(slideCount ? currentSlide === (slideCount - 1) : null) ? true : false}
            type="button"
        >
            <img src={ArrowIcon} className={styles.arrowRight} />
        </a>
    );

    const settings: Settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        centerPadding: '1rem',
        className: `${styles.slider} ${styles.sliderDots} custom-slick-arrow custom-slider-dots`,
        draggable: false,
        dots: true,
        prevArrow: <ArrowLeft />,
        nextArrow: <ArrowRight />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    className: `${styles.slider} ${styles.sliderDots} custom-slick-arrow custom-slider-dots`,
                    nextArrow: <ArrowRightSmall />,


                }
            },
        ],
    };

    const renderFormatItem = (title: string, IconElement: any, text: string, onClick: () => void, isSelected: boolean, key: number) => (
        <div key={`item-format-${key}`} className={ styles.noOutline } style={{ outline: 'none' }}>
            <FormItem style={{ outline: 'none' }}>
                <Controller
                    render={({ }) => {
                        return (
                            <div
                                className={`${isSelected ? styles.formatSelected : styles.format} ${!isSelected && selectedFormat ? styles.notSelected : styles.format}`}
                                onClick={(onClick)}
                            >
                                <Box icon={IconElement} title={title} text={text} />
                            </div>
                        );
                    }}
                    control={form.control}
                    name="format"
                    defaultValue={form.getValues('format')}
                />
            </FormItem>
        </div>
    );

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                { documentType === DocumentType.SIMPLE_MODEL && t('documents.select_format_simple_model') }
                { documentType !== DocumentType.SIMPLE_MODEL && isAdmin && t('documents.select_format_model') }
                { documentType !== DocumentType.SIMPLE_MODEL && !isAdmin && t('documents.select_format_support') }
            </div>

            <div className={styles.content}>
                <Slider {...settings} ref={sliderRef}>
                    {formatTypes.map((format, i) => {
                        const IconElement = format.icon;
                        const isSelected = selectedFormatType && format.formatType === selectedFormatType ? true : false;
                        return renderFormatItem(format.title, IconElement, format.text, () => selectFormat(isSelected ? null : format), isSelected, i);
                    })}
                </Slider>
            </div>

            <NavigationButtons
                onBack={onCancel}
                onNext={selectedFormat ? onNextSlide : undefined}
                nextDisabled={!selectedFormat}
                iconNext={true}
                style={{ padding: '0 2rem' }}
            />
        </div>
    );
}

export default FormatSlide;
