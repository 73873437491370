import React, { memo, useCallback, useRef } from 'react'
import styles from './Table.module.scss';
import EditorTablesUtils from 'screens/editor/utils/EditorTablesUtils';
import TableCell from '../tableCell/TableCell';
import { EditorTableColumns, EditorTableColumnsDisponible, EditorTableData } from 'screens/editor/models/TableModels';
import IconRemove from 'assets/svgs/Remove.svg';
import IconAdd from 'assets/svgs/Add.svg';
import Utils from 'common/services/Utils';
import { useEffect } from 'react';

const defaultColumns = EditorTablesUtils.generateTableColumns();

const lettersDisponible = EditorTableColumnsDisponible;

type Props = {
    data: EditorTableData[];
    onChange: (data: EditorTableData[]) => void;
};

const Table = ({ data, onChange }: Props) => {

    const ref = useRef<HTMLDivElement>(null);

    const [columns, setColumns] = React.useState<any>(defaultColumns);
    const [lettersArray, setLetters] = React.useState<string[]>(EditorTableColumns);
    const [addColumn, setAddColumn] = React.useState<boolean>(false);

    const onDataChange = useCallback((rowIndex: number, columnId: string, value: string) => {
        onChange(data.map((row, index) => {
            if (index === rowIndex) {
                return {
                    ...data[rowIndex],
                    [columnId]: value
                }
            }
            return row;
        }));
    }, [data]);

    const addTableColumn = () => {
        const letterPosition = columns.length-1;
        const _letter = lettersDisponible[letterPosition];
        lettersArray.push(_letter);
        setLetters(lettersArray);
        setColumns([...columns, { id: _letter, text: _letter, render: null, accessor: _letter }])
        forceDataChange();
        setAddColumn(true);
    }

    const removeTableColumn = () => {
        columns.pop()
        setColumns([...columns]);
        lettersArray.pop()
        setLetters(lettersArray);
        forceDataChange();
    }

    const addTableRow = () => {
        const columnsKeys = lettersArray.reduce<any>((acc, curr) => (acc[curr] = ' ', acc), {});
        data.push({ id: Utils.newGuid(), name: '', ...columnsKeys });
        setColumns([...columns]);
        forceDataChange();
    }

    const removeTableRow = () => {
        data.pop();
        setColumns([...columns]);
        forceDataChange();
    }

    const forceDataChange = () => {
        const value: string = columns[0].render ? columns[0].render(0) : data[0][columns[0].accessor as any];
        onDataChange(0, columns[0].id, value);
    }

    useEffect(() => {
        if(addColumn && ref.current){
            ref.current.scrollLeft += ref.current.clientWidth;
        }
    }, [columns]);

    return (
        <div className={styles.content}>
            <div className={styles.tableContent}>
                <div className={styles.tableContainer} ref={ref}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                {columns.map((column: any, columnIndex: number) => (
                                    <th key={columnIndex}>
                                        {column.text}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row, rowIndex) => (
                                <tr key={row.id}>
                                    {columns.map((column: any, columnIndex: number) => {
                                        const value: string = column.render ? column.render(rowIndex) : row[column.accessor as any];
                                        return (
                                            <td key={`${rowIndex}-${columnIndex}`}>
                                                <TableCell
                                                    onDataChange={onDataChange}
                                                    row={row}
                                                    rowIndex={rowIndex}
                                                    columnId={column.id}
                                                    value={value || ''}
                                                />
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className={styles.addColumnContent}>
                    <div className={styles.drawAddColumnContent}>
                        <img src={IconAdd} className={styles.buttonsTableColumn} onClick={() => addTableColumn()} />
                        { columns.length > 2 && <img src={IconRemove} className={styles.buttonsTableColumn} onClick={() => removeTableColumn()} /> }
                    </div>
                </div>
            </div>
            <div className={styles.addRowContent}>
                <div className={styles.drawAddRowContent}>
                    <img src={IconAdd} className={styles.buttonsTableRow} onClick={() => addTableRow()} />
                    { data.length > 1 && <img src={IconRemove} className={styles.buttonsTableRow} onClick={() => removeTableRow()} /> }
                </div>
            </div>
        </div>
    );
}

export default memo(Table);