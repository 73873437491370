import React, { ReactChild } from 'react';
import styles from './Dropdown.module.scss';

type Props = {
    text?: string;
    children?: ReactChild | ReactChild[];
    onClick?: () => void;
};

const DropdownItem: React.FC<Props> = ({ text, children, onClick }: Props) => {
    return (
        <button onClick={onClick} className={styles.dropdownItem}>
            {Boolean(text) && text}
            {children}
        </button>
    );
}

export default DropdownItem;