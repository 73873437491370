import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { UserProfile } from 'api/account/models/UserProfile';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE, SERVER_BASE_URL } from 'Config';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { addNotification, incrementTotalNotificationsNotRead, setNotificationsStatus, setTotalNotificationsNotRead } from 'store/notifications/action';
import NotificationsService from 'api/notifications/NotificationsService';
import { useTranslation } from 'react-i18next';

const NotificationsProvider = () => {
    const { t } = useTranslation();
    const user = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!user) {
            return;
        }

        const getTotalNotificationsNotRead = async () => {
            try {
                const total = await NotificationsService.getTotalNotRead();
                dispatch(setTotalNotificationsNotRead(total));
            } catch (error) {
                Logger.error(LOGGER_LOG_TYPE.NOTIFICATIONS, `Couldn't get the total of notifications not read`, error);
                dispatch(setNotificationsStatus({
                    statusType: 'error',
                    message: t('notification.popup.error_get_total_notifications'),
                }));
            }
        }

        getTotalNotificationsNotRead();

        const receiveNotification = async (notificationId: string) => {
            try {
                const notification = await NotificationsService.getById(notificationId);
                if (!notification) {
                    Logger.error(LOGGER_LOG_TYPE.NOTIFICATIONS, `Couldn't add a notification, notification is empty`, { notificationId });
                }

                dispatch(addNotification(notification));
                dispatch(incrementTotalNotificationsNotRead());
            } catch (error) {
                Logger.error(LOGGER_LOG_TYPE.NOTIFICATIONS, `Couldn't add a notification`, error);
                getTotalNotificationsNotRead();
            }
        }
        const connection = new HubConnectionBuilder()
            .withUrl(`${SERVER_BASE_URL}/hubs/notifications`, {
                transport: HttpTransportType.LongPolling,
                logger: LogLevel.None,
                accessTokenFactory: () => {
                    return user.token;
                },
            })
            .withAutomaticReconnect()
            .build();

        connection.start()
            .then(() => {
                connection.on('ReceiveNotification', receiveNotification);
            })
            .catch(e => {
                Logger.error(LOGGER_LOG_TYPE.NOTIFICATIONS, `Couldn't connect to signalr`, e);
                dispatch(setNotificationsStatus({
                    statusType: 'error',
                    message: t('notification.popup.error_connect'),
                }));
            });

        return () => {
            connection.stop()
        }
    }, [user?.token]);
    
    return null;
};

export default NotificationsProvider;