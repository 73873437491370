export enum EditorTableType {
    SIMPLE = 'SIMPLE',
    TABLE_1 = 'TABLE_1',
    TABLE_2 = 'TABLE_2',
    TABLE_3 = 'TABLE_3',
    TABLE_4 = 'TABLE_4'
}

export const EditorTableColumns = ['A', 'B', 'C', 'D', 'E'];
export const EditorTableColumnsDisponible = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

export type EditorTableColumnKeys = {
    [key in keyof typeof EditorTableColumns]: string;
}

export type EditorTableData = {
    id: string;
    name: string;
} & EditorTableColumnKeys;

export interface EditorTableDataValue {
    tableData: EditorTableData[];
    type: EditorTableType;
}

export interface EditorSidebarTableItem {
    icon: any;
    tableType: EditorTableType;
}