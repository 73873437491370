import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../common/components/button/Button';
import FormItem from '../../../../common/components/formItem/FormItem';
import Label from '../../../../common/components/label/Label';
import styles from '../../LoginScreen.module.scss';
import { FaChevronLeft } from 'react-icons/fa';
import InputWithError from '../../../../common/components/input/InputWithError';

export type RecoverPasswordFormModel = {
    email: string;
}

type Props = {
    onSubmit: (model: RecoverPasswordFormModel) => void;
    onGoBack: () => void;
}

const RecoverPasswordForm: React.FunctionComponent<Props> = ({ onSubmit, onGoBack }: Props) => {
    const { t } = useTranslation();
    const { register, handleSubmit, errors, watch, clearErrors } = useForm<RecoverPasswordFormModel>();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <div className={styles.goBackButton} onClick={onGoBack}>
                <div className={styles.goBackButtonIcon}>
                    <FaChevronLeft size={14} />
                </div>
                <span className={styles.span}>{t('login.go_back')}</span>
            </div>

            <h1 className={styles.title}>{t('login.recover_password_title')}</h1>
            <h4 className={styles.subtitle}>{t('login.recover_password_subtitle')}</h4>

            <FormItem>
                <Label className={watch('email') ? styles.labelActive : styles.label}>{t('login.email').toUpperCase()}</Label>
                <InputWithError 
                    error={errors.email} 
                    type="email" 
                    name="email" 
                    placeholder={t('login.email')} 
                    ref={register({ required: true })}
                    onErrorClick={() => clearErrors('email')} />
            </FormItem>

            <Button type="submit" text={t('login.recover_password_button').toUpperCase()} className={styles.submitButton} />

        </form>
    );
}

export default RecoverPasswordForm;
