import React from 'react';
import styles from './TablesList.module.scss';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import BoxCategory from 'common/components/boxCategory/BoxCategory';
import { EditorSidebarTableItem } from 'screens/editor/models/TableModels';

type Props = {
    onClick: (table: EditorSidebarTableItem) => void;
    items: EditorSidebarTableItem[];
}

const TablesList = ({ onClick, items }: Props) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <BoxCategory
                title={t('editor.sidebar.tables')}
            >
                <Masonry
                    breakpointCols={2}
                    className={styles.masonryGrid}
                    columnClassName={styles.masonryGridColumn}
                >
                    {items.map((item, i) => (
                        <div className={styles.item} key={i} onClick={() => onClick(item)}>
                            <img src={item.icon} className={styles.itemIcon} />
                        </div>
                    ))}
                </Masonry>
            </BoxCategory>
        </div>
    );
}

export default TablesList;
