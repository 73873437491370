import React from 'react';
import styles from './ExportItem.module.scss';
import IconExportPDFWeb from '../../../assets/svgs/Export-PDF-Web.svg';
import IconExportPDFPrint from '../../../assets/svgs/Export-PDF-Print.svg';
import IconExportJPG from '../../../assets/svgs/Export-JPG.svg';
import IconExportGIF from '../../../assets/svgs/Export-GIF.svg';
import IconExportMP4 from '../../../assets/svgs/Export-MP4.svg';
import CheckBox from '../checkBox/CheckBox';
import { DocumentExport } from 'api/documents/enums/DocumentExport';
import { DocumentExportViewModel } from 'api/documents/models/DocumentFormatTypeViewModel';

type Props = {
    type: DocumentExportViewModel;
    onChangeItemExport: (type: DocumentExport, isChecked: boolean) => void;
    checked: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const ExportItem: React.FC<Props> = ({ type, onChangeItemExport, checked }: Props) => {

    const backgroundImage: React.CSSProperties = {
        backgroundImage: 'url(' +
            (type.type == DocumentExport.PDF_WEB ? IconExportPDFWeb :
                type.type == DocumentExport.PDF_PRINT ? IconExportPDFPrint :
                    type.type == DocumentExport.JPG ? IconExportJPG :
                        type.type == DocumentExport.MP4 ? IconExportMP4 :
                            type.type == DocumentExport.GIF ? IconExportGIF : IconExportPDFWeb) + ')'
    }

    const onClickitem = () => {
        onChangeItemExport && onChangeItemExport(type.type, !checked);
    }

    return (
        <div className={styles.box} onClick={onClickitem}>
            <div className={styles.left}>
                <div className={styles.imageContainer}>
                    <div className={styles.image}>
                        <div style={backgroundImage} className={styles.imageContent}></div>
                    </div>
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        {type.title}
                    </div>
                </div>
                <div className={styles.content}>
                    {type.text}
                </div>
            </div>
            <div className={styles.checkExport}>
                <CheckBox
                    checked={checked}
                    readOnly
                />
            </div>
        </div>
    );
}

export default ExportItem;
