import styles from './ToolbarButton.module.scss';

type Props = {
    color?: 'default' | 'danger';
    text: string;
    onClick?: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    icon?: string;
    iconElem?: any;
    className?: string;
    showDropdown?: boolean;
    children?: React.ReactNode;
};

const ToolbarButton = ({ text, onClick, icon, iconElem, color = 'default', className, showDropdown, children }: Props) => {
    const IconElem = iconElem;
    return (
        <div
            onMouseDown={onClick}
            className={`${styles.container} ${className || ''} ${styles[color] || styles.default}`}
        >
            <div className={`${styles.button} ${showDropdown && styles.buttonWithDropdown}`}>
                {(icon || IconElem) && (<div className={styles.iconContainer}>
                    {icon && <img src={icon} className={styles.icon} />}
                    {IconElem && <IconElem className={styles.icon} />}
                </div>)}
                <div className={styles.textContainer}>{text}</div>
            </div>
            {showDropdown && <div className={styles.dropdownContainer}>
                {children}
            </div>}
        </div>
    );
}

export default ToolbarButton;
