import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import HomeScreen from './screens/home/HomeScreen';
import LoginScreen from './screens/login/LoginScreen';
import AuthenticatedLayout from './common/layouts//authenticatedLayout/AuthenticatedLayout';
import ProtectedRoute from './common/routes/ProtectedRoute';
import PolicyRoute from './common/routes/PolicyRoute';
import NotFoundScreen from './screens/errors/NotFoundScreen';
import EditorScreen from './screens/editor/EditorScreen';
import ListUsersScreen from './screens/users/list/ListUsersScreen';
import ListDocumentsScreen from './screens/documents/list/ListDocumentsScreen';
import ListDocumentsToValidateScreen from './screens/documents-validate/list/ListDocumentsToValidateScreen';
import MediasScreen from './screens/medias/MediasScreen';
import ListManageModelsScreen from './screens/manage-models/list/ListManageModelsScreen';
import NotificationsListScreen from 'screens/notification/list/NotificationsListScreen';
import NotificationsScreen from 'screens/notification/NotificationsScreen';
import ListSimpleModelsScreen from 'screens/documents/simple-models/ListSimpleModelsScreen';
import Analytics from './Analytics';

import ListTipsScreen from 'screens/tips/list/ListTipsScreen';
import TipScreen from 'screens/tips/TipScreen';

import ListWelcomeMessagesScreen from 'screens/welcomeMessages/list/ListWelcomeMessagesScreen';
import WelcomeMessagesScreen from 'screens/welcomeMessages/WelcomeMessagesScreen';
import CompanyAnalyticsScreen from 'screens/companies/analytics/CompanyAnalyticsScreen';
import ListCompaniesScreen from 'screens/companies/list/ListCompaniesScreen';
import AnalyticsScreen from 'screens/users/analytics/AnalyticsScreen';

const DefaultRouter: React.FunctionComponent = () => {

    return (
        <Router>
            <Analytics />
            <Switch>
                <Route path="/login" component={LoginScreen} />
                <Route path="/404" component={NotFoundScreen} />
                <ProtectedRoute path="/editor/:id/:previousPage" component={EditorScreen} />
                <ProtectedRoute path="*">
                    <AuthenticatedLayout>
                        <Switch>
                            <PolicyRoute path="/" exact component={HomeScreen} policies={[]} />
                            <PolicyRoute path="/users-list" component={ListUsersScreen} policies={['USERS_WRITE']} />
                            <PolicyRoute path="/users" component={AnalyticsScreen} policies={['USERS_WRITE']} />
                            {/* <PolicyRoute path="/companies-analytics" component={CompanyAnalyticsScreen} policies={['COMPANIES_WRITE']} /> */}
                            <PolicyRoute path="/companies" component={CompanyAnalyticsScreen} policies={['COMPANIES_WRITE']} />
                            <PolicyRoute path="/companies-list" component={ListCompaniesScreen} policies={['COMPANIES_WRITE']} />
                            {/* <PolicyRoute path="/companies" component={ListCompaniesScreen} policies={['COMPANIES_WRITE']} /> */}
                            <PolicyRoute path="/supports" exact component={ListDocumentsScreen} policies={['DOCUMENTS_WRITE']} />
                            <PolicyRoute path="/simple-models" exact component={ListSimpleModelsScreen} policies={['SIMPLE_MODELS_WRITE']} />
                            <PolicyRoute path="/supports-validate" exact component={ListDocumentsToValidateScreen} policies={['DOCUMENTS_VALIDATE']} />
                            <PolicyRoute path="/manage-pre-filled-models" exact component={ListManageModelsScreen} policies={['PRE_FILLED_DOCUMENTS_WRITE']} />
                            <PolicyRoute path="/medias" exact component={MediasScreen} policies={['MEDIAS_WRITE']} />
                            <PolicyRoute path="/notification" exact component={NotificationsListScreen} policies={['NOTIFICATIONS_WRITE']} />
                            <PolicyRoute path="/notification/:type/:id" component={NotificationsScreen} policies={['NOTIFICATIONS_WRITE']} />
                            <PolicyRoute path="/tips" exact component={ListTipsScreen} policies={['TIPS_WRITE']} />
                            <PolicyRoute path="/tips/:type/:id" component={TipScreen} policies={['NOTIFICATIONS_WRITE']} />
                            <PolicyRoute path="/tips/:type" component={TipScreen} policies={['NOTIFICATIONS_WRITE']} />
                            <PolicyRoute path="/welcomeMessages" exact component={ListWelcomeMessagesScreen} policies={['WELCOME_MESSAGES_WRITE']} />
                            <PolicyRoute path="/welcomeMessages/:type/:id" component={WelcomeMessagesScreen} policies={['WELCOME_MESSAGES_WRITE']} />
                            <PolicyRoute path="/welcomeMessages/:type" component={WelcomeMessagesScreen} policies={['WELCOME_MESSAGES_WRITE']} />

                            {/* <Route path="/notification/edit/:id" component={NotificationsScreen} /> */}
                            <Route>
                                <Redirect to="/404" />
                            </Route>
                        </Switch>
                    </AuthenticatedLayout>
                </ProtectedRoute>
                <Route path="*">
                    <Redirect to="/404" />
                </Route>
            </Switch>
        </Router>
    );
}

export default DefaultRouter;
