import { GIPHY_API_KEY } from '../../Config';
import { GiphyFetch, PaginationOptions, SearchOptions, TrendingOptions } from '@giphy/js-fetch-api'

class GiphyService {

    private giphy: GiphyFetch;

    constructor() {
        this.giphy = new GiphyFetch(GIPHY_API_KEY)
    }

    public searchGifs(term: string , paginationOptions: PaginationOptions) {
        return this.giphy.search(term, {
            limit: paginationOptions.limit,
            offset: paginationOptions.offset
        });
    }

    public getTrendingGifs(paginationOptions: PaginationOptions) {
        return this.giphy.trending({
            limit: paginationOptions.limit,
            offset: paginationOptions.offset
        });
    }
}

export default new GiphyService();