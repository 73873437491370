import React, { useRef, useState } from 'react';
import debounce from 'lodash.debounce';

type Props<T, R> = {
    data: T;
    onSave: (data: T) => Promise<R>;
    interval?: number;
};

const Autosave = <T extends unknown, R extends unknown>({
    data,
    onSave,
    interval = 2000,
}: Props<T, R>): null => {

    const [firstTime, setFirstTime] = useState(true);
    const destroyed = useRef<boolean>(false);
    
    const debouncedSave = React.useCallback(
        debounce(async (data: T) => {
            if (!firstTime && !destroyed.current) {
                await onSave(data);
            } else {
                setFirstTime(false);
            }
        }, interval),
        [firstTime],
    );

    React.useEffect(() => {
        destroyed.current = false;

        if (data) {
            debouncedSave(data);
        }

        return () => {
            destroyed.current = true;
        }
    }, [data, debouncedSave]);

    return null;
};

export default Autosave;