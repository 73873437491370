export enum ToolbarOptionType {
    DELETE,
    TEXT_FORMAT,
    DUPLICATE,
    TEXT_FONT_SIZE,
    TEXT_FONT_FAMILY,
    TEXT_COLOR,
    FILL_COLOR,
    BACKGROUND_COLOR,
    EMPTY_PUSH,
    SEPARATOR,
    TEXT_ALIGN,
    CLIP,
    BORDER_RADIUS,
    BORDER,
    ORGANISATION,
    ALIGN,
}

export interface ToolbarOption {
    type: ToolbarOptionType;
    removeText?: boolean;
}
