import React from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from 'common/services/Toaster';
import Loading from '../../../../common/services/Loading';
import styles from './CreateDocumentModal.module.scss';
import { DocumentCompanyViewModel, DocumentViewModel } from '../../../../api/documents/models/DocumentViewModel';
import { useForm } from 'react-hook-form';
import Slider, { Settings } from 'react-slick';
import Backdrop from '../../../../common/components/backdrop/Backdrop';
import DocumentsService from '../../../../api/documents/DocumentsService';
import { DocumentType } from '../../../../api/documents/enums/DocumentType';
import FormatSlide from './components/formatSlide/FormatSlide';
import InformationSlide from './components/informationSlide/InformationSlide';
import ModelSlide from './components/modelSlide/ModelSlide';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../../store/types';
import { UserProfile } from '../../../../api/account/models/UserProfile';
import Logger from 'common/services/Logger';
import { IS_ADMIN, IS_COMPANY_ADMIN, LOGGER_LOG_TYPE } from 'Config';
import { PreviousPage } from '../../../../api/editor/enums/PreviousPage';
import EditorUtils from 'screens/editor/utils/EditorUtils';
import EditorInternalService from 'screens/editor/utils/EditorInternalService';
import { useHistory } from 'react-router-dom';

type Props = {
    userId?: string;
    isVisible: boolean;
    mode: string | null;
    item: DocumentViewModel | null;
    onCancel: () => void;
    documentType?: DocumentType
};

const CreateDocumentModal: React.FC<Props> = ({ mode, onCancel, documentType }: Props) => {

    const { t } = useTranslation();
    const history = useHistory();
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const isAdmin = IS_ADMIN(loggedUser);

    
    const form = useForm<DocumentViewModel>({ shouldUnregister: false });

    const sliderRef = React.useRef<Slider>(null);

    const onSubmit = async (form: DocumentViewModel) => {
        Loading.show();

        form.companyId = loggedUser?.companyId;
        form.type = documentType ? documentType : DocumentType.MODEL_TO_CUSTOMIZE;
        form.isAdmin = IS_COMPANY_ADMIN(loggedUser);

        if(loggedUser?.companyId){
            form.type =  DocumentType.DEFAULT;
            const companies: DocumentCompanyViewModel[] = [{ companyId: loggedUser?.companyId }]
            form.companies = companies;
        }

        try {
            if (!form.name || !form.format) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            const documentId = await DocumentsService.create(form);

            if (form.editorData && documentId) {
                const pages = EditorUtils.parseDocumentEditorData(form.editorData, form.format).pages;
                await EditorInternalService.createDocumentEvents(pages, documentId);
            }

            history.push(`/editor/${documentId}/${(form.type === DocumentType.SIMPLE_MODEL) ? PreviousPage.SIMPLE_MODELS : PreviousPage.DOCUMENTS}`);

            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.DOCUMENTS , `Couldn't save document`, error);
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const performSubmit = () => {
        form.handleSubmit(onSubmit)();
    }

    const goToPrevious = () => {
        sliderRef.current?.slickPrev();
    }

    const goToNext = () => {
        sliderRef.current?.slickNext();
    }

    const slidesContent = [
        <FormatSlide
            onCancel={onCancel}
            onNextSlide={goToNext}
            form={form}
            key={'format-slide'}
            documentType={documentType}
        />,
        <InformationSlide
            onPreviousSlide={goToPrevious}
            onNextSlide={isAdmin ? performSubmit : goToNext}
            mode={mode}
            form={form}
            key={'information-slide'}
            nextButtonText={isAdmin ? t('documents.start').toUpperCase() : t('common.next').toUpperCase()}
            documentType={documentType}
        />,
    ];

    if (!isAdmin) {
        slidesContent.push(<ModelSlide
            onPreviousSlide={goToPrevious}
            onSubmit={performSubmit}
            mode={mode}
            form={form}
            key={'model-slide'}
            documentType={documentType}
        />);
    }

    const settings: Settings = {
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        centerPadding: '120px',
        className: `${styles.slider} slider-track-center`,
        draggable: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    centerPadding: '50px',
                    className: `${styles.slider} slider-track-center`,

                }
            },
        ],
    };

    return (
        <div>
            <Backdrop onClick={onCancel} />
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Slider {...settings} ref={sliderRef}>
                    {slidesContent.map((slideContent, i) => (
                        <div key={i} className={styles.slideContainer}>
                            <div className={styles.slide}>
                                {slideContent}
                            </div>
                        </div>
                    ))}
                </Slider>
            </form>
        </div >
    );
}

export default CreateDocumentModal;
