import React from 'react';
import { useSelector } from 'react-redux';
import { EditorState } from '../../../../store/editor/type';
import { Reducers } from '../../../../store/types';
import EditorResolver from '../../utils/EditorResolver';
import EditorUtils from '../../utils/EditorUtils';
import styles from './EditorToolbar.module.scss';

export const TOOLBAR_CLASS_NAME = 'toolbar-container';

type Props = {
    width: number;
}

const EditorToolbar = ({ width }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {

    const editorState = useSelector<Reducers, EditorState>(state => state.editor.present)
    const [elements, page] = EditorUtils.getSelectedElementsFromState(editorState); // TODO: 
    const options = elements && elements.length > 1 
        ? EditorResolver.getToolbarOptionsOfGroupOfElement() 
        : (elements && elements.length > 0 ? EditorResolver.getToolbarOptionsOfElementByType(elements[0].type) : []);

    const show = (editorState.selectedPageId || (editorState.selectedElementsIds && editorState.selectedElementsIds.length > 0)) 
        && options.length > 0 ? true : false;

    const elementsIds = elements?.map(x => x.id).join('-');

    return (
        <div
            className={`${styles.container} ${TOOLBAR_CLASS_NAME}`}
            style={{ visibility: show ? 'visible' : 'hidden', width: `${width}px` }}
            key={`toolbar-${page?.id || ''}-${elementsIds || ''}`}
            ref={ref}
        >
            <div className={styles.options}>
                {options.map((option, i) => {
                    const OptionElement = EditorResolver.getToolbarOptionElement(option.type);
                    if (!OptionElement) {
                        return <div key={i}>TOOLBAR TYPE NOT FOUND</div>;
                    }

                    return (
                        <OptionElement
                            key={i}
                            option={option}
                            page={page}
                            elements={elements}
                            className={styles.option}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default React.forwardRef<HTMLDivElement, Props>(EditorToolbar);
