import React, { useCallback } from 'react';
import styles from './TablesContent.module.scss';
import { PageElementType } from 'screens/editor/models/EditorModels';
import TablesList from './components/tablesList/TablesList';
import TableModifier from './components/tableModifier/TableModifier';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { setSidebarSelectedTableType, updatePageElement } from 'store/editor/action';
import EditorUtils from 'screens/editor/utils/EditorUtils';
import store from 'store/store';
import { EditorTableType, EditorSidebarTableItem } from 'screens/editor/models/TableModels';

import IconTable1 from 'assets/svgs/Table1.svg';
import IconTable2 from 'assets/svgs/Table2.svg';
import IconTable3 from 'assets/svgs/Table3.svg';
import IconTable4 from 'assets/svgs/Table4.svg';

const tablesItems: EditorSidebarTableItem[] = [
    {
        icon: IconTable1,
        tableType: EditorTableType.TABLE_1,
    },
    {
        icon: IconTable2,
        tableType: EditorTableType.TABLE_2,
    },
    {
        icon: IconTable3,
        tableType: EditorTableType.TABLE_3,
    },
    {
        icon: IconTable4,
        tableType: EditorTableType.TABLE_4,
    }
];

const TablesContent: React.FC = () => {
    const dispatch = useDispatch();
    const selectedTableType = useSelector<Reducers, EditorTableType | null>(state => state.editor.present.sidebarState.selectedTableType);

    const onSetTableType = (tableType: EditorTableType) => {
        const [selectedElement, selectedPage] = EditorUtils.getFirstSelectedElementFromState(store.getState().editor.present);

        if (selectedElement && selectedPage && selectedElement.type === PageElementType.TABLE && tableType) {
            dispatch(updatePageElement(selectedPage, {
                ...selectedElement,
                value: {
                    ...selectedElement.value,
                    type: tableType,
                },
            }));
        }

        setTableType(tableType);
    }

    const setTableType = useCallback((tableType: EditorTableType | null) => {
        dispatch(setSidebarSelectedTableType(tableType));
    }, []);

    const onTableModifierClick = useCallback(() => { setTableType(null) }, [setTableType]);

    return (
        <div className={styles.container}>
            {selectedTableType
                ? <TableModifier
                    tableType={selectedTableType}
                    onGoBack={onTableModifierClick}
                />
                : <TablesList
                    items={tablesItems}
                    onClick={table => { onSetTableType(table.tableType) }}
                />
            }
        </div>
    );
}

export default TablesContent;
