import { Dispatch } from 'redux';
import AnalyticsService from 'api/analytics/AnalyticsService';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import { Reducers } from 'store/types';
import { AnalyticEventViewModel } from 'api/analytics/models/AnalyticEventViewModel';
import { SET_LAST_VISIT_ID } from './type';

export const visitPage = (page: string) => async (dispatch: Dispatch, getState: () => Reducers) => {
    try {
        const { sessionId, lastVisitId } = getState().analytics;
        const visitId = await AnalyticsService.visit({ page, sessionId, lastVisitId });
        dispatch({
            type: SET_LAST_VISIT_ID,
            lastVisitId: visitId,
        });
    } catch (error) {
        Logger.error(LOGGER_LOG_TYPE.ANALYTICS, `error analytics visit page`, error);
    }
}

export const createEvent = (model: AnalyticEventViewModel) => async (dispatch: Dispatch, getState: () => Reducers) => {
    const { sessionId } = getState().analytics;
    try {
        await AnalyticsService.createEvent({ ...model, sessionId });
    } catch (error) {
        Logger.error(LOGGER_LOG_TYPE.ANALYTICS, `error analytics create event`, error);
    }
}


export const createEvents = (models: AnalyticEventViewModel[]) => async (dispatch: Dispatch, getState: () => Reducers) => {
    const { sessionId } = getState().analytics;
    try {
        await AnalyticsService.createEvents({ list: models.map(ev => ({ ...ev, sessionId })) });
    } catch (error) {
        Logger.error(LOGGER_LOG_TYPE.ANALYTICS, `error analytics create events`, error);
    }
}
