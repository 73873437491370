import React, { useMemo } from 'react';
import EditorDefaults from '../../../../utils/EditorDefaults';
import { ElementProps } from '../../../../utils/EditorProps';
import Prefixer from '../../../../../../common/services/Prefixer';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TriangleElementRender = ({ page, element, selected, ...props }: ElementProps, ref: React.ForwardedRef<HTMLDivElement>) => {

    const style = useMemo(() => Prefixer.prefixStyle(EditorDefaults.getElementStyles(element)), [element]);
    const borderStyle = useMemo(() => Prefixer.prefixStyle(EditorDefaults.getElementBorderStyle(element)), [element]);

    const borderWidth = borderStyle?.borderWidth ? Number(borderStyle?.borderWidth) : 0;
    
    return (
        <div
            {...props}
            ref={ref}
            style={style}
        >
            <svg 
                width={element.width} 
                height={element.height} 
                viewBox={`0 0 450 220`} 
                preserveAspectRatio="xMidYMax slice"
            >
                <polygon
                    points={`225,${0 + borderWidth} ${100 + borderWidth},${220 - borderWidth/2} ${350 - borderWidth},${220 - borderWidth/2}`}
                    width={`100%`}
                    height={`100%`}
                    style={{
                        color: 'inherit',
                        fill: style.fill,
                        stroke: borderStyle?.borderColor,
                        strokeWidth: borderStyle?.borderWidth,
                    }}
                />
            </svg>
        </div>
    );

}

export default React.forwardRef<HTMLDivElement, ElementProps>(TriangleElementRender);
