import toast from 'react-hot-toast';

type ToastParameters = {
    appearance: 'success' | 'error' | 'warning';
};

export const addToast = (message: string, params: ToastParameters) => {
    if(!params?.appearance){
        toast.success(message);
        return;
    }

    switch(params.appearance){
        case 'error': toast.error(message, {
            style: {
                border: '1px solid red',
                color: 'white',
                backgroundColor: 'red',
              },
              iconTheme: {
                primary: 'white',
                secondary: 'red',
              },
        }); break;
        case 'success': toast.success(message, {
            style: {
                border: '1px solid green',
                color: 'white',
                backgroundColor: 'green',
              },
              iconTheme: {
                primary: 'white',
                secondary: 'green',
              },
        }); break;
        case 'warning': toast.error(message, {
            icon: '⚠️',
            style: {
                border: '1px solid #ffae00',
                color: 'white',
                backgroundColor: '#ffae00',
              },
              iconTheme: {
                primary: 'white',
                secondary: '#ffae00',
              },
        }); break;
    }
}