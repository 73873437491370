import React, { memo, useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, LabelList, Legend, XAxis, YAxis } from 'recharts';
import PropTypes from 'prop-types';
import { ChartRenderProps } from 'screens/editor/utils/EditorProps';
import EditorChartsUtils from 'screens/editor/utils/EditorChartsUtils';
import colors from 'styles/export/colors.module.scss';

type Props = {
    stacked?: boolean;
    extraMargin?: boolean;
    showLabel?: boolean;
    maxSize?: number;
} & ChartRenderProps;

const VerticalBarsChart = ({
    data, height, width, fallback, stacked = false, withoutLegend, extraMargin,
    showLabel = true, maxSize, ...props
}: Props, ref: React.ForwardedRef<any>) => {
    if (!data || !data.tableData || data.tableData.length < 2) {
        return fallback || <></>;
    }

    const [chartData, chartRows] = useMemo(() => {
        return EditorChartsUtils.tableDataToLinesChartData(data.tableData);
    }, [data.tableData]);

    if (!chartData || chartData.length === 0) {
        return fallback || <></>;
    }

    const RectangleBar = (props: any) => {
        const { fill, x, y, width, height } = props;
        return <rect x={x} y={y} rx={width / 2} ry={width / 2} width={width} height={height} style={{ fill: fill }} />;
    };

    RectangleBar.propTypes = {
        fill: PropTypes.string,
        x: PropTypes.number,
        y: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
    };

    const renderCustomXAxisTick = (props: any) => {
        const { x, y, payload } = props;
        return (
            <text x={x} y={y + 4} fill={colors.chartGray} style={{ fontSize: 10, fontWeight: 600 }} textAnchor="middle" dominantBaseline="middle">
                {payload.value}
            </text>
        );
    };

    const renderCustomYAxisTick = (props: any) => {
        const { x, y, payload } = props;
        return (
            <text x={x - 6} y={y + 1} fill={colors.chartGray} style={{ fontSize: 10, fontWeight: 600 }} textAnchor="middle" dominantBaseline="middle">
                {payload.value}
            </text>
        );
    };

    const renderCustomizedLabel = (props: any) => {
        const { x, y, width, value, color } = props;
        const radius = 10;

        if (value === 0 || value === '0') {
            return null
        }

        return (
            <g>
                <text x={x + width / 2} y={y - radius} fill={color} style={{ fontSize: 12, fontWeight: 600 }} textAnchor="middle" dominantBaseline="middle">
                    {value}
                </text>
            </g>
        );
    };

    const renderLegend = (props: any) => {
        const { payload } = props;
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                {
                    payload.map((entry: any, index: number) => {
                        const { value, color } = entry;
                        return (
                            <div key={`item-${index}`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '1rem' }}>
                                <div style={{ width: 15, height: 15, backgroundColor: color, borderRadius: '50%', marginRight: '0.2rem' }}></div>
                                <div style={{ fontSize: 12, fontWeight: 600, color: colors.chartGray }}>{value}</div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    return (
        <BarChart
            {...props}
            data={chartData}
            height={height}
            width={width}
            ref={ref}
            margin={{
                top: 20,
                right: 10,
                left: 10,
                bottom: extraMargin ? 40 : 20,
            }}
            maxBarSize={maxSize ? maxSize : 15}
        >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="name" tick={renderCustomXAxisTick} axisLine={false} tickLine={false} />
            <YAxis tick={renderCustomYAxisTick} axisLine={false} tickLine={false} allowDecimals={false} />
            {chartRows.map((row, i) =>
                <Bar
                    key={`bar-${i}`}
                    dataKey={row.name}
                    shape={<RectangleBar />}
                    fill={row.color}
                    stackId={stacked ? 'stack' : undefined}
                >
                    {showLabel &&
                        <LabelList key={`LabelList-${i}`} dataKey={row.name} color={row.color} content={renderCustomizedLabel} />}
                </Bar>
            )}
            {!withoutLegend &&
                <Legend
                    wrapperStyle={{ position: 'relative' }}
                    iconType={'circle'}
                    content={renderLegend}
                />}
        </BarChart >
    );
}

export default memo(React.forwardRef<any, Props>(VerticalBarsChart));
