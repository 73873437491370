import useOnClickOutside from 'common/hooks/useOnClickOutside';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './NotificationsPopup.module.scss';
import CloseIcon from 'assets/svgs/Cross-bleu2.svg';
import NotificationsIcon from '../notificationsIcon/NotificationsIcon';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { NotificationDto } from 'api/notifications/models/NotificationDto';
import NotificationsService from 'api/notifications/NotificationsService';
import { decrementTotalNotificationsNotRead, setNotifications, setNotificationsStatus } from 'store/notifications/action';
import { LOGGER_LOG_TYPE } from 'Config';
import Logger from 'common/services/Logger';
import LoadingSpinner from '../loading/LoadingSpinner';
import { NotificationStatusState } from 'store/notifications/type';
import store from 'store/store';
import { addToast } from 'common/services/Toaster';
import ScrollShadow from '../scrollShadow/ScrollShadow';
import { stringify } from 'qs';


type Props = {
    onClose: () => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const NotificationsPopup = ({ onClose, ...props }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const notificationsMenuRef = useRef<HTMLDivElement | null>(null);
    const notifications = useSelector<Reducers, NotificationDto[]>(state => state.notifications.notifications);
    const notificationsStatus = useSelector<Reducers, NotificationStatusState | null>(state => state.notifications.status);
    const [showLoading, setShowLoading] = useState(true);
    

    const getNotifications = async () => {
        try {
            setShowLoading(true);
            const notifications = await NotificationsService.getList({ showNotReadOnly: true });

            // Convert string to date
            notifications?.items?.map(n => n.createdDate = new Date(n.createdDate));

            dispatch(setNotifications(notifications.items));
            setShowLoading(false);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.NOTIFICATIONS, `Couldn't get the notifications`, error);
            dispatch(setNotificationsStatus({
                statusType: 'error',
                message: t('notification.popup.error_get_notifications'),
            }));
            setShowLoading(false);
        }
    }

    const readNotification = async (notification: NotificationDto) => {
        try {
            setShowLoading(true);

            if (notification.url && notification.url.length > 0) {
                window.open(notification.url, '_blank');
            }

            await NotificationsService.read(notification.id);
            dispatch(setNotifications(store.getState().notifications.notifications.filter(n => n.id !== notification.id)));
            dispatch(decrementTotalNotificationsNotRead());
            setShowLoading(false);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.NOTIFICATIONS, `Couldn't read notification`, error);
            addToast(t('common.messages.error_process'), { appearance: 'error' });
            setShowLoading(false);
        }
    }

    useEffect(() => {
        getNotifications();
    }, [])

    useOnClickOutside(notificationsMenuRef, () => {
        onClose();
    }, []);

    const onNotificationsMenuClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
    }

    return (
        <div {...props} className={styles.popup} ref={notificationsMenuRef} onClick={onNotificationsMenuClick}>
            <div className={styles.header}>
                <NotificationsIcon iconClassName={styles.headerIcon} />
                <span className={styles.headerTitle}>{t('notification.popup.title')}</span>
                <img src={CloseIcon} className={styles.headerCloseIcon} onClick={onClose} />
            </div>
            <ScrollShadow scrollId="scrollNotifications" className={styles.scrollContainer} scrollClassName={styles.scroll}>
                <div className={styles.listContainer}>
                    {showLoading && <div className={styles.loadingContainer}><LoadingSpinner /></div>}

                    {!showLoading && notificationsStatus && notificationsStatus.statusType === 'error' && notificationsStatus.message
                        && <div className={styles.statusError}>{notificationsStatus.message}</div>}

                    {!showLoading && notifications.map((notification, i) => (

                        <div
                            key={i}
                            className={`${styles.item} ${i % 2 ? styles.itemOdd : ''}`}
                            onClick={() => readNotification(notification)}
                        >
                            <div className={styles.itemDate}>{notification.createdDate ? notification.createdDate.toLocaleDateString() + ' - ' + notification.createdDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : <i>{t('notification.popup.no_subject')}</i>}</div>
                            <div className={styles.itemTitle}>{notification.title ? notification.title : <i>{t('notification.popup.no_subject')}</i>}</div>
                            <div className={styles.itemDescription}>{notification.message ? (notification.message).replace(/<[^>]+>/g, '') : <i>{t('notification.popup.no_message')}</i>}</div>
                        </div>
                    ))}
                </div>
            </ScrollShadow>

            {notifications.length === 0 && <div className={styles.noNotificationsText}>{t('notification.popup.no_notifications')}</div>}
        </div>
    );
};

export default NotificationsPopup;