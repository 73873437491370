import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from 'common/services/Toaster';
import Button from '../../../common/components/button/Button';
import Loading from '../../../common/services/Loading';
import styles from './CompanyModal.module.scss';
import CompanyService from '../../../api/companies/CompaniesService';
import { Color, CompanyViewModel } from '../../../api/companies/models/CompanyViewModel';
import OverlayedModal from '../../../common/components/overlayedModal/OverlayedModal';
import FormItem from '../../../common/components/formItem/FormItem';
import { Controller, useForm } from 'react-hook-form';
import QuestionYesNo from '../../../common/components/questionYesNo/QuestionYesNo';
import CompaniesService from '../../../api/companies/CompaniesService';
import UsersService from '../../../api/users/UsersService';
import image from '../../../assets/img/addUser.jpg';
import IconClose from '../../../assets/svgs/Cross-bleu2-contour.svg';
import IconCheck from '../../../assets/svgs/Check-blanc.svg';
import IconSend from '../../../assets/svgs/Send.svg';
import InputWithError from '../../../common/components/input/InputWithError';
import RemoveMessage from 'common/components/removeMessage/RemoveMessage';
import Select from 'common/components/select/Select';
import WelcomeMessagesService from 'api/welcomeMessages/WelcomeMessagesService';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import { UserViewModel } from 'api/users/models/UserViewModel';
import ColorPallete from 'common/components/colorPallete/ColorPallete';

type Props = {
    userId?: string;
    isVisible: boolean;
    mode: string | null;
    item: CompanyViewModel | null;
    onCancel: () => void;
};

type Form = {
    name: string;
    userName: string;
    userEmail: string;
    welcomeMessageId: string;
    colors: Color[];
}

const CompanyModal: React.FC<Props> = ({ isVisible, mode, item, onCancel }: Props) => {

    const { t } = useTranslation();
    
    const { register, handleSubmit, errors, setValue, getValues, clearErrors } = useForm<Form>({ 
        shouldUnregister: false, defaultValues: { welcomeMessageId: item?.welcomeMessageId }
    });
    const [itemToRemove, setItemToRemove] = useState<CompanyViewModel>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [nameIsAvailable, setNameIsAvailable] = useState<boolean>(true);
    const [userEmailIsAvailable, setUserEmailIsAvailable] = useState<boolean>(true);

    const [welcomeMessagesOptions, setWelcomeMessagesOptions] = useState<SelectValueLabel[]>([]);

    const [editColors, setEditColors] = useState<Color[] | null>(null);

    const [showDelete, setShowDelete] = useState<boolean>(false);
    const { control } = useForm<UserViewModel>();

    const [modeCompany, setModeCompany] = useState<string | null>(mode);

    const onSubmit = async (form: Form) => {

        try {
            if ((modeCompany == 'new' && (!form.name || !form.userName || !form.userEmail)) || (modeCompany != 'new' && !form.name)) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            Loading.show();
            
            const resultCompany = await CompaniesService.checkCompanyInfoAvailability({ companyId: item?.id, name: form.name });
            let resultUser = null;

            if (modeCompany == 'new') {
                resultUser = await UsersService.checkEmailAvailability({ email: form.userEmail });
            }

            if (resultCompany != null && resultCompany.available == false) {
                if (!!resultCompany.errors) {
                    if (!!resultCompany.errors.find((x: string) => x == 'NAME')) {
                        setNameIsAvailable(false);
                        addToast(t('users.username_not_available'), { appearance: 'warning' });
                    }
                }
            }

            if (resultUser != null && resultUser.available == false) {
                if (!!resultUser.errors) {
                    if (!!resultUser.errors.find((x: string) => x == 'EMAIL')) {
                        setUserEmailIsAvailable(false);
                        addToast(t('common.errors.email_not_available'), { appearance: 'warning' });
                    }
                }
            }

            if (resultCompany != null && resultCompany.available == false ||
                resultUser != null && resultUser.available == false) {
                Loading.hide();
                return;
            }
            
            const model: CompanyViewModel = {
                ...form,
                id: item?.id,
                name: form.name,
                userName: form.userName,
                userEmail: form.userEmail,
                welcomeMessageId: form.welcomeMessageId,
                colors: form.colors && form.colors.length > 0 ? form.colors.map(x => ({ color: x.hex, alpha: x.alpha })) : []
            };

            await CompanyService.save(model);

            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            onCancel();

        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const getData = async () => {
        try {

            Loading.show();

            const welcomeMessages = await WelcomeMessagesService.getAllMessagesForSelectItem();
            const result = welcomeMessages.map(x => ({ value: x.id || '', label: x.name || '' }));
            setWelcomeMessagesOptions(result);

            if (item?.id) {
                const result = await CompanyService.getCompanyById(item.id);

                const colors = result.colors.map((x, i) => ({ 
                    index: i, hex: x.color, color: x.color, 
                    r: parseInt(x.color.slice(1, 3), 16) ? parseInt(x.color.slice(1, 3), 16).toString() : '0', 
                    g: parseInt(x.color.slice(3, 5), 16) ? parseInt(x.color.slice(3, 5), 16).toString() : '0', 
                    b: parseInt(x.color.slice(5, 7), 16) ? parseInt(x.color.slice(5, 7), 16).toString() : '0', 
                    alpha: x.alpha,
                    alphaApply: (parseInt(x.alpha)/100).toString()
                }));

                setEditColors(colors);
                setValue('name', result?.name);
                setValue('welcomeMessageId', result?.welcomeMessageId);
                setValue('colors', colors);
            }
            else {
                setEditColors([]);
            }


            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const showRemoveItemDialog = (item: CompanyViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await CompanyService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                onCancel();
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'success' });
                Loading.hide();
            }
        }
    };

    const onChangeColor = (colors: Color[]) => {
        setValue('colors', colors);
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <OverlayedModal isOpen={isVisible} className={showDelete ? styles.showDelete : ''}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.container}>
                    <div className={styles.left}>
                        <div className={styles.image}>
                            <img src={image}/>
                        </div>
                        { editColors && 
                            <div className={modeCompany == 'details' ? styles.colorPalleteDetails : styles.colorPallete }>
                                <ColorPallete
                                    isDetails={modeCompany == 'details' ? true : false}
                                    isEdit={modeCompany == 'edit' ? true : false}
                                    editColors={editColors}
                                    title={t('companies.company_colors')}
                                    onChangeColor={onChangeColor}
                                /> 
                            </div> }
                    </div>
                    <div className={styles.right}>
                        <div className={styles.header}>
                            <div className={styles.title}>
                                {modeCompany == 'new' && t('companies.add_company')}
                                {modeCompany == 'edit' && t('companies.edit')}
                                {modeCompany == 'details' && t('companies.details')}
                            </div>
                            <img src={IconClose} onClick={onCancel} className={styles.icon} />
                        </div>
                        <div className={styles.form}>
                            <h2 className={styles.h2}>{t('companies.company')}</h2>
                            <FormItem>
                                <InputWithError
                                    error={!nameIsAvailable ? { type: 'name_not_available' } : errors.name}
                                    name="name"
                                    placeholder={t('common.name')}
                                    ref={register({ required: true, maxLength: 255 })}
                                    disabled={modeCompany == 'details'}
                                    onKeyPress={() => setNameIsAvailable(true)}
                                    onErrorClick={() => clearErrors('name')} />
                            </FormItem>

                            {modeCompany == 'new' && <div className={styles.containerUser}>
                                <h2 className={styles.h2}>{t('companies.user')}</h2>

                                <FormItem>
                                    <InputWithError
                                        error={errors.userName}
                                        name="userName"
                                        placeholder={t('common.name')}
                                        ref={register({ required: true, maxLength: 255 })}
                                        onErrorClick={() => clearErrors('userName')} />
                                </FormItem>

                                <FormItem>
                                    <InputWithError
                                        error={!userEmailIsAvailable ? { type: 'email_not_available' } : errors.userEmail}
                                        name="userEmail"
                                        type="email"
                                        placeholder={t('common.email')}
                                        ref={register({ required: true, maxLength: 255 })}
                                        onKeyPress={() => setUserEmailIsAvailable(true)}
                                        onErrorClick={() => clearErrors('userEmail')} />
                                </FormItem>
                            </div>}

                            <div>
                                <h2 className={styles.h2}>{t('companies.welcome_message')}</h2>
                                <FormItem>
                                    <Controller
                                        render={({ onChange, value }) => {
                                            return (
                                                <Select
                                                    options={welcomeMessagesOptions}
                                                    isClearable
                                                    isDisabled={modeCompany == 'details'}
                                                    placeholder={t('companies.welcome_message')}
                                                    onChange={(data: SelectValueLabel) => {
                                                        onChange(data?.value);
                                                        setValue('welcomeMessageId', data ? data.value : null);
                                                    }}
                                                    value={welcomeMessagesOptions.find(x => x.value === value) ? { label: welcomeMessagesOptions.find(x => x.value === value)?.label ?? '', value: value } : null}
                                                    filterOption={(candidate: any, input: any) => input ? candidate.label.toUpperCase().includes(input.toUpperCase()) : true}
                                                />
                                            );
                                        }}
                                        control={control}
                                        name="welcomeMessageId"
                                        defaultValue={getValues('welcomeMessageId')}
                                    />
                                </FormItem>
                            </div>
                        </div>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.removeCompany}>
                    {(modeCompany == 'edit' || modeCompany == 'details') &&
                        <RemoveMessage
                            message={t('companies.remove')}
                            onElementClick={() => { setShowDelete(true); showRemoveItemDialog({ id: item?.id } as CompanyViewModel) }} />
                    }
                </div>
                <div className={styles.buttonsContainer} >
                    <Button
                        type="button"
                        text={t('common.cancel').toUpperCase()}
                        preset={'secondary'}
                        onClick={onCancel}
                        className={styles.button}
                    />
                    {modeCompany == 'details' && 
                        <Button
                            type="button"
                            text={t('common.edit').toUpperCase()}
                            onClick={() => setModeCompany('edit')}
                            className={styles.button}
                        >
                        </Button> }
                    {modeCompany == 'new' && <Button
                        type={'submit'}
                        text={t('companies.send_invitation').toUpperCase()}
                        className={styles.button}
                    >
                        <img src={IconSend} className={styles.icon} />
                    </Button>}
                    {modeCompany == 'edit' && <Button
                        type={'submit'}
                        text={t('common.save').toUpperCase()}
                        className={styles.button}
                    >
                        <img src={IconCheck} className={styles.icon} />
                    </Button>}
                    <QuestionYesNo
                        title={t('common.remove')}
                        message={t('common.messages.remove_record')}
                        isVisible={dialogDeleteItemIsOpen}
                        onYes={() => removeItem()}
                        onNo={() => { setShowDelete(false); setDialogDeleteItemIsOpen(false) }}
                    />
                </div>
            </div>
            </form>
        </OverlayedModal >
    );
}

export default CompanyModal;
