import GoogleFontsService from 'api/googleFonts/GoogleFontsService';
import { GoogleFont } from 'api/googleFonts/models/GoogleFont';
import Button from 'common/components/button/Button';
import LoadingSpinner from 'common/components/loading/LoadingSpinner';
import OverlayedModal from 'common/components/overlayedModal/OverlayedModal';
import Select from 'common/components/select/Select';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EditorFont } from 'screens/editor/models/EditorModels';
import { setFonts } from 'store/editor/action';
import { Reducers } from 'store/types';
import styles from './AddGoogleFontsModal.module.scss';
import { components } from 'react-select';

const MenuList = ({ children, ...props }: any) => {
    return (
        <components.MenuList {...props}>
            {Array.isArray(children)
                ? children.slice(0, 20) /* Options */
                : children /* NoOptionsLabel */
            }
        </components.MenuList>
    );
};

type Props = {
    isVisible: boolean;
    onClose: () => void;
}

const AddGoogleFontsModal = ({ isVisible, onClose }: Props) => {
    const { t } = useTranslation();
    const [googleFonts, setGoogleFonts] = useState<GoogleFont[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorCode, setErrorCode] = useState<number | null>(null);
    const dispatch = useDispatch();
    const currentFonts = useSelector<Reducers, EditorFont[]>(state => state.editor.present.fonts || []);

    const onChange = (data: SelectValueLabel[]) => {
        const newFonts: EditorFont[] = (data
            .map(item => googleFonts.find(f => f.family === item.value))
            .filter(x => x) as EditorFont[])
            .map(x => ({
                family: x.family,
            }));
        
        dispatch(setFonts(newFonts));
    }

    const getFonts = async () => {
        try {
            const googleFonts = await GoogleFontsService.getAll();
            setGoogleFonts(googleFonts.items);
            setIsLoading(false);
        } catch (error) {
            setErrorCode(error.status || 500);
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        getFonts();
    }, []);

    const selectOptions = googleFonts.map(f => ({ value: f.family, label: f.family }));
    const selectValue = currentFonts ? currentFonts.filter(x => selectOptions.find(f => f.value === x.family)).map(f => ({ value: f.family, label: f.family })) : [];

    return (
        <OverlayedModal isOpen={isVisible} style={{ content: { width: '50%', overflow: 'unset' } }} onRequestClose={onClose}>
            <div>
                {isLoading && <div className={styles.loadingContainer}><LoadingSpinner /></div>}
                {!isLoading && <div style={{ position: 'relative' }}>
                    <h1 className={styles.title}>{t('editor.toolbar.add_fonts')}</h1>
                    <Select
                        isClearable
                        isMulti
                        options={selectOptions}
                        value={selectValue}
                        filterOption={(candidate: any, input: any) => {
                            return input ? candidate.label.toUpperCase().includes(input.toUpperCase()) : true;
                        }}
                        placeholder={t('editor.toolbar.search_fonts_placeholder')}
                        onChange={onChange}
                        maxHeight={200}
                        components={{ MenuList }}
                    />
                    <div className={styles.footer}>
                        <Button onClick={onClose} size="small">{t('common.close')}</Button>
                    </div>
                </div>}
                {errorCode && <div className={styles.errorContainer}>{t('common.messages.error_load_info')}</div>}
            </div>
        </OverlayedModal>
    );
}

export default AddGoogleFontsModal;
