
class EditorUnstatedStore {

    public deletedMediasIds: string[] = [];
    public deletedMediasIdsInProgress: string[] = [];

    public addMediaToBeDeleted(id: string) {
        this.deletedMediasIds.push(id);
    }

    public progressMediasToBeDeleted() {
        this.deletedMediasIdsInProgress = this.deletedMediasIds;
    }

    public clearInProgressMediasToBeDeleted() {
        this.deletedMediasIds = this.deletedMediasIds.filter(x => !this.deletedMediasIdsInProgress.find(p => p === x));
        this.deletedMediasIdsInProgress = [];
    }
}

export default new EditorUnstatedStore();
