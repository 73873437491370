import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { IS_ADMIN, POLICIES } from 'Config';
import { Reducers } from 'store/types';
import Sidebar, { SidebarItem } from '../sidebar/Sidebar';
import AppSettingsModal from 'screens/app-settings/AppSettingsModal';
import styles from './AuthenticatedLayout.module.scss';
import { ReactComponent as IconDashboard } from 'assets/svgs/Menu-bleu2.svg';
import { ReactComponent as IconDocuments } from 'assets/svgs/Fold-blue2.svg';
import { ReactComponent as IconValidateDocuments } from 'assets/svgs/SupportLight.svg';
import { ReactComponent as IconUsers } from 'assets/svgs/People-bleu2.svg';
import { ReactComponent as IconPhoto } from 'assets/svgs/Photo-bleu2.svg';
import { ReactComponent as IconNotification } from 'assets/svgs/Bell-bleu2.svg';
import { ReactComponent as IconTips } from 'assets/svgs/Bulb-bleu2.svg';
import { ReactComponent as IconCompany } from 'assets/svgs/Entreprises-blue.svg';
import { ReactComponent as IconMessage } from 'assets/svgs/Messages-blue2.svg';
import { ReactComponent as IconSettings } from 'assets/svgs/Help.svg';
import { ReactComponent as IconAddDocument } from 'assets/svgs/AddDoc-bleu2.svg';

import UsersService from 'api/users/UsersService';
import CreateDocumentModal from 'screens/documents/components/createDocumentModal/CreateDocumentModal';

type Props = {
    children: React.ReactNode;
}

type SidebarItemWithValidation = {
    policies: (keyof typeof POLICIES)[];
    type?: 'OR' | 'AND';
    show?: boolean;
} & SidebarItem;

const AuthenticatedLayout: React.FunctionComponent<Props> = ({ children }: Props) => {
    const { t } = useTranslation();
    const [openAppSettings, setOpenAppSettings] = React.useState<boolean>(false);
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const isAdmin: boolean = IS_ADMIN(loggedUser);

    const validateItem = useCallback((item: SidebarItemWithValidation) => {
        const type = item.type || 'AND';
        const policies = item.policies || [];
        const userPolicies = loggedUser?.policies || [];

        return UsersService.hasPolicies(userPolicies, policies, type) && (item.show === undefined || (item.show !== undefined && item.show === true));
    }, [loggedUser]);

    const sidebarItems: SidebarItemWithValidation[] = useMemo(() => {
        const items: SidebarItemWithValidation[] = [
            {
                text: t('menu.dashboard'),
                icon: IconDashboard,
                url: '/',
                policies: [],
            },
            {
                text: t('menu.add_document'),
                icon: IconAddDocument,
                onClick: () => setOpenDialog(true),
                policies: ['DOCUMENTS_WRITE'],
                show: !isAdmin
            },
            {
                text: isAdmin ? t('menu.models') : t('menu.documents'),
                icon: IconDocuments,
                url: '/supports',
                policies: ['DOCUMENTS_WRITE'],

            },
            {
                text: t('menu.simplePages'),
                icon: IconDocuments,
                url: '/simple-models',
                policies: ['SIMPLE_MODELS_WRITE'],

            },
            {
                text: t('menu.manage_models'),
                icon: IconValidateDocuments,
                url: '/manage-pre-filled-models',
                policies: ['PRE_FILLED_DOCUMENTS_WRITE'],
                show: !isAdmin
            },
            {
                text: t('menu.medias'),
                icon: IconPhoto,
                url: '/medias',
                policies: ['MEDIAS_WRITE'],

            },
            {
                text: t('menu.documents_validate'),
                icon: IconValidateDocuments,
                url: '/supports-validate',
                policies: ['DOCUMENTS_VALIDATE'],

            },
            {
                text: t('menu.user_management'),
                icon: IconUsers,
                url: '/users',
                policies: ['USERS_WRITE'],

            },
            {
                text: t('menu.companies'),
                icon: IconCompany,
                url: '/companies',
                policies: ['COMPANIES_WRITE'],

            },
            {
                text: t('menu.notification_management'),
                icon: IconNotification,
                url: '/notification',
                policies: ['NOTIFICATIONS_WRITE'],
                style: { width: '4.3rem' }
            },
            {
                text: t('menu.tips'),
                icon: IconTips,
                url: '/tips',
                policies: ['TIPS_WRITE']
            },
            {
                text: t('menu.welcome_messages'),
                icon: IconMessage,
                url: '/welcomeMessages',
                policies: ['WELCOME_MESSAGES_WRITE']
            },
            {
                text: t('menu.appSettings'),
                icon: IconSettings,
                onClick: () => setOpenAppSettings(true),
                policies: ['APP_SETTINGS_WRITE'],
                show: isAdmin
            }
        ];

        return items.filter(item => validateItem(item));
    }, [loggedUser, validateItem, isAdmin]);

    return (
        <div className={styles.container} >
            <div className={styles.sidebarContainer}>
                <Sidebar items={sidebarItems} />
            </div>
            <div className={styles.contentContainer} id="scrollHere">
                {children}
            </div>

            {openAppSettings && <AppSettingsModal
                mode={'edit'}
                isVisible={openAppSettings}
                onCancel={() => setOpenAppSettings(false)}
            />}

            {openDialog && <CreateDocumentModal
                mode={'new'}
                item={null}
                isVisible={openDialog}
                onCancel={() => setOpenDialog(false)}
            />}
        </div>
    );
}

export default AuthenticatedLayout;
