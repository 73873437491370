import React from 'react';
import styles from './RemoveMessage.module.scss';
import IconTrash from 'assets/svgs/Trash-red.svg';

type Props = {
    message: string;
    onElementClick: () => void;
    className?: string;
    style?: any;
}

const RemoveMessage: React.FC<Props> = ({ message, onElementClick, className, style, ...props }: Props) => {

    return (
        // <div>
        //     <div
        //         {...props}
        //         className={`${styles.messageContainer} ${className}`}
        //         style={style}
        //         onClick={() => onElementClick()}>

        //         <div style={{ textAlign: 'center' }}>
        //             <span className={styles.messageContainerContent}>{message}</span>
        //         </div>
        //         <div>
        //             <img src={IconTrash} className={styles.iconRemove} />
        //         </div>
        //     </div>
        // </div>
        <div style={style}>
            <div
                {...props}
                className={`${styles.messageContainer}  ${props || ''}`}
                onClick={() => onElementClick()}
            >
                <span className={`${className ? className : styles.messageContainerContent}  ${props || ''}`}>{message}</span>
                <img src={IconTrash} className={styles.iconRemove} />
            </div>
        </div>

    );
}

export default RemoveMessage;