import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from 'common/services/Toaster';
import Loading from '../../../common/services/Loading';
import styles from './ListSimpleModelsScreen.module.scss';
import ScreenTitle from '../../../common/components/screenTitle/ScreenTitle';
import PageHeader from '../../../common/components/pageHeader/PageHeader';
import FormItem from '../../../common/components/formItem/FormItem';
import Select from '../../../common/components/select/Select';
import { SelectValueLabel } from '../../../common/types/SelectValueLabel';
import DocumentsService from '../../../api/documents/DocumentsService';
import CheckBox from '../../../common/components/checkBox/CheckBox';
import CreateDocumentModal from '../components/createDocumentModal/CreateDocumentModal';
import { DocumentViewModel } from '../../../api/documents/models/DocumentViewModel';
import { DocumentFormat } from '../../../api/documents/enums/DocumentFormat';
import IconPaperBlue from '../../../assets/svgs/Paper-blue2.svg';
import BoxItem from '../list/components/box/BoxItem';
import { DocumentsSearchCriteria } from '../../../api/documents/models/DocumentsSearchCriteria';
import DocumentModal from '../list/components/modal/DocumentModal';
import InfinityScroll from '../../../common/components/infinityScroll/InfinityScroll';
import { DocumentVisibility } from 'api/documents/enums/DocumentVisibility';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Logger from 'common/services/Logger';
import { IS_ADMIN, LOGGER_LOG_TYPE } from 'Config';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import EditorDefaults from 'screens/editor/utils/EditorDefaults';
import { PreviousPage } from '../../../api/editor/enums/PreviousPage';
import { DocumentType } from 'api/documents/enums/DocumentType';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from 'common/components/pagination/Pagination';
import { useHistory } from 'react-router-dom';

export type OpenDialogType = 'details' | 'edit' | 'new' | 'validation';
export type CloseDialogResultType = 'edit' | 'validate' | 'refuse';

const ListSimpleModelsScreen: React.FC = () => {
    const { t } = useTranslation();
    
    const history = useHistory();

    const documentFormats: SelectValueLabel[] = [
        { value: DocumentFormat.PRODUCT_SHEET, label: t('documents.format.PRODUCT_SHEET') },
        { value: DocumentFormat.PRODUCT_BROCHURE, label: t('documents.format.PRODUCT_BROCHURE') },
        { value: DocumentFormat.PRESENTATION_SLIDES, label: t('documents.format.PRESENTATION_SLIDES') },
        { value: DocumentFormat.SOCIAL_MEDIA_POSTS, label: t('documents.format.SOCIAL_MEDIA_POSTS') },
    ];

    const [documents, setDocuments] = useState<DocumentViewModel[]>([]);
    const [privateDocuments, setPrivateDocuments] = useState<boolean>(false);
    const [publicDocuments, setPublicDocuments] = useState<boolean>(false);

    const [openDialog, setOpenDialog] = React.useState<OpenDialogType | null>(null);
    const [openDialogItem, setOpenDialogItem] = React.useState<DocumentViewModel | null>(null);

    const [openDocModal, setOpenDocModal] = React.useState<boolean>(false);
    const [openDocRemoveModal, setOpenDocRemoveModal] = React.useState<boolean>(false);
    const [openDocumentModalItem, setOpenDocumentModalItem] = React.useState<DocumentViewModel | null>(null);

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const companyId = loggedUser?.companyId;
    const isAdmin = IS_ADMIN(loggedUser);
    const [criteria, setCriteria] = useState<DocumentsSearchCriteria>({ 
        page: 1, 
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE, 
        isAdmin: !companyId ? true : false, 
        type: DocumentType.SIMPLE_MODEL, 
        // getSimpleModelsList: true, 
        companyId: companyId 
    } );

    const [totalPages, setTotalPages] = useState<number>(1);

    const [searchValue, setSearchValue] = useState('');

    const getData = async () => {
        try {
            Loading.show();

            const docs = await DocumentsService.getList({
                ...criteria,
                orderColumn: 'name',
                orderBy: 'asc',
                name: searchValue
            });

            // Convert string to date
            docs?.items?.map(doc => doc.createdDate = new Date(doc.createdDate));

            docs?.items?.map(doc => {
                if(doc) {
                    const PageInfo = EditorDefaults.getPageSizeByFormat(doc.format);
                    doc.orientation = PageInfo.orientation;
                }
            });

            setDocuments([
                ...(criteria.page == 1 ? [] : documents),
                ...docs?.items
            ]);
            setTotalPages(docs?.totalPages || 1);


            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.DOCUMENTS , `Couldn't get documents list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const openModal = (item: DocumentViewModel | null, dialogType: OpenDialogType) => {
        setOpenDialog(dialogType);
        setOpenDialogItem(item);
    };

    const openModalDocument = (item: DocumentViewModel) => {
        setOpenDocModal(true);
        setOpenDocumentModalItem(item);
    };

    const openModalRemoveDocument = () => {
        setOpenDocModal(false);
        setOpenDocRemoveModal(true);
    };

    const closeModalRemoveDocumentYes = () =>  {
        setOpenDocRemoveModal(false);
        setOpenDocumentModalItem(null);
    };

    const closeModalRemoveDocumentNo = () =>  {
        setOpenDocRemoveModal(false);
        setOpenDocModal(true);
    };

    const onUserModalClose = (result?: CloseDialogResultType, id?: string) => {
        setOpenDialog(null);
        setOpenDocModal(false);
        setCriteria({ ...criteria, page: 1 });

        if(id){
            if (result === 'edit') {
                history.push(`/editor/${id}/${PreviousPage.SIMPLE_MODELS}`);
            }
        }
        
        if (openDocumentModalItem?.id && !id) {
            if (result === 'edit') {
                history.push(`/editor/${openDocumentModalItem.id}/${PreviousPage.SIMPLE_MODELS}`);
            }
        }

        getData();
    };

    const changePrivateDocuments = () => {
        const checked = !privateDocuments;
        setPublicDocuments(false);
        setPrivateDocuments(checked);
        setCriteria({ ...criteria, page: 1, visibility: checked ? DocumentVisibility.PRIVATE : undefined });
    };

    const changePublicDocuments = () => {
        const checked = !publicDocuments;
        setPrivateDocuments(false);
        setPublicDocuments(checked);
        setCriteria({ ...criteria, page: 1, visibility: checked ? DocumentVisibility.PUBLIC : undefined });
    };

    const removeItem = async () => {
        try {
            Loading.show();
            if(openDocumentModalItem && openDocumentModalItem.id){
                await DocumentsService.remove({ id: openDocumentModalItem.id });
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                closeModalRemoveDocumentYes();
                setCriteria({ ...criteria, page: 1 });
                Loading.hide();
            } else {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
                closeModalRemoveDocumentYes();
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.DOCUMENTS , `Couldn't remove document`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            Loading.hide();
            closeModalRemoveDocumentYes();
        }
    };

    const onChangeSearch= (text: string) => {
        setSearchValue(text);
        setCriteria({
            ...criteria,
            page: 1
        });
    }

    useEffect(() => {
        getData();
    }, [criteria, searchValue]);

    return (
        <ScreenTitle title={t('menu.simplePages')}>
            <PageHeader title={t('documents.simplePages')} onChangeSearch={onChangeSearch} searchValue={searchValue}></PageHeader>
            <div className={styles.container}>
                <div className={styles.line}></div>
                <div className={styles.header}>
                    <div className={styles.select}>
                        <FormItem>
                            <Select
                                menuPortalTarget={document.querySelector('body')}
                                options={documentFormats}
                                placeholder={t('documents.classify_by_simple_model_type').toUpperCase()}
                                onChange={(data: SelectValueLabel) => {
                                    setCriteria({
                                        ...criteria,
                                        format: data?.value,
                                        page: 1
                                    });
                                }}
                                value={documentFormats.find(x => x.value === criteria.format)}
                                icon={IconPaperBlue}
                            />
                        </FormItem>
                    </div>
                    { !isAdmin && <div className={styles.checks}>
                        <CheckBox checked={privateDocuments} onChange={() => changePrivateDocuments()}>
                            <a className={styles.a}>{t('documents.types.private').toUpperCase()}</a>
                        </CheckBox>
                        <CheckBox checked={publicDocuments} onChange={() => changePublicDocuments()}>
                            <a className={styles.a}>{t('documents.types.public').toUpperCase()}</a>
                        </CheckBox>
                    </div> }
                </div>
                <div className={styles.content}>
                    <InfinityScroll
                        inModal={false}
                        totalItems={documents.length}
                        executeOnScroll={() => {
                            setCriteria({
                                ...criteria,
                                page: criteria.page + 1
                            });
                        }}
                        continueScroll={totalPages > criteria.page}
                        elementIdToScroll='scrollHere'
                    >
                        <div className={styles.list}>
                            <BoxItem newLabelText={t('documents.add_simple_model')} onClick={() => openModal(null, 'new')} />
                            {
                                documents.map((value, index) => {
                                    return <BoxItem
                                        key={index}
                                        document={value}
                                        onClick={() => openModalDocument(value)}
                                    />
                                })
                            }
                        </div>
                    </InfinityScroll>

                    { openDocModal && openDocumentModalItem && <DocumentModal
                        mode={'details'}
                        item={openDocumentModalItem}
                        isVisible={openDocModal}
                        onCancel={onUserModalClose}
                        onRemove={openModalRemoveDocument}
                    /> }
                </div>
                <div>
                    { openDialog !== null && <CreateDocumentModal
                        mode={openDialog}
                        item={openDialogItem}
                        isVisible={openDialog ? true : false}
                        onCancel={onUserModalClose}
                        documentType={DocumentType.SIMPLE_MODEL}
                    /> }
                </div>
                <div>
                    {openDocRemoveModal != null && <QuestionYesNo
                                title={t('common.remove')}
                                message={t('common.messages.remove_record')}
                                isVisible={openDocRemoveModal}
                                onYes={() => removeItem()}
                                onNo={() => { closeModalRemoveDocumentNo(); }}/>}
                </div>
            </div>
        </ScreenTitle>
    );
}

export default ListSimpleModelsScreen;
