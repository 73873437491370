export enum AnalyticEventType {
    DOCUMENT_EXPORT = 'DOCUMENT_EXPORT',
    DOCUMENT_TOTAL_INTERNAL_IMAGES = 'DOCUMENT_TOTAL_INTERNAL_IMAGES',
    DOCUMENT_TOTAL_UNSPLASH_IMAGES = 'DOCUMENT_TOTAL_UNSPLASH_IMAGES',
    DOCUMENT_TOTAL_GIFS = 'DOCUMENT_TOTAL_GIFS',
    DOCUMENT_TOTAL_TABLES = 'DOCUMENT_TOTAL_TABLES',
    DOCUMENT_TOTAL_GRAPHICS = 'DOCUMENT_TOTAL_GRAPHICS',
    DOCUMENT_TOTAL_TEXTS = 'DOCUMENT_TOTAL_TEXTS',
    DOCUMENT_TOTAL_ICONS = 'DOCUMENT_TOTAL_ICONS',
    DOCUMENT_TOTAL_CHARTS = 'DOCUMENT_TOTAL_CHARTS',
}
