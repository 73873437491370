import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './BottomButtons.module.scss';
import IconRedoEnabled from '../../../../../../assets/svgs/Back2-bleu2.svg';
import IconRedoDisabled from '../../../../../../assets/svgs/Back2-Bleu2Fade.svg';
import IconUndoEnabled from '../../../../../../assets/svgs/Back-bleu2.svg';
import IconUndoDisabled from '../../../../../../assets/svgs/Back-bleu2fade.svg';
import IconDownload from '../../../../../../assets/svgs/Download-bleu2.svg';

type Props = {
    canUndo: boolean;
    canRedo: boolean;
    onUndo: () => void;
    onRedo: () => void;
    onExport: () => void;
    className?: string;
};

const BottomButtons: React.FC<Props> = ({ canUndo, canRedo, onUndo, onRedo, onExport, className }: Props) => {
    const { t } = useTranslation();
    
    return (
        <div className={`${styles.container} ${className ? className : ''}`}>
            <div className={`${styles.item} ${!canUndo ? styles.disabled : ''}`} onClick={() => canUndo && onUndo()}>
                <img src={canUndo ? IconUndoEnabled : IconUndoDisabled} className={styles.icon} />
                <span className={styles.text}>
                    {t('editor.undo')}
                </span>
            </div>
            <div className={`${styles.item} ${!canRedo ? styles.disabled : ''}`} onClick={() => canRedo && onRedo()}>
                <img src={canRedo ? IconRedoEnabled : IconRedoDisabled} className={styles.icon} />
                <span className={styles.text}>
                    {t('editor.redo')}
                </span>
            </div>
            <div className={styles.item} onClick={onExport}>
                <img src={IconDownload} className={styles.icon} />
                <span className={styles.text}>
                    {t('editor.export')}
                </span>
            </div>
        </div>
    );
}

export default BottomButtons;
