import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToolbarOptionProps } from '../../../../utils/EditorProps';
import ToolbarButton from '../toolbarButton/ToolbarButton';
import IconRadius from 'assets/svgs/toolbar/Radius.svg';
import IconRadius1 from 'assets/svgs/toolbar/Radius1.svg';
import IconRadius2 from 'assets/svgs/toolbar/Radius2.svg';
// import IconRadius3 from 'assets/svgs/toolbar/Radius3.svg';
import ToolbarButtonOption from '../toolbarButtonOption/ToolbarButtonOption';
import { PageElementBorderRadiusType } from 'screens/editor/models/EditorModels';
import { useDispatch } from 'react-redux';
import { updatePageElement } from 'store/editor/action';

const BorderRadiusToolbarOption: React.FC<ToolbarOptionProps> = ({ elements, page, className }: ToolbarOptionProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showDropdown, setShowDropdown] = useState(false);
    const element = elements![0];
    const borderRadius = element.borderRadius || { type: 'none' };

    const setType = (type: PageElementBorderRadiusType) => {
        const el = element;

        if (type !== borderRadius.type) {
            dispatch(updatePageElement(page!, {
                ...el,
                borderRadius: {
                    ...borderRadius,
                    type,
                }
            }));
        }
    }

    return (
        <ToolbarButton
            className={className}
            text={t('editor.toolbar.corners')}
            onClick={() => setShowDropdown(!showDropdown)}
            icon={IconRadius}
            showDropdown={showDropdown}
        >
            <ToolbarButtonOption
                icon={IconRadius1}
                text={t('editor.toolbar.radius_straight')}
                active={borderRadius.type === 'none'}
                onClick={() => setType('none')}
            />
            <ToolbarButtonOption
                icon={IconRadius2}
                text={t('editor.toolbar.radius_round')}
                active={borderRadius.type === 'round'}
                onClick={() => setType('round')}
            />
            {/* <ToolbarButtonOption
                icon={IconRadius3}
                text={t('editor.toolbar.radius_bevel')}
                active={borderRadius.type === 'bevel'}
                onClick={() => setType('bevel')}
            /> */}
        </ToolbarButton>
    );
}

export default BorderRadiusToolbarOption;
