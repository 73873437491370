import React, { memo, useCallback, useEffect } from 'react';
import { Page, PageElement, PageElementType } from '../../models/EditorModels';
import Moveable, { OnClip, OnClipEnd, OnDrag, OnDragEnd, OnDragStart, OnResize, OnResizeEnd, OnResizeStart, OnRotate, OnRotateEnd, OnRotateStart, OnScale, OnScaleEnd, OnScaleStart } from 'react-moveable';
import EditorResolver from '../../utils/EditorResolver';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedElementsIds, setSidebarSelectedCategoryId, setSidebarSelectedChartType, setSidebarSelectedTableType, updatePageElement } from 'store/editor/action';
import { Reducers } from 'store/types';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import { MenuProvider } from 'react-contexify';
import ElementContextMenu from './components/elementContextMenu/ElementContextMenu';
import EditorUtils from '../../utils/EditorUtils';
import { EditorSidebarCategoryId } from 'screens/editor/models/SidebarModels';
import { EditorChartDataValue } from 'screens/editor/models/ChartModels';
import { EditorTableDataValue } from 'screens/editor/models/TableModels';
import store from 'store/store';

type Props = {
    page: Page;
    element: PageElement;
};

const EditorPageElement: React.FC<Props> = ({ page, element }: Props) => {

    const dispatch = useDispatch();
    const selected = useSelector<Reducers, boolean>(state => Boolean(state.editor.present.selectedElementsIds && state.editor.present.selectedElementsIds.find(id => id === element.id)));
    const isTextElementInEditingMode = useSelector<Reducers, boolean>(state => state.editorControl.isTextElementInEditingMode);
    const clipActiveElementId = useSelector<Reducers, string | null>(state => state.editorControl.clipActiveElementId);
    const clipActive = Boolean(clipActiveElementId && clipActiveElementId === element.id && element.clip && element.clip.enabled);
    const ElementTag = EditorResolver.getElementByType(element.type);

    const targetRef = React.useRef<HTMLDivElement>(null);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const moveableRef = React.useRef<Moveable>(null);

    const translate = [element.x, element.y];
    const rotation = element.rotation;
    const scale = element.scale;
    const cmId = `cm-element-${element.id}`;

    const onChange = useCallback((el: PageElement, generateThumbnail = true) => {
        dispatch(updatePageElement(page, el, generateThumbnail));
    }, [page]);

    // #region On Drag
    const onDragStart = useCallback((e: OnDragStart) => {
        e.set(translate);
    }, [translate]);
    
    const onDrag = useCallback((e: OnDrag) => {
        e.target.style.transform = `translate(${e.beforeTranslate[0]}px, ${e.beforeTranslate[1]}px) rotate(${rotation}deg) scale(${scale[0]}, ${scale[1]})`;
    }, [rotation, scale]);

    const onDragEnd = useCallback((e: OnDragEnd) => {
        const lastEvent = e.lastEvent;
        if (lastEvent) {
            onChange({
                ...element,
                width: lastEvent.width,
                height: lastEvent.height,
                x: lastEvent.beforeTranslate[0],
                y: lastEvent.beforeTranslate[1],
            });
        }
    }, [onChange, element]);
    // #endregion

    // #region On Resize
    const onResizeStart = useCallback((e: OnResizeStart) => {
        e.dragStart && e.dragStart.set(translate);
    }, [translate]);

    const onResize = useCallback((e: OnResize) => {
        const beforeTranslate = e.drag.beforeTranslate;

        e.target.style.width = `${e.width}px`;
        e.target.style.height = `${e.height}px`;
        e.target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px) rotate(${rotation}deg) scale(${scale[0]}, ${scale[1]})`;
    }, [rotation, scale]);

    const onResizeEnd = useCallback((e: OnResizeEnd) => {
        const lastEvent = e.lastEvent;

        if (lastEvent) {
            onChange({
                ...element,
                width: lastEvent.width,
                height: lastEvent.height,
                x: lastEvent.drag.beforeTranslate[0],
                y: lastEvent.drag.beforeTranslate[1],
            });
        }
    }, [onChange, element]);
    // #endregion

    // #region On Rotate
    const onRotateStart = useCallback((e: OnRotateStart) => {
        e.set(rotation);
        e.dragStart && e.dragStart.set(translate);
    }, [rotation, translate]);

    const onRotate = useCallback((e: OnRotate) => {
        const beforeTranslate = e.drag.beforeTranslate;
        const rotate = e.rotate;

        e.target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px) rotate(${rotate}deg) scale(${scale[0]}, ${scale[1]})`;
    }, [scale]);

    const onRotateEnd = useCallback((e: OnRotateEnd) => {
        const lastEvent = e.lastEvent;

        if (lastEvent) {
            onChange({
                ...element,
                rotation: lastEvent.rotate,
                x: lastEvent.drag.beforeTranslate[0],
                y: lastEvent.drag.beforeTranslate[1],
            });
        }
    }, [onChange, element]);
    // #endregion

    // #region On Scale
    const onScaleStart = useCallback((e: OnScaleStart) => {
        e.set(scale);
        e.dragStart && e.dragStart.set(translate);
    }, [scale, translate]);

    const onScale = useCallback((e: OnScale) => {
        const beforeTranslate = e.drag.beforeTranslate;
        const scale = e.scale;

        e.target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px) rotate(${rotation}deg) scale(${scale[0]}, ${scale[1]})`;
    }, [rotation, scale]);

    const onScaleEnd = useCallback((e: OnScaleEnd) => {
        const lastEvent = e.lastEvent;

        if (lastEvent) {
            onChange({
                ...element,
                scale: lastEvent.scale,
                x: lastEvent.drag.beforeTranslate[0],
                y: lastEvent.drag.beforeTranslate[1],
            });
        }
    }, [onChange, element]);
    // #endregion

    // #region On Clip
    const onClip = useCallback((e: OnClip) => {
        if (e.clipType === 'rect') {
            e.target.style.clip = e.clipStyle;
        } else {
            e.target.style.clipPath = e.clipStyle;
        }
    }, [onChange, element]);

    const onClipEnd = useCallback((e: OnClipEnd) => {
        const lastEvent = e.lastEvent;

        if (lastEvent) {
            // TODO: isto não pode estar aqui, só devia de mudar o estado dele depois de finalizar ou meter o active em outra store
            onChange({
                ...element,
                clip: {
                    ...(element.clip ? element.clip : { croppedMedia: null }),
                    enabled: true,
                    type: lastEvent.clipType,
                    style: lastEvent.clipStyle,
                    styles: lastEvent.clipStyles
                },
            }, false);
        }
    }, [onChange, element]);
    // #endregion

    // #region On Element Click
    const onMouseDown = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!selected) {
            Logger.info(LOGGER_LOG_TYPE.EDITOR, 'EditorPageElement ElementTag onMouseDown & !selected (select element)');
            
            let elemsToSelect = [element.id];

            if (event.shiftKey) {
                const [selectedElements, selectedPage] = EditorUtils.getSelectedElementsFromState(store.getState().editor.present);
                if (selectedPage && selectedPage.id === page.id && selectedElements && selectedElements.length > 0) {
                    elemsToSelect = [...selectedElements.map(x => x.id), ...elemsToSelect];
                }
            }
            
            dispatch(setSelectedElementsIds(page.id, elemsToSelect));
        }
    }, [selected, page.id, element.id]);

    const onClick = useCallback(() => {
        if (element.type === PageElementType.CHART) {
            dispatch(setSidebarSelectedCategoryId(EditorSidebarCategoryId.CHARTS));
            dispatch(setSidebarSelectedChartType((element.value as EditorChartDataValue)?.type || null));
        } else if(element.type === PageElementType.TABLE) {
            dispatch(setSidebarSelectedCategoryId(EditorSidebarCategoryId.TABLES));
            dispatch(setSidebarSelectedTableType((element.value as EditorTableDataValue)?.type || null));
        }
    }, [element.type, element.value]);
    // #endregion

    const activateMoveable = true;
    const isClippable = activateMoveable && clipActive && element.type === PageElementType.IMAGE;

    const updateRect = () => {
        setTimeout(() => {
            moveableRef.current?.updateRect();
        }, 100);
    }

    // This fixes a bug when you enable the clippable of something with a clip
    useEffect(() => {
        if (isClippable) {
            updateRect();
        }
    }, [isClippable]);

    useEffect(() => {
        updateRect();
    }, [element.updateRefreshNumber]);

    if (ElementTag) {
        return (
            <MenuProvider id={cmId} storeRef={false} data={element}>
                <div ref={containerRef}>
                    <ElementTag
                        element={element}
                        page={page}
                        ref={targetRef}
                        selected={selected}
                        onMouseDown={onMouseDown}
                        onClick={onClick}
                        id={EditorUtils.getPageElementId(element.id)}
                    /> 
                    <Moveable
                        // General
                        target={targetRef}
                        ref={moveableRef}
                        origin={activateMoveable}
                        keepRatio={element.keepRatio}
                        individualGroupable={true}
                        className={`moveable-element ${selected && !isTextElementInEditingMode ? 'moveable-selected' : ''}`}
                        container={null}

                        // Snap
                        snappable={true}

                        // Drag
                        draggable={activateMoveable && !element.locked}
                        onDragStart={onDragStart}
                        onDrag={onDrag}
                        onDragEnd={onDragEnd}

                        // Resize
                        resizable={activateMoveable}
                        onResizeStart={onResizeStart}
                        onResize={onResize}
                        onResizeEnd={onResizeEnd}

                        // Rotation
                        rotatable={activateMoveable}
                        onRotateStart={onRotateStart}
                        onRotate={onRotate}
                        onRotateEnd={onRotateEnd}

                        // Scale
                        scalable={activateMoveable}
                        onScaleStart={onScaleStart}
                        onScale={onScale}
                        onScaleEnd={onScaleEnd}

                        // Clipable
                        clippable={isClippable}
                        defaultClipPath={element.clip?.type || 'rect'}
                        clipRelative={false}
                        clipArea={false}
                        onClip={onClip}
                        onClipEnd={onClipEnd}
                    />
                    <ElementContextMenu id={cmId} page={page} element={element} />
                </div>
            </MenuProvider>
        );
    }

    return <div>ELEMENT TYPE NOT FOUND</div>;
}

export default memo(EditorPageElement);
