import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { visitPage } from 'store/analytics/action';

const Analytics = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(visitPage(history.location.pathname));
    }, []);

    useEffect(() => {
        const unsubscribe = history.listen(location => {
            dispatch(visitPage(location.pathname));
        });

        return unsubscribe;
    }, [history, dispatch]);

    return null;
}

export default Analytics;
