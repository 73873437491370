import React from 'react';
import styles from './Input.module.scss';


type Props = {
    text?: string;
    content?: string;
    titleClassName?: string;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const InputWithTextComponent = ({ text, content, titleClassName, children, ...props }: Props, ref: React.ForwardedRef<HTMLInputElement>) => {


    return (
        <div
            {...props}
            className={`${styles.inputWithTextComponent} `} >

            <div className={styles.info}>
                <span className={`${styles.infoTitle} ${titleClassName || ''}`}>{text}</span>
                <span className={styles.infoContent}>{content}</span>
                <div>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default React.forwardRef<HTMLInputElement, Props>(InputWithTextComponent);
