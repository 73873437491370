import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MediaDto } from '../../../../api/medias/models/MediaDto';
import OverlayedModal from '../../../../common/components/overlayedModal/OverlayedModal';
import styles from './MediaModal.module.scss';
import IconClose from '../../../../assets/svgs/Cross-bleu2-contour.svg';
import RemoveMessage from 'common/components/removeMessage/RemoveMessage';
import EditableLabel from 'common/components/editableLabel/EditableLabel';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import { addToast } from 'common/services/Toaster';
import MediasService from 'api/medias/MediasService';
import InputWithTextComponent from 'common/components/input/InputWithTextComponent';
import Tags from 'common/components/tags/Tags';
import { Tag } from 'react-tag-autocomplete';
import Button from 'common/components/button/Button';
import IconDownload from 'assets/svgs/Download-blanc.svg';
import { MediaViewModel } from 'api/medias/models/MediaViewModel';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import { TagsSearchCriteria } from 'api/tags/models/TagsSearchCriteria';
import TagsService from 'api/tags/TagsService';
import useEffectOnChange from 'common/hooks/useEffectOnChange';
import CustomFile from 'common/models/CustomFile';


type Props = {
  isVisible: boolean;
  selectedMedia: MediaDto | null;
  onCloseModal: () => void;
  onRemoveMedia: () => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const MediaModal: React.FC<Props> = ({ isVisible, onCloseModal, selectedMedia, onRemoveMedia }: Props) => {
  const { t } = useTranslation();
  

  const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
  const companyId = loggedUser?.companyId;

  const [listTags, setListTags] = useState<Tag[]>([]);
  const [currentTags, setCurrentTags] = useState<Tag[]>([]);
  const [tags, setTags] = useState<string[]>(selectedMedia?.tags || []);
  const [startChange, setStartChange] = useState<boolean>(false);

  const criteriaTags: TagsSearchCriteria = {};

  const backgroundImage: React.CSSProperties = {
    backgroundImage: 'url(\'' + selectedMedia?.url + '\')',
  };

  const getData = async () => {
    try {
      criteriaTags.companyId = companyId;

      Loading.show();
      /*get media para ter tags atualizadas*/
      const mediaItem = selectedMedia?.id ? await MediasService.getById(selectedMedia?.id) : null;
      if (mediaItem && selectedMedia) {
        if (mediaItem.tags) {
          setTags(mediaItem.tags.map(t => t));
        }
      }

      const tagsListResult = await TagsService.getList(criteriaTags);
      setListTags(tagsListResult.map(x => ({ id: x.id, name: x.description })));

      setStartChange(true);

      Loading.hide();

    } catch (error) {
      Logger.error(LOGGER_LOG_TYPE.MEDIAS, `Couldn't get media`, error);
      addToast(t('common.messages.error_load_info'), { appearance: 'error' });
    }
  }

  const update = async () => {
    try {
      Loading.show();

      const model: MediaViewModel = { id: selectedMedia?.id, fileName: selectedMedia?.fileName, tags: tags };
      const media: CustomFile[] = [];
      await MediasService.update(model, media );

      Loading.hide();

    } catch (error) {

      Logger.error(LOGGER_LOG_TYPE.MEDIAS, `Couldn't update media`, error);
      addToast(t('common.messages.error_load_info'), { appearance: 'error' });
      Loading.hide();

    }
  }

  const onChangeText = (text: string) => {
    if (text && selectedMedia?.fileName) {
      selectedMedia.fileName = text;
      update();
    }
  }

 
  useEffectOnChange(() => {
    if (startChange) {
      update();
    }
  }, [tags]);

  useEffect(() => {
    getData();
  }, [selectedMedia]);

  return (
    <OverlayedModal isOpen={isVisible && selectedMedia != null}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.imageContainer}>
            <div className={styles.image}>
              <div className={styles.imageContent} style={backgroundImage}></div>
            </div>
          </div>
        </div>

        <div className={styles.right}>
          <div className={styles.header}>
            <div className={styles.title}>
              {t('medias.modal.informations')}
            </div>
            <img src={IconClose} onClick={() => onCloseModal()} className={styles.icon} />
          </div>

          <div className={styles.content}>
            <h2 className={styles.h2}>
              <EditableLabel
                text={selectedMedia?.fileName ? selectedMedia.fileName : ''}
                onChangeText={onChangeText}
                placeholder={t('documents.document_name')} />
            </h2>
            <div className={styles.infos}>
              <InputWithTextComponent
                text={t('documents.modal.created_at')}
                content={selectedMedia?.timestamp ? new Date(selectedMedia.timestamp).toLocaleDateString() : ''}
                placeholder={t('common.name')}
              />

              <InputWithTextComponent
                text={t('documents.modal.created_by')}
                content={selectedMedia?.createdByName ? selectedMedia.createdByName : ''}
                name="name"
                placeholder={t('common.name')}
              />
            </div>
            <div className={styles.tags}>
              <Tags
                placeholderText={t('documents.tags')}
                onAddition={tag => {
                  setTags([
                    ...(tags || []),
                    tag.name
                  ]);

                  if (!(listTags || []).find(x => x.name == tag.name) && !(currentTags || []).find(x => x.name == tag.name)) {
                    setCurrentTags([...(currentTags || []), { id: '', name: tag.name }])
                  }
                }}
                onDelete={tagIndex => {
                  const tagNameRemove = (tags || []).filter((_, i) => i === tagIndex)[0];

                  setTags((tags || []).filter((_, i) => i !== tagIndex));

                  if ((currentTags || []).find(x => x.name == tagNameRemove)) {
                    setCurrentTags((currentTags || []).filter(x => x.name !== tagNameRemove));
                  }
                }}
                tags={(tags || []).map((x: string) => ({ id: x, name: x }))}
                allowNew={true}
                addOnBlur={true}
                suggestions={listTags.concat(currentTags)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.remove} onClick={() => onRemoveMedia()}>
          <RemoveMessage
            style={{ width: '100%' }}
            message={t('medias.modal.remove')}
            onElementClick={() => onRemoveMedia()} />
        </div>

        <div className={styles.buttonsContainer} >

          <Button
            type="button"
            text={t('documents.modal.download').toUpperCase()}
            className={styles.buttonDownload}
            onClick={() => window.open(selectedMedia?.url, '_blank')}>
            <img src={IconDownload} className={styles.icon} />
          </Button>
        </div>
      </div>
    </OverlayedModal>
  );
}

export default MediaModal;
