import React from 'react';
import { useDispatch } from 'react-redux';
import { updatePageElement } from 'store/editor/action';
import { ToolbarOptionProps } from '../../../../utils/EditorProps';
import ToolbarItemIconGroup from '../toolbarItemIconGroup/ToolbarItemIconGroup';
import ToolbarItemIconGroupItem from '../toolbarItemIconGroup/ToolbarItemIconGroupItem';
import { FaBold, FaItalic, FaStrikethrough, FaUnderline } from 'react-icons/fa';
import EditorUtils from 'screens/editor/utils/EditorUtils';
import store from 'store/store';

const TextFormatToolbarOption: React.FC<ToolbarOptionProps> = ({ page, elements, className }: ToolbarOptionProps) => {

    const dispatch = useDispatch();
    const element = elements![0];

    const toggleBold = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        evt.preventDefault();
        evt.stopPropagation();

        const isTextElementInEditingMode = store.getState().editorControl.isTextElementInEditingMode;
        if (isTextElementInEditingMode) {
            document.execCommand('bold', false, undefined);
        } else {
            const value = EditorUtils.toggleTextTag(element?.value as string, 'b');
            dispatch(updatePageElement(page!, {
                ...element,
                value,
            }));
        }
    }

    const toggleItalic = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        evt.preventDefault();
        evt.stopPropagation();

        const isTextElementInEditingMode = store.getState().editorControl.isTextElementInEditingMode;
        if (isTextElementInEditingMode) {
            document.execCommand('italic', false, undefined);
        } else {
            const value = EditorUtils.toggleTextTag(element?.value as string, 'i');
            dispatch(updatePageElement(page!, {
                ...element,
                value,
            }));
        }
    }

    const toggleUnderline = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        evt.preventDefault();
        evt.stopPropagation();

        const isTextElementInEditingMode = store.getState().editorControl.isTextElementInEditingMode;
        if (isTextElementInEditingMode) {
            document.execCommand('underline', false, undefined);
        } else {
            const value = EditorUtils.toggleTextTag(element?.value as string, 'u');
            dispatch(updatePageElement(page!, {
                ...element,
                value,
            }));
        }
    }

    const toggleStrikethrough = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        evt.preventDefault();
        evt.stopPropagation();

        const isTextElementInEditingMode = store.getState().editorControl.isTextElementInEditingMode;
        if (isTextElementInEditingMode) {
            document.execCommand('strikeThrough', false, undefined);
        } else {
            const value = EditorUtils.toggleTextTag(element?.value as string, 'strike');
            dispatch(updatePageElement(page!, {
                ...element,
                value,
            }));
        }
    }

    return (
        <ToolbarItemIconGroup className={className}>
            <ToolbarItemIconGroupItem iconElem={FaBold} onClick={toggleBold} />
            <ToolbarItemIconGroupItem iconElem={FaItalic} onClick={toggleItalic} />
            <ToolbarItemIconGroupItem iconElem={FaUnderline} onClick={toggleUnderline} />
            <ToolbarItemIconGroupItem iconElem={FaStrikethrough} onClick={toggleStrikethrough} />
        </ToolbarItemIconGroup>
    );
}

export default TextFormatToolbarOption;
