import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from 'common/services/Toaster';
import Loading from '../../common/services/Loading';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import PageHeader from '../../common/components/pageHeader/PageHeader';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from '../../common/components/pagination/Pagination';
import { MediaViewModel } from '../../api/medias/models/MediaViewModel';
import { MediaSearchCriteria } from '../../api/medias/models/MediaSearchCriteria';
import InfinityScroll from '../../common/components/infinityScroll/InfinityScroll';
import styles from './MediasScreen.module.scss';

import MediaItem from './components/media/MediaItem';
import { Reducers } from '../../store/types';
import { useSelector } from 'react-redux';
import { UserProfile } from '../../api/account/models/UserProfile';
import { MediaDto } from '../../api/medias/models/MediaDto';
import CustomFile from '../../common/models/CustomFile';
import MediaService from '../../api/medias/MediasService';

import Logger from '../../common/services/Logger';
import { LOGGER_LOG_TYPE, MIMETYPES_IMAGE } from '../../Config';
import MediaModal from './components/mediaModal/MediaModal';
import QuestionYesNo from '../../common/components/questionYesNo/QuestionYesNo';

export type OpenDialogType = 'details' | 'edit' | 'new';

type Props = {
    name?: string;
};

const MediasScreen: React.FC<Props> = ({ name }: Props) => {
    const { t } = useTranslation();
    

    const [medias, setMedias] = useState<MediaDto[]>([]);
    const [totalItems, setTotalItems] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const [criteria, setCriteria] = useState<MediaSearchCriteria>({ page: 1, itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE, companyId: userProfile?.companyId ?? '' });
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [currentSelectedMedia, setCurrentSelectedMedia] = useState<MediaDto | null>(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState('');

    const getData = async () => {
        try {
            Loading.show();

            const mediasList = await MediaService.getList({
                ...criteria,
                orderColumn: 'file_name',
                orderBy: 'asc',
                name: searchValue
            });

            setMedias([
                ...(criteria.page == 1 ? [] : medias),
                ...mediasList?.items
            ]);

            setTotalItems(mediasList?.totalItems || 1);
            setTotalPages(mediasList?.totalPages || 1);

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.MEDIAS, `Couldn't get the medias`, error);
            Loading.hide();
        }
    }

    const uploadFiles = async (event: any) => {
        const images: CustomFile[] = [];
        for (let size = 0; size < event.target.files.length; size++) {
            const file: CustomFile = event.target.files[size];
            images.push(file);
        }

        if (images.length > 0) {
            Loading.show();

            try {
                const model: MediaViewModel = { companyId: userProfile?.companyId ?? '', tags: [] };
                await MediaService.create(model, images);
                setMedias([]);
                criteria.page = 1;
                Loading.hide();
                getData();
                event.target.value = null; //to clear the file input


            } catch (error) {
                addToast(t('common.messages.record_save_error'), { appearance: 'error' });
                Logger.error(LOGGER_LOG_TYPE.MEDIAS, `Couldn't save the medias`, error);
                Loading.hide();
            }
        }
    }

    const importFile = () => {
        const inputMedias = document.getElementById('uploadFiles');
        if (inputMedias) {
            inputMedias.click();
        }
    }

    const onModalVisibilityChange = (selectedMedia: MediaDto) => {
        setModalOpen(!isModalOpen);
        setCurrentSelectedMedia(selectedMedia);
    }

    const onCloseModal = () => {
        setModalOpen(false);
        setDeleteModalOpen(false);
        setCurrentSelectedMedia(null);
    }

    const onRemoveMedia = async () => {
        setModalOpen(false);
        setDeleteModalOpen(true);
    }

    const onCloseDeleteModal = () => {
        setDeleteModalOpen(false);
        setModalOpen(true);
    }

    const removeMedia = async () => {
        Loading.show();

        try {
            await MediaService.remove(currentSelectedMedia?.id ?? '');
            onCloseModal();
            setCriteria({
                ...criteria,
                page: 1,
            });
            Loading.hide();
            addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
        } catch (error) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.MEDIAS, `Couldn't remove the medias`, error);
            Loading.hide();
        }
    }

    const onChangeSearch= (text: string) => {
        setSearchValue(text);
        setCriteria({
            ...criteria,
            page: 1
        });
       
    }

    useEffect(() => {
        getData();
    }, [criteria, searchValue]);


    return (
        <ScreenTitle title={t('menu.medias')}>
            <div>
                <PageHeader title={t('medias.title')} onChangeSearch={onChangeSearch} searchValue={searchValue}></PageHeader>
                <input type="file" id="uploadFiles" hidden multiple name="myImage" accept={MIMETYPES_IMAGE} className="multiple-upload" onChange={uploadFiles} />

                <div className={styles.infiniteScrollContainer}>
                    <hr className={styles.divider} />

                    <div className={styles.content}>
                        <InfinityScroll
                            totalItems={medias.length}
                            executeOnScroll={() => {
                                setCriteria({
                                    ...criteria,
                                    page: criteria.page + 1
                                });
                            }}
                            continueScroll={medias.length < totalItems}
                            elementIdToScroll="scrollHere"
                        >
                            <div className={styles.list}>
                                <MediaItem onClick={() => importFile()} />
                                {
                                    medias.map((value, index) => {
                                        return <MediaItem key={index} media={value} onClick={() => onModalVisibilityChange(value)}></MediaItem>
                                    })
                                }
                            </div>
                        </InfinityScroll>
                    </div>
                </div>

                {isModalOpen && <MediaModal isVisible={isModalOpen} onCloseModal={() => onCloseModal()} selectedMedia={currentSelectedMedia} onRemoveMedia={() => onRemoveMedia()} />}
                <QuestionYesNo
                    title={t('common.remove')}
                    message={t('medias.delete_modal.remove', { filename: currentSelectedMedia?.fileName })} isVisible={isDeleteModalOpen}
                    onYes={() => removeMedia()}
                    onNo={() => onCloseDeleteModal()} />
            </div>
        </ScreenTitle>
    );
}

export default MediasScreen;