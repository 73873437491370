import { useEffect, useState } from 'react';

type display = 'auto' | 'block' | 'swap' | 'fallback' | 'optional';

interface Font {
    font: string;
    weights?: (string | number)[];
}

interface Props {
    fonts: Font[];
    subsets?: string[];
    display?: display | null;
}

export const createLinkUrl = (fonts: Font[], subsets?: string[], display?: display | null) => {
    const families = (fonts as any).reduce((acc: any, font: any) => {
        const family = font.font.replace(/ +/g, '+');
        const weights = (font.weights || []).join(',');

        return [
            ...acc,
            family + (weights && `:${weights}`),
        ];
    }, []).join('|');

    let href = `https://fonts.googleapis.com/css?family=${families}`;

    if (subsets && Array.isArray(subsets) && subsets.length > 0) {
        href += `&subset=${subsets.join(',')}`;
    }

    if (display) {
        href += `&display=${display}`;
    }

    return href;
};

const createLink = (fonts: Font[], subsets?: string[], display?: display | null) => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = createLinkUrl(fonts, subsets, display);
    return link;
};

const GoogleFontLoader = ({ fonts, subsets, display = null }: Props) => {
    const [link, setLink] = useState(createLink(fonts, subsets, display));

    useEffect(() => {
        document.head.appendChild(link);
        return () => {
            document.head.removeChild(link);
        };
    }, [link]);

    useEffect(() => {
        setLink(createLink(fonts, subsets, display));
    }, [fonts, subsets, display]);

    return null;
};

export default GoogleFontLoader;