import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import EditorInternalService from 'screens/editor/utils/EditorInternalService';
import AutoSave from 'common/components/autoSave/AutoSave';
import { Reducers } from 'store/types';
import { EditorFont, Page } from '../../models/EditorModels';

const EditorAutoSave: React.FC = () => {

    const statePages = useSelector<Reducers, Page[]>(state => state.editor.present.pages);
    const stateFonts = useSelector<Reducers, (EditorFont[] | undefined)>(state => state.editor.present.fonts);

    const stateToCheck = useMemo(() => () => {
        return { pages: statePages, fonts: stateFonts };
    }, [statePages, stateFonts])
    
    const onSave = async () => {
        await EditorInternalService.quickSave();
    }

    return (
        <AutoSave data={stateToCheck} onSave={onSave} interval={2000} />
    );
}

export default EditorAutoSave;
