/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, { CSSProperties, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Input.module.scss';
import CloseIcon from 'assets/svgs/Cross-bleu2.svg';

type InputPreset = 'default' | 'login';

export type Props = {
    preset?: InputPreset;
    icon?: any;
    onChangeText?: (text: string) => void;
    cleanInputIcon?: () => void;
    hasShadow?: boolean;
    containerStyle?: CSSProperties | null;
    isSearchPanel?: boolean;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, Props>(({ preset, icon, onChangeText, cleanInputIcon, hasShadow = false, containerStyle = null, isSearchPanel, ...props }, ref) => {
    const presetClassName = preset ? styles[preset] : styles.default;

    const { t } = useTranslation();
    const [textLength, setTextLength] = useState<number>(0);

    const onChange = (text: any) => {
        onChangeText && onChangeText(text);
    };

    const border: React.CSSProperties = {
        border: '2px solid #80a9fd',
        borderRight: isSearchPanel ? 'none' : '2px solid #80a9fd',
        ...containerStyle
    }

    const shadow: React.CSSProperties = {
        boxShadow: '-7px 8px 23px 0px rgba(0, 83, 252, 0.2)',
        borderRight: isSearchPanel ? 'none' : '2px solid #80a9fd',
        ...containerStyle
    }

    return (
        <div>
            { !icon ?
                <input
                    ref={ref}
                    type={props.type || 'text'}
                    {...props}
                    onChange={e => {
                        setTextLength(e.currentTarget.value.length);
                        onChangeText && onChange(e.currentTarget.value)
                    }}
                    className={`${styles.inputNormal} ${presetClassName} ${props.className || ''} ${props.disabled ? styles.disabled : ''} `}
                /> :
                <div className={styles.contentWithIcon} style={ hasShadow ? shadow : border }>
                    <img src={icon} className={styles.imageIcon}></img>
                    <input
                        ref={ref}
                        type={props.type || 'text'}
                        {...props}
                        onChange={e => {
                            setTextLength(e.currentTarget.value.length);
                            onChangeText && onChange(e.currentTarget.value)
                        }}
                        className={`${styles.inputIcon} ${props.disabled ? styles.disabled : ''} `}
                    />
                    {textLength > 0 && <img src={CloseIcon} className={styles.imageIconClose} onClick={() => { 
                        cleanInputIcon && cleanInputIcon(); 
                        setTextLength(0);
                        } }></img> }
                </div>
            }
            { textLength === props.maxLength && <div className={styles.maxLength}>{t('common.field_max_length', { value: textLength })}</div>}
        </div>
    );
});

export default memo(Input);
