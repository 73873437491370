import React from 'react';
import ReactTags, { ReactTagsProps } from 'react-tag-autocomplete';

export type Props = {
    type?: 'default' | 'custom';
} & ReactTagsProps;

const Tags = ({ type = 'default', ...props }: Props, ref: any) => {
    return (
        <ReactTags
            {...props}
            ref={ref}
            autoresize={true}
            classNames={{
                root: 'react-tags ' + type,
                rootFocused: 'is-focused',
                selected: 'react-tags__selected',
                selectedTag: 'react-tags__selected-tag',
                selectedTagName: 'react-tags__selected-tag-name',
                search: 'react-tags__search' ,
                searchWrapper: 'react-tags__search-wrapper',
                searchInput: 'react-tags__search-input',
                suggestions: 'react-tags__suggestions',
                suggestionActive: 'is-active',
                suggestionDisabled: 'is-disabled',
            } as any}
        />
    );
};

export default React.forwardRef<ReactTags, Props>(Tags);
