import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './BoxItem.module.scss';
import { DocumentViewModel } from '../../../../../api/documents/models/DocumentViewModel';
import Label from '../../../../../common/components/label/Label';
import Background from '../../../../../assets/img/no_image.png';
import { DocumentVisibility, DocumentVisibilityAction } from 'api/documents/enums/DocumentVisibility';
import IconEye from 'assets/svgs/Eye-vert-blanc.svg';
import IconSlashEye from 'assets/svgs/EyeBarre-blanc.svg';
import IconPlus from 'assets/svgs/Plus-green-outlines.svg';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import { IS_ADMIN } from 'Config';
import { DocumentOrientation } from 'api/documents/enums/DocumentOrientation';
import { DocumentType } from 'api/documents/enums/DocumentType';

type Props = {
    document?: DocumentViewModel | null;
    onClick?: () => void;
    newLabelText?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const BoxItem: React.FC<Props> = ({ document, onClick, newLabelText, ...props }: Props) => {
    const { t } = useTranslation();
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const isAdmin: boolean = IS_ADMIN(loggedUser);
    const imageCacheKey = useRef(new Date().getTime());

    let noImage = false;
    if (document == null || document?.thumbnailMediaUrl == null) {
        noImage = true;
    }

    const visibilityClass = (document?.visibilityAction == DocumentVisibilityAction.ACCEPTED && document.visibility == DocumentVisibility.PUBLIC) ? styles.accepted : document?.visibilityAction == DocumentVisibilityAction.REJECTED ? styles.rejected : undefined;

    return (
        <div className={styles.boxContainer} onClick={onClick} {...props}>
            {/* CREATE NEW */}
            {!document && <div className={styles.create}>
                <img className={styles.icon} src={IconPlus} />
                <Label className={styles.label} >{newLabelText ? newLabelText : t('documents.add_document')}</Label>
            </div>}

            {/* SHOW DOCUMENT ITEM */}
            {document && <>
                {noImage && <div className={styles.boxItem} style={{ backgroundColor: 'white', backgroundImage: 'url(' + Background + ')', backgroundSize: '10rem', backgroundPosition: 'center', height: document.orientation === DocumentOrientation.PORTRAIT ? '18rem' : '9rem' }}></div>}
                {!noImage && <div className={styles.boxItem} style={{ backgroundImage: 'url(' + document?.thumbnailMediaUrl + '?_=' + imageCacheKey.current + ')', height: document.orientation === DocumentOrientation.PORTRAIT ? '18rem' : '9rem' }}></div>}

                {document?.visibilityAction && !isAdmin && <div className={`${styles.badge} ${visibilityClass}`}>
                    {document.visibilityAction == DocumentVisibilityAction.ACCEPTED && document.visibility == DocumentVisibility.PUBLIC && <img className={styles.iconEye} src={IconEye} />}
                    {document.visibilityAction == DocumentVisibilityAction.REJECTED && document.visibility == DocumentVisibility.PRIVATE && <img className={styles.iconEye} src={IconSlashEye} />}
                </div>}

                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0.5rem' }}>
                    <label className={styles.documentName}> {document?.name} </label>
                    <label className={styles.documentInfo}> {document?.createdDate.toLocaleDateString()} </label>
                    <label className={styles.documentInfo}> {document?.userCreatedName} </label>
                </div>
            </>}
        </div>
    );
}

export default BoxItem;