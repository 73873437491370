import React from 'react';
import { Item, Menu, Separator, contextMenu } from 'react-contexify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { removePageElements, updatePage } from 'store/editor/action';
import { Page, PageElement } from '../../../../models/EditorModels';
import arrayMove from 'array-move';
import styles from './ElementContextMenu.module.scss';
import IconTop from 'assets/svgs/toolbar/Top.svg';
import IconBottom from 'assets/svgs/toolbar/Bottom.svg';
import IconBackgroud from 'assets/svgs/toolbar/Backgroud.svg';
import IconForeground from 'assets/svgs/toolbar/Foreground.svg';
import IconTrash from 'assets/svgs/toolbar/Trash.svg';
import IconLock from 'assets/svgs/toolbar/Lock.svg';

type Props = {
    id: string;
    page: Page;
    element: PageElement;
};

const ElementContextMenu: React.FC<Props> = ({ id, page, element }: Props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const bringBackward = () => {
        const oldIndex = page.elements.findIndex(el => el.id === element.id);
        const newIndex = oldIndex > 0 ? oldIndex - 1 : 0;
        const newElements = arrayMove(page.elements, oldIndex, newIndex);
        contextMenu.hideAll();

        dispatch(updatePage({
            ...page,
            elements: newElements,
        }));
    }

    const bringBackwardMax = () => {
        const oldIndex = page.elements.findIndex(el => el.id === element.id);
        const newElements = arrayMove(page.elements, oldIndex, 0);
        contextMenu.hideAll();

        dispatch(updatePage({
            ...page,
            elements: newElements,
        }));
    }

    const bringForward = () => {
        const oldIndex = page.elements.findIndex(el => el.id === element.id);
        const newIndex = oldIndex < page.elements.length ? oldIndex + 1 : page.elements.length;
        const newElements = arrayMove(page.elements, oldIndex, newIndex);
        contextMenu.hideAll();

        dispatch(updatePage({
            ...page,
            elements: newElements,
        }));
    }

    const bringForwardMax = () => {
        const oldIndex = page.elements.findIndex(el => el.id === element.id);
        const newElements = arrayMove(page.elements, oldIndex, page.elements.length);
        contextMenu.hideAll();

        dispatch(updatePage({
            ...page,
            elements: newElements,
        }));
    }

    const remove = () => {
        dispatch(removePageElements(page, [element]));
    }

    const lockUnlock = () => {
        contextMenu.hideAll();
        element.locked = !element.locked;
        dispatch(updatePage(page));
    }

    return (
        <Menu id={id}>
            <Item onClick={bringForwardMax} >
                <div className={styles.menuItem}>
                    <img src={IconBackgroud} className={styles.menuItemIcon} />
                    <span>{t('editor.bring_forward_max')}</span>
                </div>
            </Item>
            <Item onClick={bringForward} >
                <div className={styles.menuItem}>
                    <img src={IconTop} className={styles.menuItemIcon} />
                    <span>{t('editor.bring_forward')}</span>
                </div>
            </Item>
            <Item onClick={bringBackward}>
                <div className={styles.menuItem}>
                    <img src={IconBottom} className={styles.menuItemIcon} />
                    <span>{t('editor.bring_backward')}</span>
                </div>
            </Item>
            <Item onClick={bringBackwardMax}>
                <div className={styles.menuItem}>
                    <img src={IconForeground} className={styles.menuItemIcon} />
                    <span>{t('editor.bring_backward_max')}</span>
                </div>
            </Item>
            <Separator />
            <Item onClick={remove}>
                <div className={`${styles.menuItem} ${styles.menuItemDanger}`}>
                    <img src={IconTrash} className={styles.menuItemIcon} />
                    <span>{t('editor.delete')}</span>
                </div>
            </Item>
            <Separator />
            <Item onClick={lockUnlock}>
                <div className={`${styles.menuItem}`}>
                    <img src={IconLock} className={styles.menuItemIcon} />
                    <span>{t(element.locked ? 'editor.unlock' : 'editor.lock')}</span>
                </div>
            </Item>
        </Menu>
    );
}

export default ElementContextMenu;
