import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './IconsContent.module.scss';
import Searchbar from 'common/components/searchbar/Searchbar';
import BoxCategory from '../../../../../../common/components/boxCategory/BoxCategory';
import Masonry from 'react-masonry-css'
import DraggableContentItem from '../draggableContentItem/DraggableContentItem';
import EditorElementGenerator from '../../../../utils/EditorElementGenerator';
import EditorUtils from '../../../../utils/EditorUtils';
import { PageElement, PageElementType } from '../../../../models/EditorModels';
import { useDispatch } from 'react-redux';
import { updatePageElement } from 'store/editor/action';
import RenderSVG from 'common/components/renderSVG/RenderSVG';
import store from 'store/store';
import colors from 'styles/export/colors.module.scss';
import { useDebouncedCallback } from 'use-debounce';
import { PagedSearchCriteria } from 'api/common/types/PagedSearchCriteria';
import FlaticonService from 'api/flaticon/FlaticonService';
import Loading from 'common/services/Loading';
import InfinityScroll from 'common/components/infinityScroll/InfinityScroll';
import ScrollShadow from 'common/components/scrollShadow/ScrollShadow';
import IconSaveDanger from 'assets/svgs/Cross-danger.svg';
import IconSuccess from 'assets/svgs/Check-bleu2.svg';
import { remove as diacriticsRemove } from 'diacritics';
import IconfinderService from 'api/iconfinder/IconfinderService';
import { IconfinderResponseDto } from 'api/iconfinder/models/IconfinderResponseDto';

interface IconItem {
    id: string;
    svg: string,
    preview: string,
    width: number;
    height: number;
}

type Props = {
};

const IconsContent: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState<string>('');
    const dispatch = useDispatch();

    const defaultPage = 1;
    const defaultItemsPerPage = 60;
    const [flaticonCriteria, setFlaticonCriteria] = useState<PagedSearchCriteria>({ page: defaultPage, itemsPerPage: defaultItemsPerPage });
    const [flaticonIcons, setFlaticonIcons] = useState<IconItem[]>([]);
    const [flaticonLoadMore, setFlaticonLoadMore] = useState<boolean>(true);
    const [flaticonErrorCode, setFlaticonErrorCode] = useState<string>('');
    const [tryingFlaticon, setTryingFlaticon] = useState(false);

    const escapeSearchTerm = (value: string) => {
        value = diacriticsRemove(value);
        value = value.replace(/[^a-zA-Z0-9 ]/g, '');
        return value;
    }

    const debounced = useDebouncedCallback(value => {
        value = escapeSearchTerm(value);
        setSearchValue(value);
        setFlaticonIcons([]);

        setFlaticonCriteria({
            ...flaticonCriteria,
            page: 1
        });
    }, 500);

    const onChangeText = (value: string) => {
        debounced(value);
    }

    // const loadFlaticonIcons = async () => {
    //     try {
    //         const res = searchValue
    //             ? await FlaticonService.searchIcons(searchValue, flaticonCriteria)
    //             : await FlaticonService.itemsIcons(flaticonCriteria);

    //         const icons: IconItem[] = (res.data || []).map(r => ({
    //             id: r.id.toString(),
    //             svg: r.images.svg,
    //             width: 80,
    //             height: 80
    //         }));

    //         setFlaticonIcons([
    //             ...flaticonIcons,
    //             ...icons
    //         ]);

    //         const loadMoreValue = Math.ceil(res.metadata.total / (flaticonCriteria.itemsPerPage ?? defaultItemsPerPage)) > (flaticonCriteria.page ?? defaultPage);
    //         setFlaticonLoadMore(loadMoreValue);
    //         setFlaticonErrorCode('');
    //         setTryingFlaticon(false);

    //         Loading.hide();
    //     } catch (error) {
    //         setFlaticonErrorCode(error.status || 500);
    //         setTryingFlaticon(false);
    //         Loading.hide();
    //     }
    // }

    const loadFlaticonIcons = async () => {
        try {
            const res: IconfinderResponseDto = await IconfinderService.searchIcons(searchValue || 'icon', flaticonCriteria)

            const icons: IconItem[] = (res.icons || []).map(r => ({
                id: r.icon_id.toString(),
                svg: r.vector_sizes.sort((a, b) => b.size-a.size)[0].formats.find(x => x.format == 'svg')?.download_url || '',
                preview: r.raster_sizes.sort((a, b) => b.size-a.size)[0].formats[0].preview_url || '',
                width: 40,
                height: 40
            }));

            setFlaticonIcons([
                ...flaticonIcons,
                ...icons
            ]);

            const loadMoreValue = Math.ceil(res.total_count / (flaticonCriteria.itemsPerPage ?? defaultItemsPerPage)) > (flaticonCriteria.page ?? defaultPage);
            setFlaticonLoadMore(loadMoreValue);
            setFlaticonErrorCode('');
            setTryingFlaticon(false);

            Loading.hide();
        } catch (error) {
            setFlaticonErrorCode(error.status || 500);
            setTryingFlaticon(false);
            Loading.hide();
        }
    }

    const retryFlaticon = () => {
        setTryingFlaticon(true);
        setTimeout(() => loadFlaticonIcons(), 100);
    }

    const createIconElement = (iconItem: IconItem): PageElement => {
        return EditorElementGenerator.createSvgElement(iconItem.svg, iconItem.width, iconItem.height, colors.primary, iconItem.id);
    }

    const onIconClick = async (icon: IconItem) => {
        const [selectedElement, selectedPage] = EditorUtils.getFirstSelectedElementFromState(store.getState().editor.present);

        if (selectedElement && selectedPage && selectedElement.type === PageElementType.SVG) {
            let value = icon.svg;
            try {
                value = (await IconfinderService.download(value)).svg;
            } catch (ex) {
            }

            // TODO: e se só mudar o tamanho se for de um aspect ratio diferente? 🤔
            dispatch(updatePageElement(selectedPage, {
                ...selectedElement,
                value: value,
                width: icon.width,
                height: icon.height,
            }));
        }

    }

    const renderIconsList = (icons: IconItem[]) => {
        return (
            <ScrollShadow scrollId="scrollFlaticon" className={styles.scrollContentContainer} scrollClassName={styles.scrollContent}>
                <InfinityScroll
                    inModal={true}
                    totalItems={icons.length}
                    executeOnScroll={() => {
                        setFlaticonCriteria({
                            ...flaticonCriteria,
                            page: (flaticonCriteria.page ?? defaultPage) + 1
                        });
                    }}
                    continueScroll={flaticonLoadMore}
                    elementIdToScroll="scrollFlaticon"
                >
                    {!flaticonErrorCode
                        ? icons.length > 0
                            ? <Masonry
                                breakpointCols={4}
                                className={styles.masonryGrid}
                                columnClassName={styles.masonryGridColumn}
                            >
                                {icons.map((icon, i) => {
                                    return (
                                        <DraggableContentItem key={i} generateElement={() => createIconElement(icon)}>
                                            <div className={styles.iconItem} onClick={() => onIconClick(icon)}>
                                                {/* <RenderSVG value={icon.svg} /> */}
                                                <img src={icon.preview} width={icon.width} height={icon.height} />
                                            </div>
                                        </DraggableContentItem>
                                    );
                                })}
                            </Masonry>
                            : <div className={styles.loadInfo}>
                                <img src={IconSuccess} className={styles.loadInfoIcon} />
                                <div className={styles.loadInfoText}>
                                    <span>{t('common.messages.search_no_results')}</span>
                                </div>
                            </div>
                        : <div className={styles.loadError}>
                            <img src={IconSaveDanger} className={styles.loadErrorIcon} />
                            {flaticonErrorCode == '500'
                                ? <div className={styles.loadErrorText}>
                                    <span>{t('editor.sidebar.error_connecting_flaticon')}</span>
                                    <a
                                        onClick={retryFlaticon}
                                        className={`${styles.tryAgainLink} ${tryingFlaticon && styles.tryAgainLinkDisabled}`}
                                    >
                                        {t('common.try_again')}
                                    </a>
                                </div>
                                : <div className={styles.loadErrorText}>
                                    <span>{t(('flaticon.errors.' + flaticonErrorCode as any))}</span>
                                </div>
                            }
                        </div>
                    }
                </InfinityScroll>
            </ScrollShadow>
        );
    }

    useEffect(() => {
        loadFlaticonIcons();
    }, [flaticonCriteria]);

    return (
        <div className={styles.container}>
            <Searchbar
                defaultValue={searchValue}
                placeholder={t('editor.sidebar.icons_searchbar_placeholder')}
                onChange={onChangeText}
            />
            <div className={styles.boxCategoryContainer}>
                <BoxCategory
                    title={t('editor.sidebar.icons')}
                    className={styles.boxCategory}
                >
                    {renderIconsList(flaticonIcons)}
                </BoxCategory>
            </div>
        </div>
    );

}

export default IconsContent;
