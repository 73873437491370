import { SearchListItemsViewModel } from 'api/home/models/SearchListItemsViewModel';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SearchPanel.module.scss';
import SearchItem from '../searchItem/SearchItem';
import { DocumentViewModel } from 'api/documents/models/DocumentViewModel';
import { addToast } from 'common/services/Toaster';
import { PreviousPage } from 'api/editor/enums/PreviousPage';
import Loading from 'common/services/Loading';
import DocumentsService from 'api/documents/DocumentsService';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import DocumentModal from 'screens/documents/list/components/modal/DocumentModal';
import QuestionYesNo from '../questionYesNo/QuestionYesNo';
import InfinityScroll from '../infinityScroll/InfinityScroll';
import ScrollShadow from '../scrollShadow/ScrollShadow';
import { useHistory } from 'react-router-dom';

export type OpenDialogType = 'details' | 'edit' | 'new' | 'validation';
export type CloseDialogResultType = 'edit' | 'validate' | 'refuse';

type Props = {
    searchResults: SearchListItemsViewModel | null | undefined;
    stateBackDrop?: (state: boolean) => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const SearchPanel: React.FC<Props> = ({ searchResults, stateBackDrop, ...props }: Props) => {
    const { t } = useTranslation();
    
    const history = useHistory();
    
    const [openDocModal, setOpenDocModal] = React.useState<boolean>(false);
    const [openDocumentModalItem, setOpenDocumentModalItem] = React.useState<DocumentViewModel | null>(null);
    const [openDialog, setOpenDialog] = React.useState<OpenDialogType | null>(null);
    const [openDocRemoveModal, setOpenDocRemoveModal] = React.useState<boolean>(false);

    const openModalDocument = (item: DocumentViewModel) => {
        stateBackDrop && stateBackDrop(false);
        setOpenDocModal(true);
        setOpenDocumentModalItem(item);
    };

    const onUserModalClose = (result?: CloseDialogResultType, id?: string) => {
        setOpenDialog(null);
        setOpenDocModal(false);

        if (id) {
            if (result === 'edit') {
                history.push(`/editor/${id}/${PreviousPage.DOCUMENTS}`);
            }
        }

        if (openDocumentModalItem?.id && !id) {
            if (result === 'edit') {
                history.push(`/editor/${openDocumentModalItem.id}/${PreviousPage.DOCUMENTS}`);
            }
        }

        stateBackDrop && stateBackDrop(true);
    };

    const openModalRemoveDocument = () => {
        setOpenDocModal(false);
        setOpenDocRemoveModal(true);
    };

    const closeModalRemoveDocumentYes = () => {
        setOpenDocRemoveModal(false);
        setOpenDocumentModalItem(null);
        stateBackDrop && stateBackDrop(true);
    };

    const closeModalRemoveDocumentNo = () => {
        setOpenDocRemoveModal(false);
        setOpenDocModal(true);
        stateBackDrop && stateBackDrop(true);
    };

    const removeItem = async () => {
        try {
            Loading.show();
            if (openDocumentModalItem && openDocumentModalItem.id) {
                await DocumentsService.remove({ id: openDocumentModalItem.id });
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                closeModalRemoveDocumentYes();
                Loading.hide();
            } else {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
                closeModalRemoveDocumentYes();
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.DOCUMENTS, `Couldn't remove document`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            Loading.hide();
            closeModalRemoveDocumentYes();
        }
    };

    const renderTitle = (type: string) => {
        return <div className={styles.title}>
            {t(`home.home_search.${type}` as any)}
        </div>
    };

    const renderDocs = (docs: DocumentViewModel[]) => {
        return <div className={styles.documentsContainer}>
                { docs.map((doc, index) => {
            return <SearchItem key={index} document={doc} onClick={() => openModalDocument(doc)}></SearchItem>;
        })}
        </div>
    };

    return (
        <div {...props}>
            {
                !(!searchResults || (searchResults && searchResults.mySupports.length == 0 && searchResults.sharedSupports.length == 0 && searchResults.models.length == 0 && searchResults.simplePages.length == 0)) &&
                    <ScrollShadow scrollId="scrollImages" className={styles.scrollContentContainer} scrollClassName={styles.scrollContent}>
                        <InfinityScroll
                            inModal={true}
                            totalItems={!searchResults ? 0 : (searchResults.mySupports.length + searchResults.sharedSupports.length + searchResults.models.length + searchResults.simplePages.length)}
                            executeOnScroll={() => { return }}
                            continueScroll={false}
                            elementIdToScroll='scrollImages'
                        >
                            <div className={styles.list}>
                                { searchResults && searchResults.mySupports.length > 0 && <>
                                    {renderTitle('my_supports')}
                                    {renderDocs(searchResults.mySupports)}
                                </> }
                                
                                { searchResults && searchResults.sharedSupports.length > 0 && <>
                                    {renderTitle('shared_supports')}
                                    {renderDocs(searchResults.sharedSupports)}
                                </> }
                                
                                { searchResults && searchResults.models.length > 0 && <>
                                    {renderTitle('models')}
                                    {renderDocs(searchResults.models)}
                                </> }
                                
                                { searchResults && searchResults.simplePages.length > 0 && <>
                                    {renderTitle('simple_pages')}
                                    {renderDocs(searchResults.simplePages)}
                                </> }
                            </div>
                        </InfinityScroll>
                    </ScrollShadow>
            }

            { !searchResults || (searchResults && searchResults.mySupports.length == 0 && searchResults.sharedSupports.length == 0 && searchResults.models.length == 0 && searchResults.simplePages.length == 0) && <div className={styles.withoutElements}> {t('common.without_elements')} </div> }

            {openDocModal && openDocumentModalItem && <DocumentModal
                mode={'details'}
                item={openDocumentModalItem}
                isVisible={openDocModal}
                onCancel={onUserModalClose}
                onRemove={openModalRemoveDocument}
            />}

            <div>
                {openDocRemoveModal != null && <QuestionYesNo
                    title={t('common.remove')}
                    message={t('common.messages.remove_record')}
                    isVisible={openDocRemoveModal}
                    onYes={() => removeItem()}
                    onNo={() => { closeModalRemoveDocumentNo(); }} />}
            </div>
        </div>
    );
}

export default SearchPanel;
