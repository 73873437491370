import React, { useCallback, useMemo, useState } from 'react';
import styles from './TableModifier.module.scss';
import { useTranslation } from 'react-i18next';
import { Page, PageElement, PageElementType } from 'screens/editor/models/EditorModels';
import BoxCategory from 'common/components/boxCategory/BoxCategory';
import Table from '../table/Table';
import TablePreview from '../tablePreview/TablePreview';
import EditorTablesUtils from 'screens/editor/utils/EditorTablesUtils';
import EditorUtils from 'screens/editor/utils/EditorUtils';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import store from 'store/store';
import { updatePageElement } from 'store/editor/action';
import { EditorTableDataValue, EditorTableData, EditorTableType } from 'screens/editor/models/TableModels';

const defaultTableData = EditorTablesUtils.generateTableData();

type Props = {
    tableType: EditorTableType;
    onGoBack: () => void;
}

const TableModifier = ({ tableType, onGoBack }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedElement] = useSelector<Reducers, ([(PageElement | null), (Page | null)])>(state => EditorUtils.getFirstSelectedElementFromState(state.editor.present))
    const [data, setData] = useState<EditorTableData[]>(defaultTableData);

    const onDataChange = useCallback((newData: EditorTableData[]) => {
        const [selectedElementUpdated, selectedPageUpdated] = EditorUtils.getFirstSelectedElementFromState(store.getState().editor.present);
        if (selectedElementUpdated && selectedPageUpdated && selectedElementUpdated.type === PageElementType.TABLE) {
            dispatch(updatePageElement(selectedPageUpdated, {
                ...selectedElementUpdated,
                value: {
                    ...selectedElementUpdated.value,
                    type: tableType,
                    tableData: newData,
                } as EditorTableDataValue,
            }));
        } else {
            setData(newData);
        }
    }, []);

    const currentData = useMemo(() => (
        selectedElement && selectedElement.value && (selectedElement.value as EditorTableDataValue).tableData && selectedElement.type === PageElementType.TABLE
            ? (selectedElement.value as EditorTableDataValue).tableData
            : data
    ), [selectedElement, data]);

    return (
        <div className={styles.container}>
            <BoxCategory
                title={t('editor.sidebar.modify_table')}
                onGoBack={() => { setData(EditorTablesUtils.generateTableData()); onGoBack(); }}
                className={styles.boxContainer}
            >
                <Table data={currentData} onChange={onDataChange} />
                <TablePreview tableType={tableType} data={currentData} />
            </BoxCategory>
        </div>
    );
}

export default TableModifier;
