import React from 'react';
import { useSelector } from 'react-redux';
import { EditorFont } from 'screens/editor/models/EditorModels';
import { Reducers } from 'store/types';
import GoogleFontLoader from 'common/components/googleFontsLoader/GoogleFontsLoader';

export const prepareEditorFonts = (fonts: EditorFont[]) => {
    return fonts.map(f => ({
        font: f.family,
        weights: [300, 400, 700]
    }));
}

const EditorFontsLoader = () => {
    const fonts = useSelector<Reducers, EditorFont[]>(state => state.editor.present.fonts || []);

    return (
        (fonts && fonts.length > 0) ? <GoogleFontLoader
            fonts={prepareEditorFonts(fonts)}
        /> : null
    );
}

export default EditorFontsLoader;
