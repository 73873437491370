import { SelectValueLabel } from 'common/types/SelectValueLabel';
import dayjs from 'dayjs';
import { RGBColor } from 'react-color';
import { v4 as uuidv4 } from 'uuid';
import CustomFile from '../models/CustomFile';

const isValidHex = (hex: string) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)

const getChunksFromString = (st: string, chunkSize: number) => st.match(new RegExp(`.{${chunkSize}}`, 'g'))

const convertHexUnitTo256 = (hexStr: string) => parseInt(hexStr.repeat(2 / hexStr.length), 16)

class Utils {

    public newGuid(): string {
        return uuidv4();
    }

    public dateToUtcDate(date: Date) {
        const aux = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
            date.getUTCMilliseconds()
        );
        return aux;
    }

    public getTimeStringByTimestamp(timestamp: number) { //format: HH:mm:ss
        // Create a new JavaScript Date object based on the timestamp
        // multiplied by 1000 so that the argument is in milliseconds, not seconds.
        const date = new Date(timestamp * 1000);
        // Hours part from the timestamp
        const hours = date.getHours();
        // Minutes part from the timestamp
        const minutes = '0' + date.getMinutes();
        // Seconds part from the timestamp
        const seconds = '0' + date.getSeconds();
        // Will display time in 10:30:23 format
        const formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
        return formattedTime;
    }

    public objectArraybyStringPath(o: any, s: string) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        const a = s.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            const k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }

    public getMonthsInRange(startDate: Date, endDate: Date) {
        let dStartDate = dayjs(startDate);
        const dEndDate = dayjs(endDate);
        const result = [];

        while (dStartDate.isBefore(dEndDate) || dStartDate.isSame(dEndDate, 'month')) {
            result.push(dStartDate.toDate());
            dStartDate = dStartDate.add(1, 'month');
        }

        return result;
    }

    public getYearsInRange(startDate: Date, endDate: Date) {
        let dStartDate = dayjs(startDate);
        const dEndDate = dayjs(endDate);
        const result = [];

        while (dStartDate.isBefore(dEndDate) || dStartDate.isSame(dEndDate, 'year')) {
            result.push(dStartDate.toDate());
            dStartDate = dStartDate.add(1, 'year');
        }

        return result;
    }

    public getMonthsInYear(date: Date) {
        return new Array(12).fill({}).map((_, i) => (new Date(date.getFullYear(), i, 1, 0, 0, 0, 0)));
    }

    public getImageDimensions(dataUrl: string): Promise<{ width: number, height: number }> {
        return new Promise(resolve => {
            const image = new Image();
            image.onload = () => {
                resolve({ width: image.width, height: image.height });
            };
            image.src = dataUrl;
        });
    }

    public blobToBase64(blobFile: Blob): Promise<string> {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve((reader.result) as string);
            };
            reader.readAsDataURL(blobFile);
        });
    }

    public async urlToBase64(url: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = () => {
                this.blobToBase64(xhr.response).then(resolve).catch(reject);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        });
    }

    public resizeBase64Image(dataUrl: string, width: number, height: number): Promise<string> {
        return new Promise(resolve => {
            const img = new Image();
            img.src = dataUrl;
            img.onload = () => {

                // create an off-screen canvas
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // set its dimension to target size
                canvas.width = width;
                canvas.height = height;

                // draw source image into the off-screen canvas:
                ctx!.drawImage(img, 0, 0, width, height);

                // encode image to data-uri with base64 version of compressed image
                resolve(canvas.toDataURL());
            };
        });
    }

    public dataURLtoFile(dataurl: string, filename: string, containerName = '') {
        const arr = dataurl.split(',');
        if (!arr || arr.length <= 0) {
            return null;
        }

        const mime = arr[0].match(/:(.*?);/)![1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        const file = new File([u8arr], filename, { type: mime }) as CustomFile;
        file.containerName = containerName;
        return file;
    }

    public getAllMatches(regex: any, text: string) {
        if (regex.constructor !== RegExp) {
            throw new Error('not RegExp');
        }

        const res = [];
        let match = null;

        if (regex.global) {
            while (match = regex.exec(text)) {
                res.push(match);
            }
        }
        else {
            if (match = regex.exec(text)) {
                res.push(match);
            }
        }

        return res;
    }

    public getAlphafloat(a: number, alpha: number) {
        if (typeof a !== 'undefined') { return a / 255 }
        if ((typeof alpha != 'number') || alpha < 0 || alpha > 1) {
            return 1
        }
        return alpha
    }

    public hexToRGBA(hex: string, alpha = 1) {
        if (!isValidHex(hex)) { throw new Error('Invalid HEX') }
        const chunkSize = Math.floor((hex.length - 1) / 3)
        const hexArr = getChunksFromString(hex.slice(1), chunkSize)
        const [r, g, b, a] = (hexArr || []).map(convertHexUnitTo256)
        return `rgba(${r}, ${g}, ${b}, ${this.getAlphafloat(a, alpha)})`
    }

    public rgbaToString(rgba: RGBColor): string {
        const alpha = rgba.a === undefined || rgba.a === null ? 1 : rgba.a;
        return `rgba(${rgba.r},${rgba.g},${rgba.b},${alpha})`;
    }

    public stringToRGBA(rgba: string): RGBColor {
        const arr = rgba.trim()
            .replaceAll('rgba(', '')
            .replaceAll('rgb(', '')
            .replace(')', '')
            .split(',')
            .map(s => parseFloat(s.trim()));

        const r = arr[0];
        const g = arr[1];
        const b = arr[2];
        const a = arr.length > 3 ? arr[3] : 1;

        return { r, g, b, a };
    }

    public lightenDarkenColor(colorCode: string, amount: number) {
        // Usage
        // Lighten
        // lightenDarkenColor("#F06D06", 20);
        // Darken
        // lightenDarkenColor("#F06D06", -20);

        let usePound = false;

        if (colorCode[0] === '#') {
            colorCode = colorCode.slice(1);
            usePound = true;
        }

        const num = parseInt(colorCode, 16);

        // tslint:disable-next-line: no-bitwise
        let r = (num >> 16) + amount;

        if (r > 255) {
            r = 255;
        } else if (r < 0) {
            r = 0;
        }

        // tslint:disable-next-line: no-bitwise
        let b = ((num >> 8) & 0x00FF) + amount;

        if (b > 255) {
            b = 255;
        } else if (b < 0) {
            b = 0;
        }

        // tslint:disable-next-line: no-bitwise
        let g = (num & 0x0000FF) + amount;

        if (g > 255) {
            g = 255;
        } else if (g < 0) {
            g = 0;
        }

        // tslint:disable-next-line: no-bitwise
        return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
    }

    public sortAlphabetically(arr: SelectValueLabel[]): SelectValueLabel[] {
        const removeAccents = (str: string) => {
            return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }

        return arr.sort(function (a, b) {
            if (removeAccents(a.label) < removeAccents(b.label)) { return -1; }
            if (removeAccents(a.label) > removeAccents(b.label)) { return 1; }
            return 0;
        });
    }
}

export default new Utils();
