import Http from '../../common/services/Http';
import { AppSettingsViewModel } from './models/AppSettingsViewModel';
import { AppSettingsDto } from './models/AppSettingsDto';

class AppSettingsService {
    public getAppSettings() {
        return Http.get<AppSettingsDto>('appSettings');
    }

    public getFaqUrl() {
        return Http.get<AppSettingsDto>('appSettings/faq');
    }

    public update(model: AppSettingsViewModel){
        return Http.put<AppSettingsViewModel>('appSettings', model);
    }
}

export default new AppSettingsService();