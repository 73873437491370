import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DocumentModal.module.scss';
import { DocumentCollaboratorViewModel, DocumentCompanyViewModel, DocumentViewModel } from '../../../../../api/documents/models/DocumentViewModel';
import OverlayedModal from '../../../../../common/components/overlayedModal/OverlayedModal';

import IconClose from '../../../../../assets/svgs/Cross-bleu2-contour.svg';
import Button from '../../../../../common/components/button/Button';
import Loading from '../../../../../common/services/Loading';

import Background from '../../../../../assets/img/no_image.png';
import IconEdit from '../../../../../assets/svgs/Modify-blanc.svg';
import IconDuplicate from '../../../../../assets/svgs/Duplicate-blanc.svg';
import IconDownload from '../../../../../assets/svgs/Download-blanc.svg';
import IconValidate from 'assets/svgs/Check-blanc.svg';
import IconRefuse from 'assets/svgs/Cross-white.svg';
import { addToast } from 'common/services/Toaster';
import InputWithTextComponent from '../../../../../common/components/input/InputWithTextComponent';
import IconEye from '../../../../../assets/svgs/Eye-bleu1.svg';
import IconSlashEye from '../../../../../assets/svgs/EyeBarre-bleu1.svg';
import { DocumentVisibility, DocumentVisibilityAction } from '../../../../../api/documents/enums/DocumentVisibility';
import Tags from '../../../../../common/components/tags/Tags';
import { Tag } from 'react-tag-autocomplete';
import { TagsSearchCriteria } from '../../../../../api/tags/models/TagsSearchCriteria';
import TagsService from '../../../../../api/tags/TagsService';
import { useSelector } from 'react-redux';
import { UserProfile } from '../../../../../api/account/models/UserProfile';
import { Reducers } from '../../../../../store/types';
import DocumentsService from 'api/documents/DocumentsService';
import useEffectOnChange from 'common/hooks/useEffectOnChange';
import RemoveMessage from 'common/components/removeMessage/RemoveMessage';
import DocumentExportModal from 'common/components/documentExport/DocumentExport';
import Logger from 'common/services/Logger';
import { IS_ADMIN, IS_COMPANY_ADMIN, LOGGER_LOG_TYPE } from 'Config';
import EditableLabel from 'common/components/editableLabel/EditableLabel';
import { DocumentVisibilityTypeViewModel } from 'api/documents/models/DocumentFormatTypeViewModel';
import { DocumentOrientation } from 'api/documents/enums/DocumentOrientation';
import { DocumentType } from 'api/documents/enums/DocumentType';
import EditorInternalService from 'screens/editor/utils/EditorInternalService';
import EditorUtils from 'screens/editor/utils/EditorUtils';
import ReactTooltip from 'react-tooltip';

type Props = {
    isVisible: boolean;
    item: DocumentViewModel;
    mode: string | null;
    onCancel: (value?: CloseDialogResultType, id?: any) => void;
    onRemove: () => void;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export type CloseDialogResultType = 'edit' | 'validate' | 'refuse';

const DocumentModal: React.FC<Props> = ({ isVisible, item: _item, mode, onCancel, onRemove }: Props) => {
    const { t } = useTranslation();
    
    const criteria: TagsSearchCriteria = {};
    const [item, setItem] = useState<DocumentViewModel>(_item);
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const companyId = loggedUser?.companyId;
    const isAdmin: boolean = IS_ADMIN(loggedUser);

    const [listTags, setListTags] = useState<Tag[]>([]);
    const [currentTags, setCurrentTags] = useState<Tag[]>([]);
    const [listCollabs, setListCollabs] = useState<Tag[]>();
    const [listCompanies, setListCompanies] = useState<Tag[]>();

    const [startChange, setStartChange] = useState<boolean>(false);
    const [tags, setTags] = useState<string[]>(item?.tags || []);
    const [collaborators, setCollaborators] = useState<DocumentCollaboratorViewModel[]>([]);
    const [companies, setCompanies] = useState<DocumentCollaboratorViewModel[]>([]);
    const [backgroundImage, setBackgroundImage] = useState<React.CSSProperties>();
    const [openDocModalExport, setOpenDocModalExport] = React.useState<boolean>(false);

    const [showPlaceholderCollaborators, setShowPlaceholderCollaborators] = React.useState<boolean>(false);

    const visibilityTypes: DocumentVisibilityTypeViewModel[] = [
        { visibility: DocumentVisibility.PRIVATE, icon: IconSlashEye },
        { visibility: DocumentVisibility.PUBLIC, icon: IconEye },
    ]

    const backgroundNoImage: React.CSSProperties = {
        backgroundImage: 'url(\'' + Background + '\')'
    }

    const avoidRerender = useRef<boolean>(false);

    const getData = async () => {
        try {
            criteria.companyId = companyId;

            /*get do documento para ter tags e colaboradores atualizados*/
            const documentItem = item?.id ? await DocumentsService.getById(item?.id) : null;
            if (documentItem && item) {
                if (documentItem.tags) {
                    setTags(documentItem.tags.map(t => t));
                }

                if (documentItem.collaborators) {
                    setCollaborators(documentItem.collaborators.map(c => ({ userId: c.userId, userName: c.userName })));
                }

                if (documentItem.companies) {
                    setCompanies(documentItem.companies.map(c => ({ userId: c.companyId, userName: c.companyName })));
                }
            }

            const tagsListResult = await TagsService.getList(criteria);
            setListTags(tagsListResult.map(x => ({ id: x.id, name: x.description })));

            if (isAdmin) {
                let listCompaniesResult = await DocumentsService.getUsersForCollaboration(criteria as any);
                if (documentItem && documentItem.companies) {
                    documentItem.companies.forEach(companies => {
                        listCompaniesResult = listCompaniesResult.filter(x => x.userId !== companies.companyId);
                    });
                }

                setListCompanies(listCompaniesResult.map(y => ({ id: y.userId ?? '', name: y.userName ?? '' })));
            }
            else {
                let listCollabsResult = await DocumentsService.getUsersForCollaboration(criteria as any);
                if (documentItem && documentItem.collaborators) {
                    documentItem.collaborators.forEach(collaborator => {
                        listCollabsResult = listCollabsResult.filter(x => x.userId !== collaborator.userId);
                    });
                }

                setListCollabs(listCollabsResult.map(y => ({ id: y.userId ?? '', name: y.userName ?? '' })));
            }

            setStartChange(true);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.DOCUMENTS, `Couldn't get document info`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const update = async () => {
        try {
            Loading.show();
            await DocumentsService.update({
                ...item,
                companyId,
                tags,
                collaborators,
                isAdmin: IS_COMPANY_ADMIN(loggedUser),
                companies: companies.map(x => ({ companyId: x.userId } as DocumentCompanyViewModel))
            });

            if (IS_COMPANY_ADMIN(loggedUser) && item.visibilityAction === DocumentVisibilityAction.REQUESTED) {
                const tempItem = { ...item };
                tempItem.visibilityAction = DocumentVisibilityAction.ACCEPTED;
                avoidRerender.current = true;
                setItem(tempItem);
            }

            Loading.hide();
            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.DOCUMENTS, `Couldn't update document`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    const onChangeText = (text: string) => {
        if (text && item?.name) {
            const tempItem = { ...item };
            tempItem.name = text;
            setItem(tempItem);
        }
    }

    const onSelectType = (type: any) => {
        if (type.visibility !== item?.visibility) {
            const tempItem = { ...item };
            tempItem.visibility = type?.visibility;

            if (type.visibility == DocumentVisibility.PUBLIC) {
                tempItem.visibilityAction = DocumentVisibilityAction.REQUESTED;
            }

            setItem(tempItem);
        }
    }

    const duplicate = async () => {
        try {
            Loading.show();

            const id = await DocumentsService.create({
                ...item,
                companyId,
                tags,
                collaborators,
                isAdmin: IS_COMPANY_ADMIN(loggedUser),
                companies: companies.map(x => ({ companyId: x.userId } as DocumentCompanyViewModel))
            });

            if (item.editorData && item.id) {
                const pages = EditorUtils.parseDocumentEditorData(item.editorData, item.format).pages;
                await EditorInternalService.createDocumentEvents(pages, id);
            }

            Loading.hide();
            onCancel('edit', id);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.DOCUMENTS, `Couldn't duplicate document`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
        }
    }

    useEffectOnChange(() => {
        if (startChange) {
            update();
        }
    }, [tags, collaborators, companies]);

    useEffect(() => {
        setBackgroundImage({
            backgroundImage: 'url(\'' + item?.thumbnailMediaUrl + '?_=' + new Date().getTime() + '\')'
        });
        
        getData();
    }, []);

    useEffectOnChange(() => {
        console.log('useEffectOnChange', avoidRerender)
        if(avoidRerender.current){
            avoidRerender.current = false;
        }
        else if (startChange){
            update();
        }
    }, [item]);

    const renderVisibilityItem = (icon: string, onClick: () => void, isSelected: boolean, key: number) => (
        <div
            onClick={(onClick)}
            key={`visibility-${key}`}
        >
            <div className={styles.icon}>
                <div className={isSelected ? styles.selected : styles.notSelected}>
                    <img src={icon} className={styles.img} />
                </div>
            </div>

        </div>
    );

    return (
        <>
            <OverlayedModal isOpen={!openDocModalExport}>
                <div className={styles.container}>

                    <div className={styles.left}>
                        <div className={styles.imageContainer}>
                            <div className={styles.image}>
                                <div style={item?.thumbnailMediaUrl != null ? backgroundImage : backgroundNoImage}
                                    className={item.orientation === DocumentOrientation.PORTRAIT ? styles.imageContentPortrait : styles.imageContent}>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.right}>
                        <div className={styles.header}>
                            <div className={styles.title}>
                                {t(mode == 'validate' ? (item.type == DocumentType.MODEL_PRE_FILLED ? 'documents.modal.validation_pre_filled' : 'documents.modal.validation') : 'documents.modal.informations')}
                            </div>
                            <img src={IconClose} onClick={() => onCancel()} className={styles.icon} />
                        </div>

                        <div className={styles.content}>
                            <h2 className={styles.h2}>
                                <EditableLabel
                                    text={item?.name ? item.name : ''}
                                    onChangeText={onChangeText}
                                    placeholder={t('documents.document_name')} />
                            </h2>
                            <div className={styles.info}>
                                <InputWithTextComponent
                                    text={t('documents.modal.created_at')}
                                    content={item?.createdDate ? new Date(item.createdDate).toLocaleDateString() : ''}
                                    placeholder={t('common.name')}
                                />

                                <InputWithTextComponent
                                    text={t('documents.modal.created_by')}
                                    content={item?.userCreatedName ? item.userCreatedName : ''}
                                    name="name"
                                    placeholder={t('common.name')}
                                />
                                <div>
                                    {mode == 'details' && !isAdmin && (item.userCreatedId == loggedUser?.id || item.collaborators.find(x => x.userId == loggedUser?.id)) &&
                                    <div className={`${styles.visibility} ${item?.visibilityAction === DocumentVisibilityAction.REQUESTED ? styles.disabled : ''}`}
                                        data-for="visibility-tooltip"
                                        data-tip={t('documents.waiting_approval')}
                                        data-iscapture="true"
                                    >
                                        {visibilityTypes.map((visibilityType, i) => {
                                            const isSelected = item?.visibility && visibilityType.visibility === item?.visibility ? true : false;
                                            const icon = visibilityType?.icon ? visibilityType?.icon : '';
                                            return renderVisibilityItem(icon, () => item?.visibilityAction !== DocumentVisibilityAction.REQUESTED ? onSelectType(visibilityType) : null, isSelected, i);
                                        })}
                                    </div>}
                                </div>
                                {item?.visibilityAction === DocumentVisibilityAction.REQUESTED && 
                                    <ReactTooltip
                                        id="visibility-tooltip"
                                        place="top"
                                        type="info"
                                        effect="solid"
                                        multiline={true}
                                    />
                                }
                            </div>
                            {item.type != DocumentType.MODEL_PRE_FILLED && <div className={styles.tags}>
                                <Tags
                                    placeholderText={t('documents.tags')}
                                    onAddition={tag => {
                                        setTags([
                                            ...(tags || []),
                                            tag.name
                                        ]);

                                        if (!(listTags || []).find(x => x.name == tag.name) && !(currentTags || []).find(x => x.name == tag.name)) {
                                            setCurrentTags([...(currentTags || []), { id: '', name: tag.name }])
                                        }
                                    }}
                                    onDelete={tagIndex => {
                                        const tagNameRemove = (tags || []).filter((_, i) => i === tagIndex)[0];

                                        setTags((tags || []).filter((_, i) => i !== tagIndex));

                                        if ((currentTags || []).find(x => x.name == tagNameRemove)) {
                                            setCurrentTags((currentTags || []).filter(x => x.name !== tagNameRemove));
                                        }
                                    }}
                                    tags={(tags || []).map((x: string) => ({ id: x, name: x }))}
                                    allowNew={true}
                                    addOnBlur={true}
                                    suggestions={listTags.concat(currentTags)}
                                />
                            </div>}

                            {mode == 'details' && isAdmin && <div className={styles.collabs}>

                                <InputWithTextComponent
                                    titleClassName={styles.title}
                                    text={t('documents.companies') + ' :'}
                                    placeholder={t('common.name')}
                                >
                                    <Tags
                                        onFocus={() => setShowPlaceholderCollaborators(true)}
                                        onBlur={() => setShowPlaceholderCollaborators(false)}
                                        placeholderText={showPlaceholderCollaborators ? t('documents.companies') : ''}

                                        onAddition={company => {
                                            setCompanies([...(companies || []),
                                            {
                                                userId: company.id as string,
                                                userName: company.name,
                                            }]);
                                            setListCompanies([...((listCompanies || []).filter(collabTag => collabTag.id !== company.id))]);
                                        }}

                                        onDelete={companyIndex => {
                                            const comp = (companies || []).find((_, i) => i === companyIndex);
                                            setCompanies((companies || []).filter((_, i) => i !== companyIndex));
                                            if (comp) {
                                                setListCompanies([...(listCompanies || []), { id: comp.userId ?? '', name: comp.userName ?? '' }]);
                                            }
                                        }}
                                        tags={(companies || []).map(x => ({ id: x.userId!, name: x.userName! }))}
                                        addOnBlur={true}
                                        suggestions={listCompanies}
                                        type={'custom'}
                                    />
                                </InputWithTextComponent>
                            </div>}
                            {mode == 'details' && !isAdmin && item.type != DocumentType.MODEL_PRE_FILLED && <div className={styles.collabs}>

                                <InputWithTextComponent
                                    titleClassName={styles.title}
                                    text={t('documents.modal.contributeurs') + ' :'}
                                    placeholder={t('common.name')}
                                >
                                    <Tags
                                        onFocus={() => setShowPlaceholderCollaborators(true)}
                                        onBlur={() => setShowPlaceholderCollaborators(false)}
                                        placeholderText={showPlaceholderCollaborators ? t('documents.collaborator') : ''}

                                        onAddition={collaborator => {
                                            setCollaborators([...(collaborators || []),
                                            {
                                                userId: collaborator.id as string,
                                                userName: collaborator.name,
                                            }]);
                                            setListCollabs([...((listCollabs || []).filter(collabTag => collabTag.id !== collaborator.id))]);
                                        }}

                                        onDelete={collabIndex => {
                                            const collab = (collaborators || []).find((_, i) => i === collabIndex);
                                            setCollaborators((collaborators || []).filter((_, i) => i !== collabIndex));
                                            if (collab) {
                                                setListCollabs([...(listCollabs || []), { id: collab.userId ?? '', name: collab.userName ?? '' }]);
                                            }
                                        }}
                                        tags={(collaborators || []).map(x => ({ id: x.userId!, name: x.userName! }))}
                                        addOnBlur={true}
                                        suggestions={listCollabs}
                                        type={'custom'}
                                    />
                                </InputWithTextComponent>
                            </div>}
                        </div>
                    </div>
                </div>

                <div className={styles.footer}>
                    <div className={styles.remove} onClick={() => onRemove()}>
                        <RemoveMessage
                            style={{ width: '100%' }}
                            message={
                                item.type === DocumentType.SIMPLE_MODEL ? t('documents.modal.remove_simple_model') :
                                    isAdmin ? t('documents.modal.remove_model') : t('documents.modal.remove_support')
                            }
                            onElementClick={() => onRemove()} />
                    </div>

                    <div className={styles.buttonsContainer} >

                        <Button
                            type="button"
                            text={t('documents.modal.edit').toUpperCase()}
                            className={mode == 'validate' ? styles.buttonEditValidate : styles.buttonEdit}
                            onClick={() => onCancel('edit')}
                        >
                            <img src={IconEdit} className={styles.icon} />
                        </Button>

                        {mode == 'details' &&
                            <Button
                                type="button"
                                text={t('documents.modal.duplicate').toUpperCase()}
                                className={styles.button}
                                onClick={duplicate}
                            >
                                <img src={IconDuplicate} className={styles.icon} />
                            </Button>}

                        {mode == 'details' &&
                            <Button
                                type="button"
                                text={t('documents.modal.download').toUpperCase()}
                                className={styles.button}
                                onClick={() => setOpenDocModalExport(true)}>
                                <img src={IconDownload} className={styles.icon} />
                            </Button>}

                        {mode == 'validate' &&
                            <Button
                                type="button"
                                text={t('documents.modal.validate').toUpperCase()}
                                className={styles.button}
                                onClick={() => onCancel('validate')}
                            >
                                <img src={IconValidate} className={styles.icon} />
                            </Button>}

                        {mode == 'validate' &&
                            <Button
                                type="button"
                                text={t('documents.modal.refuse').toUpperCase()}
                                className={styles.button}
                                onClick={() => onCancel('refuse')}
                            >
                                <img src={IconRefuse} className={styles.icon} />
                            </Button>}
                    </div>
                </div>
            </OverlayedModal>
            {openDocModalExport && <DocumentExportModal
                documentName={item.name}
                documentId={item.id}
                isVisible={isVisible}
                onCancel={() => setOpenDocModalExport(false)}
                onSuccess={() => onCancel('validate')}
            />}
        </>
    );
}

export default DocumentModal;