import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaDrawPolygon, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from 'common/services/Toaster';
import { PageElementClipType } from 'screens/editor/models/EditorModels';
import EditorInternalService from 'screens/editor/utils/EditorInternalService';
import store from 'store/store';
import { Reducers } from 'store/types';
import { setClipActiveElementId } from 'store/editorControl/action';
import {  updatePageElement } from 'store/editor/action';
import { ToolbarOptionProps } from '../../../../utils/EditorProps';
import ToolbarButton from '../toolbarButton/ToolbarButton';
import IconClip from 'assets/svgs/toolbar/Crop.svg';
import IconCircle from 'assets/svgs/toolbar/Cercle.svg';
import IconSquare from 'assets/svgs/toolbar/Square.svg';
import IconResetCrop from 'assets/svgs/toolbar/ResetCrop.svg';
import ToolbarButtonOption from '../toolbarButtonOption/ToolbarButtonOption';

const ClipToolbarOption: React.FC<ToolbarOptionProps> = ({ page, elements, className }: ToolbarOptionProps) => {
    const dispatch = useDispatch();
    const clipActiveElementId = useSelector<Reducers, string | null>(state => state.editorControl.clipActiveElementId);
    const element = elements![0];
    const clipActive = Boolean(clipActiveElementId && clipActiveElementId === element.id);
    const { t } = useTranslation();
    const elementClipType = element.clip?.type;
    
    const [showDropdown, setShowDropdown] = useState(false);

    const saveClip = async () => {
        Loading.show();

        try {
            const el = element;
            const croppedMedia = await EditorInternalService.cropImageElement(el);
            dispatch(updatePageElement(page!, {
                ...el!,
                clip: {
                    ...el.clip!,
                    croppedMedia,
                }
            }));
            dispatch(setClipActiveElementId(clipActive ? null : element.id));
        } catch (error) {
            addToast(t('common.messages.error_process'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.EDITOR, `Couldn't crop image in document: ${store.getState().editor.present.documentId}`, error);
        }

        Loading.hide();
    }

    const cancelEdit = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        evt.preventDefault();
        evt.stopPropagation();

        dispatch(setClipActiveElementId(null));
    }

    const setType = (type: PageElementClipType) => {
        const el = element;

        if (type !== el.clip?.type) {
            dispatch(updatePageElement(page!, {
                ...el,
                clip: !el.clip
                    ? {
                        type,
                        enabled: true,
                        style: null,
                        styles: null,
                        croppedMedia: null,
                    }
                    : {
                        ...el.clip,
                        type,
                        enabled: true,
                        style: null,
                        styles: null, // ??
                    },
            }));
        }

        dispatch(setClipActiveElementId(element.id));
    }

    const resetCrop = () => {
        dispatch(updatePageElement(page!, {
            ...element,
            clip: {
                type: null,
                enabled: false,
                style: null,
                styles: null,
                croppedMedia: null,
            }
        }));
        dispatch(setClipActiveElementId(null));
    }

    return(
        <ToolbarButton
            className={className}
            text={t('editor.toolbar.clip')}
            onClick={() => setShowDropdown(!showDropdown)}
            icon={IconClip}
            showDropdown={showDropdown || clipActive}
        >
            {!clipActive && (<>
                <ToolbarButtonOption
                    icon={IconSquare}
                    text={t('editor.toolbar.crop_square')}
                    active={elementClipType === 'rect'}
                    onClick={() => setType('rect')}
                />
                <ToolbarButtonOption
                    icon={IconCircle}
                    text={t('editor.toolbar.crop_circle')}
                    active={elementClipType === 'circle'}
                    onClick={() => setType('circle')}
                />
                <ToolbarButtonOption
                    iconElem={FaDrawPolygon}
                    text={t('editor.toolbar.crop_poly')}
                    active={elementClipType === 'polygon'}
                    onClick={() => setType('polygon')}
                />
                <ToolbarButtonOption
                    icon={IconResetCrop}
                    text={t('editor.toolbar.crop_reset')}
                    onClick={resetCrop}
                />
            </>)}
            {clipActive && (<>
                <ToolbarButtonOption
                    iconElem={FaCheck}
                    text={t('editor.toolbar.clip_done')}
                    onClick={saveClip}
                />
                <ToolbarButtonOption
                    iconElem={FaTimes}
                    text={t('editor.toolbar.clip_cancel')}
                    onClick={cancelEdit}
                />
            </>)}
        </ToolbarButton>
    );
}

export default ClipToolbarOption;
