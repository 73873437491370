import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from 'common/services/Toaster';
import CompanyService from 'api/companies/CompaniesService';
import { CompanySearchCriteria } from 'api/companies/models/CompanySearchCriteria';
import Button from 'common/components/button/Button';
import Loading from 'common/services/Loading';
import styles from './CompanyAnalyticsScreen.module.scss';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import PageHeader from 'common/components/pageHeader/PageHeader';
import IconIncrease from 'assets/svgs/Increase-gris.svg';
import IconCompany from 'assets/svgs/Entreprises-white.svg';
import IconDocumentGreen from 'assets/svgs/Document-green.svg';
import IconDocumentBlue from 'assets/svgs/Document-bleu2.svg';
import { MAX_ITEMS_PER_PAGE } from 'common/components/pagination/Pagination';
import colors from 'styles/export/colors.module.scss';
import Select from 'common/components/select/Select';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import IconPaperBlue from 'assets/svgs/Paper-blue2.svg';
import IconPeopleRed from 'assets/svgs/People-Red.svg';
import { useHistory } from 'react-router';
import DocumentsNumberScreen from './components/documentsNumber/DocumentsNumberScreen';
import TimeScreen from './components/time/TimeScreen';
import Utils from 'common/services/Utils';
import { AnalyticsConnectionYearDto, CompanyAnalyticsDto } from 'api/analytics/models/CompanyAnalyticsDto';
import AnalyticsService from 'api/analytics/AnalyticsService';
import { SystemAnalyticsDto } from 'api/analytics/models/SystemAnalyticsDto';
import VerticalBarsChartWrapper from './components/VerticalBarsChartWrapper/VerticalBarsChartWrapper';
import { analyticsConnection, analyticsCreatedDocuments, analyticsCreatedDocumentsPercentage, analyticsPhotosVsUnsplash, analyticsTimeSpent } from './components/utils/parsers';
import { EditorChartDataValue } from 'screens/editor/models/ChartModels';
import ValueInfo from './components/valueInfo/ValueInfo';

export type OpenDialogType = 'details' | 'edit' | 'new';

type Props = {
    name?: string;
};

const CompanyAnalyticsScreen: React.FC<Props> = ({ name }: Props) => {
    const { t } = useTranslation();
    

    const [reziseReRender, setReziseReRender] = useState<number>(0);

    const [selectedCompanyAnalytics, setSelectedCompanyAnalytics] = useState<SelectValueLabel | null>(null);
    const [companiesList, setCompaniesList] = useState<SelectValueLabel[]>([]);

    const [companyAnalytics, setCompanyAnalytics] = useState<CompanyAnalyticsDto | null>(null);
    const [systemAnalytics, setSystemAnalytics] = useState<SystemAnalyticsDto | null>(null);

    const [connectionGraphYears, setConnectionGraphYears] = useState<number[]>([]);
    const [connectionGraphSelectedYears, setConnectionGraphSelectedYears] = useState<number[]>();
    const [connectionGraph, setConnectionGraph] = useState<EditorChartDataValue>();
    const [connectionData, setConnectionData] = useState<AnalyticsConnectionYearDto[]>();

    const [createdDocumentsGraph, setCreatedDocumentsGraph] = useState<EditorChartDataValue>();
    const [createdDocumentsGraphYears, setCreatedDocumentsGraphYears] = useState<number[]>();
    const [createdDocumentsGraphSelectedYear, setCreatedDocumentsGraphSelectedYear] = useState<number>();

    const [timeSpentGraph, setTimeSpentGraph] = useState<EditorChartDataValue>();
    const [timeSpentGraphYears, setTimeSpentGraphYears] = useState<number[]>();
    const [timeSpentGraphSelectedYear, setTimeSpentGraphSelectedYear] = useState<number>();

    const [createdDocumentsPercentageGraph, setCreatedDocumentsPercentageGraph] = useState<EditorChartDataValue[]>([]);

    const [photosVsUnsplashGraph, setPhotosVsUnsplashGraph] = useState<EditorChartDataValue>();
    const [photosVsUnsplashGraphYears, setPhotosVsUnsplashGraphYears] = useState<number[]>();
    const [photosVsUnsplashGraphSelectedYear, setPhotosVsUnsplashGraphSelectedYear] = useState<number>();

    const [selectCompany, setSelectedCompany] = useState<SelectValueLabel | null>(null)

    const history = useHistory();

    const getCompanies = async () => {
        try {
            Loading.show();

            const criteria: CompanySearchCriteria = { name };
            criteria.page = 1;
            criteria.itemsPerPage = MAX_ITEMS_PER_PAGE;
            criteria.orderColumn = 'name';
            criteria.orderBy = 'asc';

            const result = await CompanyService.getList(criteria);
            const itemList = result.items.map(elem => ({ value: elem.id ?? '', label: elem.name }));

            itemList.push({ value: 'system_company', label: t('app.name') });

            setCompaniesList(Utils.sortAlphabetically(itemList));
            setSelectedCompany(itemList.find(x => x.value === 'system_company') || null);
            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const getAnalytics = async () => {
        setSelectedCompanyAnalytics(selectCompany);
        try {
            Loading.show();

            if (selectCompany?.value === 'system_company') {
                const result = await AnalyticsService.getSystemAnalytics({});
                setSystemAnalytics(result);
                setCompanyAnalytics(null);

                const photosVsUnsplashYears = result.images.map(x => x.year).sort((a, b) => b - a);
                if (photosVsUnsplashYears && photosVsUnsplashYears.length > 0) {
                    const photosVsUnsplashYear = photosVsUnsplashYears[0];
                    const photosVsUnsplash = analyticsPhotosVsUnsplash(t, result.images, photosVsUnsplashYear);

                    setPhotosVsUnsplashGraphSelectedYear(photosVsUnsplashYear);
                    setPhotosVsUnsplashGraphYears(photosVsUnsplashYears);
                    setPhotosVsUnsplashGraph(photosVsUnsplash);
                }

                const createdDocumentsPercentage = analyticsCreatedDocumentsPercentage(result.createdDocuments);
                setCreatedDocumentsPercentageGraph(createdDocumentsPercentage);

                setConnectionGraph(undefined);
                setCreatedDocumentsGraph(undefined);
                setTimeSpentGraph(undefined);
            } else {
                const result = await AnalyticsService.getCompanyAnalytics({ companyId: selectCompany?.value ?? '' });
                setCompanyAnalytics(result);
                setSystemAnalytics(null);

                // #region connection
                const connectionYears = result.connections.map(x => x.year).sort((a, b) => b - a);
                setConnectionGraphYears(connectionYears);
                setConnectionGraphSelectedYears([connectionYears[0]]);
                setConnectionData(result.connections);
                const connection = analyticsConnection(t, result.connections.filter(x => x.year === connectionYears[0]), [connectionYears[0]]);
                setConnectionGraph(connection);
                // #endregion

                const createdDocumentsYears = result.createdDocuments.map(x => x.year).sort((a, b) => b - a);
                if (createdDocumentsYears && createdDocumentsYears.length > 0) {
                    const createdDocumentsYear = createdDocumentsYears[0];
                    const createdDocuments = analyticsCreatedDocuments(t, result.createdDocuments, createdDocumentsYear);

                    setCreatedDocumentsGraphSelectedYear(createdDocumentsYear);
                    setCreatedDocumentsGraphYears(createdDocumentsYears);
                    setCreatedDocumentsGraph(createdDocuments);
                }

                const timeSpentYears = result.timeSpent.map(x => x.year).sort((a, b) => b - a);
                if (timeSpentYears && timeSpentYears.length > 0) {
                    const timeSpentYear = timeSpentYears[0];
                    const timeSpent = analyticsTimeSpent(t, result.timeSpent, timeSpentYear);

                    setTimeSpentGraphSelectedYear(timeSpentYear);
                    setTimeSpentGraphYears(timeSpentYears);
                    setTimeSpentGraph(timeSpent);
                }

                const createdDocumentsPercentage = analyticsCreatedDocumentsPercentage(result.createdDocuments);
                setCreatedDocumentsPercentageGraph(createdDocumentsPercentage);
            }

            Loading.hide();
        }
        catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const onChangeTimeSpentYear = (year: number) => {
        setTimeSpentGraphSelectedYear(year);

        if (companyAnalytics) {
            const timeSpent = analyticsTimeSpent(t, companyAnalytics.timeSpent, year);
            setTimeSpentGraph(timeSpent);
        }
    }

    const onChangeCreatedDocumentsYear = (years: number[]) => {
        const year = years[0];
        setCreatedDocumentsGraphSelectedYear(year);

        if (companyAnalytics) {
            const createdDocuments = analyticsCreatedDocuments(t, companyAnalytics.createdDocuments, year);
            setCreatedDocumentsGraph(createdDocuments);
        }
    }

    const onChangeConnections = (years: number[]) => {
        if (!connectionData) return;

        setConnectionGraphSelectedYears(years);

        const connection = analyticsConnection(t, connectionData.filter(x => !!years.find(y => y === x.year)), years);
        setConnectionGraph(connection);
    }

    const onChangePhotosVsUnsplashYear = (years: number[]) => {
        const year = years[0];
        setPhotosVsUnsplashGraphSelectedYear(year);

        if (systemAnalytics) {
            const photosVsUnsplash = analyticsPhotosVsUnsplash(t, systemAnalytics.images, year);
            setPhotosVsUnsplashGraph(photosVsUnsplash);
        }
    }

    const handleResize = useCallback(() => {
        setReziseReRender(reziseReRender + 1);
    }, [setReziseReRender, reziseReRender])

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return function cleanupListener() {
            window.removeEventListener('resize', handleResize)
        }
    }, [handleResize]);

    useEffect(() => {
        getCompanies();
    }, []);

    useEffect(() => {
        getAnalytics();
    }, [selectCompany]);

    const renderCompanyGraph = (): JSX.Element => {
        return <div
            className={styles.chartContainer}
            key={`container-${selectedCompanyAnalytics}-${reziseReRender}`}>
            <div className={`${styles.chart} ${styles.noMarginLeft}`}>
                {connectionGraph &&
                    <VerticalBarsChartWrapper
                        key={`connection_number-${connectionGraph}`}
                        title={t('companies.analytics.charts.connection_number')}
                        data={connectionGraph}
                        withoutLegend
                        selectYear
                        yearsList={connectionGraphYears}
                        selectedYears={connectionGraphSelectedYears}
                        onSelectYears={onChangeConnections}
                        maxSelectableYears={3} />}
            </div>
            <div className={`${styles.chart} ${styles.noMarginRight}`}>
                {createdDocumentsPercentageGraph &&
                    <DocumentsNumberScreen
                        key={`connection_number-${createdDocumentsPercentageGraph}`}
                        data={createdDocumentsPercentageGraph}
                    />}
            </div>
            <div className={`${styles.chart} ${styles.noMarginLeft}`}>
                {timeSpentGraph &&
                    <TimeScreen
                        key={`connection_number-${timeSpentGraph}`}
                        data={companyAnalytics?.totalExports ?? 0}
                        dataGraph={timeSpentGraph}
                        title={t('companies.analytics.charts.number_exports')}
                        titleGraph={t('companies.analytics.charts.time_spent')}
                        selectYear
                        yearsList={timeSpentGraphYears}
                        currentYear={timeSpentGraphSelectedYear}
                        onSelectYear={onChangeTimeSpentYear}
                    />}
            </div>
            <div className={`${styles.chart} ${styles.noMarginRight}`}>
                {createdDocumentsGraph &&
                    <VerticalBarsChartWrapper
                        key={`connection_number-${createdDocumentsGraph}`}
                        title={t('companies.analytics.charts.created_supports')}
                        data={createdDocumentsGraph}
                        extraMargin
                        selectYear
                        yearsList={createdDocumentsGraphYears}
                        selectedYears={createdDocumentsGraphSelectedYear ? [createdDocumentsGraphSelectedYear] : []}
                        onSelectYears={onChangeCreatedDocumentsYear}
                        maxSelectableYears={1}
                    />}
            </div>
        </div>
    }

    const renderSystemGraph = (): JSX.Element => {
        return <div
            className={styles.chartContainer}
            key={`container-${selectedCompanyAnalytics}-${reziseReRender}`}>
            <div className={`${styles.chart} ${styles.noMarginLeft}`}>
                {photosVsUnsplashGraph &&
                    <VerticalBarsChartWrapper
                        title={t('companies.analytics.charts.photos_vs_unsplash')}
                        data={photosVsUnsplashGraph}
                        extraMargin
                        showLabel={true}
                        maxSize={5}
                        selectYear
                        yearsList={photosVsUnsplashGraphYears}
                        selectedYears={photosVsUnsplashGraphSelectedYear ? [photosVsUnsplashGraphSelectedYear] : []}
                        onSelectYears={onChangePhotosVsUnsplashYear}
                        maxSelectableYears={1}
                    />}
            </div>
            <div className={`${styles.chart} ${styles.noMarginRight}`}>
                <div className={styles.valuesGrid}>
                    <div className={styles.valuesGridRow}>
                        <ValueInfo
                            title={t('companies.analytics.charts.flaticon')}
                            description={t('companies.analytics.charts.flaticon_description')}
                            value={systemAnalytics?.totalIcons || 0}
                            color={colors.primary}
                        />
                        <ValueInfo
                            title={t('companies.analytics.charts.graphics')}
                            description={t('companies.analytics.charts.graphics_description')}
                            value={systemAnalytics?.totalGraphics || 0}
                            color={colors.success}
                        />
                    </div>
                    <div className={styles.valuesGridRow}>
                        <ValueInfo
                            title={t('companies.analytics.charts.gifs')}
                            description={t('companies.analytics.charts.gifs_description')}
                            value={systemAnalytics?.totalGifs || 0}
                            color={colors.danger}
                        />
                        <ValueInfo
                            title={t('companies.analytics.charts.tables')}
                            description={t('companies.analytics.charts.tables_description')}
                            value={systemAnalytics?.totalTables || 0}
                            color={colors.lightblack}
                        />
                    </div>
                </div>
            </div>
            <div className={`${styles.chart} ${styles.noMarginLeft}`}>
                {createdDocumentsPercentageGraph &&
                    <DocumentsNumberScreen
                        data={createdDocumentsPercentageGraph} />}
            </div>
            <div className={`${styles.chart} ${styles.noMarginRight}`}>
                <div className={styles.valuesGridRow}>
                    <ValueInfo
                        title={t('companies.analytics.charts.total_number')}
                        description={t('companies.analytics.charts.total_number_description')}
                        value={systemAnalytics?.totalUsers || 0}
                        color={colors.danger}
                        icon={IconPeopleRed}
                        bold
                        autoMargins={false}
                        backgroundColor={'#ffecec'}
                    />
                </div>
                <div className={styles.valuesGridRow} style={{ marginTop: '1rem' }}>
                    <ValueInfo
                        title={t('companies.analytics.charts.support_created')}
                        description={t('companies.analytics.charts.support_created_description')}
                        value={systemAnalytics?.totalDocuments || 0}
                        color={colors.buttonViewSupport}
                        icon={IconDocumentGreen}
                        bold
                        autoMargins={false}
                        backgroundColor={'#f0fcf4'}
                    />
                </div>
                <div className={styles.valuesGridRow} style={{ marginTop: '1rem' }}>
                    <ValueInfo
                        title={t('companies.analytics.charts.support_exported')}
                        description={t('companies.analytics.charts.support_exported_description')}
                        value={systemAnalytics?.totalExportedDocuments || 0}
                        color={colors.chartBlue}
                        icon={IconDocumentBlue}
                        bold
                        autoMargins={false}
                    />
                </div>
            </div>
        </div>
    }

    return (
        <ScreenTitle title={t('menu.companies')}>
            <PageHeader title={t('companies.title')} hideSearchIcon />

            <div className={styles.container}>

                <div className={styles.line}></div>

                <div className={styles.buttonsContainer}>
                    <div className={styles.title}>{t('companies.analytics.title')}</div>
                    <div className={styles.selectContainer}>
                        <Select
                            options={companiesList}
                            placeholder={t('companies.analytics.select_company_placeholder').toUpperCase()}
                            onChange={(data: any) => {
                                setSelectedCompany(data);
                            }}
                            maxHeight={300}
                            value={selectCompany}
                            icon={IconPaperBlue}
                            isClearable={false}
                            menuPortalTarget={document.querySelector('body')}
                        />
                    </div>

                    <div className={styles.headerButtonsContainer}>
                        <Button
                            type="button"
                            text={t('companies.analytics.title').toUpperCase()}
                            preset={'success'} onClick={getAnalytics}
                            className={styles.button}
                            style={{ color: colors.doveGray, marginRight: '1rem' }}
                            disabled={selectedCompanyAnalytics === selectCompany}
                        >
                            <img src={IconIncrease} className={styles.buttonIcon} />
                        </Button>
                        <Button
                            type="button"
                            text={t('companies.title').toUpperCase()}
                            preset={'primary'} onClick={() => history.push('companies-list')}
                            className={styles.button}
                        >
                            <img src={IconCompany} className={styles.buttonIcon} />
                        </Button>
                    </div>
                </div>

                {!selectCompany && !selectedCompanyAnalytics && <div className={styles.noCompany}>
                    {t('companies.analytics.select_company')}
                </div>}
                <div key={`container-${reziseReRender}`}>
                    {!!selectedCompanyAnalytics
                        && selectedCompanyAnalytics.value !== 'system_company'
                        && renderCompanyGraph()}
                    {!!selectedCompanyAnalytics
                        && selectedCompanyAnalytics.value === 'system_company'
                        && renderSystemGraph()}
                </div>
            </div>

        </ScreenTitle>
    );
}

export default CompanyAnalyticsScreen;
