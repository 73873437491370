import Prefixer from 'common/services/Prefixer';
import { CSSProperties } from 'react';
import colors from 'styles/export/colors.module.scss';

const styles: { [key: string]: CSSProperties } = Prefixer.prefixStyles({
    tableContainerPreview: {
        overflow: 'auto',
    },
    tableContainer: {
        marginBottom: '2rem',
    },
    table: {
        color: colors.midnightBlue,
        marginBottom: '0.3rem',
        width: '100%',
        height: '100%',
        border: 'none',
    },
    tr: {
        color: colors.dodgerBlue,
        height: '1.4rem',
        border: 'none',
    },
    trFirstChild: {
        background: colors.primaryLight,
        color: colors.white,
    },
    td: {
        textAlign: 'center',
        fontSize: '0.9rem',
        minWidth: '56px',
        borderBottom: `1px solid ${colors.primaryLight}`,
    },
    tdFirst: {
        borderLeft: `1px solid ${colors.primaryLight}`,
    },
    tdLast: {
        borderRight: `1px solid ${colors.primaryLight}`,
    },
});

export default styles;
