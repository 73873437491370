import React, { useEffect, useMemo, useState } from 'react';
import EditorDefaults from '../../../../utils/EditorDefaults';
import { ElementProps } from '../../../../utils/EditorProps';
import Prefixer from '../../../../../../common/services/Prefixer';
import EditorResolver from 'screens/editor/utils/EditorResolver';
import { EditorChartDataValue } from 'screens/editor/models/ChartModels';

type Props = {
    isExporting?: boolean;
} & ElementProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ChartElementRender = ({ page, element, selected, isExporting, ...props }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {

    const dataValue = element.value as EditorChartDataValue;
    const ChartElement = EditorResolver.getChartRenderByType(dataValue.type);

    const [show, setShow] = useState(isExporting ? false : true);

    useEffect(() => {
        setShow(true);
    }, [element]);

    const style = useMemo(() => Prefixer.prefixStyle(EditorDefaults.getElementStyles(element)), [element]);

    return (
        <div
            {...props}
            ref={ref}
            style={style}
        >
            {ChartElement && <ChartElement data={dataValue} width={element.width} height={element.height}>{show}</ChartElement>}
        </div>
    );

}

export default React.forwardRef<HTMLDivElement, Props>(ChartElementRender);
