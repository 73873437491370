import editor from './reducer';
import ReduxUndo, { includeAction, StateWithHistory } from 'redux-undo';
import {
    ADD_ELEMENT_TO_PAGE,
    ADD_PAGE,
    DUPLICATE_PAGE, EditorState, EDITOR_REDO, EDITOR_UNDO, REMOVE_PAGE, REMOVE_PAGE_ELEMENTS, RE_ORDER_PAGES, SET_INITIAL_STATE,
    UPDATE_PAGE, UPDATE_PAGE_ELEMENT,
} from './type';

const editorUndoReducer = ReduxUndo(editor, {
    // limit: 20,
    undoType: EDITOR_UNDO,
    redoType: EDITOR_REDO,
    // ignoreInitialState: true,
    // filter: excludeAction([
    //     SET_SELECTED_PAGE_ID,
    //     SET_SELECTED_PAGE_ELEMENT_ID,
    //     SET_PAGES,
    //     CLEAR_SELECTED,
    //     SET_IS_TEXT_ELEMENT_IN_EDITING_MODE,
    //     UPDATE_PAGE_THUMBNAIL_CHANGER_ID,
    //     UPDATE_PAGE_THUMBNAIL,
    //     SET_AUTO_SAVE,
    //     SET_SIDEBAR_STATE,
    //     SET_CLIP_ACTIVE_ELEMENT_ID,
    //     REMOVE_CROPPED_FROM_PAST,
    // ]),
    filter: includeAction([
        SET_INITIAL_STATE,
        ADD_PAGE,
        UPDATE_PAGE,
        DUPLICATE_PAGE,
        REMOVE_PAGE,
        RE_ORDER_PAGES,
        UPDATE_PAGE_ELEMENT,
        ADD_ELEMENT_TO_PAGE,
        REMOVE_PAGE_ELEMENTS,
    ]),
});

const editorUndoReducerCustom = (state: StateWithHistory<EditorState>, action: any): StateWithHistory<EditorState> => {
    switch (action.type) {
        case 'REMOVE_CROPPED_FROM_PAST':
            const newState = {
                ...state,
                past: state.past.map(s => removeCroppedFromState(s, action.pageId, action.elementId))
            };
            return editorUndoReducer(newState, action);
    
        default:
            return editorUndoReducer(state, action);
    }
}

function removeCroppedFromState(s: EditorState, pageId: string, elementId: string | null): EditorState {
    return {
        ...s,
        pages: s.pages.map(p => {
            if (p.id !== pageId) {
                return p;
            }
            
            return {
                ...p,
                elements: p.elements.map(el => {
                    if (el.id !== elementId && elementId) {
                        return el;
                    }

                    return {
                        ...el,
                        clip: null,
                    }
                }),
            };
        }),
    }
}

export default editorUndoReducerCustom;
