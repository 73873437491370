import React from 'react';
import { useDispatch } from 'react-redux';
import { duplicatePageElement } from 'store/editor/action';
import { ToolbarOptionProps } from '../../../../utils/EditorProps';
import ToolbarButton from '../toolbarButton/ToolbarButton';
import IconDuplicate from 'assets/svgs/toolbar/Duplicate.svg';
import { useTranslation } from 'react-i18next';

const DuplicateToolbarOption: React.FC<ToolbarOptionProps> = ({ page, elements, className }: ToolbarOptionProps) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const duplicate = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        evt.preventDefault();
        evt.stopPropagation();

        for (const element of (elements || [])) {
            dispatch(duplicatePageElement(page!.id, element.id));
        }
    }

    return (
        <ToolbarButton
            className={className}
            text={t('editor.toolbar.duplicate')}
            onClick={duplicate}
            icon={IconDuplicate}
        />
    );
}

export default DuplicateToolbarOption;
