import React from 'react';
import { useDrag, DragSourceMonitor } from 'react-dnd';
import { PageDropElement, PageElement } from '../../../../models/EditorModels';

type Props = {
    generateElement: () => PageElement;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const DraggableContentItem: React.FC<Props> = ({ children, generateElement, ...props }: Props) => {
    
    const [{ opacity }, dragRef] = useDrag(
        () => ({
            type: 'element',
            item: (): PageDropElement => {
                return {
                    element: generateElement(),
                };
            },
            collect: (monitor: DragSourceMonitor) => ({
                opacity: monitor.isDragging() ? 0.4 : 1,
            }),
        }),
        [generateElement],
    )

    return (
        <div {...props} ref={dragRef} style={{ opacity }}>
            {children}
        </div>
    );

}

export default DraggableContentItem;
