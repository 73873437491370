import styles from './ChartPreview.module.scss';
import IconEye from 'assets/svgs/Eye-vert.svg';
import { useTranslation } from 'react-i18next';
import React from 'react';
import DraggableContentItem from 'screens/editor/components/editorSidebar/components/draggableContentItem/DraggableContentItem';
import EditorElementGenerator from 'screens/editor/utils/EditorElementGenerator';
import EditorResolver from 'screens/editor/utils/EditorResolver';
import { EditorChartDataValue, EditorChartTableData, EditorChartType } from 'screens/editor/models/ChartModels';

type Props = {
    chartType: EditorChartType;
    data: EditorChartTableData[];
};

const ChartPreview = ({ chartType, data }: Props) => {
    const { t } = useTranslation();
    const ChartElement = EditorResolver.getChartRenderByType(chartType);
    const chartDataValue: EditorChartDataValue = {
        type: chartType,
        tableData: data,
    };
    const width = 260;
    const height = 200;

    const createChartElement = () => {
        return EditorElementGenerator.createChartElement(width, height, {
            type: chartType,
            tableData: data,
        });
    }

    const renderFallback = () => {
        return <>
            <img src={IconEye} className={styles.icon} />
            <span>{t('editor.sidebar.chart_preview_text')}</span>
        </>;
    }

    return (
        <DraggableContentItem generateElement={() => createChartElement()} className={styles.container}>
            <ChartElement data={chartDataValue} width={width} height={height} fallback={renderFallback()} preview={true} />
        </DraggableContentItem>
    );
}

export default ChartPreview;