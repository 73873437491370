import React, { ReactElement } from 'react';
import Modal from 'react-modal';
import styles from './OverlayedModal.module.scss';

type Props = {
    children: ReactElement | ReactElement[];
} & ReactModal.Props;

const OverlayedModal: React.FC<Props> = ({ children, ...props }: Props) => {

    const customStyles = {
        content: {
            ...(props.style?.content ? props.style.content : {})
        },
        overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            backdropFilter: 'blur(5px)',
            backgroundColor: 'rgba(0,0,0,0.2)',
            ...(props.style?.overlay ? props.style.overlay : {})
        },
    };

    return (
        <Modal
            {...props}
            style={{
                ...(props.style ? props.style : {}),
                ...customStyles,
                
            }}
            className={styles.content}
        >
            {children}
        </Modal >
    );
}

export default OverlayedModal;
