import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ModelSlide.module.scss';
import { UseFormMethods } from 'react-hook-form';
import { DocumentViewModel } from 'api/documents/models/DocumentViewModel';
import NavigationButtons from '../navigationButtons/NavigationButtons';
import DocumentModelPicker from 'common/components/documentModelPicker/DocumentModelPicker';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import { DocumentType } from 'api/documents/enums/DocumentType';
import Button from 'common/components/button/Button';
import { FaArrowLeft } from 'react-icons/fa';
import EditorUtils from 'screens/editor/utils/EditorUtils';
import DocumentEditorData from 'api/documents/models/DocumentEditorData';
import DateFormat from 'common/components/dateFormat/dateFormat';
import MiniThumbnail from './components/miniThumbnail/MiniThumbnail';
import InfinityScroll from 'common/components/infinityScroll/InfinityScroll';
import { DocumentFormat } from 'api/documents/enums/DocumentFormat';
import { Page } from 'screens/editor/models/EditorModels';


type Props = {
    onPreviousSlide: () => void;
    onSubmit: () => void;
    form: UseFormMethods<DocumentViewModel>;
    mode: string | null; // TODO: usar enum
    documentType?: DocumentType;
};

const ModelSlide: React.FC<Props> = ({ onPreviousSlide, onSubmit, form, documentType }: Props) => {

    const { t } = useTranslation();
    const [selectedModel, setSelectedModel] = useState<string | null>(null);

    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [modelToShow, setModelToShow] = useState<DocumentViewModel | null>();
    const [editorData, setEditorData] = useState<DocumentEditorData | null>(null);

    const [totalPages, setTotalPages] = useState<number>();


    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const selectModel = (model: DocumentViewModel | null) => {
        setSelectedModel(model?.id || null);
        form.setValue('editorData', model?.editorData || null);
    }

    const selectModelToShowDetails = (model: DocumentViewModel | null) => {
        setShowDetails(true);
        setModelToShow(model);
    }

    const onCloseDetails = () => {
        setShowDetails(false);
        setModelToShow(null);
    }

    const renderBrochureThumbnails = () => {
        if (!editorData) return;
        const parentContainer = [];

        for (let i = 0; i < editorData.pages.length; i += 2) {
            const page1: Page | null = i < editorData.pages.length ? editorData.pages[i] : null;
            const page2: Page | null = (i + 1) < editorData.pages.length ? editorData.pages[(i + 1)] : null;

            const container =
                <div className={styles.thumbnailBrochureContainer} key={'minithumb-' + i}>
                    {page1 && <MiniThumbnail thumbnail={page1.thumbnail} isDouble={true} number={page1.number} key={`thumbnail-${i}`} />}
                    {page2 && <MiniThumbnail thumbnail={page2.thumbnail} isDouble={true} number={page2.number} key={`thumbnail-${i}`} />}
                </div>;

            parentContainer.push(container);
        }

        return parentContainer;
    };

    useEffect(() => {
        const model: DocumentEditorData | null = (modelToShow) ? EditorUtils.parseDocumentEditorData(modelToShow.editorData, modelToShow.format) : null;
        if (model) {
            setTotalPages(model?.pages.length);

            if (model?.pages.length > 1) {
                model.pages.shift();
            }
            setEditorData(model);
        }

    }, [showDetails]);

    return (
        <div className={styles.container}>
            <div style={{ display: !showDetails ? 'block' : 'none' }}>
                <div className={styles.header}>
                    {t('documents.select_model_to_start')}
                </div>
                <DocumentModelPicker
                    onSelectModel={selectModel}
                    selectedModelId={selectedModel}
                    companyId={loggedUser?.companyId ?? null}
                    format={form.watch('format')}
                    documentType={documentType}
                    style={{ maxHeight: '500px' }}
                    onShowDetails={selectModelToShowDetails}
                />
                <div>
                    <NavigationButtons
                        onBack={onPreviousSlide}
                        onNext={onSubmit}
                        isSubmit={true}
                        backText={t('common.back')}
                        nextText={t('documents.start').toUpperCase()}
                        iconBack={true}
                        style={{ padding: '0 2rem' }}
                    />
                </div>
            </div>
            <div style={{ display: showDetails ? 'block' : 'none' }}>
                <div className={styles.header}>
                    {`${t('show_model_details_modal.title')} ${modelToShow?.name}`}
                </div>
                <div className={styles.containerDetails}>
                    <div className={styles.containerLeft}>
                        <div className={styles.itemImageContainer}>
                            <div className={styles.itemImage} style={{ backgroundImage: `url('${modelToShow?.thumbnailMediaUrl}')` }}>
                            </div>
                            <span>1</span>
                        </div>

                        <div className={styles.itemInfoDetails}>
                            <span className={styles.itemInfoDetailsTitle}>{t('documents.formatTitle')}</span>
                            <span className={styles.itemInfoDetailsContent}>{t(`documents.format.${modelToShow?.format}` as any)}</span>
                        </div>
                        <div className={styles.itemInfoDetails}>
                            <span className={styles.itemInfoDetailsTitle}>{t('documents.numberOfPage')}</span>
                            <span className={styles.itemInfoDetailsContent}>
                                {`${editorData?.pages ? editorData?.pages.find(x => x.id)?.number : editorData?.pages.length}`}
                                {`${totalPages == 1 ? ' ' + (t('documents.page')).toLowerCase() : ' ' + (t('documents.pages')).toLowerCase()}`}
                            </span>
                        </div>
                        <div className={styles.itemInfoDetails}>
                            <span className={styles.itemInfoDetailsTitle}>{t('documents.createdDateInfo')}</span>
                            <span className={styles.itemInfoDetailsContent}>{modelToShow && modelToShow.createdDate && <DateFormat value={modelToShow.createdDate}></DateFormat>}</span>
                        </div>
                    </div>
                    <div className={styles.containerRight}>
                        <div id="scrollModalHere" className={styles.documents}>
                            <div>
                                <InfinityScroll
                                    totalItems={editorData ? editorData.pages.length : 0}
                                    inModal={true}
                                    executeOnScroll={() => { return }}
                                    continueScroll={false}
                                    elementIdToScroll='scrollModalHere'>
                                    <div className={styles.list}>
                                        {modelToShow?.format !== DocumentFormat.PRODUCT_BROCHURE && editorData && editorData.pages.length > 0 && editorData.pages.map((page, i) => {
                                            return <MiniThumbnail thumbnail={page.thumbnail} number={page.number} key={`thumbnail-${i}`} />
                                        })}

                                        {modelToShow?.format === DocumentFormat.PRODUCT_BROCHURE && editorData && editorData.pages.length > 0 && renderBrochureThumbnails()}
                                    </div>
                                </InfinityScroll>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    <Button
                        className={styles.button}
                        type="button"
                        preset={'secondary'}
                        onClick={onCloseDetails}
                    >
                        <FaArrowLeft className={styles.iconLeft} />
                        <span className={styles.span}>{t('common.back').toUpperCase()}</span>
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ModelSlide;

