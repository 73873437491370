import React, { useEffect, useState } from 'react';
import styles from './AddCollaboratorsModal.module.scss';
import { useTranslation } from 'react-i18next';
import OverlayedModal from 'common/components/overlayedModal/OverlayedModal';
import { DocumentCollaboratorViewModel } from 'api/documents/models/DocumentViewModel';
import Button from 'common/components/button/Button';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Tag } from 'react-tag-autocomplete';
import Tags from 'common/components/tags/Tags';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserSearchCriteria } from 'api/users/models/UserSearchCriteria';
import DocumentsService from 'api/documents/DocumentsService';
import UsersService from 'api/users/UsersService';
import Logger from 'common/services/Logger';
import { IS_ADMIN, LOGGER_LOG_TYPE } from 'Config';
import { addToast } from 'common/services/Toaster';
import Loading from 'common/services/Loading';
import { UserProfile } from 'api/account/models/UserProfile';
import { DocumentsSearchCriteria } from 'api/documents/models/DocumentsSearchCriteria';

type Props = {
    isVisible: boolean;
    onApply: (colls: DocumentCollaboratorViewModel[], companies: DocumentCollaboratorViewModel[]) => void;
    onClose: () => void;
    id: string;
};

const AddCollaboratorsModal: React.FC<Props> = ({ isVisible, onApply, onClose, id }: Props) => {
    const { t } = useTranslation();
    
    
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const companyId = loggedUser?.companyId;
    const isAdmin = IS_ADMIN(loggedUser);

    // const userCriteria: UserSearchCriteria = { getUsersList: true, companyId: companyId ?? undefined };
    const criteria = { companyId: companyId } as any;

    const [collaborators, setCollaborators] = useState<DocumentCollaboratorViewModel[]>([]);
    const [listCollabs, setListCollabs] = useState<Tag[]>([]);
    const [companies, setCompanies] = useState<DocumentCollaboratorViewModel[]>([]);
    const [listCompanies, setListCompanies] = useState<Tag[]>([]);

    const getInfos = async () => {
        Loading.show();
        try {
            if(isAdmin)
            {
                const comps = await DocumentsService.getCompaniesById(id);
                setCompanies(comps);
                let listCompaniesResult = await DocumentsService.getUsersForCollaboration(criteria);
                comps.forEach(company => {
                    listCompaniesResult = listCompaniesResult.filter(x => x.userId !== company.userId);
                });
                setListCompanies(listCompaniesResult.map(y => ({ id: y.userId ?? '', name: y.userName ?? '' })));
            } 
            else
            {
                const collab = await DocumentsService.getCollaboratorsById(id);
                setCollaborators(collab);
                let listCollabsResult = await DocumentsService.getUsersForCollaboration(criteria);
                collab.forEach(collaborator => {
                    listCollabsResult = listCollabsResult.filter(x => x.userId !== collaborator.userId);
                });
                setListCollabs(listCollabsResult.map(y => ({ id: y.userId ?? '', name: y.userName ?? '' })));
            }

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.DOCUMENTS, `Couldn't get the collaborators from document ${id}`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    useEffect(() => {
        getInfos();
    }, []);

    return (
        <OverlayedModal isOpen={isVisible} style={{ content: { maxWidth: '80%' } }}>
            
            <div className={styles.modalTitle} dangerouslySetInnerHTML={
                { __html: isAdmin && !companyId ? t('add_companies_modal.title') : t('add_collaborators_modal.title') }}>
            </div>

            <div className={styles.modalDescription} dangerouslySetInnerHTML={{ 
                __html: isAdmin && !companyId ? t('add_companies_modal.description') : t('add_collaborators_modal.description') }}>
            </div>

            <div className={styles.tags}>
                <div className={styles.collabs}>
                        { isAdmin && !companyId && <Tags
                            placeholderText={t('documents.companies')}

                            onAddition={company => {
                                setCompanies([...(companies || []),
                                    {
                                        userId: company.id as string,
                                        userName: company.name,
                                    }]);
                                setListCompanies([...((listCompanies || []).filter(collabTag => collabTag.id !== company.id))]);
                            }}
                        
                            onDelete={companyIndex => {
                                const comp = (companies || []).find((_, i) => i === companyIndex);
                                setCompanies((companies || []).filter((_, i) => i !== companyIndex));
                                if(comp) {
                                    setListCompanies([...(listCompanies || []), { id: comp.userId ?? '', name: comp.userName ?? '' }]);
                                }
                            }}
                            tags={(companies || []).map(x => ({ id: x.userId!, name: x.userName! }))}
                            addOnBlur={true}
                            suggestions={listCompanies}
                            type={'custom'}
                        /> }
                        { !(isAdmin && !companyId) && <Tags
                            placeholderText={t('documents.collaborator')}

                            onAddition={collaborator => {
                                setCollaborators([...(collaborators || []),
                                    {
                                        userId: collaborator.id as string,
                                        userName: collaborator.name,
                                    }]);
                                setListCollabs([...((listCollabs || []).filter(collabTag => collabTag.id !== collaborator.id))]);
                            }}
                        
                            onDelete={collabIndex => {
                                const collab = (collaborators || []).find((_, i) => i === collabIndex);
                                setCollaborators((collaborators || []).filter((_, i) => i !== collabIndex));
                                if(collab) {
                                    setListCollabs([...(listCollabs || []), { id: collab.userId ?? '', name: collab.userName ?? '' }]);
                                }
                            }}
                            tags={(collaborators || []).map(x => ({ id: x.userId!, name: x.userName! }))}
                            addOnBlur={true}
                            suggestions={listCollabs}
                            type={'custom'}
                        /> }
                </div>
            </div>
            <div className={styles.buttonsContainer} >
                <Button
                    className={styles.button}
                    type="button"
                    preset={'secondary'}
                    onClick={onClose}
                >
                    <FaTimes className={styles.iconLeft} />
                    <span>{(t('common.cancel')).toUpperCase()}</span>

                </Button>

                <Button
                    className={styles.button}
                    type={'button'}
                    text={(t('common.add')).toUpperCase()}
                    onClick={() => onApply(collaborators, companies)}
                >
                    <FaCheck className={styles.icon} />
                </Button>
            </div>
        </OverlayedModal>
    );
}

export default AddCollaboratorsModal;
