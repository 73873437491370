import React, { memo, useMemo } from 'react';
import { TableRenderProps } from '../../../../utils/EditorProps';
import { Global } from 'recharts';
import styles from './Table1Render.style';
import EditorTablesUtils from 'screens/editor/utils/EditorTablesUtils';
Global.isSsr = true;

const Table1Render = ({ data, width, height, fallback, inPreview = false, ...props }: TableRenderProps, ref: React.ForwardedRef<HTMLDivElement>) => {

    const columns = EditorTablesUtils.generateTableColumns();
    if (!data || !data.tableData || data.tableData.length < 1) {
        return fallback || <></>;
    }

    const [tableData] = useMemo(() => {
        return EditorTablesUtils.tableDataToRowsData(data.tableData);
    }, [data.tableData]);

    if (!tableData) {
        return fallback || <></>;
    }

    return (
        <div
            {...props}
            ref={ref}
            className="previewScrollbars"
            style={{
                'width': width,
                'height': height,
                ...(inPreview ? styles.tableContainerPreview : styles.tableContainerEditor)
            }}
        >
            <table style={styles.table} cellSpacing="0" cellPadding="0">
                <tbody>
                    {data.tableData.map((row, rowIndex) => (
                        <tr key={row.id} style={styles.tr}>
                            {columns.map((column, columnIndex) => {
                                if(columnIndex > 0) {
                                    const value: string = column.render ? column.render(rowIndex) : row[column.accessor as any];
                                    return (
                                        <td
                                            key={`${rowIndex}-${columnIndex}`}
                                            style={{
                                                ...styles.td,
                                                ...(rowIndex === 0 ? styles.trFirstChild : {}),
                                                ...(rowIndex === data.tableData.length - 1 ? styles.trLastChild : {}),
                                                ...(rowIndex % 2 == 0 ? styles.trEven : styles.trOdd),
                                            }}
                                        >
                                            <span>
                                                {value}
                                            </span>
                                        </td>
                                    );
                                }
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default memo(React.forwardRef<HTMLDivElement, TableRenderProps>(Table1Render));