import { UserProfile } from 'api/account/models/UserProfile';
import DocumentsService from 'api/documents/DocumentsService';
import { DocumentType } from 'api/documents/enums/DocumentType';
import { DocumentViewModel } from 'api/documents/models/DocumentViewModel';
import Button from 'common/components/button/Button';
import DateFormat from 'common/components/dateFormat/dateFormat';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addToast } from 'common/services/Toaster';
import EditorDefaults from 'screens/editor/utils/EditorDefaults';
import { Reducers } from 'store/types';
import styles from './DocumentPreview.module.scss';

type Props = {
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const DocumentPreview = ({ ...props }: Props) => {
    
    const { t } = useTranslation();
    const history = useHistory();

    const navigateTo = () => {
        history.push(`/supports`);
        return;
    };

    const loggedUser = useSelector<Reducers, UserProfile | null>(
        state => state.authentication.profile
    );
    const companyId = loggedUser?.companyId;
    const [documents, setDocuments] = useState<DocumentViewModel[]>([]);

    const getData = async () => {
        try {
            Loading.show();

            const docs = await DocumentsService.getList({
                page: 1,
                itemsPerPage: 15,
                isAdmin: !companyId ? true : false,
                type: !companyId ? DocumentType.MODEL_TO_CUSTOMIZE : DocumentType.DEFAULT, 
                companyId: companyId,
                orderColumn: 'created_date',
                orderBy: 'desc'
            });

            // Convert string to date
            docs?.items?.map(
                doc => (doc.createdDate = new Date(doc.createdDate))
            );

            docs?.items?.map(doc => {
                if (doc) {
                    const PageInfo = EditorDefaults.getPageSizeByFormat(
                        doc.format
                    );
                    doc.orientation = PageInfo.orientation;
                }
            });

            setDocuments([ ...docs?.items ]);

            Loading.hide();
        } catch (error) {
            Logger.error(
                LOGGER_LOG_TYPE.DOCUMENTS,
                `Couldn't get documents list`,
                error
            );
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });
            Loading.hide();
        }
    };

    const navigateToEditor = (id: string) => {
        history.push(`/editor/${id}/documents`);
        return;
    };

    useEffect(() => {
        getData();
    }, []);

    const renderDocuments = (doc: DocumentViewModel, index: number) => {
        return (
            <div key={index} className={styles.documentsContent} onClick={() => navigateToEditor(doc.id)}>
                <div className={styles.documentsContentImage}>
                    <img
                        src={doc.thumbnailMediaUrl}
                        alt=''
                        style={{
                            border: '1px solid #e8e8e8',
                            borderRadius: '0.25rem',
                            width:
                                doc.orientation === 'PORTRAIT'
                                    ? '3rem'
                                    : '4rem',
                            height:
                                doc.orientation === 'PORTRAIT'
                                    ? '4rem'
                                    : '3rem',
                        }}
                    />
                </div>
                <div className={styles.documentsContentInfos}>
                    <span className={styles.documentsContentInfosTitle}>
                        {doc.name}
                    </span>
                    <DateFormat
                        className={styles.documentsContentInfosText}
                        value={doc.createdDate}
                    ></DateFormat>
                    <span className={styles.documentsContentInfosText}>
                        {doc.userCreatedName}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div {...props} className={`${styles.container} ${props.className || ''}`}>
            <div className={styles.innerContainer}>
                <div className={styles.title}>
                    {t('home.document_preview.title')}
                </div>
                <div className={styles.documentsContainer}>
                    {documents.map((doc, key) => {
                        return renderDocuments(doc, key);
                    })}
                </div>
            </div>
            <div className={styles.containerOverlay}>
                <Button
                    type='button'
                    text={t('home.document_preview.button').toUpperCase()}
                    preset={'primary'}
                    onClick={() => navigateTo()}
                />
            </div>
        </div>
    );
};

export default DocumentPreview;
