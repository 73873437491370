import Http from '../../common/services/Http';
import { CompanyAnalyticsDto } from './models/CompanyAnalyticsDto';
import { SystemAnalyticsDto } from './models/SystemAnalyticsDto';
import { AnalyticEventsListViewModel, CreateAnalyticEventViewModel } from './models/AnalyticEventViewModel';
import CompanyAnalyticsSearchCriteria from './models/CompanyAnalyticsSearchCriteria';
import SystemAnalyticsSearchCriteria from './models/SystemAnalyticsSearchCriteria';
import { CreateAnalyticVisitViewModel } from './models/AnalyticVisitViewModel';

class AnalyticsService {
    
    public getCompanyAnalytics(criteria: CompanyAnalyticsSearchCriteria) {
        return Http.get<CompanyAnalyticsDto>('analytics/company', criteria);
    }
    
    public getSystemAnalytics(criteria: SystemAnalyticsSearchCriteria) {
        return Http.get<SystemAnalyticsDto>('analytics/system', criteria);
    }

    public visit(model: CreateAnalyticVisitViewModel) {
        return Http.post<string>('analytics/visit', model);
    }
    
    public createEvent(model: CreateAnalyticEventViewModel) {
        return Http.post<string>('analytics/create-event', model);
    }
    
    public createEvents(model: AnalyticEventsListViewModel) {
        return Http.post('analytics/create-events', model);
    }
}

export default new AnalyticsService();