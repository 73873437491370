import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './NavigationButtons.module.scss';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Button from '../../../../../../common/components/button/Button';

type Props = {
    onBack?: () => void;
    onNext?: () => void;
    backDisabled?: boolean;
    nextDisabled?: boolean;
    backText?: string;
    nextText?: string;
    iconBack?: boolean;
    iconNext?: boolean;
    isSubmit?: boolean;
    style?: CSSProperties;
};

const NavigationButtons: React.FC<Props> = ({ onBack, onNext, backDisabled = false, nextDisabled = false, backText, nextText, iconBack, iconNext, isSubmit, style }: Props) => {

    const { t } = useTranslation();

    return (
        <div className={styles.buttonsContainer} style={style} >
            <Button
                className={styles.button}
                type="button"
                preset={'secondary'}
                onClick={onBack}
                disabled={backDisabled}
            >
                {iconBack && <FaArrowLeft className={styles.iconLeft} />}
                <span className={styles.span}>{(backText || t('common.cancel')).toUpperCase()}</span>

            </Button>

            <Button
                isDisabled={nextDisabled}
                className={styles.button}
                type={'button'}
                onClick={onNext}
            >
                <span className={styles.span}>{nextText || t('common.next').toUpperCase()}</span>
                {iconNext && <FaArrowRight className={styles.icon} />}
            </Button>
        </div>
    );
}

export default NavigationButtons;
