import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { CheckUsernameAndEmailAvailabilityViewModel } from './models/CheckUsernameAndEmailAvailabilityViewModel';
import { UserSearchCriteria } from './models/UserSearchCriteria';
import { UserViewModel } from './models/UserViewModel';
import { RoleDto } from './models/RoleDto';
import { RoleSearchCriteria } from './models/RoleSearchCriteria';
import { POLICIES } from 'Config';

class UserService {

    public getList(criteria: UserSearchCriteria) {
        return Http.get<Page<UserViewModel>>('users', criteria);
    }

    public getUserById(id: string) {
        return Http.get<UserViewModel>('users/'+ id);
    }

    public save(model: UserViewModel) {
        return !!model.id ? Http.put('users', model) : Http.post('users', model);
    }

    public remove(model: UserViewModel) {
        return Http.put('users/deactivate', model);
    }

    public checkEmailAvailability(model: CheckUsernameAndEmailAvailabilityViewModel) {
        return Http.post<any>('users/check-email-availability', model);
    }
    
    public getAuthors(criteria: UserSearchCriteria) {
        return Http.get<UserViewModel[]>('users/get-authors', criteria);
    }

    public getRoles(criteria: RoleSearchCriteria) {
        return Http.get<RoleDto[]>('users/get-all-roles', criteria);
    }

    public hasPolicies(userPolicies: string[], policies: (keyof typeof POLICIES)[], type: 'OR' | 'AND' = 'AND') {

        const allowed = type === 'AND'
            ? (policies || []).map(p => (userPolicies || []).find(up => up === POLICIES[p]) ? true : false).filter(p => !p).length === 0 ? true : false
            : (policies || []).map(p => (userPolicies || []).find(up => up === POLICIES[p]) ? true : false).filter(p => p).length > 0 ? true : false;

        return allowed;
    }
}

export default new UserService();