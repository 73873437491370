import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EditorState } from '../../../../store/editor/type';
import { Reducers } from '../../../../store/types';
import styles from './EditorSideOptions.module.scss';
import { editorRedo, editorUndo, setZoom } from '../../../../store/editor/action';
import EditorInternalService from '../../utils/EditorInternalService';
import Loading from '../../../../common/services/Loading';
import Logger from '../../../../common/services/Logger';
import { LOGGER_LOG_TYPE } from '../../../../Config';
import { addToast } from 'common/services/Toaster';
import AutoSaveMessage from './components/autoSaveMessage/AutoSaveMessage';
import ZoomSlider from './components/zoomSlider/ZoomSlider';
import BottomButtons from './components/bottomButtons/BottomButtons';
import PagesList from './components/pagesList/PagesList';
import DocumentExportModal from 'common/components/documentExport/DocumentExport';
import { EditorAutoSave } from 'screens/editor/models/EditorModels';

export const SIDE_OPTIONS_WIDTH = 300;

const EditorSideOptions: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const editorState = useSelector<Reducers, EditorState>(state => state.editor.present);
    const autoSave = useSelector<Reducers, EditorAutoSave | null>(state => state.editorControl.autoSave);
    const totalPastStates = useSelector<Reducers, number>(state => state.editor.past.length);
    const totalFutureStates = useSelector<Reducers, number>(state => state.editor.future.length);

    const canUndo = totalPastStates > 1;
    const canRedo = totalFutureStates > 0;

    const zoom = editorState.zoom;

    const [openDocModalExport, setOpenDocModalExport] = React.useState<boolean>(false);

    const onSetZoom = useCallback((zoom: number) => {
        dispatch(setZoom(zoom));
    }, []);

    const undo = useCallback(() => {
        dispatch(editorUndo());
    }, []);

    const redo = useCallback(() => {
        dispatch(editorRedo());
    }, []);

    const onExport = useCallback(async () => {
        try {
            Loading.show();
            await EditorInternalService.quickSave();
            Loading.hide();
            setOpenDocModalExport(true);

        } catch (error) {
            Loading.hide();
            addToast(t('common.messages.error_process'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't export pages`);
        }
    }, [editorState.pages]);


    return (
        <div className={styles.container} style={{ width: `${SIDE_OPTIONS_WIDTH}px` }}>

            <div className={styles.innerContainer}>

                <PagesList
                    pages={editorState.pages}
                    selectedPageId={editorState.selectedPageId}
                    className={styles.boxContainer}
                />

                <ZoomSlider value={zoom} onChange={onSetZoom} className={styles.boxContainer} />

                <BottomButtons
                    canUndo={canUndo}
                    canRedo={canRedo}
                    onUndo={undo}
                    onRedo={redo}
                    onExport={onExport}
                />

                <div className={styles.filler}></div>

                {autoSave && <AutoSaveMessage autoSave={autoSave} />}
                {
                    editorState.documentId && openDocModalExport && <DocumentExportModal
                        documentId={editorState.documentId}
                        isVisible={true}
                        onCancel={() => setOpenDocModalExport(false)}
                        onSuccess={() => setOpenDocModalExport(false)}
                    />
                }
            </div>
        </div>
    );
}

export default EditorSideOptions;
