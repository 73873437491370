import React, { CSSProperties, useState } from 'react';
import { Item, Menu, MenuProvider } from 'react-contexify';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import styles from './TextAreaWithHelper.module.scss';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css'

type Props = {
    value: string;
    setValue: (content: string) => void;
    contextMenuOptions: ContextMenuOption[];
    id: string;
    style?: CSSProperties;
    readOnly: boolean;
}

export type ContextMenuOption = {
    onClick: (element: HTMLInputElement | ReactQuill | undefined) => void;
    name: string;
}

const TextAreaWithHelper: React.FC<Props> = ({ value, setValue, contextMenuOptions, id, style, readOnly }: Props) => {
    const [element, setElement] = useState<HTMLInputElement | ReactQuill>();
    const { t } = useTranslation();

    const onRegisterRef = (instance: HTMLInputElement | ReactQuill | null) => {
        if (instance) setElement(instance);
    }

    const renderContextMenu = () => {
        return <Menu id={id}>
            {contextMenuOptions.map((option, index) => {
                return <Item key={`item-option-${index}`} onClick={() => option.onClick(element)}>
                    <div className={styles.menuItem}>
                        <span>{t(option.name as any)}</span>
                    </div>
                </Item>;
            })}
        </Menu>;
    }

    const renderReactQuill = () => {
        return <ReactQuill
            ref={onRegisterRef}
            theme={'snow'}
            value={value}
            onChange={(content, delta, source, editor) => setValue(content)}
            readOnly={readOnly}
            modules={{
                clipboard: {
                    matchVisual: false
                }
            }}
        />;
    }

const renderReactQuillWithContainer = () => {
    return <MenuProvider id={id} storeRef={false} style={style} >
        {renderReactQuill()}
        {renderContextMenu()}
    </MenuProvider>
}

return (
    <>
        {readOnly && renderReactQuill()}
        {!readOnly && renderReactQuillWithContainer()}
    </>
);
}

export default TextAreaWithHelper;
