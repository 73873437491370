import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { addToast } from 'common/services/Toaster';
import { FaCheck, FaEdit } from 'react-icons/fa';
import Button from 'common/components/button/Button';
import Loading from 'common/services/Loading';
import FormItem from 'common/components/formItem/FormItem';
import InputWithError from 'common/components/input/InputWithError';
import OverlayedModal from 'common/components/overlayedModal/OverlayedModal';
import Label from 'common/components/label/Label';
import AppSettingsService from 'api/appSettings/AppSettingsService';
import { AppSettingsViewModel } from 'api/appSettings/models/AppSettingsViewModel';
import IconClose from 'assets/svgs/Cross-bleu2-contour.svg';
import image from 'assets/img/login.jpg';
import styles from './AppSettingsModal.module.scss';

type Props = {
    isVisible: boolean;
    mode: string | null;
    onCancel: () => void;
};

const AppSettingsModal: React.FC<Props> = ({ isVisible, mode, onCancel }: Props) => {
    const { t } = useTranslation();
    
    const { register, handleSubmit, setValue, watch } = useForm<AppSettingsViewModel>({ shouldUnregister: false });
    const [currentMode, setCurrentMode] = useState<string | null>(mode);

    const getData = async () => {
        try {
            Loading.show();

            const result = await AppSettingsService.getAppSettings();
            setValue('faqUrl', result?.faqUrl);

            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const onSubmit = async (form: AppSettingsViewModel) => {
        try {
            if (currentMode === 'details') {
                setCurrentMode('edit');
                return;
            }

            Loading.show();

            await AppSettingsService.update(form);

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            Loading.hide();
            onCancel();
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [currentMode]);

    return (
        <OverlayedModal isOpen={isVisible}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.container}>
                    <div className={styles.left}>
                        <div className={styles.image}>
                            <img src={image} width="400"/>
                        </div>
                    </div>

                    <div className={styles.right}>
                        <div className={styles.header}>
                            <div className={styles.title}>
                                {t('appSettings.appSettings')}
                            </div>
                            <img src={IconClose} onClick={onCancel} className={styles.icon} />
                        </div>

                        <div className={styles.form}>
                            <FormItem>
                                <Label className={watch('faqUrl') ? styles.labelActive : styles.label}>{t('appSettings.faqUrl').toUpperCase()}</Label>
                                <InputWithError
                                    name="faqUrl"
                                    minLength={5}
                                    placeholder={t('appSettings.faqUrl')}
                                    ref={register({ minLength: 5 })}/>
                            </FormItem>
                        </div>
                    </div>
                </div>

                <div className={styles.footer}>
                    <div className={styles.buttonsContainer} >
                        {currentMode == 'details' && <Button
                            className={styles.button}
                            type={'submit'}
                            text={t('common.edit').toUpperCase()} >
                            <FaEdit className={styles.icon} />
                        </Button>}

                        <Button
                            className={styles.button}
                            type="button"
                            text={t('common.cancel').toUpperCase()}
                            preset={'secondary'}
                            onClick={onCancel}
                        />

                        {currentMode == 'edit' && <Button
                            className={styles.button}
                            type={'submit'}
                            text={t('common.save').toUpperCase()} >
                            <FaCheck className={styles.icon} />
                        </Button>}
                    </div>
                </div>
            </form>
        </OverlayedModal>
    );
}

export default AppSettingsModal;
