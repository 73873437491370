import styles from './ToolbarItemIconGroup.module.scss';

type ToolbarItemIconGroupProps = {
    text?: string;
    className?: string;
    children: React.ReactElement | React.ReactElement[];
};

const ToolbarItemIconGroup = ({ text, className, children }: ToolbarItemIconGroupProps) => {

    return (
        <div className={`${styles.container} ${className || ''}`}>
            {text && <div className={styles.textContainer}>{text}</div>}
            <div className={styles.iconsContainer}>
                {children}
            </div>
        </div>
    );
}

export default ToolbarItemIconGroup;
