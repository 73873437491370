import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from 'common/services/Toaster';
import styles from './NotificationsScreen.module.scss';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import PageHeader from 'common/components/pageHeader/PageHeader';
import Loading from 'common/services/Loading';
import NotificationsTemplatesService from 'api/notificationsTemplates/NotificationsTemplatesService'
import { NotificationTemplateViewModel } from 'api/notificationsTemplates/models/NotificationTemplateViewModel';
import { useHistory, useParams } from 'react-router-dom';
import CheckBox from 'common/components/checkBox/CheckBox';
import InputWithError from 'common/components/input/InputWithError';
import FormItem from 'common/components/formItem/FormItem';
import { Controller, useForm } from 'react-hook-form';
import Button from 'common/components/button/Button';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import Label from 'common/components/label/Label';
export type OpenDialogType = 'details' | 'edit' | 'new' | 'profile';
import Editor from '@monaco-editor/react';
import { editor } from 'monaco-editor';

type Form = {
    pushSubject: string;
    pushMessage: string;
    pushEnabled: boolean;
    emailMessage: string;
    emailSubject: string;
    emailEnabled: boolean;
}

const NotificationsScreen: React.FC = () => {

    const { t } = useTranslation();
    const form = useForm<Form>({ shouldUnregister: false });
    const { register, errors, setValue, clearErrors, getValues } = form;
    const { id, type } = useParams<{ id: string, type: string }>();
    const history = useHistory();
    const isDetails = type !== null && type === 'details' ? true : false
    const [notificationTemplate, setNotificationTemplate] = useState<NotificationTemplateViewModel>();

    const getNotificationsTemplate = async () => {
        try {
            Loading.show();
            const result = await NotificationsTemplatesService.getById(id);
            if (result === undefined) {
                addToast(t('common.messages.error_load_info'), { appearance: 'error' });
                Logger.error(LOGGER_LOG_TYPE.NOTIFICATIONS, `Couldn't get the notification with id: ${id}`);
                navigateTo();
            }

            form.reset(result);
            setNotificationTemplate(result);

            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.NOTIFICATIONS, `Couldn't get the notification with id: ${id}`, error);
            navigateTo();
            Loading.hide();
        }
    }

    const onSubmit = async (form: Form) => {
        try {
            if (
                (form.pushEnabled && (!form.pushSubject || !form.pushMessage)) ||
                (form.emailEnabled && (!form.emailSubject || !form.emailMessage))
            ) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            Loading.show();
            
            const model: NotificationTemplateViewModel = { ...notificationTemplate, id: id, ...form };
            await NotificationsTemplatesService.save(model);

            Loading.hide();
            
            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            navigateTo('details');
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.NOTIFICATIONS, `Couldn't update the notification with id: ${id}`, error);
            Loading.hide();
        }
    };

    const navigateTo = (type?: string) => {
        if (!type) {
            history.push(`/notification`);
            return;
        }

        history.push(`/notification/${type}/${id}`);
        return;
    }

    const handleEditorDidMount = (editor: editor.IStandaloneCodeEditor) => {
        editor.addAction({
            id: 'nt_add_link_butotn',
            label: t('notification.contextHelper.addLinkButton'),
            contextMenuGroupId: 'nt_add_link_butotn',
            run: editor => {
                // alert('Add your custom pasting code here');
                const selection = editor.getSelection();
                if (!selection) {
                    return;
                }

                const text = '@link';
                const op = { identifier: { major: 1, minor: 1 }, range: selection, text: text, forceMoveMarkers: true };
                editor.executeEdits('my-source', [op]);
            }
        });
    }

    useEffect(() => {
        getNotificationsTemplate();
    }, []);

    const emailEnabled = form.watch<string, boolean>('emailEnabled');
    const pushEnabled = form.watch<string, boolean>('pushEnabled');

    return (
        <ScreenTitle title={t('menu.notification_management')}>
            <PageHeader title={t('notification.title')} hideSearchIcon={true}></PageHeader>

            <div className={styles.container}>

                <div className={styles.line}></div>

                <h2>{notificationTemplate?.title}</h2>

                <div style={{ minHeight: '511px', position: 'relative', marginTop: '1rem' }}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>

                        <Controller
                            render={({ onChange, value }) => (
                                <CheckBox
                                    checked={value || false}
                                    disabled={isDetails}
                                    onChange={() => onChange(!value)}
                                    style={{ width: '100%', marginBottom: '1rem' }}
                                >
                                    <h3 style={{ margin: '0', width: '100%' }}>{t('notification.info.hasPushEnabled')}</h3>
                                </CheckBox>
                            )}
                            control={form.control}
                            name={'pushEnabled'}
                            defaultValue={form.getValues('pushEnabled') || false}
                        />

                        {pushEnabled && (<div style={{ marginLeft: '2rem' }}>
                            <FormItem>
                                <Label className={styles.label}>{t('notification.info.push_notification_title')}</Label>
                                <InputWithError
                                    error={errors.pushSubject}
                                    name="pushSubject"
                                    placeholder={t('notification.info.push_notification_title')}
                                    ref={register({ required: true, maxLength: 255 })}
                                    disabled={isDetails}
                                    defaultValue={getValues('pushSubject')}
                                    onErrorClick={() => clearErrors('pushSubject')}
                                />
                            </FormItem>
                            <FormItem>
                                <Label className={styles.label}>{t('notification.info.push_notification_message')}</Label>
                                <InputWithError
                                    name="pushMessage"
                                    placeholder={t('notification.info.subject')}
                                    disabled={isDetails}
                                    defaultValue={getValues('pushMessage')}
                                    ref={register()}
                                />
                            </FormItem>
                        </div>)}

                        <FormItem>
                            <Controller
                                render={({ onChange, value }) => (
                                    <CheckBox
                                        checked={value || false}
                                        disabled={isDetails}
                                        onChange={() => onChange(!value)}
                                        style={{ width: '100%', marginBottom: '1rem' }}
                                    >
                                        <h3 style={{ margin: '0', width: '100%' }}>{t('notification.info.hasEmailEnabled')}</h3>
                                    </CheckBox>
                                )}
                                control={form.control}
                                name={'emailEnabled'}
                                defaultValue={form.getValues('emailEnabled') || false}
                            />
                        </FormItem>

                        {emailEnabled && (<div style={{ marginLeft: '2rem' }}>
                            <FormItem>
                                <Label className={styles.label}>{t('notification.info.email_subject')}</Label>
                                <InputWithError
                                    error={errors.emailSubject}
                                    name="emailSubject"
                                    placeholder={t('notification.info.email_subject')}
                                    ref={register({ required: true, maxLength: 255 })}
                                    disabled={isDetails}
                                    defaultValue={getValues('emailSubject')}
                                    onErrorClick={() => clearErrors('emailSubject')}
                                />
                            </FormItem>
                            <FormItem>
                                <Label className={styles.label}>{t('notification.info.email_message')}</Label>
                                <div className={styles.editorContainer}>
                                    <Editor
                                        height="400px"
                                        defaultLanguage="html"
                                        defaultValue={getValues('emailMessage')}
                                        onChange={content => {
                                            setValue('emailMessage', content || '')
                                        }}
                                        onMount={handleEditorDidMount}
                                        options={{ readOnly: isDetails }}
                                    />
                                </div>
                            </FormItem>
                        </div>)}

                        <div className={styles.buttonsContainer} >
                            <Button
                                type="button"
                                text={t('common.cancel').toUpperCase()}
                                preset={'secondary'}
                                onClick={() => navigateTo()}
                                className={styles.button}
                            />
                            {isDetails && <Button
                                type="button"
                                text={t('common.edit').toUpperCase()}
                                onClick={() => navigateTo('edit')}
                                className={styles.button}
                            >
                            </Button>}
                            {!isDetails && <Button
                                type={'submit'}
                                text={t('common.save').toUpperCase()}
                                className={styles.button}
                            >
                            </Button>}
                        </div>
                    </form>
                </div>
            </div>
        </ScreenTitle>
    );
}

export default NotificationsScreen;
