import { UserProfile } from 'api/account/models/UserProfile';

/**
 * Languages
 */
export const DEFAULT_LANGUAGE = 'fr';
export const DEFAULTNS = 'translations';

/**
 * API
 */
export const SERVER_BASE_URL = process.env.REACT_APP_SERVER_URL;
export const API_BASE_URL = process.env.REACT_APP_API_URL;

/**
 * Unsplash
 */
export const UNSPLASH_ACCESS_KEY: string = process.env.REACT_APP_UNSPLASH_ACCESS_KEY || '';

/**
 * Gliphy
 */
export const GIPHY_API_KEY: string = process.env.REACT_APP_GIPHY_API_KEY || '';

/**
 * Flaticon
 */
export const FLATICON_ACCESS_KEY: string = process.env.REACT_APP_FLATICON_ACCESS_KEY || '';

/**
 * Google Fonts
 */
export const GOOGLE_FONTS_API: string = process.env.REACT_APP_GOOGLE_FONTS_API_KEY || '';

/**
 * Storage
 */
export const STORAGE_KEY = 'cQfTjWnZr4u7x!A%D*G-KaNdRgUkXp2s';
export const STORAGE = {
    CURRENT_LOCALE: 'CURRENT_LOCALE',
    AUTH_USER: 'AUTH_USER',
    FLATICON_TOKEN: 'FLATICON_TOKEN'
};

/**
 * Logger
 */
export enum LOGGER_MESSAGE_TYPE {
    INFO = 1,
    ERROR,
}
export enum LOGGER_LOG_TYPE {
    REQUEST,
    APP_CRASH,
    EDITOR,
    MEDIAS,
    NOTIFICATIONS,
    DOCUMENTS,
    ANALYTICS,
    TIPS,
    WELCOME_MESSAGES,
    COMPANIES
}
export const LOGGER_PRINT_LOGS = process.env.REACT_APP_LOGGER_PRINT_LOGS || false;
export const LOGGER_PRINT_LOGS_LEVEL = LOGGER_MESSAGE_TYPE.INFO;


/**
 * DATE FORMAT
 */
export const DATE_FORMAT_DEFAUT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT_DEFAUT = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_MONTH_YEAR = 'MM/YYYY';


/**
 * NUMBERS
 */
export const NUMBERS_MAX_LENGTH = 14;
export const NUMBERS_MAX_VALUE = 999999999.99;

/**
 * POLICIES
 */
 export const POLICIES = {
    COMPANIES_WRITE: 'COMPANIES_WRITE',
    USERS_WRITE: 'USERS_WRITE',
    DOCUMENTS_VALIDATE: 'DOCUMENTS_VALIDATE',
    PRE_FILLED_DOCUMENTS_WRITE: 'PRE_FILLED_DOCUMENTS_WRITE',
    MEDIAS_WRITE: 'MEDIAS_WRITE',
    NOTIFICATIONS_WRITE: 'NOTIFICATIONS_WRITE',
    DOCUMENTS_WRITE: 'DOCUMENTS_WRITE',
    SIMPLE_MODELS_WRITE: 'SIMPLE_MODELS_WRITE',
    TIPS_WRITE: 'TIPS_WRITE',
    WELCOME_MESSAGES_WRITE: 'WELCOME_MESSAGES_WRITE',
    APP_SETTINGS_WRITE: 'APP_SETTINGS_WRITE'
};

/**
 * POLICIES
 */
 export const ROLES = {
    PLATFORM_ADMINISTRATOR: 'PLATFORM_ADMINISTRATOR',
    ADMINISTRATOR : 'ADMINISTRATOR',
    CONTRIBUTOR: 'CONTRIBUTOR'
};

/**
 * MIMETYPES
 */
export const MIMETYPES_IMAGE = 'image/png, image/gif, image/jpeg';
export const MIMETYPES_VIDEO = 'video/*';

export const IS_ADMIN = (user: UserProfile | null) => {
    return user ? user.roles.includes(ROLES.PLATFORM_ADMINISTRATOR) : false;
}

export const IS_COMPANY_ADMIN = (user: UserProfile | null) => {
    return user ? user.roles.includes(ROLES.ADMINISTRATOR) : false;
}

export const isTablet = (width: number) => {
    return (width <= 1200);
}

export const CHARTLEGENDMARGIN = 50;