import { PageElement, PageElementType } from '../models/EditorModels';
import Utils from '../../../common/services/Utils';
import { EditorChartDataValue } from '../models/ChartModels';
import { EditorTableDataValue } from '../models/TableModels';

class EditorElementGenerator {

    public createImageElement(url: string, width: number, height: number, thumbnailUrl: string | null): PageElement {
        return {
            id: Utils.newGuid(),
            type: PageElementType.IMAGE,
            height,
            width,
            x: 0,
            y: 0,
            rotation: 0,
            scale: [1, 1],
            keepRatio: true,
            value: { url, thumbnailUrl },
            style: {},
        }
    }

    public createGifElement(url: string, width: number, height: number, thumbnailUrl: string | null): PageElement {
        return {
            id: Utils.newGuid(),
            type: PageElementType.GIF,
            height,
            width,
            x: 0,
            y: 0,
            rotation: 0,
            scale: [1, 1],
            keepRatio: true,
            value: { url, thumbnailUrl },
            style: {},
        }
    }

    public createSvgElement(svg: string, width: number, height: number, color: string, flatIconId: string): PageElement {
        return {
            id: Utils.newGuid(),
            type: PageElementType.SVG,
            height,
            width,
            x: 0,
            y: 0,
            rotation: 0,
            scale: [1, 1],
            keepRatio: true,
            value: svg,
            style: {
                fill: color,
                color,
            },
            extraArgs: {
                flatIconId,
            },
        }
    }

    public createTextElement(text: string, width: number, height: number): PageElement {
        return {
            id: Utils.newGuid(),
            type: PageElementType.TEXT,
            height,
            width,
            x: 0,
            y: 0,
            rotation: 0,
            scale: [1, 1],
            keepRatio: false,
            value: text,
            style: {
                fontSize: 80,
                fontFamily: 'Arial',
            },
        }
    }

    public createRectangleElement(width: number, height: number, color: string): PageElement {
        return {
            id: Utils.newGuid(),
            type: PageElementType.RECTANGLE,
            height,
            width,
            x: 0,
            y: 0,
            rotation: 0,
            scale: [1, 1],
            keepRatio: false,
            value: '',
            style: {
                fill: color,
                color,
            },
        }
    }

    public createTriangleElement(width: number, height: number, color: string): PageElement {
        return {
            id: Utils.newGuid(),
            type: PageElementType.TRIANGLE,
            height,
            width,
            x: 0,
            y: 0,
            rotation: 0,
            scale: [1, 1],
            keepRatio: true,
            value: '',
            style: {
                fill: color,
                color,
            },
        }
    }

    public createCircleElement(width: number, height: number, color: string): PageElement {
        return {
            id: Utils.newGuid(),
            type: PageElementType.CIRCLE,
            height,
            width,
            x: 0,
            y: 0,
            rotation: 0,
            scale: [1, 1],
            keepRatio: true,
            value: '',
            style: {
                fill: color,
                color,
            },
        }
    }

    public createChartElement(width: number, height: number, value: EditorChartDataValue): PageElement {
        return {
            id: Utils.newGuid(),
            type: PageElementType.CHART,
            height,
            width,
            x: 0,
            y: 0,
            rotation: 0,
            scale: [1, 1],
            keepRatio: false,
            value,
            style: {
            },
        }
    }

    public createTableElement(width: number, height: number, value: EditorTableDataValue): PageElement {
        return {
            id: Utils.newGuid(),
            type: PageElementType.TABLE,
            height,
            width,
            x: 0,
            y: 0,
            rotation: 0,
            scale: [1, 1],
            keepRatio: false,
            value,
            style: {
            },
        }
    }
}

export default new EditorElementGenerator();