import { Page, PageElement, PageElementType } from '../models/EditorModels';
import html2canvas from 'html2canvas';
import EditorUtils from './EditorUtils';
import Utils from '../../../common/services/Utils';
import store from 'store/store';
import DocumentsService from 'api/documents/DocumentsService';
import { setAutoSave } from 'store/editorControl/action';
import { API_BASE_URL, LOGGER_LOG_TYPE } from 'Config';
import { PAGE_CLS_NAME } from '../components/editorPage/EditorPage';
import { CropEditorImageType, CropEditorImageViewModel } from 'api/documents/models/CropEditorImageViewModel';
import Logger from 'common/services/Logger';
import EditorUnstatedStore from './EditorUnstatedStore';
import { createEvents } from 'store/analytics/action';
import { AnalyticEventType } from 'api/analytics/enums/AnalyticEventType';
import { EditorAnalytics } from '../models/EditorAnalytics';
import { updateEditorAnalytics } from 'store/editor/action';

type ThumbnailSize = { width: number, height: number };

class EditorInternalService {

    public async generatePageThumbnail(page: Page, size?: ThumbnailSize): Promise<string | null> {
        const node = document.getElementById(EditorUtils.getPageId(page.number));
        if (!node) {
            return null;
        }

        const proxyUrl = `${API_BASE_URL}/proxy/image`;

        const canvas = await html2canvas(node, {
            useCORS: false,
            backgroundColor: '#ffffff',
            proxy: proxyUrl,
            onclone: async doc => {
                [].forEach.call(doc.querySelectorAll('.moveable-element'), (e: Element) => {
                    if (e && e.parentNode) {
                        e.parentNode.removeChild(e);
                    }
                });
                [].forEach.call(doc.querySelectorAll(`.${PAGE_CLS_NAME}`), (e: HTMLDivElement) => {
                    if (e) {
                        e.style.border = '0';
                        e.style.borderRadius = '0';
                    }
                });
            },
        });

        const dataUrl = canvas.toDataURL();

        return size ? await Utils.resizeBase64Image(dataUrl, size.width, size.height) : dataUrl;
    }

    public async createDocumentEvents(pages: Page[], documentId: string, analytics?: EditorAnalytics) {
        let totalInternalImages = 0;
        let totalUnsplashImages = 0;
        let totalGifs = 0;
        let totalTables = 0;
        let totalGraphics = 0;
        let totalTexts = 0;
        let totalIcons = 0;
        let totalCharts = 0;
    
        for (const page of pages) {
            for (const element of page.elements) {

                if (element.type === PageElementType.IMAGE) {
                    const url: string = typeof element.value === 'string' ? element.value : element.value.url;

                    if (url.includes('unsplash.com')) {
                        totalUnsplashImages++;
                    } else {
                        totalInternalImages++;
                    }
                } else if (element.type === PageElementType.GIF) {
                    totalGifs++;
                } else if (element.type === PageElementType.TABLE) {
                    totalTables++;
                } else if (element.type === PageElementType.CIRCLE || element.type === PageElementType.RECTANGLE || element.type === PageElementType.TRIANGLE) {
                    totalGraphics++;
                } else if (element.type === PageElementType.TEXT) {
                    totalTexts++;
                } else if (element.type === PageElementType.SVG) {
                    totalIcons++;
                } else if (element.type === PageElementType.CHART) {
                    totalCharts++;
                }
            }
        }

        const eventsToAdd = [];

        if (!analytics || analytics.totalInternalImages !== totalInternalImages) {
            eventsToAdd.push({ type: AnalyticEventType.DOCUMENT_TOTAL_INTERNAL_IMAGES, value: totalInternalImages.toString(), documentId: documentId });
        }

        if (!analytics || analytics.totalUnsplashImages !== totalUnsplashImages) {
            eventsToAdd.push({ type: AnalyticEventType.DOCUMENT_TOTAL_UNSPLASH_IMAGES, value: totalUnsplashImages.toString(), documentId: documentId });
        }

        if (!analytics || analytics.totalGifs !== totalGifs) {
            eventsToAdd.push({ type: AnalyticEventType.DOCUMENT_TOTAL_GIFS, value: totalGifs.toString(), documentId: documentId });
        }

        if (!analytics || analytics.totalTables !== totalTables) {
            eventsToAdd.push({ type: AnalyticEventType.DOCUMENT_TOTAL_TABLES, value: totalTables.toString(), documentId: documentId });
        }

        if (!analytics || analytics.totalGraphics !== totalGraphics) {
            eventsToAdd.push({ type: AnalyticEventType.DOCUMENT_TOTAL_GRAPHICS, value: totalGraphics.toString(), documentId: documentId });
        }

        if (!analytics || analytics.totalTexts !== totalTexts) {
            eventsToAdd.push({ type: AnalyticEventType.DOCUMENT_TOTAL_TEXTS, value: totalTexts.toString(), documentId: documentId });
        }

        if (!analytics || analytics.totalIcons !== totalIcons) {
            eventsToAdd.push({ type: AnalyticEventType.DOCUMENT_TOTAL_ICONS, value: totalIcons.toString(), documentId: documentId });
        }

        if (!analytics || analytics.totalCharts !== totalCharts) {
            eventsToAdd.push({ type: AnalyticEventType.DOCUMENT_TOTAL_CHARTS, value: totalCharts.toString(), documentId: documentId });
        }

        if (eventsToAdd.length > 0) {
            store.dispatch<any>(createEvents(eventsToAdd));
        }

        if (analytics) {
            store.dispatch<any>(updateEditorAnalytics({
                totalInternalImages,
                totalUnsplashImages,
                totalGifs,
                totalTables,
                totalGraphics,
                totalTexts,
                totalIcons,
                totalCharts,
            }));
        }
    } 

    public async quickSave() {
        try {
            const updatedState = store.getState().editor.present;
            const thumbnailBase64 = await this.generatePageThumbnail(updatedState.pages[0]);
            const thumbnail = thumbnailBase64 ? Utils.dataURLtoFile(thumbnailBase64, 'thumbnail.png', 'thumbnail') : null;

            EditorUnstatedStore.progressMediasToBeDeleted();

            await DocumentsService.updateEditorData(
                {
                    id: updatedState.documentId!,
                    editorData: EditorUtils.serializeDocumentEditorData({
                        pages: updatedState.pages,
                        fonts: updatedState.fonts || [],
                    }),
                    mediasToDeleteIds: EditorUnstatedStore.deletedMediasIdsInProgress,
                },
                thumbnail,
            );

            await this.createDocumentEvents(updatedState.pages, updatedState.documentId!, updatedState.analytics);

            EditorUnstatedStore.clearInProgressMediasToBeDeleted();

            store.dispatch(setAutoSave({ status: 'success', date: new Date() }));
        } catch (error) {
            store.dispatch(setAutoSave({ status: 'error', date: new Date() }));
            Logger.error(LOGGER_LOG_TYPE.EDITOR, `Couldn't auto save document: ${store.getState().editor.present.documentId}`, error);
        }
    }

    public async cropImageElement(el: PageElement): Promise<{ id: string, url: string }> {
        if (el.type !== PageElementType.IMAGE) {
            throw new Error(`Can't crop an element that it's not an image`);
        }

        if (!el.clip || !el.clip.enabled || !el.clip.styles) {
            return { id: '', url: el.value.url || el.value }; // Old version have the url in el.value withou an object
        }

        const res = await DocumentsService.cropEditorImage(this.elementClipPathToCropModel(el));
        return res;
    }

    private elementClipPathToCropModel(el: PageElement): CropEditorImageViewModel {
        const styles = (el.clip?.styles || []).map(s => s.replaceAll('px', ''));

        let type = CropEditorImageType.RECT;

        let rectWidth: number | null = null;
        let rectHeight: number | null = null;
        let rectX: number | null = null;
        let rectY: number | null = null;

        let circleX: number | null = null;
        let circleY: number | null = null;
        let circleRadius: number | null = null;

        let polygonPaths: number[][] | null = null;

        if (el.clip?.type === 'rect') {
            type = CropEditorImageType.RECT;
            rectWidth = Math.floor(Number(styles[1]) - Number(styles[3]));
            rectHeight = Math.floor(Number(styles[2]) - Number(styles[0]));
            rectX = Math.floor(Number(styles[3]));
            rectY = Math.floor(Number(styles[0]));
        } else if (el.clip?.type === 'circle') {
            type = CropEditorImageType.CIRCLE;
            circleX = Math.floor(Number(styles[2]));
            circleY = Math.floor(Number(styles[3]));
            circleRadius = Math.floor(Number(styles[0]));
        } else if (el.clip?.type === 'polygon') {
            type = CropEditorImageType.POLYGON;
            polygonPaths = styles.map(s => s.split(' ').map(v => Math.floor(Number(v))));
        }

        return {
            imageUrl: el.value.url || el.value,
            currentCroppedMediaId: el.clip?.croppedMedia?.id || null,
            width: el.width,
            height: el.height,
            type,
            rectWidth,
            rectHeight,
            rectX,
            rectY,
            circleX,
            circleY,
            circleRadius,
            polygonPaths,
        };
    }
}

export default new EditorInternalService();