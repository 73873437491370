import React from 'react';
import { ElementProps } from '../../../../utils/EditorProps';
import SvgElementRender from './SvgElementRender';

const SvgElement = ({ ...props }: ElementProps, ref: React.ForwardedRef<HTMLDivElement>) => {

    return <SvgElementRender {...props} ref={ref} />;
}

export default React.forwardRef<HTMLDivElement, ElementProps>(SvgElement);
