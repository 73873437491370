import Http from '../../common/services/Http';
import { WelcomeMessagesSearchCriteria } from './models/WelcomeMessagesSearchCriteria';
import { CheckCompanyInUseViewModel, WelcomeMessageViewModel } from './models/WelcomeMessageViewModel';
import { Page } from '../common/types/Page';
import { WelcomeMessageDto, WelcomeMessageCompanyDto } from './models/WelcomeMessageDto';
import CustomFile from 'common/models/CustomFile';
import { IdNameDto } from 'api/common/models/IdNameDto';

class WelcomeMessagesService {

    public getList(criteria: WelcomeMessagesSearchCriteria) {
        return Http.get<Page<WelcomeMessageDto>>('welcomeMessages', criteria);
    }

    public getById(id: string) {
        return Http.get<WelcomeMessageDto>('welcomeMessages/' + id);
    }

    public save(model: WelcomeMessageViewModel, files: CustomFile[]) {
        return !!model.id ? Http.putWithFile<string>('welcomeMessages', model, files) : Http.postWithFile<string>('welcomeMessages', model, files);
    }

    public remove(model: WelcomeMessageViewModel) {
        return Http.put('welcomeMessages/deactivate', model);
    }

    public getCompaniesById(id: string) {
        return Http.get<WelcomeMessageCompanyDto[]>('welcomeMessages/get-companies/' + id);
    }

    public checkCompaniesInUse(model: CheckCompanyInUseViewModel){
        return Http.post<string[]>('welcomeMessages/check-companies-in-use', model);
    }
    
    public getMessageByCompanyId(id: string) {
        return Http.get<WelcomeMessageDto>('welcomeMessages/get-by-company/' + id);
    }

    public getAllMessagesForSelectItem() {
        return Http.get<IdNameDto[]>('welcomeMessages/get-all-welcome-messages-for-select-item');
    }
}

export default new WelcomeMessagesService();