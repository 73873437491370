import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './MediaItem.module.scss';
import { MediaDto } from '../../../../api/medias/models/MediaDto';
import IconPlus from 'assets/svgs/Plus-green-outlines.svg';

import Label from '../../../../common/components/label/Label';
import Background from '../../../../assets/img/no_image.png';

type Props = {
    media?: MediaDto | null;
    onClick?: () => void;
}

const MediaItem: React.FC<Props> = ({ media, onClick }: Props) => {
    const { t } = useTranslation();
    const imageCacheKey = useRef(new Date().getTime());

    let noImage = false;
    if(media == null || media?.thumbnailUrl == null){
        noImage = true;
    }

    return (
        <div className={styles.boxContainer} onClick={onClick}>
            {/* #region Create new */}
            {!media && <div className={ `${styles.create} ${!media ? styles.clickable : '' }` }>
                <img className={styles.icon} src={IconPlus} />
                <Label className={ `${styles.label} ${styles.clickable}` }>{t('medias.add_media')}</Label>
            </div>}

            {/* #region Show document item */}
            {media && <>
                {noImage && <div className={ `${styles.boxItem} ${ onClick != undefined ? styles.clickable : ''}` } style={ { backgroundColor: 'white', backgroundImage: 'url(' +  Background + ')', backgroundSize: '10rem', backgroundPosition: 'center' } }></div>}
                {!noImage && <div className={`${styles.boxItem} ${onClick != undefined ? styles.clickable : ''}`} style={{ backgroundImage: 'url(\'' + media?.thumbnailUrl + '?_=' + imageCacheKey.current + '\')' } }></div>}
                <div>
                    <label className={styles.documentName}> {media?.fileName} </label>
                    <br/>
                    <label className={styles.documentInfo}>{new Date(media.timestamp).toLocaleDateString()}</label>
                </div>
            </>}
        </div>
    );
}

export default MediaItem;