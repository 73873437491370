import styles from './ToolbarItemIconGroupItem.module.scss';

type Props = {
    icon?: string;
    iconElem?: any;
    color?: 'default' | 'danger';
    className?: string;
    onClick?: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    active?: boolean;
};

const ToolbarItemIconGroupItem = ({ icon, iconElem, onClick, color = 'default', className, active }: Props) => {
    const IconElem = iconElem;
    return (
        <div
            className={`${styles.container} ${className || ''} ${styles[color] || styles.default} ${active && styles.active}`}
            onMouseDown={onClick}
        >
            <div className={styles.iconContainer}>
                {icon && <img src={icon} className={styles.icon} />}
                {IconElem && <IconElem className={styles.icon} />}
            </div>
        </div>
    );
}

export default ToolbarItemIconGroupItem;
