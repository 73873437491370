import React, { useEffect, useState } from 'react';
import styles from './HomeInformation.module.scss';
import { WelcomeMessageViewModel } from './../../../../api/home/models/WelcomeMessageViewModel';
import { useTranslation } from 'react-i18next';
import Button from 'common/components/button/Button';
import { useHistory } from 'react-router-dom';
import { WelcomeMessagesSearchCriteria } from 'api/welcomeMessages/models/WelcomeMessagesSearchCriteria';
import Loading from 'common/services/Loading';
import WelcomeMessagesService from 'api/welcomeMessages/WelcomeMessagesService';
import Logger from 'common/services/Logger';
import { IS_ADMIN, LOGGER_LOG_TYPE } from 'Config';
import { addToast } from 'common/services/Toaster';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import { CompanyViewModel } from 'api/companies/models/CompanyViewModel';
import IconComany from 'assets/svgs/Entreprises-blue.svg';
import CompaniesService from 'api/companies/CompaniesService';
import { CompanySearchCriteria } from 'api/companies/models/CompanySearchCriteria';
import DateFormat from 'common/components/dateFormat/dateFormat';

type Props = {
    isWhite?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const HomeInformation: React.FC<Props> = ({ isWhite = false, ...props }: Props) => {

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const isAdmin: boolean = IS_ADMIN(loggedUser);

    const [infos, setInfos] = useState<WelcomeMessageViewModel | null>();

    const [companies, setCompanies] = useState<CompanyViewModel[]>([]);

    const { t } = useTranslation();
    const history = useHistory();
    

    const navigateTo = (page: string) => {
        history.push(`/${page}`);
        return;
    }

    const getData = async () => {
        try {
            Loading.show();

            const criteria: WelcomeMessagesSearchCriteria = {};
            criteria.page = 1;
            criteria.itemsPerPage = 10;
            criteria.orderColumn = 'title';
            criteria.orderBy = 'asc';

            if (loggedUser?.companyId) {
                const result = await WelcomeMessagesService.getMessageByCompanyId(loggedUser?.companyId);
                if (result) {
                    setInfos(result);
                }
            } else {
                const criteriaCompanies: CompanySearchCriteria = { page: 1, orderColumn: 'name', orderBy: 'asc' };
                const result = await CompaniesService.getList(criteriaCompanies);
                if (result) {
                    setCompanies(result.items);
                }
            }
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.WELCOME_MESSAGES, `Couldn't get welcome message`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }


    useEffect(() => {
        getData();
    }, [])

    const renderCompanies = (company: CompanyViewModel, index: number) => {
        return (
            <div key={index} className={styles.companiesContent}>
                <div className={styles.companiesContentImage}>
                    <img src={IconComany} alt='' style={{ width: '3rem', height: '4rem' }} />
                </div>
                <div className={styles.companiesContentInfos}>
                    <span className={styles.companiesContentInfosTitle}>
                        {company.name}
                    </span>
                    {company.lastConnectionDate ? <DateFormat format="DD/MM/YYYY HH:mm"
                        className={styles.companiesContentInfosNormal}
                        value={company.lastConnectionDate}></DateFormat> : <span>{t('companies.without_connection')}</span>}
                    {company.usersAdmin && company.usersAdmin.join(', ')}
                </div>
            </div>
        );
    };

    return (
        <div {...props} className={`${styles.container} ${props.className || ''}`} >
            {!isAdmin && infos && <div className={styles.informationContainer}>
                <div className={styles.informationContentInfos}>
                    <span className={styles.informationContentInfosTitle}>
                        {infos?.title}
                    </span>
                    <span className={styles.informationContentInfosSubtitle}>
                        {infos?.subtitle}
                    </span>
                    <span className={styles.informationContentInfosDescription}>
                        {infos?.description}
                    </span>
                </div>
                <div className={styles.informationContentVideo}>
                    <video className={styles.video}
                        autoPlay
                        muted
                        src={infos?.mediaUrl}
                    ></video>
                    <div className={styles.informationContentVideoOverlay}>
                        <Button
                            type='button'
                            text={t('home.home_information.button').toUpperCase()}
                            preset={'secondary'}
                            onClick={() => navigateTo('supports')}
                        />
                    </div>
                </div>
            </div>}

            {isAdmin &&
                <div style={{ flex: 1 }}>
                    <div className={styles.innerContainer}>
                        <div className={styles.title}>
                            {t('home.home_information.companies')}
                        </div>
                        <div className={styles.companiesContainer}>
                            {companies.map((comp, key) => {
                                return renderCompanies(comp, key);
                            })}
                        </div>
                    </div>
                    <div className={styles.containerOverlay}>
                        <Button
                            type='button'
                            text={t('home.document_preview.button').toUpperCase()}
                            preset={'primary'}
                            onClick={() => navigateTo('companies')}
                        />
                    </div>
                </div>}
        </div>

    );
};

export default HomeInformation;
