import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from 'common/services/Toaster';
import Button from 'common/components/button/Button';
import Loading from 'common/services/Loading';
import styles from './AnalyticsScreen.module.scss';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import PageHeader from 'common/components/pageHeader/PageHeader';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import AnalyticsService from 'api/analytics/AnalyticsService';
import { useHistory } from 'react-router-dom';
import { EditorChartDataValue } from 'screens/editor/models/ChartModels';
import { analyticsConnection, analyticsCreatedDocuments, analyticsCreatedDocumentsPercentage, analyticsTimeSpent } from 'screens/companies/analytics/components/utils/parsers';
import colors from 'styles/export/colors.module.scss';
import IconIncrease from 'assets/svgs/Increase-gris.svg';
import IconCompany from 'assets/svgs/Entreprises-white.svg';
import VerticalBarsChartWrapper from 'screens/companies/analytics/components/VerticalBarsChartWrapper/VerticalBarsChartWrapper';
import DocumentsNumberScreen from 'screens/companies/analytics/components/documentsNumber/DocumentsNumberScreen';
import TimeScreen from 'screens/companies/analytics/components/time/TimeScreen';
import { AnalyticsConnectionYearDto, CompanyAnalyticsDto } from 'api/analytics/models/CompanyAnalyticsDto';

export type OpenDialogType = 'details' | 'edit' | 'new' | 'profile';

const AnalyticsScreen: React.FC = () => {
    const { t } = useTranslation();
    

    const [reziseReRender, setReziseReRender] = useState<number>(0);

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const [connectionGraphYears, setConnectionGraphYears] = useState<number[]>([]);
    const [connectionGraphSelectedYears, setConnectionGraphSelectedYears] = useState<number[]>();
    const [connectionGraph, setConnectionGraph] = useState<EditorChartDataValue>();
    const [connectionData, setConnectionData] = useState<AnalyticsConnectionYearDto[]>();

    const [createdDocumentsGraph, setCreatedDocumentsGraph] = useState<EditorChartDataValue>();
    const [createdDocumentsGraphYears, setCreatedDocumentsGraphYears] = useState<number[]>();
    const [createdDocumentsGraphSelectedYear, setCreatedDocumentsGraphSelectedYear] = useState<number>();

    const [timeSpentGraph, setTimeSpentGraph] = useState<EditorChartDataValue>();
    const [timeSpentGraphYears, setTimeSpentGraphYears] = useState<number[]>();
    const [timeSpentGraphSelectedYear, setTimeSpentGraphSelectedYear] = useState<number>();

    const [createdDocumentsPercentageGraph, setCreatedDocumentsPercentageGraph] = useState<EditorChartDataValue[]>([]);
    const [totalExports, setTotalExports] = useState<number>(0);
    const [companyAnalytics, setCompanyAnalytics] = useState<CompanyAnalyticsDto | null>(null);

    const history = useHistory();

    const getAnalytics = async () => {
        try {
            Loading.show();

            const result = await AnalyticsService.getCompanyAnalytics({ companyId: loggedUser?.companyId ?? '' });
            setCompanyAnalytics(result);

            // #region connection
            const connectionYears = result.connections.map(x => x.year).sort((a, b) => b - a);
            setConnectionGraphYears(connectionYears);
            setConnectionGraphSelectedYears([connectionYears[0]]);
            setConnectionData(result.connections);
            const connection = analyticsConnection(t, result.connections.filter(x => x.year === connectionYears[0]), [connectionYears[0]]);
            setConnectionGraph(connection);
            // #endregion

            const createdDocumentsYears = result.createdDocuments.map(x => x.year).sort((a, b) => b - a);
            if (createdDocumentsYears && createdDocumentsYears.length > 0) {
                const createdDocumentsYear = createdDocumentsYears[0];
                const createdDocuments = analyticsCreatedDocuments(t, result.createdDocuments, createdDocumentsYear);

                setCreatedDocumentsGraphSelectedYear(createdDocumentsYear);
                setCreatedDocumentsGraphYears(createdDocumentsYears);
                setCreatedDocumentsGraph(createdDocuments);
            }

            const timeSpentYears = result.timeSpent.map(x => x.year).sort((a, b) => b - a);
            if (timeSpentYears && timeSpentYears.length > 0) {
                const timeSpentYear = timeSpentYears[0];
                const timeSpent = analyticsTimeSpent(t, result.timeSpent, timeSpentYear);

                setTimeSpentGraphSelectedYear(timeSpentYear);
                setTimeSpentGraphYears(timeSpentYears);
                setTimeSpentGraph(timeSpent);
            }

            const createdDocumentsPercentage = analyticsCreatedDocumentsPercentage(result.createdDocuments);
            setCreatedDocumentsPercentageGraph(createdDocumentsPercentage);

            setTotalExports(result.totalExports);

            Loading.hide();
        }
        catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    }

    const onChangeConnections = (years: number[]) => {
        if (!connectionData) return;

        setConnectionGraphSelectedYears(years);

        const connection = analyticsConnection(t, connectionData.filter(x => !!years.find(y => y === x.year)), years);
        setConnectionGraph(connection);
    }

    const onChangeTimeSpentYear = (year: number) => {
        setTimeSpentGraphSelectedYear(year);

        if (companyAnalytics) {
            const timeSpent = analyticsTimeSpent(t, companyAnalytics.timeSpent, year);
            setTimeSpentGraph(timeSpent);
        }
    }

    const onChangeCreatedDocumentsYear = (years: number[]) => {
        const year = years[0];
        setCreatedDocumentsGraphSelectedYear(year);

        if (companyAnalytics) {
            const createdDocuments = analyticsCreatedDocuments(t, companyAnalytics.createdDocuments, year);
            setCreatedDocumentsGraph(createdDocuments);
        }
    }

    const handleResize = useCallback(() => {
        setReziseReRender(reziseReRender + 1);
    }, [setReziseReRender, reziseReRender])

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return function cleanupListener() {
            window.removeEventListener('resize', handleResize)
        }
    }, [handleResize]);

    useEffect(() => {
        getAnalytics();
    }, []);

    return (
        <ScreenTitle title={t('users.user_management')}>
            <PageHeader title={t('users.user_management')} hideSearchIcon />

            <div className={styles.container}>

                <div className={styles.line}></div>

                <div className={styles.buttonsContainer}>
                    <div className={styles.title}>{t('companies.analytics.title')}</div>

                    <div className={styles.headerButtonsContainer}>
                        <Button
                            type="button"
                            text={t('companies.analytics.title').toUpperCase()}
                            preset={'success'} onClick={getAnalytics}
                            className={styles.button}
                            style={{ color: colors.doveGray, marginRight: '1rem' }}
                            disabled={true}
                        >
                            <img src={IconIncrease} className={styles.buttonIcon} />
                        </Button>
                        <Button
                            type="button"
                            text={t('users.user_management').toUpperCase()}
                            preset={'primary'}
                            onClick={() => history.push('users-list')}
                            className={styles.button}
                        >
                            <img src={IconCompany} className={styles.buttonIcon} />
                        </Button>
                    </div>
                </div>

                <div className={styles.chartContainer}
                    key={`container-${reziseReRender}`}>
                    <div className={`${styles.chart} ${styles.noMarginLeft}`}>
                        {connectionGraph &&
                            <VerticalBarsChartWrapper
                                key={`connection_number-${connectionGraph}`}
                                title={t('companies.analytics.charts.connection_number')}
                                data={connectionGraph}
                                withoutLegend
                                selectYear
                                yearsList={connectionGraphYears}
                                selectedYears={connectionGraphSelectedYears}
                                onSelectYears={onChangeConnections}
                                maxSelectableYears={3} />}
                    </div>
                    <div className={`${styles.chart} ${styles.noMarginRight}`}>
                        {createdDocumentsPercentageGraph &&
                            <DocumentsNumberScreen
                                key={`DocumentsNumberScreen-${reziseReRender}`}
                                data={createdDocumentsPercentageGraph} />}
                    </div>
                    <div className={`${styles.chart} ${styles.noMarginLeft}`}>
                        {timeSpentGraph &&
                            <TimeScreen
                                key={`TimeScreen-${reziseReRender}`}
                                data={totalExports}
                                dataGraph={timeSpentGraph}
                                title={t('companies.analytics.charts.number_exports')}
                                titleGraph={t('companies.analytics.charts.time_spent')}
                                selectYear
                                yearsList={timeSpentGraphYears}
                                currentYear={timeSpentGraphSelectedYear}
                                onSelectYear={onChangeTimeSpentYear}
                            />}
                    </div>
                    <div className={`${styles.chart} ${styles.noMarginRight}`}>
                        {createdDocumentsGraph &&
                            <VerticalBarsChartWrapper
                                key={`created_supports-${reziseReRender}`}
                                title={t('companies.analytics.charts.created_supports')}
                                data={createdDocumentsGraph}
                                extraMargin
                                selectYear
                                yearsList={createdDocumentsGraphYears}
                                selectedYears={createdDocumentsGraphSelectedYear ? [createdDocumentsGraphSelectedYear] : []}
                                onSelectYears={onChangeCreatedDocumentsYear}
                                maxSelectableYears={1}
                            />}
                    </div>
                </div>
            </div>
        </ScreenTitle>
    );
}

export default AnalyticsScreen;
