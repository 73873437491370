import { UserProfile } from 'api/account/models/UserProfile';
import useOnClickOutside from 'common/hooks/useOnClickOutside';
import Utils from 'common/services/Utils';
import React, { memo, useEffect, useRef, useState } from 'react'
import { ColorResult, SketchPicker } from 'react-color';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import styles from './ChartTableCellColorPicker.module.scss';

const COLOR_PICKER_CLS_NAME = 'charts-table-color-picker';

type Props = {
    onChange: (color: string) => void;
    color: string;
    enabled: boolean;
};

const ChartTableCellColorPicker = ({ enabled, color: initialColor, onChange }: Props) => {

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const [showColorPicker, setShowColorPicker] = useState(false);
    const [color, setColor] = useState<string>(initialColor || 'rgba(0,0,0,1)');
    const pickerRef = useRef<any>();

    const openColorPicker = () => {
        setShowColorPicker(!showColorPicker);
    }

    const onColorChange = (color: ColorResult) => {
        setColor(Utils.rgbaToString(color.rgb));
    }

    useOnClickOutside(pickerRef, () => {
        onChange(color);
        setShowColorPicker(false);
    }, [COLOR_PICKER_CLS_NAME]);

    useEffect(() => {
        setColor(initialColor || 'rgba(0,0,0,1)')
    }, [initialColor]);

    const getColors = () => {
        const generateColors: string[] = [];
        if(loggedUser && loggedUser.companyColors){
            loggedUser.companyColors.forEach(x => {
                const rgba = Utils.hexToRGBA(x.color, parseInt(x.alpha)/100);
                generateColors.push(rgba);
            })
        }
        return generateColors;
    }

    return (
        <div>
            {enabled && <span className={styles.colorBox} style={{ backgroundColor: color || undefined }} onClick={openColorPicker}></span>}

            {showColorPicker && 
                <div className={`${styles.colorPicker} ${COLOR_PICKER_CLS_NAME}`}>
                    <SketchPicker
                        color={color}
                        onChange={onColorChange}
                        ref={pickerRef}
                        presetColors={loggedUser && loggedUser?.companyColors.length > 0 ? getColors() : undefined} />
                </div>
            }
        </div>
    );
}

export default memo(ChartTableCellColorPicker);