import Utils from 'common/services/Utils';
import { EditorTableColumns, EditorTableData } from '../models/TableModels';

class EditorTablesUtils {

    public generateTableData(): EditorTableData[] {
        const columnsKeys = EditorTableColumns.reduce<any>((acc, curr) => (acc[curr] = ' ', acc), {});
        return [
            ...new Array(5).fill({}).map(() => ({
                id: Utils.newGuid(),
                name: '',
                ...columnsKeys,
            }))
        ];
    }

    public generateTableColumns() {
        return [
            {
                id: 'id',
                text: '',
                render: (rowIndex: number): string => `${rowIndex == 0 ? '1' : rowIndex + 1}`,
                accessor: '',
            },
            ...(EditorTableColumns.map(columnKey => ({ id: columnKey, text: columnKey, render: null, accessor: columnKey })))
        ];
    }

    public tableDataToRowsData(tData: EditorTableData[]) {
        const tableData: {name: string; value: number}[] = [];

        for (let i = 0; i < tData.length; i++) {
            const rowName = tData[i].name?.trim() || '';
            const value = EditorTableColumns.length > 0 ? EditorTableColumns.map(colKey => {
                const val = tData[i][colKey as any]?.trim().replace(',', '.') || '';
                return val && val.length > 0 ? Number(val) : 0;
            }).reduce((a, b) => a + b) : 0;

            tableData.push({
                name: rowName,
                value,
            });
        }

        return tableData;
    }
}

export default new EditorTablesUtils();