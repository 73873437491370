import React from 'react';
import { ElementProps } from '../../../../utils/EditorProps';
import ImageElementRender from './ImageElementRender';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';

const ImageElement = ({ ...props }: ElementProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const clipActiveElementId = useSelector<Reducers, string | null>(state => state.editorControl.clipActiveElementId);
    const clipActive = Boolean(clipActiveElementId && clipActiveElementId === props.element.id);

    return <ImageElementRender {...props} clipActive={clipActive} ref={ref} />;
}

export default React.forwardRef<HTMLDivElement, ElementProps>(ImageElement);
