import React, { useEffect, useMemo, useState } from 'react';
import EditorDefaults from '../../../../utils/EditorDefaults';
import { ElementProps } from '../../../../utils/EditorProps';
import Prefixer from '../../../../../../common/services/Prefixer';
import { EditorTableDataValue } from 'screens/editor/models/TableModels';
import EditorResolver from 'screens/editor/utils/EditorResolver';

type Props = {
    isExporting?: boolean;
} & ElementProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TableElementRender = ({ page, element, selected, isExporting, ...props }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {

    const dataValue = element.value as EditorTableDataValue;
    const TableElement = EditorResolver.getTableRenderByType(dataValue.type);

    const [show, setShow] = useState(isExporting ? false : true);

    useEffect(() => {
        setShow(true);
    }, []);
    
    const style = useMemo(() => Prefixer.prefixStyle(EditorDefaults.getElementStyles(element)), [element]);

    return (
        <div
            {...props}
            ref={ref}
            style={style}
        >
            {TableElement && <TableElement data={dataValue} width={element.width} height={element.height} inPreview={false}>{show}</TableElement>}
        </div>
    );

}

export default React.forwardRef<HTMLDivElement, ElementProps>(TableElementRender);
