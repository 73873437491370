import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from 'common/services/Toaster';
import ListingTable, { ListingTableCell, ListingTableColumn } from 'common/components/listingTable/ListingTable';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/dropdown/DropdownItem';
import { FaEllipsisH } from 'react-icons/fa';
import styles from './ListWelcomeMessagesScreen.module.scss';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import PageHeader from 'common/components/pageHeader/PageHeader';
import Pagination, { DEFAULT_PAGINATION_ITEMS_PER_PAGE } from 'common/components/pagination/Pagination';
import Loading from 'common/services/Loading';
import { useHistory } from 'react-router-dom';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import { WelcomeMessagesSearchCriteria } from '../../../api/welcomeMessages/models/WelcomeMessagesSearchCriteria';
import WelcomeMessagesService from '../../../api/welcomeMessages/WelcomeMessagesService';
import { WelcomeMessageViewModel } from '../../../api/welcomeMessages/models/WelcomeMessageViewModel';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Button from 'common/components/button/Button';
import IconPlus from 'assets/svgs/Plus-white-outlines.svg';

export type OpenDialogType = 'details' | 'edit' | 'new' | 'profile';

const ListWelcomeMessagesScreen: React.FC = () => {

    const { t } = useTranslation();
    

    const [welcomeMessages, setWelcomeMessages] = useState<WelcomeMessageViewModel[]>([]);
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [itemToRemove, setItemToRemove] = useState<WelcomeMessageViewModel>();

    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const history = useHistory();

    const [searchValue, setSearchValue] = useState('');

    const getData = async () => {
        try {
            Loading.show();

            const criteria: WelcomeMessagesSearchCriteria = {};
            criteria.page = currentPage;
            criteria.itemsPerPage = DEFAULT_PAGINATION_ITEMS_PER_PAGE;
            criteria.orderColumn = 'title';
            criteria.orderBy = 'asc';
            criteria.title = searchValue;

            const result = await WelcomeMessagesService.getList(criteria);

            setWelcomeMessages(result.items);
            setTotalItems(result.totalItems);

            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.WELCOME_MESSAGES, `Couldn't get the welcome messages List`, error);
            Loading.hide();
        }
    }

    const navigateTo = (type: string, id?: string) => {
        if (id) {
            history.push(`/welcomeMessages/${type}/${id}`);

        } else {
            history.push(`/welcomeMessages/${type}`);
        }
    }

    const showRemoveItemDialog = (item: WelcomeMessageViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const renderTableActionCell = (cell: ListingTableCell) => {
        return (
            <Dropdown options={<>
                <DropdownItem onClick={() => navigateTo('details', (cell.row as WelcomeMessageViewModel).id)}>{t('common.details')}</DropdownItem>
                <DropdownItem onClick={() => navigateTo('edit', (cell.row as WelcomeMessageViewModel).id)}>{t('common.edit')}</DropdownItem>
                <DropdownItem onClick={() => showRemoveItemDialog(cell.row as WelcomeMessageViewModel)}>{t('common.remove')}</DropdownItem>
            </>}>
                <div><FaEllipsisH /></div>
            </Dropdown>
        );
    }

    const tableColumns: ListingTableColumn[] = [
        {
            field: 'title',
            name: t('welcome_messages.list.title'),
            width: 'fill'
        },
        {
            field: 'action',
            name: t('welcome_messages.list.options'),
            width: 12,
            cellAlignment: 'center',
            preventClick: true,
            renderCell: renderTableActionCell
        }
    ];

    const onPageChange = (page: number) => {
        setCurrentPage(page);
    }

    const onChangeSearch = (text: string) => {
        setSearchValue(text);
        setCurrentPage(1);
    }

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await WelcomeMessagesService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                getData();
                Loading.hide();
            } catch (error) {
                Logger.error(LOGGER_LOG_TYPE.TIPS, `Couldn't remove welcome message`, error);
                addToast(t('common.messages.record_delete_error'), { appearance: 'success' });
                Loading.hide();
            }
        }
    };

    useEffect(() => {
        getData();
    }, [currentPage, searchValue]);

    return (
        <ScreenTitle title={t('menu.welcome_messages')}>
            <PageHeader title={t('welcome_messages.title')} onChangeSearch={onChangeSearch} searchValue={searchValue}></PageHeader>

            <div className={styles.container}>

                <div className={styles.line}></div>

                <div className={styles.buttonsContainer}>
                    <Button
                        type="button"
                        text={t('welcome_messages.add').toUpperCase()}
                        preset={'primary'} onClick={() => navigateTo('new')}
                        className={styles.button}
                    >
                        <img src={IconPlus} className={styles.buttonIcon} />
                    </Button>
                </div>

                <div style={{ minHeight: '511px', position: 'relative', marginTop: '1rem' }}>

                    <ListingTable columns={tableColumns} rows={welcomeMessages} allowHover={true} onRowClick={row => navigateTo('details', (row as WelcomeMessageViewModel).id)} striped={true} />

                    <QuestionYesNo
                        title={t('common.remove')}
                        message={t('common.messages.remove_record')} isVisible={dialogDeleteItemIsOpen}
                        onYes={() => removeItem()}
                        onNo={() => setDialogDeleteItemIsOpen(false)} />

                    {totalItems > 0 && <div className={styles.paginationContainer}>
                        <Pagination currentPage={1} onChange={onPageChange} totalItems={totalItems} />
                    </div>}

                </div>
            </div>
        </ScreenTitle>
    );
}

export default ListWelcomeMessagesScreen;
