import { GOOGLE_FONTS_API, LOGGER_LOG_TYPE } from '../../Config';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Logger from 'common/services/Logger';
import { GoogleFont } from './models/GoogleFont';

const axiosConfig: AxiosRequestConfig = {
    responseType: 'json'
};
const http = axios.create(axiosConfig);

class GoogleFontsService {

    constructor() {
        this.initInterceptors();
    }

    private getHeaders = () => {
        return {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
        };
    };

    private initInterceptors() {
        // Request
        http.interceptors.request.use(async (config: AxiosRequestConfig) => {
            config.baseURL = 'https://www.googleapis.com/webfonts/v1/';
            config.headers = this.getHeaders();
            return config;
        }, async (error: AxiosError) => {
            return Promise.reject(error);
        });

        // Response
        http.interceptors.response.use(async (response: AxiosResponse): Promise<any> => {
            return response.data;
        }, async (error: AxiosError) => {
            return Promise.reject(error);
        });
    }

    private onError(error: any, url: string) {
        const err = error.response || error;
        let msg = err.data && err.data.message ? err.data.message : error.message;
        if (err.status) { msg = `${err.status}: ${msg}`; }

        Logger.error(LOGGER_LOG_TYPE.REQUEST, msg, { url, err }, false);

        return Promise.reject(err);
    }

    public getAll() {
        return http.get<any, { items: GoogleFont[] }>(`webfonts?key=${GOOGLE_FONTS_API}`).catch((error: any) => this.onError(error, 'webfonts'));
    }
}

export default new GoogleFontsService();