import React, { useCallback } from 'react';
import styles from './ChartsContent.module.scss';
import { PageElementType } from '../../../../models/EditorModels';
import IconBarsVertical from 'assets/svgs/Graphic-1.svg';
import IconBarsHorizontal from 'assets/svgs/Graphic-2.svg';
import IconBarsMultipleVertical from 'assets/svgs/Graphic-3.svg';
import IconBarsMultipleHorizontal from 'assets/svgs/Graphic-4.svg';
import IconBarsTripleVertical from 'assets/svgs/Graphic-5.svg';
import IconBarsTripleHorizontal from 'assets/svgs/Graphic-6.svg';
import IconPie from 'assets/svgs/Graphic-cercle.svg';
import IconDonut from 'assets/svgs/Graphic-cercle2.svg';
import IconCurveSingle from 'assets/svgs/Graphic-increase.svg';
import IconCurveMultiple from 'assets/svgs/Graphics-increase2.svg';
import ChartsList from './components/chartsList/ChartsList';
import ChartModifier from './components/chartModifier/ChartModifier';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { setSidebarSelectedChartType, updatePageElement } from 'store/editor/action';
import EditorUtils from 'screens/editor/utils/EditorUtils';
import store from 'store/store';
import { EditorChartType, EditorSidebarChartItem } from 'screens/editor/models/ChartModels';

const chartsItems: EditorSidebarChartItem[] = [
    {
        icon: IconBarsVertical,
        chartType: EditorChartType.BARS_VERTICAL,
    },
    {
        icon: IconBarsHorizontal,
        chartType: EditorChartType.BARS_HORIZONTAL,
    },
    {
        icon: IconPie,
        chartType: EditorChartType.PIE,
    },
    {
        icon: IconDonut,
        chartType: EditorChartType.DONUT,
    },
    {
        icon: IconCurveSingle,
        chartType: EditorChartType.CURVE_SINGLE,
    },
    {
        icon: IconCurveMultiple,
        chartType: EditorChartType.CURVE_MULTIPLE,
    },
    {
        icon: IconBarsMultipleVertical,
        chartType: EditorChartType.BARS_MULTIPLE_VERTICAL,
    },
    {
        icon: IconBarsMultipleHorizontal,
        chartType: EditorChartType.BARS_MULTIPLE_HORIZONTAL,
    },
    {
        icon: IconBarsTripleVertical,
        chartType: EditorChartType.BARS_TRIPLE_VERTICAL,
    },
    {
        icon: IconBarsTripleHorizontal,
        chartType: EditorChartType.BARS_TRIPLE_HORIZONTAL,
    },
];

const ChartsContent: React.FC = () => {
    const dispatch = useDispatch();
    const selectedChartType = useSelector<Reducers, EditorChartType | null>(state => state.editor.present.sidebarState.selectedChartType);

    const onSetChartType = (chartType: EditorChartType) => {
        const [selectedElement, selectedPage] = EditorUtils.getFirstSelectedElementFromState(store.getState().editor.present);

        if (selectedElement && selectedPage && selectedElement.type === PageElementType.CHART && chartType) {
            dispatch(updatePageElement(selectedPage, {
                ...selectedElement,
                value: {
                    ...selectedElement.value,
                    type: chartType,
                },
            }));
        }

        setChartType(chartType);
    }

    const setChartType = useCallback((chartType: EditorChartType | null) => {
        dispatch(setSidebarSelectedChartType(chartType));
    }, []);

    const onChartModifierClick = useCallback(() => { setChartType(null) }, [setChartType]);
    
    return (
        <div className={styles.container}>
            {selectedChartType
                ? <ChartModifier
                    chartType={selectedChartType}
                    onGoBack={onChartModifierClick}
                />
                : <ChartsList
                    items={chartsItems}
                    onClick={chart => { onSetChartType(chart.chartType) }}
                />
            }
        </div>
    );
}

export default ChartsContent;
