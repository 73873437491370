import React from 'react';
import { Page } from '../../models/EditorModels';
import EditorPageElementRender from '../../components/editorPageElement/EditorPageElementRender';

type Props = {
    page: Page;
};

const EditorPageRender: React.FC<Props> = ({ page }: Props) => {

    const elements = page.elements.map((el, i) => (
        <EditorPageElementRender
            page={page}
            element={el}
            key={i}
        />
    ));

    return (
        <div>
            {elements}
        </div>
    );
}

export default EditorPageRender;
