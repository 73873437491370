import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updatePageElement } from '../../../../../../store/editor/action';
import { ToolbarOptionProps } from '../../../../utils/EditorProps';
import styles from './TextFontSizeToolbarOption.module.scss';
import IconPlus from 'assets/svgs/toolbar/Border1_Green.svg';
import IconLess from 'assets/svgs/toolbar/Border0_Green.svg';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TextFontSizeToolbarOption: React.FC<ToolbarOptionProps> = ({ option, page, elements, ...props }: ToolbarOptionProps) => {
    const dispatch = useDispatch();
    const element = elements![0];
    const [inputValue, setInputValue] = useState<number>((element.style.fontSize as number) || 0);

    const saveFontSize = (value: number) => {
        const el = element;
        dispatch(updatePageElement(page!, {
            ...el,
            style: {
                ...el.style,
                fontSize: value,
            }
        }));
    }

    const setSize = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>, type: ('increment' | 'decrement')) => {
        evt.preventDefault();
        evt.stopPropagation();

        let newValue = type === 'increment' ? (inputValue as number + 1) : (inputValue as number - 1);
        if (newValue <= 0) {
            newValue = 1;
        } else if (newValue > 200) {
            newValue = 200;
        }

        setInputValue(newValue);
        saveFontSize(newValue);
    }

    return (
        <div
            {...props}
            className={`${styles.container} ${props.className || ''}`}
        >
            <div className={`${styles.button} ${styles.leftButton}`} onMouseDown={e => setSize(e, 'decrement')}>
                <img src={IconLess} className={styles.icon} />
            </div>
            <div className={styles.inputContainer}>
                <input
                    value={inputValue}
                    type="number"
                    step="0.01"
                    className={styles.input}
                    onChange={e => {
                        e.preventDefault();
                        e.stopPropagation();

                        setInputValue(e.target.valueAsNumber);
                    }}
                    onBlur={e => {
                        saveFontSize(e.target.valueAsNumber);
                    }}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            saveFontSize(inputValue);
                        }
                    }}
                />
            </div>
            <div className={`${styles.button} ${styles.rightButton}`} onMouseDown={e => setSize(e, 'increment')}>
                <img src={IconPlus} className={styles.icon} />
            </div>
        </div>
    );

}

export default TextFontSizeToolbarOption;
