import { CSSProperties } from 'react';

export enum PageElementType {
    TEXT,
    IMAGE,
    SVG,
    RECTANGLE,
    CHART,
    TRIANGLE,
    CIRCLE,
    TABLE,
    GIF,
}

export interface Page {
    id: string;
    number: number;
    elements: PageElement[];
    width: number;
    height: number;
    thumbnail: PageThumbnail;
    pageTime: number;
}

export interface PageThumbnail {
    dataUrl: string | null;
    width: number;
    height: number;
    changerId: string | null;
}

export interface PageElement {
    id: string;
    type: PageElementType;
    width: number;
    height: number;
    x: number;
    y: number;
    rotation: number;
    scale: number[];
    value: any;
    keepRatio: boolean;
    style: CSSProperties;
    clip?: PageElementClip | null;
    borderRadius?: PageElementBorderRadius;
    border?: PageElementBorder;
    updateRefreshNumber?: number;
    locked?: boolean;
    extraArgs?: any;
}

export type PageElementClipType = 'rect' | 'circle' | 'inset' | 'ellipse' | 'polygon';

export interface PageElementImageValue {
    url: string;
    thumbnailUrl: string;
}

export type PageElementBorderRadiusType = 'none' | 'round' | 'bevel';
export interface PageElementBorderRadius {
    type: PageElementBorderRadiusType;
}

export interface PageElementBorder {
    width: number;
    color: string;
}

export interface PageElementClip {
    enabled: boolean;
    type: null | PageElementClipType;
    style: string | null;
    styles: string[] | null;
    croppedMedia: PageElementClipMedia | null;
}

export interface PageElementClipMedia {
    id: string;
    url: string;
}

export interface PageDropElement {
    element: PageElement;
}

export interface EditorFontItem {
    name: string;
    value: string;
}

export interface EditorAutoSave {
    status: 'success' | 'error';
    date: Date;
}

export interface EditorFont {
    family: string;
}
