import { Action, ADD_ELEMENT_TO_PAGE, ADD_PAGE, CLEAR_SELECTED, DUPLICATE_PAGE, DUPLICATE_PAGE_ELEMENT, EditorState, REMOVE_PAGE, 
	REMOVE_PAGE_ELEMENTS, RE_ORDER_PAGES, SET_FONTS, SET_INITIAL_STATE, SET_PAGES, 
	SET_SELECTED_ELEMENTS_IDS, 
	SET_SELECTED_PAGE_ID, SET_SIDEBAR_SELECTED_CATEGORY_ID, SET_SIDEBAR_SELECTED_CHART_TYPE, SET_SIDEBAR_SELECTED_TABLE_TYPE, SET_SIDEBAR_STATE, SET_ZOOM, UPDATE_EDITOR_ANALYTICS, UPDATE_PAGE, UPDATE_PAGE_ELEMENT, UPDATE_PAGE_THUMBNAIL, 
	UPDATE_PAGE_THUMBNAIL_CHANGER_ID
} from './type';
import update from 'immutability-helper';
import { Page, PageElement } from 'screens/editor/models/EditorModels';
import EditorUnstatedStore from 'screens/editor/utils/EditorUnstatedStore';
import Utils from 'common/services/Utils';
import { DocumentFormat } from 'api/documents/enums/DocumentFormat';
import { DocumentType } from 'api/documents/enums/DocumentType';

const initialState: EditorState = {
	documentId: null,
	selectedElementsIds: null,
	selectedPageId: null,
	pages: [],
	format: DocumentFormat.PRODUCT_SHEET,
	documentType: DocumentType.DEFAULT,
	zoom: null,
	sidebarState: {
		selectedCategoryId: null,
		selectedChartType: null,
		selectedTableType: null
	},
	fonts: [],
	analytics: {
		totalInternalImages: 0,
		totalUnsplashImages: 0,
		totalGifs: 0,
		totalTables: 0,
		totalGraphics: 0,
		totalTexts: 0,
		totalIcons: 0,
		totalCharts: 0,
	},
};

function rootReducer(state: EditorState = initialState, action: Action): EditorState {
	switch (action.type) {
		case SET_SELECTED_PAGE_ID:
			return {
				...state,
				selectedPageId: action.selectedPageId || null,
				selectedElementsIds: state.selectedPageId !== action.selectedPageId ? null : state.selectedElementsIds,
			};

		case SET_SELECTED_ELEMENTS_IDS:
			return {
				...state,
				selectedPageId: action.selectedPageId || null,
				selectedElementsIds: action.selectedElementsIds || null,
			};

		case CLEAR_SELECTED:
			return {
				...state,
				selectedPageId: null,
				selectedElementsIds: null,
			};

		case SET_PAGES:
			return {
				...state,
				pages: action.pages || [],
			};

		case UPDATE_PAGE:
			return updatePage(state, action.page);

		case ADD_PAGE:
			return {
				...state,
				pages: update(state.pages, { $push: [action.page] }),
			};

		case DUPLICATE_PAGE:
			return duplicatePage(state, action.page);

		case REMOVE_PAGE:
			return removePage(state, action.page);

		case UPDATE_PAGE_ELEMENT:
			return updatePageElement(state, action.page, action.element);
			
		case ADD_ELEMENT_TO_PAGE:
			return addElementToPage(state, action.page, action.element);

		case REMOVE_PAGE_ELEMENTS:
			return removePageElement(state, action.page, action.elements);

		case SET_ZOOM:
			return {
				...state,
				zoom: action.zoom,
			}

		case UPDATE_PAGE_THUMBNAIL:
			return updatePageThumbnail(state, action.pageId, action.thumbnail);

		case UPDATE_PAGE_THUMBNAIL_CHANGER_ID:
			return updatePageThumbnailChangerId(state, action.pageId);

		case RE_ORDER_PAGES:
			return reOrderPages(state, action.sourceIndex, action.destinationIndex);

		case DUPLICATE_PAGE_ELEMENT:
			return duplicatePageElement(state, action.pageId, action.elementId);

		case SET_INITIAL_STATE:
			return {
				...state,
				pages: action.pages,
				format: action.format,
				documentType: action.documentType,
				documentId: action.documentId,
				fonts: action.fonts,
			};

		case SET_SIDEBAR_STATE:
			return {
				...state,
				sidebarState: {
					...state.sidebarState,
					...action.sidebarState,
				},
			};

		case SET_SIDEBAR_SELECTED_CATEGORY_ID:
			return {
				...state,
				sidebarState: {
					...state.sidebarState,
					selectedCategoryId: action.selectedCategoryId,
				},
			};

		case SET_SIDEBAR_SELECTED_CHART_TYPE:
			return {
				...state,
				sidebarState: {
					...state.sidebarState,
					selectedChartType: action.selectedChartType,
				},
			};

		case SET_SIDEBAR_SELECTED_TABLE_TYPE:
			return {
				...state,
				sidebarState: {
					...state.sidebarState,
					selectedTableType: action.selectedTableType,
				},
			};

		case SET_FONTS:
			return {
				...state,
				fonts: action.fonts,
			};

		case UPDATE_EDITOR_ANALYTICS:
			return {
				...state,
				analytics: action.analytics,
			};

		default:
			return state;
	}
}

function updatePage(state: EditorState, actionPage: Page): EditorState {
	const updatePageIndex = state.pages.findIndex(p => p.id === actionPage.id);
	return {
		...state,
		pages: update(state.pages, {
			[updatePageIndex]: {
				$apply: oldPage => ({
					...oldPage,
					...actionPage,
				})
			}
		}),
		selectedPageId: state.selectedPageId && state.selectedPageId === actionPage.id ? state.selectedPageId : null,
	};
}

function updatePageElement(state: EditorState, actionPage: Page, actionElement: PageElement): EditorState {
	const page = state.pages.find(p => p.id === actionPage.id);
	if (!page) {
		return state;
	}

	const elementIndex = page.elements.findIndex(el => el.id === actionElement.id);
	const elements = update(page.elements, {
		[elementIndex]: {
			$apply: item => ({
				...item,
				...actionElement,
			})
		}
	});

	const pageIndex = state.pages.findIndex(p => p.id === page.id);
	return {
		...state,
		pages: update(state.pages, {
			[pageIndex]: {
				$apply: oldPage => ({
					...oldPage,
					elements
				})
			}
		}),
	};
}

function addElementToPage(state: EditorState, actionPage: Page, actionElement: PageElement): EditorState {
	const page = state.pages.find(p => p.id === actionPage.id);
	if (!page) {
		return state;
	}

	const elements = update(page.elements, { $push: [actionElement] })

	const pageIndex = state.pages.findIndex(p => p.id === page.id);
	return {
		...state,
		pages: update(state.pages, {
			[pageIndex]: {
				$apply: oldPage => ({
					...oldPage,
					elements
				})
			}
		}),
	};
}

function removePageElement(state: EditorState, actionPage: Page, actionElements: PageElement[]): EditorState {
	const page = state.pages.find(p => p.id === actionPage.id);
	if (!page) {
		return state;
	}

	const elements = actionElements.map(el => page.elements.find(x => x.id === el.id)!);
	if (!elements || elements.length === 0) {
		return state;
	}

	for (const element of elements) {
		addElementMediasToBeDeleted(element);
	}

	const pageIndex = state.pages.findIndex(p => p.id === page.id);
	return {
		...state,
		pages: update(state.pages, {
			[pageIndex]: {
				$apply: oldPage => ({
					...oldPage,
					elements: oldPage.elements.filter(el => !actionElements.find(ael => el.id === ael.id)),
				})
			}
		}),
		selectedElementsIds: state.selectedElementsIds?.filter(id => !actionElements.find(ael => id === ael.id)) || null,
	};
}

function addElementMediasToBeDeleted(element: PageElement) {
	if (element.clip && element.clip.croppedMedia && element.clip.croppedMedia.id && element.clip.croppedMedia.id.length > 0) {
		EditorUnstatedStore.deletedMediasIds.push(element.clip.croppedMedia.id);
	}
}

function removePage(state: EditorState, actionPage: Page) {
	const page = state.pages.find(x => x.id === actionPage.id);
	if (!page) {
		return state;
	}

	for (const element of page.elements) {
		addElementMediasToBeDeleted(element);
	}

	return {
		...state,
		pages: state.pages
			.filter(p => p.id !== actionPage.id)
			.map((page, i) => {
				page.number = i + 1;
				return page;
			}),
	};
}

function updatePageThumbnail(state: EditorState, pageId: string, dataUrl: string | null) {
	const updatePageIndex = state.pages.findIndex(p => p.id === pageId);
	return {
		...state,
		pages: update(state.pages, {
			[updatePageIndex]: {
				$apply: oldPage => ({
					...oldPage,
					thumbnail: {
						...oldPage?.thumbnail,
						dataUrl,
					},
				})
			}
		}),
	};
}

function updatePageThumbnailChangerId(state: EditorState, pageId: string) {
	const updatePageIndex = state.pages.findIndex(p => p.id === pageId);
	return {
		...state,
		pages: update(state.pages, {
			[updatePageIndex]: {
				$apply: oldPage => ({
					...oldPage,
					thumbnail: {
						...oldPage?.thumbnail,
						changerId: Utils.newGuid(),
					},
				})
			}
		}),
	};
}

function duplicatePage(state: EditorState, actionPage: Page) {
	return {
		...state,
		pages: update(state.pages, {
			$push: [{
				...actionPage,
				id: Utils.newGuid(),
				number: state.pages.length + 1,
				elements: actionPage.elements.map(el => {
					return {
						...el,
						id: Utils.newGuid(),
						clip: null,
					}
				}),
			}]
		}),
	};
}

function duplicatePageElement(state: EditorState, pageId: string, elementId: string): EditorState {
	const page = state.pages.find(x => x.id === pageId);
	const element = page?.elements.find(x => x.id === elementId);
	if (!page || !element) {
		return state;
	}

	const defaultPositionPad = 50;
	const positionX = (element.x + defaultPositionPad + element.width) > page.width 
		? element.x - defaultPositionPad : element.x + defaultPositionPad;
	const positionY = (element.y + defaultPositionPad + element.height) > page.height 
		? element.y - defaultPositionPad : element.y + defaultPositionPad;

	const newElement = {
		...element,
		id: Utils.newGuid(),
		x: positionX,
		y: positionY,
		clip: null,
	};

	return {
		...addElementToPage(state, page, newElement),
		selectedPageId: pageId,
		selectedElementsIds: [newElement.id],
	};
}

function reOrderPages(state: EditorState, sourceIndex: number, destinationIndex: number) {
	const result = Array.from(state.pages);
	const [removed] = result.splice(sourceIndex, 1);
	result.splice(destinationIndex, 0, removed);

	const newPages = [...result].map((p, i) => ({
		...p,
		number: i + 1,
	}))

	return {
		...state,
		pages: newPages,
	};
}

export default rootReducer;
