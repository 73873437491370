import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { TipSearchCriteria } from './models/TipSearchCriteria';
import { TipViewModel } from './models/TipViewModel';
import { TipDto } from './models/TipDto';

class TipsService {

    public getList(criteria: TipSearchCriteria) {
        return Http.get<Page<TipDto>>('tips', criteria);
    }

    public getAllTips() {
        return Http.get<TipDto[]>('tips/get-all-tips');
    }

    public getById(id: string) {
        return Http.get<TipDto>('tips/'+ id);
    }

    public save(model: TipViewModel) {
        return !!model.id ? Http.put('tips', model) : Http.post('tips', model);
    }

    public remove(model: TipViewModel) {
        return Http.put('tips/deactivate', model);
    }
}

export default new TipsService();