import { CSSProperties, ReactNode } from 'react';
import styles from './ToolbarButtonOption.module.scss';

type ToolbarButtonOptionIconProps = {
    icon?: string;
    IconElem?: any;
    onClick?: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    style?: CSSProperties;
    containerStyle?: CSSProperties;
    fill?: string;
}

export const ToolbarButtonOptionIcon = ({ icon, IconElem, onClick, containerStyle, style, fill }: ToolbarButtonOptionIconProps) => {
    return (
        <div className={styles.iconContainer} onMouseDown={onClick} style={containerStyle}>
            {icon && <img src={icon} className={styles.icon} style={style} />}
            {IconElem && <IconElem className={styles.icon} style={style} fill={fill} />}
        </div>
    );
}

type Props = {
    color?: 'default' | 'danger';
    text: string;
    onClick?: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    icon?: string;
    iconElem?: any;
    className?: string;
    active?: boolean;
    activeIcon?: string;
    activeIconElem?: any;
    children?: ReactNode | ReactNode[];
};

const ToolbarButtonOption = ({ text, onClick, icon, iconElem, color = 'default', className, active, activeIcon, activeIconElem, children }: Props) => {
    return (
        <div
            onMouseDown={onClick}
            className={`${styles.container} ${className || ''} ${styles[color] || styles.default} ${active && styles.active}`}
        >
            <div className={styles.button}>
                {((icon || iconElem) && (!active || !(activeIcon || activeIconElem))) && (<ToolbarButtonOptionIcon icon={icon} IconElem={iconElem} style={{ width: '1rem' }} />)}
                {(active && (activeIcon || activeIconElem)) && (<ToolbarButtonOptionIcon icon={activeIcon} IconElem={activeIconElem} style={{ width: '1rem' }} />)}
                <div className={styles.textContainer}>{text}</div>
                {children}
            </div>
        </div>
    );
}

export default ToolbarButtonOption;
