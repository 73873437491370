import React, { memo } from 'react';
import { ChartRenderProps } from '../../../../utils/EditorProps';
import { Global } from 'recharts';
import VerticalBarsChartRender from './VerticalBarsChartRender';
Global.isSsr = true;

const VerticalBarsStackedChartRender = ({ ...props }: ChartRenderProps, ref: React.ForwardedRef<any>) => (
    <VerticalBarsChartRender {...props} ref={ref} stacked={true} />
);
export default memo(React.forwardRef<any, ChartRenderProps>(VerticalBarsStackedChartRender));
