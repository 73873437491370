import React, { useEffect, useRef, useState } from 'react';
import styles from './TimeScreen.module.scss';
import { EditorChartDataValue } from 'screens/editor/models/ChartModels';
import colors from 'styles/export/colors.module.scss';
import IconDownload from 'assets/svgs/Download-red.svg';
import CurveSingleChart from '../charts/CurveSingleChart';
import { useTranslation } from 'react-i18next';
import ValueInfo from '../valueInfo/ValueInfo';
import YearsSelect from '../yearsSelect/YearsSelect';

type Props = {
    titleGraph: string;
    dataGraph: EditorChartDataValue;
    title: string;
    data: number | undefined;
    selectYear?: boolean;
    yearsList?: number[];
    currentYear?: number;
    onSelectYear?: (year: number) => void; 
};

const TimeScreen: React.FC<Props> = ({ titleGraph, dataGraph, title, data, selectYear, yearsList, currentYear, onSelectYear }: Props) => {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);

    const [containerWidth, setContainerWidth] = useState<number>(0);
    const [containerHeight, setContainerHeight] = useState<number>(0);

    const setDimensions = () => {
        if (!ref.current) return;

        const { width, height } = ref.current?.getBoundingClientRect();
        setContainerWidth((width - 40 > 0) ? width - 40 : width);
        setContainerHeight((height > 300) ? height : 300);
    }

    useEffect(() => {
        setDimensions();

        function handleResize() {
            setDimensions();
        }

        window.addEventListener('resize', handleResize)
    }, []);

    return (
        <>
            <div ref={ref} className={styles.container}>
                <div className={styles.title}>
                    {titleGraph}
                    {selectYear && <div>
                        <YearsSelect
                            options={yearsList ?? []}
                            value={currentYear ? [currentYear] : []}
                            onChange={years => {
                                if (onSelectYear) {
                                    onSelectYear(years.length > 0 ? years[0] : yearsList![0])
                                }
                            }}
                            maxSelectable={1}
                        />
                    </div>}
                </div>
                <CurveSingleChart data={dataGraph} width={containerWidth} height={containerHeight} withoutLegend />
            </div>
            <div style={{ marginTop: '1rem', marginLeft: '-2.5%', marginRight: '-2.5%' }}>
                <ValueInfo
                    title={title}
                    description={t('companies.analytics.charts.time_spent_description')}
                    value={data ?? 0}
                    color={colors.chartDarkRed}
                    icon={IconDownload}
                    bold
                    backgroundColor={colors.chartLightRed}
                />
            </div>
        </>
    );
}

export default TimeScreen;
