import PageHeader from 'common/components/pageHeader/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import styles from './HomeScreen.module.scss';
import IconNewDoc from 'assets/svgs/AddDoc-white.svg';
import IconFolderBlue from 'assets/svgs/Fold-blue1.svg';
import IconMediaWhite from 'assets/svgs/Photo-gris2.svg';
import CreateDocumentModal from 'screens/documents/components/createDocumentModal/CreateDocumentModal';
import { useHistory } from 'react-router-dom';
import HomeButton from './components/homeButton/HomeButton';
import DocumentPreview from './components/documentPreview/DocumentPreview';
import HomeInformation from './components/homeInformation/HomeInformation';
import Backdrop from '../../common/components/backdrop/Backdrop';
import DocumentsService from 'api/documents/DocumentsService';
import { SearchListItemsViewModel } from 'api/home/models/SearchListItemsViewModel';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import { IS_ADMIN } from 'Config';
import { DocumentsSearchItems } from 'api/documents/models/DocumentsSearchCriteria';
import EditorDefaults from 'screens/editor/utils/EditorDefaults';

const HomeScreen = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const isAdmin = IS_ADMIN(loggedUser);

    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [panelResults, setPanelResults] = React.useState<boolean>(false);
    const [showBackDrop, setShowBackDrop] = React.useState<boolean>(false);
    const [itemsResults, setItemsResults] = React.useState<SearchListItemsViewModel | null>(null);
    const [searchValue, setSearchValue] = React.useState<string>('');

    const navigateTo = (page: string) => {
        if (page === 'support') {
            history.push(`/supports`);
            return;
        } else if (page === 'medias') {
            history.push(`/medias`);
            return;
        }
    }

    const onChangeSearch = async (text: string) => {
        if (text == null || text == '') {
            setPanelResults(false);
            setShowBackDrop(false);
            setItemsResults(null);
        } else {
            setPanelResults(true);
            setShowBackDrop(true);
            const search: DocumentsSearchItems = { text: text, isAdmin: isAdmin, companyId: loggedUser?.companyId }
            const results = await DocumentsService.getSearchItems(search);
            results.mySupports.map(doc => {
                if (doc) {
                    const PageInfo = EditorDefaults.getPageSizeByFormat(doc.format);
                    doc.orientation = PageInfo.orientation;
                }
            });
            results.sharedSupports.map(doc => {
                if (doc) {
                    const PageInfo = EditorDefaults.getPageSizeByFormat(doc.format);
                    doc.orientation = PageInfo.orientation;
                }
            });
            results.models.map(doc => {
                if (doc) {
                    const PageInfo = EditorDefaults.getPageSizeByFormat(doc.format);
                    doc.orientation = PageInfo.orientation;
                }
            });
            results.simplePages.map(doc => {
                if (doc) {
                    const PageInfo = EditorDefaults.getPageSizeByFormat(doc.format);
                    doc.orientation = PageInfo.orientation;
                }
            });
            setItemsResults(results);
        }
    }

    const resetSearch = () => {
        onChangeSearch('');
        setSearchValue('');
    }

    return (
        <ScreenTitle title={t('dashboard.title')}>
            <div className={styles.gridContainer}>
                {showBackDrop && <Backdrop style={{ zIndex: 299 }} />}

                <div className={styles.headerContainer}>
                    <PageHeader
                        title={''}
                        resetSearch={resetSearch}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        onChangeSearch={onChangeSearch}
                        stateBackDrop={() => setShowBackDrop}
                        showPanelResults={panelResults}
                        searchResults={itemsResults}
                        fullSearchBar={true}
                        hasTitle={false}
                    />
                    <h1 className={styles.title}>{t('dashboard.welcome')}</h1>
                </div>

                <div className={styles.container}>
                    <div className={`${styles.containerContent} ${styles.containerContentLeft}`}>
                        <div className={styles.buttonContainer}>
                            <HomeButton
                                action={() => setOpenDialog(true)}
                                title={t('home.button_add_support')}
                                icon={IconNewDoc}
                                type={'createSupport'}
                                isWhite={true}>
                            </HomeButton>
                            <HomeButton
                                action={() => navigateTo('support')}
                                title={t('home.button_view_support')}
                                icon={IconFolderBlue}
                                type={'viewSupport'}
                                isWhite={false}>
                            </HomeButton>
                            <HomeButton
                                action={() => navigateTo('medias')}
                                title={t('home.button_add_media')}
                                icon={IconMediaWhite}
                                type={'addMedia'}
                                isWhite={true}>
                            </HomeButton>
                        </div>
                        <DocumentPreview className={styles.documentPreviewContainer} />
                    </div>
                    <div className={`${styles.containerContent} ${styles.containerContentRight}`}>
                        <HomeInformation className={styles.homeInformationContainer} />
                    </div>
                </div>
                
                {openDialog &&
                    <CreateDocumentModal
                        mode={'new'}
                        item={null}
                        isVisible={openDialog}
                        onCancel={() => setOpenDialog(false)}
                    />
                }
            </div>
        </ScreenTitle>
    );
};

export default HomeScreen;
