import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToolbarOptionProps } from '../../../../utils/EditorProps';
import { ColorResult, SketchPicker } from 'react-color';
import { updatePageElement } from '../../../../../../store/editor/action';
import EditorUtils from '../../../../utils/EditorUtils';
import styles from './ColorPickerToolbarOption.module.scss';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import Utils from 'common/services/Utils';

type Props = {
    styleKey: 'color' | 'fill' | 'backgroundColor';
    colors?: string[];
} & ToolbarOptionProps;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ColorPickerToolbarOption: React.FC<Props> = ({ option, page, elements, styleKey, colors, ...props }: Props) => {

    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    
    const dispatch = useDispatch();
    const [showColorPicker, setShowColorPicker] = useState(false);
    const element = elements![0];
    const [color, setColor] = useState<string>(element.style[styleKey] || 'rgba(0,0,0,1)');
    const pickerRef = useRef<any>();

    const openColorPicker = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        evt.preventDefault();
        evt.stopPropagation();

        setShowColorPicker(!showColorPicker);
    }

    const onPickerChange = (color: ColorResult, evt: React.ChangeEvent<HTMLInputElement>) => {
        evt.preventDefault();
        evt.stopPropagation();

        const rgba = Utils.rgbaToString(color.rgb);

        setColor(rgba);

        const domElement = document.getElementById(EditorUtils.getPageElementId(element.id));
        if (domElement) {
            domElement.style[styleKey] = rgba;

            if (styleKey === 'fill') {
                domElement.style.color = rgba;
            }
        }
    }

    const saveColor = () => {
        dispatch(updatePageElement(page!, {
            ...element,
            style: {
                ...element.style,
                color: styleKey === 'fill' ? color : element.style.color,
                [styleKey]: color,
            }
        }));
    }

    const onClickOutside = () => {
        saveColor();
        setShowColorPicker(false);
    }

    const getColors = () => {
        const generateColors: string[] = [];
        if(loggedUser && loggedUser.companyColors){
            loggedUser.companyColors.forEach(x => {
                const rgba = Utils.hexToRGBA(x.color, parseInt(x.alpha)/100);
                generateColors.push(rgba);
            })
        }
        return generateColors;
    }

    return (
        <div {...props} className={`${styles.container} ${props.className || ''}`}>
            <div
                style={{ backgroundColor: color }}
                className={styles.box}
                onMouseDown={openColorPicker}
            ></div>
            {showColorPicker && <div className={styles.overlay} onClick={onClickOutside}></div>}
            {showColorPicker && <div style={{ position: 'absolute', zIndex: 399 }}>
                <SketchPicker
                    color={color}
                    onChange={onPickerChange}
                    ref={pickerRef}
                    presetColors={loggedUser && loggedUser?.companyColors && loggedUser?.companyColors.length > 0 ? getColors() : undefined} />
            </div>}
        </div>
    );
}

export default ColorPickerToolbarOption;
