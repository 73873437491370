import { PagedSearchCriteria } from 'api/common/types/PagedSearchCriteria';
import { IconfinderResponseDto } from './models/IconfinderResponseDto';
import Http from 'common/services/Http';

class IconfinderService {

    public searchIcons(term: string, criteria: PagedSearchCriteria)  {
        return Http.get<IconfinderResponseDto>('proxy/iconfinder/icons', {  
            query: term || 'icon', 
            count: criteria.itemsPerPage,
            offset: (criteria.page! -1) * criteria.itemsPerPage!,
        });
    }

    public download(url: string) {
        return Http.get<{svg:string}>('proxy/iconfinder/download', { url });
    }
}

export default new IconfinderService();