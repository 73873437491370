import { EditorAutoSave } from '../../screens/editor/models/EditorModels';
import { SetAutoSaveAction, SetClipActiveElementIdAction, SetIsTextElementInEditingModeAction, 
    SET_AUTO_SAVE, SET_CLIP_ACTIVE_ELEMENT_ID, SET_IS_TEXT_ELEMENT_IN_EDITING_MODE
} from './type';

export const setIsTextElementInEditingMode = (isTextElementInEditingMode: boolean): SetIsTextElementInEditingModeAction => ({
    type: SET_IS_TEXT_ELEMENT_IN_EDITING_MODE,
    isTextElementInEditingMode,
});

export const setAutoSave = (autoSave: EditorAutoSave): SetAutoSaveAction => ({
    type: SET_AUTO_SAVE,
    autoSave,
});

export const setClipActiveElementId = (clipActiveElementId: string | null): SetClipActiveElementIdAction => ({
    type: SET_CLIP_ACTIVE_ELEMENT_ID,
    clipActiveElementId,
});
