import React from 'react';
import styles from './ZoomSlider.module.scss';
import IconZoomMinus from '../../../../../../assets/svgs/Glass_-green.svg';
import IconZoomPlus from '../../../../../../assets/svgs/Glass+-green.svg';
import Slider from '../../../../../../common/components/slider/Slider';
import { Handle, SliderTooltip } from 'rc-slider';

type Props = {
    value: number | null;
    onChange: (v: number) => void;
    className?: string;
};

const ZoomSlider: React.FC<Props> = ({ value, onChange, className }: Props) => {
    
    const baseValue = 100;
    const sliderValue = value || baseValue;
    const minZoom = 0;
    const minZoomPrevent = 30;
    const maxZoom = 200;
    const zoomIncrement = 10;

    const onSetZoom = (zoom: number) => {
        let zoomToSet = zoom;
        if (zoom < minZoomPrevent) {
            zoomToSet = minZoomPrevent;
        } else if (zoom > maxZoom) {
            zoomToSet = maxZoom;
        }

        onChange(zoomToSet);
    }

    const handle = ({ value, dragging, index, ...restProps }: any) => {
        return (
            <SliderTooltip
                prefixCls="rc-slider-tooltip"
                overlayClassName="rc-slider-tooltip-green"
                overlay={`${value}%`}
                visible={dragging}
                placement="top"
                key={index}
            >
                <Handle value={value} {...restProps} />
            </SliderTooltip>
        );
    };

    return (
        <div className={`${styles.container} ${className ? className : ''}`}>
            <img src={IconZoomMinus} className={styles.icon} onClick={() => onSetZoom(sliderValue - zoomIncrement)} />
            <Slider
                value={sliderValue}
                step={zoomIncrement}
                onChange={onSetZoom}
                className={styles.slider}
                min={minZoom}
                max={maxZoom}
                startPoint={baseValue}
                handle={handle}
            />
            <img src={IconZoomPlus} className={styles.icon} onClick={() => onSetZoom(sliderValue + zoomIncrement)} />
        </div>
    );
}

export default ZoomSlider;
