import React, { memo, useCallback, useEffect, useRef } from 'react';
import styles from './EditorPage.module.scss';
import { Page, PageDropElement, PageElement, PageElementType } from '../../models/EditorModels';
import EditorPageElement from '../editorPageElement/EditorPageElement';
import { useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { addElementToPage, setSelectedElementsIds, setSelectedPageId, updatePageThumbnail } from 'store/editor/action';
import { Reducers } from 'store/types';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import EditorUtils from '../../utils/EditorUtils';
import InView from 'react-intersection-observer';
import PageToolbar from './components/pageToolbar/PageToolbar';
import IconfinderService from 'api/iconfinder/IconfinderService';

export const PAGE_CLS_NAME = 'global-page-cls'

type Props = {
    className?: string;
    width: number;
    height: number;
    scale: number;
    page: Page;
};

const EditorPage: React.FC<Props> = ({ className, width, height, scale, page }: Props) => {

    const dispatch = useDispatch();

    const selected = useSelector<Reducers, boolean>(state => !!state.editor.present.selectedPageId && state.editor.present.selectedPageId === page.id);
    const totalPages = useSelector<Reducers, number>(state => state.editor.present.pages.length);
    const innerRef = useRef<any>();

    const pageWidth = page.width;
    const pageHeight = page.height;

    const [{ }, dropRef] = useDrop(
        () => ({
            accept: 'element',
            drop: async (item, monitor) => {
                const droppedItem = item as PageDropElement;

                const dropTargetPosition = innerRef.current.getBoundingClientRect();
                const position = EditorUtils.getCorrectDroppedOffsetValue(
                    monitor.getInitialSourceClientOffset() || { x: 0, y: 0 }, 
                    monitor.getSourceClientOffset() || { x: 0, y: 0 },
                    dropTargetPosition,
                );

                const x = ((position.x * pageWidth) / dropTargetPosition.width);
                const y = (position.y * pageHeight) / dropTargetPosition.height;

                let width = droppedItem.element.width;
                let height = droppedItem.element.height;
                let value = droppedItem.element.value;

                // If is an image calculate the size by the page size
                if (droppedItem.element.type === PageElementType.IMAGE ||
                    droppedItem.element.type === PageElementType.GIF
                ) {
                    const newSize = EditorUtils.calculateSizeByPercentage(
                        droppedItem.element.width, droppedItem.element.height, 
                        page.width, page.height
                    );
                    width = newSize.width;
                    height = newSize.height;
                }
                // Hack: Download flaticon, flaticon url's only last for 1 hour
                else if (droppedItem.element.type === PageElementType.SVG && droppedItem.element.extraArgs?.flatIconId) {
                    try {
                        // const res = await fetch(value);
                        // value = await res.text();
                        value = (await IconfinderService.download(value)).svg;
                    } catch (error) {
                        Logger.error(LOGGER_LOG_TYPE.EDITOR, `error trying to download svg file`);
                    }
                }

                const element: PageElement = {
                    ...droppedItem.element,
                    y,
                    x,
                    width,
                    height,
                    value,
                }

                dispatch(addElementToPage(page, element));
                return { page };
            },
        }),
        [page],
    );

    const onContainerClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.target === e.currentTarget) {
            Logger.info(LOGGER_LOG_TYPE.EDITOR, 'EditorPage onContainerClick & same target (deselect element)');
            dispatch(setSelectedElementsIds(page.id, null));
        }

        if (!selected) {
            Logger.info(LOGGER_LOG_TYPE.EDITOR, 'EditorPage onContainerClick & !selected (select page as selected)');
            dispatch(setSelectedPageId(page.id));
        }
    }

    const onViewChange = useCallback((isVisible: boolean) => {
        if (isVisible) {
            dispatch(setSelectedPageId(page.id));
            EditorUtils.scrollToPageThumbnail(page.number);
        }
    }, [page]);

    useEffect(() => {
        setTimeout(() => {
            dispatch(updatePageThumbnail(page));
        }, 200);
    }, [page.thumbnail.changerId]);

    return (
        <InView
            threshold={0}
            rootMargin={'-400px'}
            onChange={onViewChange}
            className={`${styles.inViewContainer} ${className || ''}`}
            id={EditorUtils.getPageContainerId(page.number)}
        >
            <PageToolbar
                page={page}
                canDelete={totalPages > 1}
                selected={selected}
            />

            <div
                style={{ width: `${width}px`, height: `${height}px` }}
                className={`${styles.containerBeforeInner}`}
            >
                <div 
                    className={`${styles.inner}`}
                    style={{ width: `${pageWidth}px`, height: `${pageHeight}px`, transform: `scale(${scale})` }}
                    ref={innerRef}
                    id={EditorUtils.getPageId(page.number)}
                >
                    <div
                        className={`${styles.innerInside} ${selected ? styles.selected : ''} ${PAGE_CLS_NAME}`}
                        style={{ width: `${pageWidth}px`, height: `${pageHeight}px` }}
                        onClick={onContainerClick}
                        ref={dropRef}
                    >
                        {page.elements.map((el, i) => (
                            <EditorPageElement
                                page={page}
                                element={el}
                                key={`${el.id}-${i}`}
                            />
                        ))}
                    </div>

                    {/* Portal*/}
                    <div
                        id={EditorUtils.getPagePortalId(page.number)}
                        style={{ width: `${pageWidth}px`, height: `${pageHeight}px` }}
                        className={styles.portalContainer}
                    ></div>
                </div>
            </div>
        </InView>
    );
}

export default memo(EditorPage);
