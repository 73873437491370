import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ScreenTitle from '../../common/components/screenTitle/ScreenTitle';
import Editor from './components/editor/Editor';
import DocumentsService from '../../api/documents/DocumentsService';
import Logger from '../../common/services/Logger';
import { LOGGER_LOG_TYPE } from '../../Config';
import { useDispatch } from 'react-redux';
import { setInitialState } from '../../store/editor/action';
import EditorUtils from './utils/EditorUtils';
import Loading from '../../common/services/Loading';
import EditorError from './components/editorError/EditorError';

const EditorScreen: React.FC = () => {

    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const [error, setError] = useState(null);

    const getData = useCallback(
        async () => {
            try {
                Loading.show();

                const data = await DocumentsService.getById(id);
                const editorData = EditorUtils.parseDocumentEditorData(data.editorData, data.format);

                dispatch(setInitialState(editorData.pages, data.format, data.type, data.id, editorData.fonts));
                Loading.hide();
            } catch (error) {
                Logger.error(LOGGER_LOG_TYPE.EDITOR, `Couldn't get the editor data`, error);
                setError(error);
                Loading.hide();
            }
        },
        [id],
    );

    useEffect(() => {
        getData();
    }, [ getData ]);

    return (
        <ScreenTitle title={t('editor.title')}>
            {error 
                ? <EditorError /> 
                : <Editor />
            }
        </ScreenTitle>
    );
}

export default EditorScreen;
