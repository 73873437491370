import styles from './ProgressBar.module.scss';

type Props = {
    percentageValue: number;
}

const ProgressBar = ({ percentageValue }: Props) => {
    return (
        <div className={styles.progress}>
            <div className={styles.bar} style={{ width: percentageValue + '%' }}>
                {percentageValue > 0 && <p className={styles.percent}>{percentageValue}%</p>}
            </div>
        </div>
    );
}

export default ProgressBar;