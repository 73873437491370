import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../../../../common/components/button/Button';
import FormItem from '../../../../common/components/formItem/FormItem';
import InputWithError from '../../../../common/components/input/InputWithError';
import Label from '../../../../common/components/label/Label';
import styles from '../../LoginScreen.module.scss';

export type SetPasswordFirstLoginFormModel = {
    username: string;
    password: string;
    passwordRepeat: string;
}

type Props = {
    onSubmit: (model: SetPasswordFirstLoginFormModel) => void;
    queryUsername: string | null;
}

const SetPasswordFirstLoginForm: React.FunctionComponent<Props> = ({ onSubmit, queryUsername }: Props) => {
    const { t } = useTranslation();
    const { register, handleSubmit, errors, watch, clearErrors } = useForm<SetPasswordFirstLoginFormModel>();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h1 className={styles.title}>{t('login.first_connection')}</h1>
            <h4 className={styles.subtitle}>{t('login.first_connection_message')}</h4>

            <FormItem>
                <Label className={watch('username') || queryUsername ? styles.labelActive : styles.label}>{t('login.email_address').toUpperCase()}</Label>
                <InputWithError 
                    error={errors.username} 
                    name="username" 
                    placeholder={t('login.email_address')} 
                    ref={register({ required: true })}
                    defaultValue={queryUsername ? queryUsername : ''}
                    onErrorClick={() => clearErrors('username')} />
            </FormItem>

            <FormItem>
                <Label className={watch('password') ? styles.labelActive : styles.label}>{t('login.password').toUpperCase()}</Label>
                <InputWithError 
                    error={errors.password}
                    type="password"
                    name="password" 
                    placeholder={t('login.password')} 
                    ref={register({ required: true })}
                    onErrorClick={() => clearErrors('password')} />
            </FormItem>

            <FormItem>
                <Label className={watch('passwordRepeat') ? styles.labelActive : styles.label}>{t('login.confirm_password').toUpperCase()}</Label>
                <InputWithError 
                    error={errors.passwordRepeat}
                    type="password"
                    name="passwordRepeat" 
                    placeholder={t('login.password_repeat')} 
                    ref={register({ required: true, validate: value => value === watch('password') || t('common.errors.password_not_match') })}
                    onErrorClick={() => clearErrors('passwordRepeat')} />
            </FormItem>

            <Button type="submit" text={t('login.login_button').toUpperCase()} className={styles.submitButton} />
        </form>
    );
}

export default SetPasswordFirstLoginForm;
