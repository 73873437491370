import { AnalyticsConnectionYearDto, AnalyticsCreatedDocumentYearDto, AnalyticsTimeSpentMonthDto, AnalyticsTimeSpentYearDto } from 'api/analytics/models/CompanyAnalyticsDto';
import { AnalyticsImageYearDto } from 'api/analytics/models/SystemAnalyticsDto';
import { DocumentFormat } from 'api/documents/enums/DocumentFormat';
import Utils from 'common/services/Utils';
import { EditorChartColumns, EditorChartDataValue, EditorChartTableData, EditorChartType } from 'screens/editor/models/ChartModels';
import colors from 'styles/export/colors.module.scss';
import { getYearSelectColor } from '../yearsSelect/YearsSelect';

const numToSSColumn = (num: number) => {
    let s = '';
    let t = 0;

    while (num > 0) {
        t = (num - 1) % 26;
        s = String.fromCharCode(65 + t) + s;
        num = (num - t) / 26 | 0;
    }
    return s || undefined;
}

export const analyticsConnection = (t: any, data: AnalyticsConnectionYearDto[], years: number[]): EditorChartDataValue => {
    const monthsList = new Array(12).fill({}).map((_: any, index: number) => ({ value: index + 1, label: t(`common.months_mini.${index + 1}` as any) }));

    const mainObj: any = {
        id: Utils.newGuid(),
        color: colors.chartBlue,
    };

    mainObj['name'] = ' ';
    monthsList.forEach(elem => {
        mainObj[`${numToSSColumn(elem.value)}`] = `${elem.label}`
    });

    const tableData: EditorChartTableData[] = [
        mainObj
    ];

    data.forEach(analitics => {
        const indexYear: number = years.findIndex(x => x === analitics.year);
        const color = getYearSelectColor(indexYear);

        const obj: any = {
            id: Utils.newGuid(),
            color: color,
        };

        obj['name'] = `${analitics.year}`;

        for (const elem of monthsList) {
            const info = analitics.months.find(x => new Date(x.date).getUTCMonth() + 1 === elem.value);
            obj[`${numToSSColumn(elem.value)}`] = `${info?.total ?? 0}`;
        }

        tableData.push(obj);
    });

    return {
        tableData: tableData, type: EditorChartType.BARS_HORIZONTAL
    }
};

export const analyticsCreatedDocuments = (t: any, yearsData: AnalyticsCreatedDocumentYearDto[], year: number): EditorChartDataValue => {
    const data = yearsData.find(x => x.year === year)?.months || [];
    const monthsList = new Array(12).fill({}).map((_: any, index: number) => ({ value: index + 1, label: t(`common.months_mini.${index + 1}` as any) }));

    return {
        tableData: [
            ...new Array(5).fill({}).map((_: any, index: number) => {
                let obj: any = {
                    id: Utils.newGuid(),
                    color: colors.chartBlue,
                };

                if (index === 0) {
                    obj['name'] = ' ';
                    monthsList.forEach(elem => {
                        obj[`${numToSSColumn(elem.value)}`] = `${elem.label}`
                    });
                }


                if (index === 1) {
                    const format = DocumentFormat.PRODUCT_SHEET;
                    obj = {
                        ...obj,
                        color: colors.chartBlue
                    };

                    obj['name'] = t(`documents.format.${format}`);

                    const filteredData = data.filter(x => x.documentFormat === format);

                    monthsList.forEach(elem => {
                        const info = filteredData.find(x => new Date(x.date).getUTCMonth() + 1 === elem.value);
                        obj[`${numToSSColumn(elem.value)}`] = `${info?.total ?? 0}`;
                    });
                }

                if (index === 2) {
                    const format = DocumentFormat.PRODUCT_BROCHURE;
                    obj = {
                        ...obj,
                        color: colors.buttonViewSupport
                    };

                    obj['name'] = t(`documents.format.${format}`);

                    const filteredData = data.filter(x => x.documentFormat === format);

                    monthsList.forEach(elem => {
                        const info = filteredData.find(x => new Date(x.date).getUTCMonth() + 1 === elem.value);
                        obj[`${numToSSColumn(elem.value)}`] = `${info?.total ?? 0}`;
                    });
                }

                if (index === 3) {
                    const format = DocumentFormat.PRESENTATION_SLIDES;

                    obj = {
                        ...obj,
                        color: colors.chartDarkRed
                    };

                    obj['name'] = t(`documents.format.${format}`);

                    const filteredData = data.filter(x => x.documentFormat === format);

                    monthsList.forEach(elem => {
                        const info = filteredData.find(x => new Date(x.date).getUTCMonth() + 1 === elem.value);
                        obj[`${numToSSColumn(elem.value)}`] = `${info?.total ?? 0}`;
                    });
                }

                if (index === 4) {
                    const format = DocumentFormat.SOCIAL_MEDIA_POSTS;
                    obj = {
                        ...obj,
                        color: colors.lightblack
                    };

                    obj['name'] = t(`documents.format.${format}`);

                    const filteredData = data.filter(x => x.documentFormat === format);

                    monthsList.forEach(elem => {
                        const info = filteredData.find(x => new Date(x.date).getUTCMonth() + 1 === elem.value);
                        obj[`${numToSSColumn(elem.value)}`] = `${info?.total ?? 0}`;
                    });
                }

                return obj;
            })
        ], type: EditorChartType.BARS_HORIZONTAL
    }
};

export const analyticsPhotosVsUnsplash = (t: any, yearsData: AnalyticsImageYearDto[], year: number): EditorChartDataValue => {
    const data = yearsData.find(x => x.year === year)?.months || [];
    const monthsList = new Array(12).fill({}).map((_: any, index: number) => ({ value: index + 1, label: t(`common.months_mini.${index + 1}` as any) }));

    return {
        tableData: [
            ...new Array(5).fill({}).map((_: any, index: number) => {
                let obj: any = {
                    id: Utils.newGuid(),
                    color: colors.chartBlue,
                };

                if (index === 0) {
                    obj['name'] = ' ';
                    monthsList.forEach(elem => {
                        obj[`${numToSSColumn(elem.value)}`] = `${elem.label}`
                    });
                }


                if (index === 1) {
                    obj = {
                        ...obj,
                        color: colors.chartBlue,
                        name: t(`companies.analytics.charts.photos`),
                    };

                    monthsList.forEach(elem => {
                        const info = data.find(x => new Date(x.date).getUTCMonth() + 1 === elem.value);
                        obj[`${numToSSColumn(elem.value)}`] = `${info?.totalInternal ?? 0}`;
                    });
                }

                if (index === 2) {
                    obj = {
                        ...obj,
                        color: colors.buttonViewSupport,
                        name: t(`companies.analytics.charts.unsplash`),
                    };

                    monthsList.forEach(elem => {
                        const info = data.find(x => new Date(x.date).getUTCMonth() + 1 === elem.value);
                        obj[`${numToSSColumn(elem.value)}`] = `${info?.totalUnsplash ?? 0}`;
                    });
                }

                return obj;
            })
        ], type: EditorChartType.BARS_HORIZONTAL
    }
};

export const analyticsTimeSpent = (t: any, yearsData: AnalyticsTimeSpentYearDto[], year: number): EditorChartDataValue => {
    const data = yearsData.find(x => x.year === year)?.months || [];
    const monthsList = new Array(12).fill({}).map((_: any, index: number) => ({ value: index + 1, label: t(`common.months_mini.${index + 1}` as any) }));

    return {
        tableData: [
            ...new Array(2).fill({}).map((_: any, index: number) => {
                let obj: any = {
                    id: Utils.newGuid(),
                    color: colors.buttonViewSupport,
                };

                if (index === 0) {
                    obj = {
                        ...obj,
                    };
                    obj['name'] = ' ';
                    monthsList.forEach(elem => {
                        obj[`${numToSSColumn(elem.value)}`] = `${elem.label}`
                    });
                }

                if (index === 1) {
                    obj = {
                        ...obj,
                    };
                    obj['name'] = t('companies.analytics.charts.time_spent');

                    monthsList.forEach(elem => {
                        const info = data.find(x => new Date(x.date).getUTCMonth() + 1 === elem.value);
                        const result = info?.totalInSeconds ?? 0;
                        obj[`${numToSSColumn(elem.value)}`] = `${result > 0 ? result / 60 : result}`;
                    });
                }

                return obj;
            })
        ], type: EditorChartType.CURVE_SINGLE
    }
};

const adjustValues = (values: number[]) => {
    // 1. Separate integer and decimal part
    // 2. Store both in a new array of objects sorted by decimal part descending
    // 3. Add in original position to "put back" at the end
    const flooredAndSortedByDecimal = values.map((value, position) => (
        {
            floored: Math.floor(value),
            decimal: value - Number.parseInt(value as any),
            position
        }
    )).sort(({ decimal }, { decimal: otherDecimal }) => otherDecimal - decimal);

    const roundedTotal = values.reduce((total, value) => total + Math.floor(value), 0);
    let availableForDistribution = 100 - roundedTotal;

    // Add 1 to each value from what's available
    const adjustedValues = flooredAndSortedByDecimal.map(value => {
        const { floored, ...rest } = value;
        let finalPercentage = floored;
        if (availableForDistribution > 0) {
            finalPercentage = floored + 1;
            availableForDistribution--;
        }

        return {
            finalPercentage,
            ...rest
        }
    });

    // Put back and return the new values
    return adjustedValues
        .sort(({ position }, { position: otherPosition }) => position - otherPosition)
        .map(({ finalPercentage }) => finalPercentage);
}

export const analyticsCreatedDocumentsPercentage = (yearsData: AnalyticsCreatedDocumentYearDto[]): EditorChartDataValue[] => {
    const data = yearsData.flatMap(x => x.months);

    const totalProductSheets = data.filter(x => x.documentFormat === DocumentFormat.PRODUCT_SHEET).map(elem => elem.total).sum();
    const totalProductBrochure = data.filter(x => x.documentFormat === DocumentFormat.PRODUCT_BROCHURE).map(elem => elem.total).sum();
    const totalPresentation = data.filter(x => x.documentFormat === DocumentFormat.PRESENTATION_SLIDES).map(elem => elem.total).sum();
    const totalSocial = data.filter(x => x.documentFormat === DocumentFormat.SOCIAL_MEDIA_POSTS).map(elem => elem.total).sum();
    const total = totalProductSheets + totalProductBrochure + totalPresentation + totalSocial;

    const totalProductSheetsPercentage = (totalProductSheets / total * 100);
    const totalProductBrochurePercentage = (totalProductBrochure / total * 100);
    const totalPresentationPercentage = (totalPresentation / total * 100);
    const totalSocialPercentage = (totalSocial / total * 100);

    const originalValues = [totalProductSheetsPercentage, totalProductBrochurePercentage, totalPresentationPercentage, totalSocialPercentage];
    const values = adjustValues(originalValues);

    const fillter = EditorChartColumns.map(x => ({ [x]: x }));

    const tableDataProductSheets: any = [
        {
            id: Utils.newGuid(),
            color: colors.chartBlue,
            name: 'Data1',
            ...fillter,
            A: values[0],
            B: `${totalProductSheets}/${total}`,
        }
    ];

    const tableDataProductBrochure: any = [
        {
            id: Utils.newGuid(),
            color: colors.buttonViewSupport,
            name: 'Data1',
            ...fillter,
            A: values[1],
            B: `${totalProductBrochure}/${total}`,
        }
    ];

    const tableDataPresentation: any = [
        {
            id: Utils.newGuid(),
            color: colors.chartDarkRed,
            name: 'Data1',
            ...fillter,
            A: values[2],
            B: `${totalPresentation}/${total}`,
        }
    ];

    const tableDataSocial: any = [
        {
            id: Utils.newGuid(),
            color: colors.lightblack,
            name: 'Data1',
            ...fillter,
            A: values[3],
            B: `${totalSocial}/${total}`,
        }
    ];

    return [
        { tableData: tableDataProductSheets, type: EditorChartType.BARS_HORIZONTAL },
        { tableData: tableDataProductBrochure, type: EditorChartType.BARS_VERTICAL },
        { tableData: tableDataPresentation, type: EditorChartType.CURVE_SINGLE },
        { tableData: tableDataSocial, type: EditorChartType.DONUT }
    ];
};