import Http from '../../common/services/Http';
import { Page } from '../common/types/Page';
import { NotificationDto } from './models/NotificationDto';
import { NotificationsSearchCriteria } from './models/NotificationsSearchCriteria';

class NotificationsService {

    public getTotalNotRead() {
        return Http.get<number>('notifications/total-not-read');
    }

    public getList(criteria: NotificationsSearchCriteria) {
        return Http.get<Page<NotificationDto>>('notifications', criteria);
    }

    public getById(id: string) {
        return Http.get<NotificationDto>('notifications/' + id);
    }

    public read(id: string) {
        return Http.put('notifications/' + id + '/read', {});
    }
}

export default new NotificationsService();