import { TipViewModel } from 'api/tips/models/TipViewModel';
import styles from './TipCard.module.scss';
import IconClose from 'assets/svgs/Cross-bleu1.svg'
import IconTips from 'assets/svgs/Bulb.svg';
import LinesEllipsis from 'react-lines-ellipsis';
import { useState } from 'react';

type Props = {
    onClose: () => void;
    tip: TipViewModel;
    closing: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const TipCard = ({ onClose, tip, closing, ...props }: Props) => {
    // const [closing, setClosing] = useState(false);

    const onTransitionEnd = (e: any) => {
        // if (closing) {
        //     onClose();
        // }
        
        if (props.onTransitionEnd) {
            props.onTransitionEnd(e);
        }
    }

    return (
        <div
            {...props}
            className={`${styles.box} ${props.className || ''} ${closing ? styles.closing : ''}`}
            onTransitionEnd={onTransitionEnd}
        >
            <div className={styles.container}>
                <div>
                    <div className={styles.closeButton}>
                        <img src={IconClose} onClick={onClose} />
                    </div>

                    <div className={styles.title}>
                        <div className={styles.icon}>
                            <img src={tip.icon ? tip.icon : IconTips} />
                        </div>

                        <h2 className={styles.label} >
                            {tip.title}
                        </h2>

                    </div>
                </div>

                <div className={styles.text} >
                    <LinesEllipsis
                        text={(tip.text).replace(/<[^>]+>/g, '')}
                        maxLine='3'
                        ellipsis='...'
                        trimRight
                        basedOn='letters'
                    />
                </div>
            </div>
        </div>
    );
};

export default TipCard;