import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToolbarOptionProps } from '../../../../utils/EditorProps';
import ToolbarItemIconGroup from '../toolbarItemIconGroup/ToolbarItemIconGroup';
import ToolbarItemIconGroupItem from '../toolbarItemIconGroup/ToolbarItemIconGroupItem';
import IconHoriAlign from 'assets/svgs/toolbar/HoriAlign.svg';
import IconVertiAlign from 'assets/svgs/toolbar/VertiAlign.svg';
import IconTopAlign from 'assets/svgs/toolbar/TopAlign.svg';
import IconBottomAlign from 'assets/svgs/toolbar/BottomAlign.svg';
import IconLeftAlign from 'assets/svgs/toolbar/LeftAlign.svg';
import IconRightAlign from 'assets/svgs/toolbar/RightAlign.svg';
import { useDispatch } from 'react-redux';
import { updatePageElement } from 'store/editor/action';

const AlignToolbarOption: React.FC<ToolbarOptionProps> = ({ elements, page, className, option }: ToolbarOptionProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const alignHorizontal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        for (const element of elements!) {
            const x = (page!.width / 2) - (element.width / 2);

            dispatch(updatePageElement(page!, {
                ...element,
                x,
                updateRefreshNumber: (element.updateRefreshNumber ?? 0) + 1,
            }));
        }
    }

    const alignVertical = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        for (const element of elements!) {
            const y = (page!.height / 2) - (element.height / 2);

            dispatch(updatePageElement(page!, {
                ...element,
                y,
                updateRefreshNumber: (element.updateRefreshNumber ?? 0) + 1,
            }));
        }
    }

    const alignTop = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        for (const element of elements!) {
            dispatch(updatePageElement(page!, {
                ...element,
                y: 0,
                updateRefreshNumber: (element.updateRefreshNumber ?? 0) + 1,
            }));
        }
    }

    const alignBottom = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        for (const element of elements!) {
            const y = page!.height - element.height;

            dispatch(updatePageElement(page!, {
                ...element,
                y,
                updateRefreshNumber: (element.updateRefreshNumber ?? 0) + 1,
            }));
        }
    }

    const alignLeft = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        for (const element of elements!) {
            const y = page!.height - element.height;

            dispatch(updatePageElement(page!, {
                ...element,
                x: 0,
                updateRefreshNumber: (element.updateRefreshNumber ?? 0) + 1,
            }));
        }
    }

    const alignRight = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        for (const element of elements!) {
            const x = page!.width - element.width;

            dispatch(updatePageElement(page!, {
                ...element,
                x,
                updateRefreshNumber: (element.updateRefreshNumber ?? 0) + 1,
            }));
        }
    }

    return (
        <ToolbarItemIconGroup text={!option.removeText ? t('editor.toolbar.align') : undefined} className={className}>
            <ToolbarItemIconGroupItem icon={IconTopAlign} onClick={alignTop} />
            <ToolbarItemIconGroupItem icon={IconBottomAlign} onClick={alignBottom} />
            <ToolbarItemIconGroupItem icon={IconVertiAlign} onClick={alignVertical} />
            <ToolbarItemIconGroupItem icon={IconHoriAlign} onClick={alignHorizontal} />
            <ToolbarItemIconGroupItem icon={IconLeftAlign} onClick={alignLeft} />
            <ToolbarItemIconGroupItem icon={IconRightAlign} onClick={alignRight} />
        </ToolbarItemIconGroup>
    );
}

export default AlignToolbarOption;
