import { PageElementType } from '../models/EditorModels';
import TextElement from '../components/editorPageElement/components/textElement/TextElement';
import ImageElement from '../components/editorPageElement/components/imageElement/ImageElement';
import ImageElementRender from '../components/editorPageElement/components/imageElement/ImageElementRender';
import GifElement from '../components/editorPageElement/components/gifElement/GifElement';
import GifElementRender from '../components/editorPageElement/components/gifElement/GifElementRender';
import SvgElement from '../components/editorPageElement/components/svgElement/SvgElement';
import { ToolbarOption, ToolbarOptionType } from '../models/ToolbarModels';
import DeleteToolbarOption from '../components/editorToolbar/components/deleteToolbarOption/DeleteToolbarOption';
import TextFontSizeToolbarOption from '../components/editorToolbar/components/textFontSizeToolbarOption/TextFontSizeToolbarOption';
import TextFontFamilyToolbarOption from '../components/editorToolbar/components/textFontFamilyToolbarOption/TextFontFamilyToolbarOption';
import TextFormatToolbarOption from '../components/editorToolbar/components/textFormatToolbarOption/TextFormatToolbarOption';
import TextColorToolbarOption from '../components/editorToolbar/components/textColorToolbarOption/TextColorToolbarOption';
import TextAlignToolbarOption from '../components/editorToolbar/components/textAlignToolbarOption/TextAlignToolbarOption';
import DuplicateToolbarOption from '../components/editorToolbar/components/duplicateToolbarOption/DuplicateToolbarOption';
import FillColorToolbarOption from '../components/editorToolbar/components/fillColorToolbarOption/FillColorToolbarOption';
import BackgroundColorToolbarOption from '../components/editorToolbar/components/backgroundColorToolbarOption/BackgroundColorToolbarOption';
import EmptyPushToolbarOption from '../components/editorToolbar/components/emptyPushToolbarOption/EmptyPushToolbarOption';
import SeparatorToolbarOption from '../components/editorToolbar/components/separatorToolbarOption/SeparatorToolbarOption';
import ClipToolbarOption from '../components/editorToolbar/components/clipToolbarOption/ClipToolbarOption';
import TextElementRender from '../components/editorPageElement/components/textElement/TextElementRender';
import SvgElementRender from '../components/editorPageElement/components/svgElement/SvgElementRender';
import RectangleElement from '../components/editorPageElement/components/rectangleElement/RectangleElement';
import RectangleElementRender from '../components/editorPageElement/components/rectangleElement/RectangleElementRender';
import TriangleElement from '../components/editorPageElement/components/triangleElement/TriangleElement';
import TriangleElementRender from '../components/editorPageElement/components/triangleElement/TriangleElementRender';
import CircleElement from '../components/editorPageElement/components/circleElement/CircleElement';
import CircleElementRender from '../components/editorPageElement/components/circleElement/CircleElementRender';
import ChartElement from '../components/editorPageElement/components/chartElement/ChartElement';
import ChartElementRender from '../components/editorPageElement/components/chartElement/ChartElementRender';
import VerticalBarsChartRender from '../components/editorPageElement/components/chartElement/VerticalBarsChartRender';
import HorizontalBarsChartRender from '../components/editorPageElement/components/chartElement/HorizontalBarsChartRender';
import PieChartRender from '../components/editorPageElement/components/chartElement/PieChartRender';
import CurveSingleChartRender from '../components/editorPageElement/components/chartElement/CurveSingleChartRender';
import DonutChartRender from '../components/editorPageElement/components/chartElement/DonutChartRender';
import VerticalBarsStackedChartRender from '../components/editorPageElement/components/chartElement/VerticalBarsStackedChartRender';
import HorizontalBarsStackedChartRender from '../components/editorPageElement/components/chartElement/HorizontalBarsStackedChartRender';
import { ChartRenderProps, TableRenderProps } from './EditorProps';
import { EditorChartType } from '../models/ChartModels';
import { EditorTableType } from '../models/TableModels';
import Table1Render from '../components/editorPageElement/components/tableElement/Table1Render';
import Table2Render from '../components/editorPageElement/components/tableElement/Table2Render';
import Table3Render from '../components/editorPageElement/components/tableElement/Table3Render';
import Table4Render from '../components/editorPageElement/components/tableElement/Table4Render';
import TableElement from '../components/editorPageElement/components/tableElement/TableElement';
import BorderRadiusToolbarOption from '../components/editorToolbar/components/borderRadiusToolbarOption/BorderRadiusToolbarOption';
import BorderToolbarOption from '../components/editorToolbar/components/borderToolbarOption/BorderToolbarOption';
import OrganizationToolbarOption from '../components/editorToolbar/components/organizationToolbarOption/OrganizationToolbarOption';
import AlignToolbarOption from '../components/editorToolbar/components/alignToolbarOption/AlignToolbarOption';
import TableElementRender from '../components/editorPageElement/components/tableElement/TableElementRender';

class EditorResolver {

    public getElementByType(type: PageElementType) {
        switch (type) {
            case PageElementType.TEXT:
                return TextElement;
            case PageElementType.IMAGE:
                return ImageElement;
            case PageElementType.GIF:
                return GifElement;
            case PageElementType.SVG:
                return SvgElement;
            case PageElementType.RECTANGLE:
                return RectangleElement;
            case PageElementType.TRIANGLE:
                return TriangleElement;
            case PageElementType.CIRCLE:
                return CircleElement;
            case PageElementType.CHART:
                return ChartElement;
                case PageElementType.TABLE:
                return TableElement;
            default:
                return null;
        }
    }

    public getElementRenderByType(type: PageElementType) {
        switch (type) {
            case PageElementType.TEXT:
                return TextElementRender;
            case PageElementType.IMAGE:
                return ImageElementRender;
            case PageElementType.GIF:
                return GifElementRender;
            case PageElementType.SVG:
                return SvgElementRender;
            case PageElementType.RECTANGLE:
                return RectangleElementRender;
            case PageElementType.CHART:
                return ChartElementRender;
            case PageElementType.TRIANGLE:
                return TriangleElementRender;
            case PageElementType.CIRCLE:
                return CircleElementRender;
            case PageElementType.TABLE:
                return TableElementRender;
            default:
                return null;
        }
    }

    public getToolbarOptionsOfElementByType(type: PageElementType): ToolbarOption[] {
        switch (type) {
            case PageElementType.TEXT:
                return [
                    { type: ToolbarOptionType.TEXT_FONT_FAMILY },
                    { type: ToolbarOptionType.TEXT_FONT_SIZE },
                    { type: ToolbarOptionType.TEXT_COLOR },
                    { type: ToolbarOptionType.TEXT_FORMAT },
                    { type: ToolbarOptionType.SEPARATOR },
                    { type: ToolbarOptionType.BORDER_RADIUS },
                    { type: ToolbarOptionType.BORDER },
                    { type: ToolbarOptionType.SEPARATOR },
                    { type: ToolbarOptionType.TEXT_ALIGN },
                    { type: ToolbarOptionType.ALIGN, removeText: true },
                    { type: ToolbarOptionType.SEPARATOR },
                    { type: ToolbarOptionType.ORGANISATION },
                    { type: ToolbarOptionType.EMPTY_PUSH },
                    { type: ToolbarOptionType.SEPARATOR },
                    { type: ToolbarOptionType.DUPLICATE },
                    { type: ToolbarOptionType.DELETE },
                ];
            case PageElementType.IMAGE:
                return [
                    { type: ToolbarOptionType.CLIP },
                    { type: ToolbarOptionType.BORDER_RADIUS },
                    { type: ToolbarOptionType.BORDER },
                    { type: ToolbarOptionType.SEPARATOR },
                    { type: ToolbarOptionType.ORGANISATION },
                    { type: ToolbarOptionType.ALIGN },
                    { type: ToolbarOptionType.EMPTY_PUSH },
                    { type: ToolbarOptionType.SEPARATOR },
                    { type: ToolbarOptionType.DUPLICATE },
                    { type: ToolbarOptionType.DELETE },
                ];
            case PageElementType.GIF:
                return [
                    { type: ToolbarOptionType.ORGANISATION },
                    { type: ToolbarOptionType.ALIGN },
                    { type: ToolbarOptionType.EMPTY_PUSH },
                    { type: ToolbarOptionType.SEPARATOR },
                    { type: ToolbarOptionType.DUPLICATE },
                    { type: ToolbarOptionType.DELETE },
                ];
            case PageElementType.SVG:
                return [
                    { type: ToolbarOptionType.FILL_COLOR },
                    { type: ToolbarOptionType.SEPARATOR },
                    { type: ToolbarOptionType.ORGANISATION },
                    { type: ToolbarOptionType.ALIGN },
                    { type: ToolbarOptionType.EMPTY_PUSH },
                    { type: ToolbarOptionType.SEPARATOR },
                    { type: ToolbarOptionType.DUPLICATE },
                    { type: ToolbarOptionType.DELETE },
                ];
            case PageElementType.RECTANGLE:
                return [
                    { type: ToolbarOptionType.BORDER_RADIUS },
                    { type: ToolbarOptionType.FILL_COLOR },
                    { type: ToolbarOptionType.BORDER },
                    { type: ToolbarOptionType.SEPARATOR },
                    { type: ToolbarOptionType.ORGANISATION },
                    { type: ToolbarOptionType.ALIGN },
                    { type: ToolbarOptionType.EMPTY_PUSH },
                    { type: ToolbarOptionType.SEPARATOR },
                    { type: ToolbarOptionType.DUPLICATE },
                    { type: ToolbarOptionType.DELETE },
                ];
            case PageElementType.TRIANGLE:
            case PageElementType.CIRCLE:
                return [
                    { type: ToolbarOptionType.FILL_COLOR },
                    { type: ToolbarOptionType.BORDER },
                    { type: ToolbarOptionType.SEPARATOR },
                    { type: ToolbarOptionType.ORGANISATION },
                    { type: ToolbarOptionType.ALIGN },
                    { type: ToolbarOptionType.EMPTY_PUSH },
                    { type: ToolbarOptionType.SEPARATOR },
                    { type: ToolbarOptionType.DUPLICATE },
                    { type: ToolbarOptionType.DELETE },
                ];
            case PageElementType.CHART:
                return [
                    { type: ToolbarOptionType.ORGANISATION },
                    { type: ToolbarOptionType.ALIGN },
                    { type: ToolbarOptionType.EMPTY_PUSH },
                    { type: ToolbarOptionType.SEPARATOR },
                    { type: ToolbarOptionType.DUPLICATE },
                    { type: ToolbarOptionType.DELETE },
                ];
            case PageElementType.TABLE:
                return [
                    { type: ToolbarOptionType.ORGANISATION },
                    { type: ToolbarOptionType.ALIGN },
                    { type: ToolbarOptionType.EMPTY_PUSH },
                    { type: ToolbarOptionType.SEPARATOR },
                    { type: ToolbarOptionType.DUPLICATE },
                    { type: ToolbarOptionType.DELETE },
                ];
            default:
                return [];
        }
    }

    public getToolbarOptionsOfGroupOfElement(): ToolbarOption[] {
        return [
            { type: ToolbarOptionType.ORGANISATION },
            { type: ToolbarOptionType.ALIGN },
            { type: ToolbarOptionType.EMPTY_PUSH },
            { type: ToolbarOptionType.SEPARATOR },
            { type: ToolbarOptionType.DUPLICATE },
            { type: ToolbarOptionType.DELETE },
        ];
    }

    public getToolbarOptionElement(type: ToolbarOptionType) {
        switch (type) {
            case ToolbarOptionType.DELETE:
                return DeleteToolbarOption;
            case ToolbarOptionType.TEXT_FORMAT:
                return TextFormatToolbarOption;
            case ToolbarOptionType.TEXT_FONT_SIZE:
                return TextFontSizeToolbarOption;
            case ToolbarOptionType.TEXT_FONT_FAMILY:
                return TextFontFamilyToolbarOption;
            case ToolbarOptionType.TEXT_COLOR:
                return TextColorToolbarOption;
            case ToolbarOptionType.FILL_COLOR:
                return FillColorToolbarOption;
            case ToolbarOptionType.BACKGROUND_COLOR:
                return BackgroundColorToolbarOption;
            case ToolbarOptionType.EMPTY_PUSH:
                return EmptyPushToolbarOption;
            case ToolbarOptionType.SEPARATOR:
                return SeparatorToolbarOption;
            case ToolbarOptionType.TEXT_ALIGN:
                return TextAlignToolbarOption;
            case ToolbarOptionType.DUPLICATE:
                return DuplicateToolbarOption;
            case ToolbarOptionType.CLIP:
                return ClipToolbarOption;
            case ToolbarOptionType.BORDER_RADIUS:
                return BorderRadiusToolbarOption;
            case ToolbarOptionType.BORDER:
                return BorderToolbarOption;
            case ToolbarOptionType.ORGANISATION:
                return OrganizationToolbarOption;
            case ToolbarOptionType.ALIGN:
                return AlignToolbarOption;
            default:
                return null;
        }
    }

    public getChartRenderByType(type: EditorChartType): React.ElementType<ChartRenderProps> {
        switch (type) {
            case EditorChartType.BARS_VERTICAL:
            case EditorChartType.BARS_MULTIPLE_VERTICAL:
                return VerticalBarsChartRender;
            case EditorChartType.BARS_HORIZONTAL:
            case EditorChartType.BARS_MULTIPLE_HORIZONTAL:
                return HorizontalBarsChartRender;
            case EditorChartType.BARS_TRIPLE_VERTICAL:
                return VerticalBarsStackedChartRender;
            case EditorChartType.BARS_TRIPLE_HORIZONTAL:
                return HorizontalBarsStackedChartRender;
            case EditorChartType.PIE:
                return PieChartRender;
            case EditorChartType.DONUT:
                return DonutChartRender;
            case EditorChartType.CURVE_SINGLE:
            case EditorChartType.CURVE_MULTIPLE:
                return CurveSingleChartRender;
            default:
                throw new Error(`Unknown type of chart '${type}'`);
        }
    }

    public getTableRenderByType(type: EditorTableType): React.ElementType<TableRenderProps> {
        switch (type) {
            case EditorTableType.TABLE_1:
                return Table1Render;
            case EditorTableType.TABLE_2:
                return Table2Render;
            case EditorTableType.TABLE_3:
                return Table3Render;
            case EditorTableType.TABLE_4:
                return Table4Render;
            // case undefined:
            //     return SimpleTableRender;
            default:
                throw new Error(`Unknown type of table '${type}'`);
        }
    }
}

export default new EditorResolver();