import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from 'common/services/Toaster';
import styles from './WelcomeMessagesScreen.module.scss';
import ScreenTitle from 'common/components/screenTitle/ScreenTitle';
import PageHeader from 'common/components/pageHeader/PageHeader';
import Loading from 'common/services/Loading';
import { useHistory, useParams } from 'react-router-dom';
import InputWithError from 'common/components/input/InputWithError';
import FormItem from 'common/components/formItem/FormItem';
import { Controller, useForm } from 'react-hook-form';
import Button from 'common/components/button/Button';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE, MIMETYPES_VIDEO } from 'Config';
import { WelcomeMessageCompanyViewModel, WelcomeMessageViewModel } from '../../api/welcomeMessages/models/WelcomeMessageViewModel';
import WelcomeMessagesService from '../../api/welcomeMessages/WelcomeMessagesService';
import Select from 'common/components/select/Select';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import CompaniesService from 'api/companies/CompaniesService';
import CustomFile from 'common/models/CustomFile';
import RemoveMessage from 'common/components/removeMessage/RemoveMessage';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';

export type OpenDialogType = 'details' | 'edit' | 'new' | 'profile';

type Form = {
    title: string;
    subtitle: string;
    description: string;
    medias: CustomFile[];
    companies: WelcomeMessageCompanyViewModel[];
}

const WelcomeMessagesScreen: React.FC = () => {

    const { t } = useTranslation();
    
    const { register, handleSubmit, errors, setValue, clearErrors, getValues, control } = useForm<Form>({ shouldUnregister: false });

    const { id, type } = useParams<{ id: string, type: string }>();

    const history = useHistory();
    const isDetails = type !== null && type === 'details' ? true : false
    
    const [welcomeMessage, setWelcomeMessage] = useState<WelcomeMessageViewModel>();
    const [companiesOptions, setCompanies] = useState<SelectValueLabel[]>([]);

    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);
    const [itemToRemove, setItemToRemove] = useState<WelcomeMessageViewModel>();
    const [mediaName, setMediaName] = useState<string | null>(null);

    const [companiesInUse, setCompaniesInUse] = useState<string[]>();

    const getData = async () => {
        try {

            Loading.show();

            if (id) {
                const result = await WelcomeMessagesService.getById(id);

                if (result === undefined) {
                    addToast(t('common.messages.error_load_info'), { appearance: 'error' });
                    Logger.error(LOGGER_LOG_TYPE.WELCOME_MESSAGES, `Couldn't get the welcome message with id: ${id}`);
                    navigateTo();
                }

                setValue('title', result.title);
                setValue('subtitle', result.subtitle);
                setValue('description', result.description);
                setValue('companies', result.companies);
                setWelcomeMessage(result);
                setMediaName(result.mediaFileName);
            }

            const companies = await CompaniesService.getAllCompaniesForSelectItem();
            setCompanies(companies.map(x => ({ value: x.id || '', label: x.name || '' })));

            Loading.hide();

        } catch (error) {
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.WELCOME_MESSAGES, `Couldn't get the welcome message with id: ${id}`, error);
            navigateTo();
            Loading.hide();
        }
    }

    const importFile = () => {
        const inputMedias = document.getElementById('uploadFile');
        if (inputMedias) {
            inputMedias.click();
        }
    }

    const uploadFile = async (event: any) => {
        const medias: CustomFile[] = [];
        for (let size = 0; size < event.target.files.length; size++) {
            const file: CustomFile = event.target.files[size];
            medias.push(file);
            setMediaName(file.name);
        }
        setValue('medias', medias);
    }

    const onSubmit = async (form: Form) => {
        try {

            if (!isDetails && !id && (!form.title || !form.medias || (form.medias && form.medias.length == 0))) {
                if(!form.medias || (form.medias && form.medias.length == 0) || !welcomeMessage?.mediaUrl){
                    addToast(t('welcome_messages.message_upload_file'), { appearance: 'warning' });
                } else {
                    addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                }
                return;
            }

            if (!isDetails && id && (!form.title || !welcomeMessage?.mediaUrl)){
                if(!welcomeMessage?.mediaUrl){
                    addToast(t('welcome_messages.message_upload_file'), { appearance: 'warning' });
                } else {
                    addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                }
                return;
            }

            if(form.companies && form.companies.length > 0){
                const companiesInUse = await WelcomeMessagesService.checkCompaniesInUse({ id: id, companies: form.companies });
                if(companiesInUse.length > 0){
                    addToast(t('welcome_messages.companies_in_use', { companies: companiesInUse.join(' , ') }), { appearance: 'warning' });
                    return;
                }
            }
            
            Loading.show();

            const model: WelcomeMessageViewModel = { 
                id: id,
                title: form.title,
                subtitle: form.subtitle,
                description: form.description,
                companies: form.companies
            };

            const resultId = await WelcomeMessagesService.save(model, form.medias ? form.medias : []);
            const result = await WelcomeMessagesService.getById(resultId);
            setWelcomeMessage(result);

            Loading.hide();
            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            navigateTo('details', resultId);
        } catch (error) {
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.WELCOME_MESSAGES, `Couldn't update the welcome message with id: ${id}`, error);
            Loading.hide();
        }
    };

    const navigateTo = (type?: string, id?: string) => {
        if (!type) {
            history.push(`/welcomeMessages`);
            return;
        }
        history.push(`/welcomeMessages/${type}/${id}`);
        return;
    }

    const showRemoveItemDialog = (item: WelcomeMessageViewModel) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();

                await WelcomeMessagesService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                navigateTo();
                getData();
                Loading.hide();
            } catch (error) {
                Logger.error(LOGGER_LOG_TYPE.WELCOME_MESSAGES, `Couldn't remove welcome message`, error);
                addToast(t('common.messages.record_delete_error'), { appearance: 'success' });
                Loading.hide();
            }
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <ScreenTitle title={t('menu.welcome_messages')}>
            <PageHeader title={t('welcome_messages.title')} hideSearchIcon={true}></PageHeader>

            <div className={styles.container}>

                <div className={styles.line}></div>

                <div style={{ minHeight: '511px', position: 'relative', marginTop: '1rem' }}>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <FormItem>
                            <h2 className={styles.label}>{t('welcome_messages.info.title')}</h2>
                            <InputWithError
                                error={errors.title}
                                name="title"
                                placeholder={t('welcome_messages.info.title')}
                                ref={register({ required: true, maxLength: 255 })}
                                disabled={isDetails}
                                defaultValue={getValues('title')}
                                onErrorClick={() => clearErrors('title')}
                            />
                        </FormItem>

                        <FormItem>
                            <h2 className={styles.label}>{t('welcome_messages.info.subtitle')}</h2>
                            <InputWithError
                                error={errors.subtitle}
                                name="subtitle"
                                placeholder={t('welcome_messages.info.subtitle')}
                                ref={register({ maxLength: 255 })}
                                disabled={isDetails}
                                defaultValue={getValues('subtitle')}
                                onErrorClick={() => clearErrors('subtitle')}
                            />
                        </FormItem>

                        <FormItem>
                            <h2>{t('welcome_messages.info.description')}</h2>
                            <InputWithError
                                error={errors.description}
                                name="description"
                                placeholder={t('welcome_messages.info.description')}
                                ref={register({ maxLength: 255 })}
                                disabled={isDetails}
                                defaultValue={getValues('description')}
                                onErrorClick={() => clearErrors('description')}
                            />
                        </FormItem>

                        <FormItem>
                            <h2>{t('welcome_messages.info.companies')}</h2>
                            <Controller
                                render={({ onChange, value }) => {
                                    return (
                                        <Select
                                            maxHeight={'13rem'}
                                            options={companiesOptions}
                                            isClearable
                                            isMulti
                                            isDisabled={isDetails}
                                            placeholder={t('welcome_messages.info.companies')}
                                            onChange={(data: SelectValueLabel[]) => {
                                                onChange(data?.map(x => ({ companyId: x.value })));
                                            }}
                                            value={value ? companiesOptions.filter(x => value.find((y: WelcomeMessageCompanyViewModel) => y.companyId === x.value)) : []}
                                            filterOption={(candidate: any, input: any) => input ? candidate.label.toUpperCase().includes(input.toUpperCase()) : true}
                                        />
                                    );
                                }}
                                control={control}
                                name="companies"
                                defaultValue={getValues('companies')}
                            />
                        </FormItem>

                        <FormItem className={styles.marginTop2}>
                            <h2>{t('welcome_messages.info.video')}</h2>
                            <input type="file" id="uploadFile" hidden name="myVideo" accept={MIMETYPES_VIDEO} className="multiple-upload" onChange={uploadFile} />
                            { !isDetails && <Button onClick={() => importFile()}> {t('welcome_messages.info.load_video')} </Button> }
                            { !isDetails && <span className={styles.fileName}>{mediaName}</span> }
                            { isDetails && <a href={welcomeMessage?.mediaUrl || ''} target={'blank'} className={styles.fileNameDetails}>{mediaName}</a>}
                        </FormItem>

                        <div className={styles.buttonsContainer} >
                            <div className={styles.buttonRemove}>
                                {isDetails && <RemoveMessage
                                    message={(t('common.remove'))}
                                    onElementClick={() => { showRemoveItemDialog({ id: id, mediaId: welcomeMessage?.mediaId } as WelcomeMessageViewModel) }} />}

                                <QuestionYesNo
                                    title={t('common.remove')}
                                    message={t('common.messages.remove_record')}
                                    isVisible={dialogDeleteItemIsOpen}
                                    onYes={() => removeItem()}
                                    onNo={() => setDialogDeleteItemIsOpen(false)} />

                            </div>
                            <div className={styles.buttons}>
                                <Button
                                    type="button"
                                    text={t('common.cancel').toUpperCase()}
                                    preset={'secondary'}
                                    onClick={() => navigateTo()}
                                    className={styles.button}
                                />
                                {isDetails && <Button
                                    type="button"
                                    text={t('common.edit').toUpperCase()}
                                    onClick={() => navigateTo('edit', id)}
                                    className={styles.button}
                                >
                                </Button>}
                                {!isDetails && <Button
                                    type={'submit'}
                                    text={t('common.save').toUpperCase()}
                                    className={styles.button}
                                >
                                </Button>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </ScreenTitle>
    );
}

export default WelcomeMessagesScreen;
