
export enum EditorChartType {
    BARS_VERTICAL = 'BARS_VERTICAL',
    BARS_HORIZONTAL = 'BARS_HORIZONTAL',
    PIE = 'PIE',
    DONUT = 'DONUT',
    CURVE_SINGLE = 'CURVE_SINGLE',
    CURVE_MULTIPLE = 'CURVE_MULTIPLE',
    BARS_MULTIPLE_VERTICAL = 'BARS_MULTIPLE_VERTICAL',
    BARS_MULTIPLE_HORIZONTAL = 'BARS_MULTIPLE_HORIZONTAL',
    BARS_TRIPLE_VERTICAL = 'BARS_TRIPLE_VERTICAL',
    BARS_TRIPLE_HORIZONTAL = 'BARS_TRIPLE_HORIZONTAL',
}

export const EditorChartColumns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];
// export const EditorChartColumns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

export type EditorChartColumnKeys = {
    [key in keyof typeof EditorChartColumns]: string;
}

export type EditorChartTableData = {
    id: string;
    color: string | null;
    name: string;
} & EditorChartColumnKeys;

export interface EditorChartDataValue {
    tableData: EditorChartTableData[];
    type: EditorChartType;
}

export type EditorLineChartData = {
    name: string;
    [key: string]: string;
}

export type EditorPieChartData = {
    name: string;
    value: number;
}

export type EditorChartRowData = {
    name: string;
    color: string;
}

export interface EditorSidebarChartItem {
    icon: any;
    chartType: EditorChartType;
}

export interface EditorSidebarChartTableColumn {
    id: string;
    text: string;
    accessor: string;
    canChangeColor?: (rowIndex: number) => boolean;
    render?: (rowIndex: number) => string;
    canRender?: (rowIndex: number) => boolean;
}
