import React, { useEffect, useRef, useState } from 'react';
import IconUp from 'assets/svgs/Up.svg';
import styles from './YearsSelect.module.scss';
import colors from 'styles/export/colors.module.scss';
import { useTranslation } from 'react-i18next';

export const YearsSelectColorsList = [colors.chartBlue, colors.buttonViewSupport, colors.chartDarkRed];

export const getYearSelectColor = (yearIndex: number) => {
    let tempIndex = yearIndex;
    while (tempIndex > YearsSelectColorsList.length - 1) {
        tempIndex = tempIndex - YearsSelectColorsList.length;
    }
    return YearsSelectColorsList[tempIndex];
}

type Props = {
    options: number[];
    value: number[];
    onChange: (years: number[]) => void;
    maxSelectable?: number;
};

const YearsSelect  = ({ options, onChange, value, maxSelectable = 3 }: Props) => {
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);
    const singleSelect = maxSelectable === 1;

    const onClick = (year: number) => {
        const canSelect = value.length < maxSelectable || singleSelect;
        const isSelected = Boolean(value.find(x => x === year));

        if (!(canSelect || isSelected) || (maxSelectable > 1 && value.length === 1 && value[0] === year)) {
            return;
        }

        if (singleSelect) {
            onChange([year]);
        } else if (isSelected) {
            onChange([...value.filter(y => y !== year)]);
        } else {
            onChange([ ...value, year ]);
        }
    }

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (showOptions && ref.current && !ref.current.contains(e.target)) {
                setShowOptions(false)
            }
        }

        document.addEventListener('mousedown', checkIfClickedOutside)

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside)
        }
    }, [showOptions])

    return (
        <div ref={ref} className={styles.container}>
            <div className={styles.header} style={{ zIndex: showOptions ? 5 : 1 }} onClick={() => setShowOptions(!showOptions)}>
                <span className={styles.headerTitle}>{t('common.years')}</span>
                <img className={styles.headerIcon} src={IconUp} />
            </div>
            {showOptions && <div className={styles.dropdown}>
                {options.map(year => {
                    const canSelect = value.length <= maxSelectable || singleSelect;
                    const isSelected = Boolean(value.find(x => x === year));
                    const index = value.findIndex(x => x === year);
                    return (
                        <div
                            key={`dropdownItem-${year}`}
                            className={styles.dropdownItem}
                            style={(isSelected || canSelect) ? { cursor: 'pointer' } : { cursor: 'no-drop' }}
                            onClick={() => onClick(year)}
                        >
                            <span>{year}</span>
                            <div
                                className={styles.square}
                                style={isSelected && index > -1 ? { backgroundColor: getYearSelectColor(index) } : undefined }
                            ></div>
                        </div>
                    );
                })}
            </div>}
        </div>
    );
}

export default YearsSelect;