import authentication from './authentication/reducer';
import notifications from './notifications/reducer';
import editor from './editor/undoReducer';
import editorControl from './editorControl/reducer';
import analytics from './analytics/reducer';

const reducers = {
	authentication,
	editor,
	editorControl,
	notifications,
	analytics,
};

export default reducers;
