import React, { memo, useCallback, useMemo } from 'react'
import styles from './ChartTable.module.scss';
import EditorChartsUtils from 'screens/editor/utils/EditorChartsUtils';
import ChartTableCell from '../chartTableCell/ChartTableCell';
import { EditorChartTableData, EditorChartType } from 'screens/editor/models/ChartModels';

type Props = {
    chartType: EditorChartType;
    data: EditorChartTableData[];
    onChange: (data: EditorChartTableData[]) => void;
};

const ChartTable = ({ chartType, data, onChange }: Props) => {
    const columns = useMemo(() => EditorChartsUtils.generateChartTableColumns(chartType), [chartType]);

    const onDataChange = useCallback((rowIndex: number, columnId: string, value: string, color: string) => {
        onChange(data.map((row, index) => {
            if (index === rowIndex) {
                return {
                    ...data[rowIndex],
                    [columnId]: value,
                    color,
                }
            }
            return row;
        }));
    }, [data]);

    return (
        <div className={styles.tableContainer}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        {columns.map((column, columnIndex) => (
                            <th key={columnIndex}>
                                {column.text}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={row.id}>
                            {columns.filter(column => !column.canRender || column.canRender(rowIndex)).map((column, columnIndex) => {
                                const value: string = column.render ? column.render(rowIndex) : row[column.accessor as any];
                                return (
                                    <td key={`${rowIndex}-${columnIndex}`}>
                                        <ChartTableCell
                                            onDataChange={onDataChange}
                                            row={row}
                                            rowIndex={rowIndex}
                                            columnId={column.id}
                                            value={value || ''}
                                            canChangeColor={column.canChangeColor && column.canChangeColor(rowIndex) ? true : false}
                                            chartType={chartType}
                                        />
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default memo(ChartTable);