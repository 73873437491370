import React, { CSSProperties, ReactElement } from 'react';
import styles from './ListingTable.module.scss';
import { Property } from 'csstype'
import Badge from '../../../common/components/badge/Badge';
import DateFormat from '../dateFormat/dateFormat';
import CheckBox from '../checkBox/CheckBox';
import { useTranslation } from 'react-i18next';

export interface ListingTableCell {
    column: ListingTableColumn;
    row: ListingTableRow;
}

export interface ListingTableColumn {
    field: string;
    name?: string;
    renderCell?: (cell: ListingTableCell) => ReactElement;
    cellAlignment?: Property.TextAlign;
    cellStyle?: CSSProperties;
    columnCellAlignment?: Property.TextAlign;
    width?: 'fill' | number;
    preventClick?: boolean;
    type?: string;
}

export interface ListingTableRow {
    [field: string]: any;
}

interface Props {
    columns: ListingTableColumn[];
    rows: ListingTableRow[];
    allowHover?: boolean;
    onRowClick?: (row: ListingTableRow) => void;
    striped?: boolean;
}

const ListingTable: React.FunctionComponent<Props> = ({ columns, rows, allowHover, striped, onRowClick }: Props) => {

    const { t } = useTranslation();

    const getRowCellValue = (row: ListingTableRow, col: ListingTableColumn) => {
        if (col.renderCell) {
            return col.renderCell({ row, column: col });
        }

        const value = row[col.field];

        if (col.type == 'date' && !!value) {
            return <DateFormat value={value}></DateFormat>
        }
        else {
            return value;
        }
    }

    const onClick = (row: ListingTableRow, preventClick: boolean) => {
        if (preventClick) return;

        if (onRowClick) {
            onRowClick(row);
        }
    }

    const columnLength = columns.length;

    return (
        <table className={styles.table}>
            <tbody>
                <tr>
                    {columns.map((col, colIndex) => (
                        <td style={{ width: col && col.width && col.width != 'fill' ? col.width + '%' : col.width }} key={`col-${colIndex}`} className={`${styles.headerRow} ${styles.noHeight}`}>
                            <div className={` ${styles.column} `}>
                                <span className={` ${styles.columnCell} ${columnLength !== (colIndex + 1) ? styles.fullBorderRight : ''} ${colIndex === 0 ? styles.extraLeftPadding : ''} ${colIndex === (columnLength - 1) ? styles.extraRightPadding : ''}`}>{col.name}</span>
                            </div>
                        </td>
                    ))}
                </tr>
                {rows.map((row, rowIndex) => (
                    <tr key={`row-${rowIndex}`} className={`${allowHover && styles.rowHover}`}>
                        {columns.map((col, colIndex) => (
                            <td key={`col-${colIndex}`} onClick={() => onClick(row, (col?.preventClick ?? false))} className={` ${styles.noHeight} `}>
                                <div className={` ${styles.column} ${striped && rowIndex % 2 === 0 ? styles.dark : ''} ${(columnLength == 1 ? styles.columnSingle : (colIndex === 0 ? styles.columnLeft : (colIndex === (columnLength - 1) ? styles.columnRight : styles.columnMiddle)))}`}>
                                    {(!col.type || col.type && (col.type !== 'status' && col.type !== 'checkbox')) && <>
                                        <div className={` ${styles.columnCell} ${columnLength !== (colIndex + 1) ? styles.dashedBorderRight : ''} ${colIndex === 0 ? styles.extraLeftPadding : ''} `}>
                                            <span className={`${col.type === 'role' ? styles.capitalize : ''} `} >
                                                {getRowCellValue(row, col)}
                                            </span>
                                        </div>
                                    </>}
                                    {col.type && col.type === 'status' && <>
                                        <span className={` ${styles.columnCell} ${columnLength !== (colIndex + 1) ? styles.dashedBorderRight : ''}`}>
                                            {<Badge color={getRowCellValue(row, col)}>{getRowCellValue(row, columns[0])}</Badge>}
                                        </span></>}
                                    {col.type && col.type === 'checkbox' && <>
                                        <span className={` ${styles.columnCell} ${columnLength !== (colIndex + 1) ? styles.dashedBorderRight : ''}`}>
                                            {<CheckBox checked={getRowCellValue(row, col)} disabled={true}></CheckBox>}
                                        </span></>}
                                </div>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
            {Boolean(rows.length <= 0) && <tfoot>
                <tr>
                    <td colSpan={columns.length} className={styles.noItemsText}>
                        {t('common.no_results')}
                    </td>
                </tr>
            </tfoot>}
        </table>
    );
}

export default ListingTable;