import React, { useEffect } from 'react';
import styles from './Pagination.module.scss';
import ReactPaginate from 'react-paginate';
import {  FaChevronLeft, FaChevronRight } from 'react-icons/fa';

export const DEFAULT_PAGINATION_ITEMS_PER_PAGE = 20;
export const MAX_ITEMS_PER_PAGE = Number.MAX_SAFE_INTEGER;

type Props = {
    currentPage: number;
    itemsPerPage?: number,
    totalItems: number
    onChange: (page: number) => void;
}

const Pagination: React.FunctionComponent<Props> = ({ currentPage, itemsPerPage = DEFAULT_PAGINATION_ITEMS_PER_PAGE, totalItems, onChange }: Props) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    useEffect(() => {
        if (totalPages < currentPage) {
            onChange(totalPages);
        }
    }, [totalItems]);

    return (
        <ReactPaginate
            previousLabel={<FaChevronLeft/>}
            nextLabel={<FaChevronRight/>}
            breakLabel={'...'}
            breakClassName={styles.page}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={page => onChange(page.selected + 1)}
            containerClassName={styles.container}
            pageClassName={styles.page}
            activeClassName={styles.currentPage}
            nextClassName={styles.direction}
            previousClassName={styles.direction}
            disabledClassName={styles.directionDisabled}
            forcePage={currentPage - 1}
        />
    );
}

export default Pagination;