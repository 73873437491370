import React from 'react';
import Utils from '../../services/Utils';

type Props = {
    value: string;
    fill?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const RenderSVG: React.FC<Props> = ({ value, fill, ...props }: Props) => {
    const id = Utils.newGuid();
    const className = `svg-${id}`;
    const widthHeightValues = '100%';

    if (value && value.startsWith && value.startsWith('http')) {
        return <img src={value} style={{ width: widthHeightValues, height: widthHeightValues }} />
    }

    // Set svg width & heigth to fix document thumbnail
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, 'application/xml');
    doc.documentElement.setAttribute('width', '1')
    doc.documentElement.setAttribute('height', '1')

    const title = doc.documentElement.getElementsByTagName('title');
    if (title && title.length > 0) {
        for (let i = 0; i < title.length; i++) {
            const titleElem = title[i];
            titleElem.remove();
        }
    }

    const serializer = new XMLSerializer();
    value = serializer.serializeToString(doc);

    return (<div
        {...props}
        className={className}
        dangerouslySetInnerHTML={{
            __html: `
                <style>
                    .${className} svg {
                        width: ${widthHeightValues};
                        height: ${widthHeightValues};
                        ${fill ? `fill: ${fill};` : ''}
                    }
                </style>
                ${value ? value : 'error'}
            `
        }}
    />);
}

export default RenderSVG;
