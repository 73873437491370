import React from 'react';
import styles from './EditorError.module.scss';
import IconError from '../../../../assets/svgs/cross-red-outlines.svg';
import { useTranslation } from 'react-i18next';
import Button from '../../../../common/components/button/Button';
import { useHistory } from 'react-router-dom';

type Props = {
};

const EditorError: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <img src={IconError} className={styles.icon} />
                <span className={styles.title}>{t('editor.error_title')}</span>
                <span className={styles.message}>{t('editor.error_message')}</span>
                <Button text={t('common.go_back')} className={styles.goBackButton} onClick={() => history.push('/')} />
            </div>
        </div>
    );

}

export default EditorError;
