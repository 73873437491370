import React from 'react';
import IconTrash from 'assets/svgs/toolbar/Trash.svg';
import { useDispatch } from 'react-redux';
import { removePage, removePageElements } from 'store/editor/action';
import { ToolbarOptionProps } from '../../../../utils/EditorProps';
import ToolbarButton from '../toolbarButton/ToolbarButton';
import { useTranslation } from 'react-i18next';

const DeleteToolbarOption: React.FC<ToolbarOptionProps> = ({ page, elements, className }: ToolbarOptionProps) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const deleteElement = () => {
        if (page && elements && elements.length > 0) {
            dispatch(removePageElements(page, elements));
        } else if (page) {
            dispatch(removePage(page));
        }
    }
    
    return (
        <ToolbarButton
            className={className}
            text={t('editor.toolbar.delete')}
            onClick={deleteElement}
            icon={IconTrash}
            color="danger"
        />
    );
}

export default DeleteToolbarOption;
