import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PhotosContent.module.scss';
import Searchbar from 'common/components/searchbar/Searchbar';
import BoxCategory from 'common/components/boxCategory/BoxCategory';
import Masonry from 'react-masonry-css'
import DraggableContentItem from '../draggableContentItem/DraggableContentItem';
import EditorElementGenerator from '../../../../utils/EditorElementGenerator';
import EditorUtils from '../../../../utils/EditorUtils';
import { PageElement, PageElementType } from '../../../../models/EditorModels';
import Utils from 'common/services/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { updatePageElement } from 'store/editor/action';
import store from 'store/store';
import UnsplashService from 'api/unsplash/UnsplashService';
import Loading from 'common/services/Loading';
import InfinityScroll from 'common/components/infinityScroll/InfinityScroll';
import { PaginationParams as UnsplashPaginationParams } from 'unsplash-js/dist/types/request';
import ScrollShadow from 'common/components/scrollShadow/ScrollShadow';
import { useDebouncedCallback } from 'use-debounce';
import { FaEllipsisH } from 'react-icons/fa';
import Popover from 'common/components/popover/Popover';
import IconSaveDanger from 'assets/svgs/Cross-danger.svg';
import MediasService from 'api/medias/MediasService';
import { MediaSearchCriteria } from 'api/medias/models/MediaSearchCriteria';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import IconClose from 'assets/svgs/Cross-bleu2.svg';

interface ImageItem {
    id: string;
    url: string;
    unsplashUrlToTrack: string | null;
    thumbUrl?: string;
    width?: number;
    height?: number;
    description: string | null;
    author?: string | null;
    authorUsername: string | null;
}

const PhotosContent: React.FC = () => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState<string>('');
    const dispatch = useDispatch();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);


    const [unsplashCriteria, setUnsplashCriteria] = useState<UnsplashPaginationParams>({ page: 1, perPage: 30 });
    const [unsplashImages, setUnsplashImages] = useState<ImageItem[]>([]);
    const [unsplashTotalPages, setUnsplashTotalPages] = useState<number>(1);
    const [unsplashError, setUnsplashError] = useState(false);
    const [tryingUnsplash, setTryingUnsplash] = useState(false);

    const [yourPhotosCriteria, setYourPhotosCriteria] = useState<MediaSearchCriteria>({ page: 1, itemsPerPage: 30, companyId: userProfile?.companyId ?? undefined });
    const [yourPhotos, setYourPhotos] = useState<ImageItem[]>([]);
    const [yourPhotosTotalPages, setYourPhotosTotalPages] = useState<number>(1);

    const debounced = useDebouncedCallback(value => {
        setSearchValue(value);
        setUnsplashImages([]);
        setYourPhotos([]);

        setUnsplashCriteria({
            ...unsplashCriteria,
            page: 1
        });

        setYourPhotosCriteria({
            ...yourPhotosCriteria,
            page: 1
        });
    }, 500);

    const onChangeText = (value: string) => {
        debounced(value);
        setSearchValue && setSearchValue(value);
    }

    const onYourPhotoAddClick = () => {
        window.open('/medias', '_blank');
    }

    const loadYourPhotos = async () => {
        try {
            let res;
            let totalPages;
            if (searchValue) {
                yourPhotosCriteria.name = searchValue;

                res = await MediasService.getList(yourPhotosCriteria);
                if (res.items) { totalPages = res.totalPages; }

            } else {
                yourPhotosCriteria.name = searchValue;
                res = await MediasService.getList(yourPhotosCriteria);
                if (res.items) { totalPages = res.totalPages; }
            }

            const images: ImageItem[] = (res?.items || []).map(r => ({
                id: Utils.newGuid(),
                url: r.url,
                unsplashUrlToTrack: null,
                thumbUrl: r.thumbnailUrl,
                height: r.height,
                width: r.width,
                description: r.fileName,
                author: r.createdByName,
                authorUsername: r.createdByName,
            }));
            setYourPhotos([
                ...yourPhotos,
                ...images
            ]);
            setYourPhotosTotalPages(totalPages || 1);

            Loading.hide();
        } catch (error) {
            Loading.hide();
        }
    }

    const loadUnsplashPhotos = async () => {
        try {
            let res;
            let totalPages;
            if (searchValue) {
                res = await UnsplashService.searchPhotos(searchValue, unsplashCriteria);
                if (res.type == 'success') { totalPages = res.response.total_pages; }
            } else {
                res = await UnsplashService.listPhotos(unsplashCriteria);
                if (res.type == 'success') { totalPages = res.response.total; }
            }

            if (res.status != 200) {
                const errorMessage = res.errors && res.errors.length > 0 ? res.errors[0] : undefined;
                throw new Error(errorMessage);
            }

            const images: ImageItem[] = (res.response?.results || []).map(r => ({
                id: Utils.newGuid(),
                url: r.urls.regular,
                unsplashUrlToTrack: r.links.download_location,
                thumbUrl: r.urls.thumb,
                height: r.height,
                width: r.width,
                description: r.description,
                author: r.user.name,
                authorUsername: r.user.username,
            }));
            setUnsplashImages([
                ...unsplashImages,
                ...images
            ]);
            setUnsplashTotalPages(totalPages || 1);
            setUnsplashError(false);
            setTryingUnsplash(false);

            Loading.hide();
        } catch (error) {
            setUnsplashError(true);
            setTryingUnsplash(false);
            Loading.hide();
        }
    }

    const retryUnsplash = () => {
        setTryingUnsplash(true);
        setTimeout(() => loadUnsplashPhotos(), 100);
    }

    const createImageElement = (imageItem: ImageItem): PageElement => {
        if (imageItem.unsplashUrlToTrack) {
            UnsplashService.trackDownload(imageItem.unsplashUrlToTrack);
        }

        return EditorElementGenerator.createImageElement(
            imageItem.url,
            (imageItem?.width ? imageItem.width : 0),
            (imageItem?.height ? imageItem.height : 0),
            imageItem.thumbUrl || null,
        );
    }

    const onImageClick = (image: ImageItem) => {
        const [selectedElement, selectedPage] = EditorUtils.getFirstSelectedElementFromState(store.getState().editor.present);

        if (selectedElement && selectedPage && selectedElement.type === PageElementType.IMAGE) {
            if (image.unsplashUrlToTrack) {
                UnsplashService.trackDownload(image.unsplashUrlToTrack);
            }

            dispatch(updatePageElement(selectedPage, {
                ...selectedElement,
                value: image.url,
                width: image.width ? image.width : 0,
                height: image.height ? image.height : 0,
            }));
        }
    }

    const renderImagesList = (images: ImageItem[]) => (

        <ScrollShadow scrollId="scrollImages" className={styles.scrollContentContainer} scrollClassName={styles.scrollContent}>
            <InfinityScroll
                inModal={true}
                totalItems={images.length}
                executeOnScroll={() => {
                    setYourPhotosCriteria({
                        ...yourPhotosCriteria,
                        page: (yourPhotosCriteria.page ?? 1) + 1
                    });
                }}
                continueScroll={yourPhotosTotalPages > (yourPhotosCriteria.page ?? 1)}
                elementIdToScroll="scrollImages"
            >
                <Masonry
                    breakpointCols={3}
                    className={styles.masonryGrid}
                    columnClassName={styles.masonryGridColumn}
                >
                    {images.map((image, i) => {
                        return (
                            <DraggableContentItem key={i} generateElement={() => createImageElement(image)}>
                                <div className={styles.imageItem} onClick={() => onImageClick(image)}>
                                    <Popover
                                        positions={['right']}
                                        content={() => (
                                            <div>
                                                <h1 className={styles.imagePopoverTitle}>{image.description || t('editor.sidebar.no_description')}</h1>
                                            </div>
                                        )}
                                        contentContainerClassName={styles.imagePopover}
                                    >
                                        {(_, setIsOpen) => (
                                            <div className={styles.imageMenuButton} onClick={() => setIsOpen(true)}><FaEllipsisH /></div>
                                        )}
                                    </Popover>

                                    <img src={image.thumbUrl || image.url} />
                                </div>
                            </DraggableContentItem>
                        );
                    })}
                </Masonry>

            </InfinityScroll>
        </ScrollShadow>
    );

    const renderUnsplashImagesList = (images: ImageItem[]) => (
        <ScrollShadow scrollId="scrollUnsplash" className={styles.scrollContentContainer} scrollClassName={styles.scrollContent}>
            <InfinityScroll
                inModal={true}
                totalItems={images.length}
                executeOnScroll={() => {
                    setUnsplashCriteria({
                        ...unsplashCriteria,
                        page: (unsplashCriteria.page ?? 1) + 1
                    });
                }}
                continueScroll={unsplashTotalPages > (unsplashCriteria.page ?? 1)}
                elementIdToScroll="scrollUnsplash"
            >
                {!unsplashError
                    ? <Masonry
                        breakpointCols={3}
                        className={styles.masonryGrid}
                        columnClassName={styles.masonryGridColumn}
                    >
                        {images.map((image, i) => {
                            return (
                                <DraggableContentItem key={i} generateElement={() => createImageElement(image)}>
                                    <div className={styles.imageItem} onClick={() => onImageClick(image)}>
                                        <Popover
                                            positions={['right']}
                                            content={() => (
                                                <div>
                                                    <h1 className={styles.imagePopoverTitle}>{image.description || t('editor.sidebar.no_description')}</h1>
                                                    <p className={styles.imagePopoverAuthor}>
                                                        {t('editor.sidebar.unsplash_photo_by')} <a href={`https://unsplash.com/@${image.authorUsername || '#'}?utm_source=your_app_name&utm_medium=referral`} target="_blank" rel="noreferrer">{image.author}</a> on <a href="https://unsplash.com/?utm_source=otto&utm_medium=referral" target="_blank" rel="noreferrer">Unsplash</a>
                                                    </p>
                                                </div>
                                            )}
                                            contentContainerClassName={styles.imagePopover}
                                        >
                                            {(_, setIsOpen) => (
                                                <div className={styles.imageMenuButton} onClick={() => setIsOpen(true)}><FaEllipsisH /></div>
                                            )}
                                        </Popover>

                                        <img src={image.thumbUrl || image.url} />
                                    </div>
                                </DraggableContentItem>
                            );
                        })}
                    </Masonry>
                    : <div className={styles.loadError}>
                        <img src={IconSaveDanger} className={styles.loadErrorIcon} />
                        <div className={styles.loadErrorText}>
                            <span>{t('editor.sidebar.error_connecting_unsplash')}</span>
                            <a
                                onClick={retryUnsplash}
                                className={`${styles.tryAgainLink} ${tryingUnsplash && styles.tryAgainLinkDisabled}`}
                            >
                                {t('common.try_again')}
                            </a>
                        </div>
                    </div>
                }
            </InfinityScroll>
        </ScrollShadow>
    );

    useEffect(() => {
        loadUnsplashPhotos();
    }, [unsplashCriteria]);

    useEffect(() => {
        loadYourPhotos();
    }, [yourPhotosCriteria]);

    return (
        <div className={styles.container}>
            <Searchbar
                value={searchValue ? searchValue : ''}
                placeholder={t('editor.sidebar.photos_searchbar_placeholder')}
                onChange={onChangeText}
                icon={searchValue ? IconClose : ''}
            />
            <div className={styles.boxCategoryContainer}>
                <BoxCategory
                    title={t('editor.sidebar.your_photos')}
                    onAddClick={onYourPhotoAddClick}
                    className={styles.boxCategory}
                >
                    {renderImagesList(yourPhotos)}
                </BoxCategory>
                <BoxCategory
                    title={t('editor.sidebar.unsplash_photos')}
                    className={styles.boxCategory}
                >
                    {renderUnsplashImagesList(unsplashImages)}
                </BoxCategory>
            </div>
        </div>
    );

}

export default PhotosContent;

