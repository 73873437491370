import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './VerticalBarsChartWrapper.module.scss';
import { EditorChartDataValue } from 'screens/editor/models/ChartModels';
import VerticalBarsChart from '../charts/VerticalBarsChart';
import YearsSelect from '../yearsSelect/YearsSelect';
import { useWindowSize } from '@react-hook/window-size';

type Props = {
    title: string;
    data: EditorChartDataValue;
    extraMargin?: boolean;
    withoutLegend?: boolean;
    showLabel?: boolean;
    maxSize?: number;
    selectYear?: boolean;
    yearsList?: number[];
    selectedYears?: number[];
    onSelectYears?: (year: number[]) => void;
    maxSelectableYears?: number;
};

const VerticalBarsChartWrapper: React.FC<Props> = ({
    data, title, extraMargin, withoutLegend, showLabel, maxSize, selectYear, yearsList,
    onSelectYears, selectedYears, maxSelectableYears
}: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const [windowWidth, windowHeight] = useWindowSize();

    const [containerWidth, setContainerWidth] = useState<number>(0);
    const [containerHeight, setContainerHeight] = useState<number>(0);
    
    const setDimensions = () => {
        if (!ref.current) return;
        const { width, height } = ref.current?.getBoundingClientRect() || { width: 0, height: 0 };
        setContainerWidth((width - 60 > 0) ? width - 60 : width);
        setContainerHeight((height > 300) ? height - 60 : 250);
    };

    // const handleResize = useCallback(() => {
    //     setDimensions();
    // }, [setDimensions])

    useEffect(() => {
        setTimeout(() => { setDimensions(); }, 200);
    }, [windowWidth, windowHeight]);

    // useEffect(() => {
    //     setDimensions();

    //     window.addEventListener('resize', handleResize);

    //     return function cleanupListener() {
    //         window.removeEventListener('resize', handleResize)
    //     }
    // }, [handleResize]);

    return (
        <div ref={ref} className={styles.container}>
            <div className={styles.titleContainer}>
                <div>{title}</div>
                {selectYear && onSelectYears && <div className={styles.yearSelectContainer}>
                    <YearsSelect
                        options={yearsList ?? []}
                        onChange={onSelectYears}
                        value={selectedYears || []}
                        maxSelectable={maxSelectableYears}
                    />
                </div>}
            </div>
            <VerticalBarsChart
                data={data}
                width={containerWidth}
                height={containerHeight}
                extraMargin={extraMargin}
                withoutLegend={withoutLegend}
                showLabel={showLabel}
                maxSize={maxSize}
            />
        </div>
    );
}

export default VerticalBarsChartWrapper;
