
import { Action, EditorControlState, SET_AUTO_SAVE, SET_CLIP_ACTIVE_ELEMENT_ID, SET_IS_TEXT_ELEMENT_IN_EDITING_MODE } from './type';

const initialState: EditorControlState = {
    isTextElementInEditingMode: false,
    autoSave: null,
    clipActiveElementId: null,
};

function rootReducer(state: EditorControlState = initialState, action: Action): EditorControlState {
    switch (action.type) {
        
        case SET_IS_TEXT_ELEMENT_IN_EDITING_MODE:
            return {
                ...state,
                isTextElementInEditingMode: action.isTextElementInEditingMode,
            }

        case SET_AUTO_SAVE:
            return {
                ...state,
                autoSave: action.autoSave,
            };

        case SET_CLIP_ACTIVE_ELEMENT_ID:
            return {
                ...state,
                clipActiveElementId: action.clipActiveElementId,
            };

        default:
            return state;
    }
}

export default rootReducer;
