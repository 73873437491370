import React from 'react';
import RCSlider, { SliderProps as RcSliderProps } from 'rc-slider';
import colors from './../../../styles/export/colors.module.scss';

export type SliderProps = {
} & RcSliderProps;

const Slider: React.FC<SliderProps> = ({ ...props }: SliderProps) => {
    return (
        <RCSlider
            style={{
                height: 21,
            }}
            railStyle= {{
                backgroundColor: colors.successLight,
                height: 10,
            }}
            trackStyle={{
                backgroundColor: colors.success,
                height: 10,
            }}
            handleStyle={{
                border: 0,
                backgroundColor: colors.success,
                boxShadow: `0px 9px 40px rgba(${colors.success}, 0.15)`,
                height: 20,
                width: 20,
                marginLeft: -10,
                marginRight: -10,
                marginTop: -5,
            }}
            {...props}
        />
    );

}

export default Slider;
